<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary"
        v-bind="attrs"
        v-on="on">
        <v-icon class="mr-2">mdi-upload</v-icon>
        Upload Media Files</v-btn>
    </template>

    <v-card>
      <v-card-title><span class="text-h5">Video Upload</span></v-card-title>
      <v-card-text style="padding-top: 20px;">
        <v-alert type="error"
          v-show="uploadingError">
          An error occurred during upload</v-alert>

        <v-file-input
          v-model="files"
          label="Upload Files"
          multiple
          outlined
          :show-size="1000"
          accept="video/*">
        </v-file-input>
      </v-card-text>

      <v-divider></v-divider>
      <v-progress-linear
        v-model="uploadProgress"
        v-show="uploading"
        color="light-green lighten-1"></v-progress-linear>

      <v-card-actions>
        <v-btn
          text
          :disabled="uploading"
          @click="close()">
          Close</v-btn>
        <v-btn
          text
          class="ml-auto"
          color="primary"
          :disabled="files.length === 0 || uploading"
          @click="uploading = true; upload()">
          {{uploadText}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Api from '@/api/api.js';
  import Events from "@/events/events";

  const XMultipleFileUpload = {
    mounted() {
      this.projectId = this.$route.params.projectId;
    },

    data() {
      return {
        projectId: -1,
        dialog: false,

        files: [],
        uploading: false,
        uploadingStage: 0,
        uploadingError: false,
      }
    },

    computed: {
      uploadText() {
        return this.uploading ? 'Uploading...' : 'Upload';
      },

      uploadProgress() {
        return this.uploadingStage * 100 / this.files.length;
      },
    },

    methods: {
      // Recursive function to upload files
      // Runs
      upload() {
        // Check if index is out of bounds
        if (this.uploadingStage > this.files.length - 1) {
          // Emit event to indicate that upload is complete
          console.log('XMultipleFileUpload:upload(DONE)');
          this.$emit('uploaded', true);
          Events.$emit('updateMedia');
          this.close(true);
          return;
        }

        // Upload the next file
        const file = this.files[this.uploadingStage];
        Api.Media.multipleMediaUploadSave(this.projectId, file).then(() => {
          // Recall with next file
          this.uploadingStage++;
          this.upload();
        }).catch(err => {
          console.log('XMultipleFileUpload:upload(ERR)', err);
          this.resetState(true);
        });
      },

      close(files = false) {
        this.dialog = false;

        // Wait for the modal animation to close before resetting the state
        setTimeout(() => this.resetState(files), 300);
      },

      resetState(files = false) {
        if (files) this.files = [];
        this.uploading = false;
        this.uploadingStage = 0;
        this.uploadingError = false;
      },
    }
  };

  export default XMultipleFileUpload;
</script>

<style lang="scss"></style>