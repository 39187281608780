<template>
  <div class="users__container">
    <div class="px-6 py-4 table__container overflow-auto">
      <x-expand-alert
        type="success"
        ref="alert"
        :dismissible="true"
      ></x-expand-alert>

      <v-data-table
        :headers="headers"
        :items="filteredData"
        :loading="loading"
        no-data-text="No Users found!"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
        dense
        class="accent-border admin-user-table"
      >
        <!-- Table Header -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title><h2>Users</h2></v-toolbar-title>

            <!-- Add User Button -->
            <v-btn
              color="secondary"
              @click="openModal()"
              :disabled="loading"
              class="ml-auto mr-6"
            >
              Add User</v-btn
            >

            <!-- Advanced Filters Button -->
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loading"
                >
                  Filters <v-spacer></v-spacer>
                  <v-icon class="ml-2" small>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <!-- Filters dropdown -->
              <v-list class="condensed-list">
                <v-list-item
                  dense
                  v-for="(item, index) in filterableHeaders"
                  :key="index"
                  link
                >
                  <v-list-item-title>
                    <v-dialog max-width="325px">
                      <template v-slot:activator="{ on, popout }">
                        <div
                          class="d-flex align-center"
                          v-bind="popout"
                          v-on="on"
                        >
                          {{ item.text }}
                          <v-icon color="primary" class="ml-auto"
                            >mdi-chevron-right</v-icon
                          >
                        </div>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title class="headline"
                            >{{ item.text }} Filters</v-card-title
                          >

                          <v-card-text style="padding: 16px">
                            <div
                              class="filters-popout"
                              v-if="FC.EQ_FILTERS.includes(item.type)"
                            >
                              <span>Show items with a value that:</span>

                              <div class="d-flex align-center">
                                <v-text-field
                                  v-model="
                                    filterableHeaders[index].filters.first
                                  "
                                  label="First Equation"
                                  outlined
                                  dense
                                  :hide-details="true"
                                ></v-text-field>
                                <div
                                  v-if="item.type === FC.FILTER_EQUATION_T"
                                  class="filter-datetime-picker__container"
                                >
                                  <v-btn icon>
                                    <v-icon>mdi-calendar</v-icon>
                                  </v-btn>
                                  <div class="filter-datetime-picker">
                                    <v-datetime-picker
                                      v-model="firstEquationDate"
                                      label="Select date"
                                      @input="
                                        () =>
                                          (filterableHeaders[
                                            index
                                          ].filters.first +=
                                            firstEquationDate + ')')
                                      "
                                      date
                                    >
                                      <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                      </template>
                                      <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                      </template>
                                    </v-datetime-picker>
                                  </div>
                                </div>
                              </div>

                              <v-radio-group
                                v-model="
                                  filterableHeaders[index].filters.operator
                                "
                                row
                              >
                                <v-radio :label="'And'" value="and"></v-radio>
                                <v-radio :label="'Or'" value="or"></v-radio>
                              </v-radio-group>

                              <div class="d-flex align-center">
                                <v-text-field
                                  v-model="
                                    filterableHeaders[index].filters.second
                                  "
                                  label="Second Equation"
                                  outlined
                                  dense
                                  :hide-details="true"
                                ></v-text-field>
                                <div
                                  v-if="item.type === FC.FILTER_EQUATION_T"
                                  class="filter-datetime-picker__container"
                                >
                                  <v-btn icon>
                                    <v-icon>mdi-calendar</v-icon>
                                  </v-btn>
                                  <div class="filter-datetime-picker">
                                    <v-datetime-picker
                                      v-model="secondEquationDate"
                                      label="Select date"
                                      @input="
                                        () =>
                                          (filterableHeaders[
                                            index
                                          ].filters.first +=
                                            secondEquationDate + ')')
                                      "
                                      date
                                    >
                                      <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                      </template>
                                      <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                      </template>
                                    </v-datetime-picker>
                                  </div>
                                </div>
                              </div>

                              <v-btn
                                text
                                @click="equationInfo = !equationInfo"
                                style="margin-top: 16px"
                              >
                                How to Use<v-icon>{{
                                  equationInfo
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                }}</v-icon>
                              </v-btn>
                            </div>

                            <div
                              class="filters-popout"
                              v-if="item.type === FC.FILTER_BINARY"
                            >
                              <span>Choose which values display:</span>

                              <v-checkbox
                                v-model="filterableHeaders[index].filters.true"
                                :label="'True'"
                                :hide-details="true"
                                style="margin: 0; padding: 0"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="filterableHeaders[index].filters.false"
                                :label="'False'"
                                :hide-details="true"
                                style="margin: 0; padding: 0"
                              ></v-checkbox>
                            </div>
                          </v-card-text>

                          <!-- Equation Instructions -->
                          <v-expand-transition
                            v-if="item.type !== FC.FILTER_BINARY"
                          >
                            <div v-show="equationInfo">
                              <v-card-text>
                                <p>
                                  All equations are formatted like:
                                  <i>"operator(value)"</i> where operator is an
                                  operator (list below) and value is the value
                                  you want to compare to.
                                </p>
                                <p>
                                  Use <i>"not operator(value)"</i> to invert an
                                  operator.
                                </p>
                                <p>
                                  For example: <i>"equals(123)"</i> would only
                                  show values equal to "123".
                                  <i>"not equals(123)"</i> would show everything
                                  that doesn't equal 123.
                                </p>
                                <p>
                                  Picking "and/or" just tests the first equation
                                  against the second. If an equation is invalid
                                  it wont do any filtering.
                                </p>
                                <p v-if="item.type === FC.FILTER_EQUATION_T">
                                  <b>Dates</b>: If you pick a time in a
                                  date/time picker, it will copy it to your
                                  clipboard to easily paste into an equation.
                                </p>
                                <p><b>Operators:</b></p>
                                <ul
                                  v-for="(op, opIndex) in FC.validOperations(
                                    item.type
                                  )"
                                  :key="opIndex"
                                >
                                  <li>{{ op.op }}(): {{ op.help }}</li>
                                </ul>
                              </v-card-text>
                            </div>
                          </v-expand-transition>

                          <v-card-actions>
                            <v-btn
                              text
                              class="ml-auto"
                              color="secondary"
                              @click="filterData(), (dialog.value = false)"
                              >Filter</v-btn
                            >
                            <v-btn
                              text
                              @click="
                                resetAdvancedFilter(index),
                                  (dialog.value = false)
                              "
                              >Clear</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Clear Button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  v-on="on"
                  v-bind="attrs"
                  :disabled="loading || !data.length"
                  @click="initFilters()"
                >
                  <v-icon>mdi-cancel</v-icon></v-btn
                >
              </template>
              <span>Clear Filters</span>
            </v-tooltip>
          </v-toolbar>
        </template>

        <!-- Table Items -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <div class="d-flex justify-center">
                <v-btn
                  icon
                  color="secondary"
                  @click="editItem(item)"
                  :disabled="loading"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>

                <v-btn
                  mx-5
                  icon
                  color="secondary"
                  @click="deleteItemConfirm(item)"
                  :disabled="loading"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>

                <v-btn
                  icon
                  :color="item.isLockedOut ? 'secondary' : 'green lighten-1'"
                  @click="lockState(item.userKey)"
                  :disabled="loading"
                >
                  <v-icon>{{
                    item.isLockedOut ? "mdi-lock" : "mdi-lock-open"
                  }}</v-icon>
                </v-btn>
              </div>
            </td>

            <td>{{ item.userName }}</td>
            <td>{{ item.emailAddress }}</td>
            <td>{{ item.roles }}</td>
            <td>{{ item.lastLoginDatePretty }}</td>
            <td>{{ item.passwordExpirationPretty }}</td>
            <td>{{ item.accountExpirationPretty }}</td>
            <td>{{ item.isRegistrationComplete ? "YES" : "NO" }}</td>
            <td>
              <v-btn
                text
                v-if="!item.isRegistrationComplete"
                :disabled="item.sending"
                @click="sendVerificationEmail(item.userKey)"
              >
                {{ item.sending ? "Sending..." : "Resend Registration Email" }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- Add User Dialog -->
    <v-dialog
      v-model="dialogUser"
      persistent
      max-width="900px"
      scrollable
      height="600px"
    >
      <v-card :loading="loadingEdit || loadingCreate">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-divider />

        <v-card-text style="min-height: 500px">
          <x-expand-container>
            <v-form v-model="dialogForm" ref="diagform" class="mt-4">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.userName"
                    label="Username*"
                    outlined
                    required
                    :rules="required"
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.emailAddress"
                    label="Email*"
                    outlined
                    required
                    :rules="emailRules.concat(required)"
                  ></v-text-field>

                  <v-select
                    v-model="editedItem.securityRoleKey"
                    :items="userRolesList"
                    label="Security Role Key*"
                    item-text="title"
                    item-value="roleKey"
                    @change="setRoles"
                    outlined
                    required
                    :rules="required"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.passwordExpiration"
                    label="Password Exp*"
                    type="date"
                    outlined
                    required
                    :rules="validDate"
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.accountExpiration"
                    label="Account Exp*"
                    type="date"
                    outlined
                    required
                    :rules="validDate"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedOrganization"
                    :items="this.mappedCustomers"
                    item-text="name"
                    :item-value="(item) => item"
                    @input="setInputOrganization"
                    @update:search-input="setInputOrganization"
                    label="Organization"
                    outlined
                    hint="Select an organization and click ‘Add Organization’ OR enter a new organization name and click ‘New Organization"
                    persistent-hint
                  >
                  </v-autocomplete>
                  <br />
                </v-col>

                <v-col cols="3">
                  <v-btn
                    depressed
                    color="primary"
                    @click="addOrganization"
                    class="mt-2"
                  >
                    Add Organization
                  </v-btn>
                </v-col>

                <v-col cols="3">
                  <v-btn
                    depressed
                    color="secondary"
                    @click="newOrganization"
                    :disabled="isDuplicatedOrganization"
                    class="mt-2"
                  >
                    New Organization
                  </v-btn>
                </v-col>

                <v-col class="12">
                  <v-data-table
                    :headers="org"
                    :items="editedItem.profileCustomers"
                    class="elevation-0"
                    hide-default-footer
                    no-data-text="No organizations found!"
                    dense
                  >
                    <!-- Table Items -->
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td>
                          <v-btn
                            icon
                            color="secondary"
                            @click="deleteCustomer(index)"
                            :disabled="loading"
                          >
                            <v-icon>mdi-slash-forward-box</v-icon></v-btn
                          >
                        </td>

                        <td>{{ item.name }}</td>

                        <td>
                          <v-checkbox v-model="item.canAddProjects" />
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </x-expand-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close()"
            :disabled="loadingCreate"
          >
            Cancel</v-btn
          >

          <v-btn
            color="secondary"
            text
            @click="save()"
            v-if="this.editedIndex < 0"
            :disabled="loadingCreate"
          >
            Save</v-btn
          >

          <v-btn
            color="secondary"
            text
            @click="update()"
            v-else
            :disabled="loadingCreate"
          >
            Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-confirm-dialog
      ref="confirmDelete"
      title="Delete User?"
      :message="`${
        selectedDeleteItem
          ? `${selectedDeleteItem.userName} will be deleted`
          : ''
      }`"
      :showConfirmCheckbox="true"
    />
  </div>
</template>

<script>
import Api from "@/api/api";
import XExpandContainer from "@/components/_generics/x-expand-container.vue";
import XExpandAlert from "@/components/_generics/x-expand-alert.vue";
import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";
import InputRules from "@/utils/inputFieldRules";

import * as FC from "@/components/filtering/constants.js";
import dayjs from "dayjs";

export default {
  name: "AdminUsers",

  components: {
    XExpandAlert,
    XExpandContainer,
    XConfirmDialog,
  },
  mixins: [InputRules],

  mounted() {
    this.getUsers();
    this.getCustomers();
    this.getRoles();
    this.initFilters();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add User" : "Edit User";
    },

    mappedCustomers() {
      return this.organizations.map((item) => ({
        customerKey: item.value,
        name: item.text,
        canAddProjects: false,
      }));
    },

    filterableHeaders() {
      return this.headers.filter((header) => header.value !== "");
    },
  },

  data() {
    return {
      loading: false,
      loadingStage: 0,
      loadingEdit: false,
      loadingCreate: false,

      dialog: false,
      dialogUser: false,
      rolesDialog: false,
      deleteUserDialog: false,
      organizations: [],
      userRolesList: [],
      selectedOrg: null,
      editedIndex: -1,
      selectedOrganization: null,
      inputOrganization: "",

      headers: [
        { text: "", value: "", sortable: false, type: FC.FILTER_NONE },
        {
          text: "USERNAME",
          value: "userName",
          sortable: true,
          type: FC.FILTER_EQUATION_S,
          filters: {},
        },
        {
          text: "EMAIL ADDRESS",
          value: "emailAddress",
          sortable: true,
          type: FC.FILTER_EQUATION_S,
          filters: {},
        },
        {
          text: "ROLE",
          value: "roles",
          sortable: true,
          type: FC.FILTER_EQUATION_S,
          filters: {},
        },
        {
          text: "LAST LOGIN DATE",
          value: "lastLoginDate",
          sortable: true,
          type: FC.FILTER_EQUATION_T,
          filters: {},
        },
        {
          text: "PASSWORD EXP",
          value: "passwordExpiration",
          sortable: true,
          type: FC.FILTER_EQUATION_T,
          filters: {},
        },
        {
          text: "ACCOUNT EXP",
          value: "accountExpiration",
          sortable: true,
          type: FC.FILTER_EQUATION_T,
          filters: {},
        },
        {
          text: "COMPLETED REGISTRATION",
          value: "isRegistrationComplete",
          sortable: true,
          type: FC.FILTER_BINARY,
          filters: {},
        },
        { text: "", value: "", sortable: false, type: FC.FILTER_NONE },
      ],

      // Rules
      dialogForm: false, // If the form is valid
      required: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      validDate: [
        (v) => !!v || "Date is required",

        // Year must be between 1000 and 9999
        (v) => {
          const year = dayjs(v, "YYYY-MM-DD", true).year();
          if (year < 1000 || year > 9999 || isNaN(year)) {
            return "Please enter a valid year";
          } else {
            return true;
          }
        },
      ],

      org: [
        {
          text: "Remove",
          value: "remove",
        },
        {
          text: "Organization",
          value: "organization",
        },
        {
          text: "Can Add Projects?",
          value: "can-add",
        },
      ],

      addedOrg: [],
      data: [],
      filteredData: [],

      user: {
        accountExpiration: null,
        passwordExpiration: null,
        emailAddress: null,
        roles: null,
        updateProfile: false,
        userName: null,
        isLockedOut: false,
      },
      editedItem: {
        accountExpiration: "",
        passwordExpiration: "",
        emailAddress: null,
        updateProfile: false,
        userName: null,
        isLockedOut: false,
        roles: "",
        securityRoleKey: 0,
        profileCustomers: [],
        organizations: 0,
      },
      defaultItem: {
        accountExpiration: null,
        passwordExpiration: null,
        emailAddress: null,
        updateProfile: false,
        userName: null,
        isLockedOut: false,
        roles: "",
        securityRoleKey: null,
        profileCustomers: [],
        organizations: 0,
      },
      selectedDeleteItem: null,
      isDuplicatedOrganization: false,

      FC,
      equationInfo: false,
      firstEquationDate: "",
      secondEquationDate: "",
    };
  },

  methods: {
    getCustomers() {
      Api.Projects.getCustomerList()
        .then((res) => {
          this.organizations = res.data.$values;
        })
        .catch(() => {
          this.handleError("Failed to retrieve customers");
        });
    },

    getUsers(triggerLoading = true) {
      if (triggerLoading) this.loading = true;

      Api.Authentication.getUserRoles()
        .then((res) => {
          // Add "sending" field to all users
          res.data.$values.forEach((item) => {
            item.sending = false;
          });

          this.data = res.data.$values;
          this.filteredData = res.data.$values;
          if (this.filteredData instanceof Array) {
            this.filteredData.forEach((user) => {
              if (dayjs(user.lastLoginDate).isValid()) {
                user.lastLoginDatePretty = dayjs(user.lastLoginDate).format(
                  "MM/DD/YYYY h:MMA"
                );
              }

              if (dayjs(user.passwordExpiration).isValid()) {
                user.passwordExpirationPretty = dayjs(
                  user.passwordExpiration
                ).format("MM/DD/YYYY h:MMA");
              }

              if (dayjs(user.accountExpiration).isValid()) {
                user.accountExpirationPretty = dayjs(
                  user.accountExpiration
                ).format("MM/DD/YYYY h:MMA");
              }
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.handleError("Failed to retrieve users");
        });
    },

    getUserByUserKey(userKey) {
      this.loadingEdit = true;
      const user = Api.Profile.edit(userKey)
        .then((response) => {
          this.loadingEdit = false;
          return response.data;
        })
        .catch((error) => {
          this.loadingEdit = false;
          this.handleError(error);
        });

      return user;
    },

    getRoles() {
      Api.Authentication.getRoles()
        .then((res) => {
          this.userRolesList = res.data.$values;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createUser(userModel) {
      if (
        this.editedItem.profileCustomers &&
        this.editedItem.profileCustomers.length === 0
      ) {
        delete this.editedItem.profileCustomers;
      }

      this.loadingCreate = true;

      const appBaseURL = window.location.origin;

      Api.Profile.create(userModel, appBaseURL)
        .then((res) => {
          this.loadingCreate = false;

          if (res.data.errors) {
            const errs = res.data.errors;

            if (errs.$values) {
              let msg = "";
              for (let i = 0; i < errs.$values.length; i++) {
                msg += errs.$values[i].message;
                if (i < errs.$values.length - 1) {
                  msg += " | ";
                }
              }

              this.handleError(msg, true);
            } else {
              this.handleError(errs, true);
            }

            return;
          }

          this.handleSuccess("User created successfully");
          this.close();
          this.getUsers();
        })
        .catch((err) => {
          this.loadingCreate = false;
          this.handleError("An unknown error occurred...", true);
        });
    },

    editUser(userModel) {
      const formattedUserModel = {
        profileKey: userModel.profileKey,
        userKey: userModel.userKey,
        accountExpiration: userModel.accountExpiration,
        passwordExpiration: userModel.passwordExpiration,
        emailAddress: userModel.emailAddress,
        userName: userModel.userName,
        isActive: true,
        isAdminEdit: false,
        isChildModel: false,
        passwordMustBeReset: false,
        isLockedOut: false,
        roles: this.getRoleTitle(userModel.securityRoleKey),
        securityRoleKey: userModel.securityRoleKey,
        profileCustomers: userModel.profileCustomers,
        organizations: 0,
      };

      this.loadingCreate = true;
      Api.Profile.update(formattedUserModel)
        .then((res) => {
          this.loadingCreate = false;

          if (res.data.errors) {
            const errs = res.data.errors;

            if (errs.$values) {
              let msg = "";
              for (let i = 0; i < errs.$values.length; i++) {
                msg += errs.$values[i].message;
                if (i < errs.$values.length - 1) {
                  msg += " | ";
                }
              }

              this.handleError(msg, true);
            } else {
              this.handleError(errs, true);
            }

            return;
          }

          this.handleSuccess("User updated successfully");
          this.close();
          this.getUsers();
        })
        .catch((err) => {
          this.loadingCreate = false;
          this.handleError("An unknown error occurred...", true);
        });
    },

    async createUserRole() {
      await Api.Authentication.createUserRole()
        .then((response) => {
          console.log(response.data);
          this.data.push(this.user);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    lockState(userKey) {
      this.loading = true;
      Api.Authentication.toggleLockState(userKey)
        .then((response) => {
          const lockMessage = response.data ? "locked" : "unlocked";
          this.handleSuccess(`User ${lockMessage} successfully`);
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteUser(userKey) {
      this.loading = true;
      Api.Authentication.deleteUser(userKey.userKey)
        .then((res) => {
          this.handleSuccess("User deleted successfully");
          this.getUsers();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    openModal() {
      this.editedItem.profileCustomers = [];
      this.dialogUser = true;
    },

    close() {
      this.$refs.diagform.reset();

      this.dialogUser = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.selectedOrganization = null;

      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    save() {
      const valid = this.$refs.diagform.validate();
      if (!valid) return;

      this.createUser(this.editedItem);
    },

    update() {
      const valid = this.$refs.diagform.validate();
      if (!valid) return;

      this.editUser(this.editedItem);
    },

    async deleteItemConfirm(item) {
      this.selectedDeleteItem = item;
      const shouldDelete = await this.$refs.confirmDelete.confirmAction();
      if (shouldDelete) this.deleteUser(item);
    },

    async editItem(item) {
      this.editedIndex = item.userKey;
      this.dialogUser = true;

      // Get edited item and reformat expiration dates
      const res = await this.getUserByUserKey(item.userKey);
      res.profileCustomers = res.profileCustomers.$values || [];
      res.accountExpiration = res.accountExpiration
        ? dayjs(res.accountExpiration).format("YYYY-MM-DD")
        : null;
      res.passwordExpiration = res.passwordExpiration
        ? dayjs(res.passwordExpiration).format("YYYY-MM-DD")
        : null;

      this.editedItem = res;
    },

    addOrganization() {
      if (this.selectedOrganization) {
        if (this.editedItem.profileKey) {
          this.selectedOrganization.profileKey = this.editedItem.profileKey;
        }

        this.editedItem.profileCustomers.push(this.selectedOrganization);
      }
    },

    newOrganization() {
      if (this.inputOrganization) {
        const newOrg = {
          name: this.inputOrganization,
          customerKey: 0,
          canAddProjects: false,
        };

        if (this.editedItem.profileKey) {
          newOrg.profileKey = this.editedItem.profileKey;
        }

        this.editedItem.profileCustomers.push(newOrg);
      }
    },

    setInputOrganization(name) {
      this.inputOrganization = name;

      // Validate no duplicated organization
      if (
        this.mappedCustomers.find((org) => org.name === name) ||
        this.editedItem.profileCustomers.find((org) => org.name === name) ||
        name === "" ||
        typeof name === "object"
      ) {
        this.isDuplicatedOrganization = true;
      } else {
        this.isDuplicatedOrganization = false;
      }
    },

    deleteCustomer(index) {
      this.editedItem.profileCustomers.splice(index, 1);
    },

    handleError(msg = "An error occurred, try again later", inDialog = false) {
      this.loading = false;

      if (inDialog) {
        this.$refs.dialogAlert.setMessage(msg);
      } else {
        this.$refs.alert.setMessage(msg, "error");
      }
    },

    handleSuccess(msg = "Success!") {
      this.loading = false;
      this.$refs.alert.setMessage(msg);
    },

    setRoles(roleKey) {
      const role = this.userRolesList.find((item) => item.roleKey === roleKey);
      this.editedItem.roles = role.title || "";
    },

    getRoleTitle(roleKey) {
      const role = this.userRolesList.find((item) => item.roleKey === roleKey);
      if (role) return role.title;
      else return "";
    },

    sendVerificationEmail(userKey) {
      // Get index of user with userKey
      const index = this.data.findIndex((user) => user.userKey === userKey);
      this.data[index].sending = true;

      const appBaseURL = window.location.origin;

      // Send verification email through API request
      Api.Authentication.resendActivationEmail(userKey, appBaseURL)
        .then((res) => {
          this.data[index].sending = false;
          this.$refs.alert.setMessage("Verification email sent successfully");
        })
        .catch((err) => {
          this.data[index].sending = false;
          this.handleError(
            "Unable to send verification email. Try again later."
          );
        });
    },

    initFilters() {
      // Set advanced filters
      this.filterableHeaders.forEach((header, i) => {
        if (header.type !== FC.FILTER_NONE) {
          this.resetAdvancedFilter(i);
        }
      });
    },

    filterData() {
      this.filteredData = FC.filterData(this.data, this.filterableHeaders);
    },

    resetAdvancedFilter(index) {
      // Reset a specific filter
      if (FC.EQ_FILTERS.includes(this.filterableHeaders[index].type)) {
        this.filterableHeaders[index].filters = {
          first: "",
          operator: "",
          second: "",
        };
      } else if (this.filterableHeaders[index].type === FC.FILTER_BINARY) {
        this.filterableHeaders[index].filters = { true: true, false: true }; // Check both boxes
      }

      this.filterData();
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    loadingStage(val) {
      // If all loading is done, disable it
      if (val === 2) {
        console.log("Admin Users: All loading finished!");
        this.loading = false;
        this.loadingStage = 0;
      }
    },

    // headers: {
    //   handler() {
    //     this.filterData();
    //   },
    //   deep: true
    // },
  },
};
</script>

<style lang="scss">
.admin-user-table {
  th {
    position: relative;
  }
}

.users__container {
  display: grid;
}

.table__container {
  max-width: 100%;
}

.loader__container {
  display: flex;
  height: 600px;
}
</style>
