<template>
  <!-- Custom app background color -->
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <!-- https://stackoverflow.com/questions/58615754/how-to-hide-global-component-e-g-navbar-on-some-routes -->
    <app-navbar v-if="!$route.meta.hideNavbar"></app-navbar>

    <v-main>
      <!-- Inserted here to have it render before anything that relies on this.  -->
      <x-toast-alert></x-toast-alert>

      <app-worker-status></app-worker-status>

      <!-- Plays a quick fade in transition on route change  -->
      <div class="page-container padding px-0 px-sm-4 mt-0 mt-sm-4">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </v-main>

    <v-footer padless class="footer">
      <v-col class="text-center ds-footer">
        <span style="font-weight: 400">Dialsmith</span> ©
        {{ new Date().getFullYear() }}. All rights reserved.
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

import AppWorkerStatus from "./components/AppWorkerStatus.vue";
import AppNavbar from "@/components/AppNavbar.vue";
import XToastAlert from "@/components/_generics/x-toast-alert.vue";

export default {
  name: "App",

  components: {
    AppNavbar,
    XToastAlert,
    AppWorkerStatus,
  },
  
  
  data: () => {
    return {
      connection: null,
    };
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  methods: {},

  mounted() {

  },
};
</script>

<style lang="scss">

@import "@/styles/vuetify-fixes.scss";
@import "@/styles/globals.scss";

// limiting width of page content and centering it
.v-main {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.v-main__wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.page-container {
  width: 100%;
  height: 100%;
  max-width: $content-width;
}

.v-footer > .ds-footer {
  font-size: 12px;
  color: rgb(90, 90, 90);
}

/** Better heading style for pages */
.v-sheet h1 {
  font-weight: 500;
}

// fade transition for router
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.05s ease-in-out;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
