<template><div>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="!edit" color="secondary"
        @click="loadNewQuestion()"
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-plus</v-icon>
        Add Question
      </v-btn>

      <v-btn v-else icon @click="$emit('test-click', $event)" class="mr-1" color="secondary"
         :disabled="loading"
         v-bind="attrs" v-on="on">
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ edit ? 'Edit Question' : 'Add Question' }}</span>
      </v-card-title>
      <v-card-subtitle v-if="!isHotKeyQuestion">Questions will be added to {{name}} at {{ frameString }}</v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-checkbox
                v-if="hotKeyButtons.length > 0"
                v-model="isHotKeyQuestion"
                dense
                label="Use Button instead of time point"
                class="mb-2 mt-0 font-weight-medium"
              />
              <v-select
                v-if="isHotKeyQuestion"
                v-model="hotKey"
                outlined
                dense
                label="Hotkey Option"
                :items="hotKeyButtons"
                item-text="hotKeyText"
                item-value="hotKey"></v-select>
              <v-text-field
                v-model="frameString"
                label="Time Point:"
                outlined
                v-if="edit && !isHotKeyQuestion"
                dense
                @change="formatFrame()"></v-text-field>
              <v-select v-model="mediaQuestionType"
                outlined
                dense
                label="Question Type"
                :items="questionTypes"
                :disabled="edit"
                item-text="name"
                item-value="index"></v-select>
              <v-text-field v-model="question"
                label="Question"
                outlined
                dense></v-text-field>

              <!-- Add Choices -->
              <div v-if="mediaQuestionType === 2 || mediaQuestionType === 3" class="d-flex justify-end" style="margin-bottom: 26px;">
                <v-btn
                  flat
                  color="secondary"
                  @click="addChoice">
                  <v-icon dark>mdi-plus</v-icon>
                  Add Choice</v-btn>
              </div>

              <v-list v-if="surveyProviderParameters.length > 0"
                outlined>

                <v-subheader>Choices</v-subheader>
                <v-list-item
                  v-for="(item, i) in surveyProviderParameters"
                  :key="i"
                  color="#F7F9FA"
                  dense
                  class="tile py-1">

                  <v-list-item-content>
                    <v-text-field v-model="item.Value"
                      dense
                      required></v-text-field>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn dense icon color="secondary"
                      @click="deleteChoice(i)">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- Dialog Footer -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="dialog = false, isHotKeyQuestion = false">
          <v-icon>mdi-cancel</v-icon>
          Cancel</v-btn>
        <v-btn
          color="Secondary"
          text
          @click="saveQuestion">
          <v-icon>mdi-plus</v-icon>{{ edit ? 'Edit Question' : 'Add Question' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div></template>

<script>
  import Events from "@/events/events";
  import Api from '@/api/api';

  import videojs from 'video.js';

  export default {
    props: { edit: Boolean, name: String, time: Number, mediaId: String, questionModel: Object, type: Number,
      availableHotKeyButtons: Array, media: Object, loading: Boolean
    },

    mounted() {
      Events.$on('sendYoutubeTime', (data) => { this.readYoutubeDuration(data) });
      Events.$on('loadQuestionData', (data) => this.loadQuestionData(data));


    },

    data() {
      const TYPE_HOSTED = 1,
            TYPE_THIRD_PARTY = 2,
            TYPE_YOUTUBE = 3;

      return {
        frame: (this.questionModel && this.questionModel.Frame >= 0) ? this.questionModel.Frame : 0,
        frameString: "",
        mediaKey: (this.questionModel && this.questionModel.MediaKey) ? this.questionModel.MediaKey : parseInt(this.mediaId),
        mediaQuestionKey: (this.questionModel && this.questionModel.MediaQuestionKey) ? this.questionModel.MediaQuestionKey : 0,
        dialog: false,
        question: (this.questionModel && this.questionModel.Question) ? this.questionModel.Question : "",
        mediaQuestionType: (this.questionModel && this.questionModel.MediaQuestionType) ? this.questionModel.MediaQuestionType : 0,
        questionTypes: [{index: 1, name: 'Yes/No'}, {index: 2, name: 'Discrete Choice'}, {index: 3, name: 'Multiple Choice'}, {index: 4, name: 'Open-End'}],
        selected: 1,
        surveyProviderParameters: (this.questionModel && this.questionModel.SurveyProviderParameters) ? this.questionModel.SurveyProviderParameters.map((item) => { return {surveyProviderParameterKey: item.SurveyProviderParameterKey, Value: item.Value};}) : [],
        mediaType: this.type,
        isHotKeyQuestion: (this.questionModel && this.questionModel.IsHotKeyQuestion) ? this.questionModel.IsHotKeyQuestion : false,
        hotKey: (this.questionModel && this.questionModel.HotKey) ? this.questionModel.HotKey : null,
        TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE,
      }
    },

    computed: {
      hotKeyButtons() {
        const buttons = [...this.availableHotKeyButtons]

        if (this.questionModel && this.questionModel.HotKey) {
          if (this.questionModel.HotKey === 1)
            buttons.push({hotKey: this.questionModel.HotKey, hotKeyText: this.media.hotButton1Text})

          if (this.questionModel.HotKey === 2)
            buttons.push({hotKey: this.questionModel.HotKey, hotKeyText: this.media.hotButton2Text})

          if (this.questionModel.HotKey === 3)
            buttons.push({hotKey: this.questionModel.HotKey, hotKeyText: this.media.hotButton3Text})
        }

        return buttons
      }
    },

    methods: {
      addChoice() {
        this.surveyProviderParameters.push({value: ""});
      },

      deleteChoice(index) {
        this.surveyProviderParameters.splice(index, 1);
      },
      loadNewQuestion() {
        this.getFrame();
        this.mediaKey = parseInt(this.mediaId);
        this.mediaQuestionKey = 0;
        this.question = "";
        this.mediaQuestionType = 0;
        this.surveyProviderParameters = [];
        this.hotKey = null;
        this.isHotKeyQuestion = false
      },
      openDialog: () => {
        Events.$emit('openQuestionDialog');
      },
      loadQuestionData(data) {
        if (data && data.Frame >= 0) {
          this.frame = data.Frame;
          this.frameString = this.formatTime(this.frame);
        } else {
          this.getFrame();
        }
        this.mediaKey = (data && data.MediaKey) ? data.MediaKey : parseInt(this.mediaId);
        this.mediaQuestionKey = (data && data.MediaQuestionKey) ? data.MediaQuestionKey : 0;
        this.question = (data && data.Question) ? data.Question : "";
        this.mediaQuestionType = (data && data.MediaQuestionType) ? data.MediaQuestionType : 0;
        this.surveyProviderParameters = (data && data.SurveyProviderParameters) ? data.SurveyProviderParameters.map((item) => { return {surveyProviderParameterKey: item.SurveyProviderParameterKey, Value: item.Value};}) : [];
      },
      saveQuestion() {
        if (this.edit) {
          // Update Item
          Api.Media.mediaQuestionUpdate(this.$data).then(res => {
            this.dialog = false;
            console.log(res);
            Events.$emit('refreshQuestions');
          }).catch(err => {
            console.log(err);
          });
        } else {
          // New Item
          Api.Media.saveMediaQuestion(this.$data).then(res => {
            this.dialog = false;
            console.log(res);
            Events.$emit('refreshQuestions');
          }).catch(err => {
            console.log(err);
          });
        }
      },

      getFrame() {
        if (this.type !== this.TYPE_YOUTUBE) {
          var video = document.getElementsByClassName("vjs-tech")[0];
          var videoJS = videojs(video.id);
          if (!videoJS.paused()) {
            videoJS.pause();
          }
          this.frame = videoJS.currentTime();
          this.frame = Math.round(this.frame);
          if (this.frame === undefined) {
            this.frame = 0;
          }
          this.frameString = this.formatTime(this.frame);
        } else {
          Events.$emit('pauseYoutube');
          Events.$emit('getYoutubeTime');
        }
      },

      readYoutubeDuration(duration) {
        this.frame = duration;
        this.frame = Math.round(this.frame);
        this.frameString = this.formatTime(this.frame);
      },

      formatTime(frame) {
        var time = "";
        var hours = Math.floor(frame / 3600);
        time += (hours.toString().length == 1) ? "0" : "";
        time += hours;
        time += ":";
        var minutes = Math.floor((frame / 60) % 60);
        time += (minutes.toString().length == 1) ? "0" : "";
        time += minutes;
        time += ":";
        var seconds = frame % 60;
        var secondsString = seconds.toString();
        secondsString = (secondsString.length == 1) ? "0" + secondsString : secondsString;
        secondsString = (secondsString.charAt(1) == ".") ? "0" + secondsString : secondsString;
        time += secondsString.substring(0, 2);
        return time;
      },

      formatFrame() {
        var parts = this.frameString.split(":");
        var time = 0;

        //Seconds
        if (parts[parts.length - 1] !== undefined) {
          time += Math.abs(parseInt(parts[parts.length - 1]));
        }
        //Minutes
        if (parts[parts.length - 2] !== undefined) {
          time += Math.abs(parseInt(parts[parts.length - 2])) * 60;
        }
        //Hours
        if (parts[parts.length - 3] !== undefined) {
          time += Math.abs(parseInt(parts[parts.length - 3])) * 3600;
        }

        this.frame = time;
        this.frameString = this.formatTime(this.frame);
      },
    }
  };
</script>

<style scoped lang="scss">
  .tile:nth-of-type(even) {
    background-color: #F7F9FA;
  }

  .v-list {
    border-radius: 4px;
  }
</style>
