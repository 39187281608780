<template>
    <x-youtube-player v-if="youtube"
        :videoID="src"
        :startTime="startTime"
        :endTime="endTime"></x-youtube-player>

    <x-video-player v-else
        :src="src" :showControls="showControls"
        :height="height"></x-video-player>
</template>

<script>
    import XVideoPlayer from './x-video-player.vue';
	import XYoutubePlayer from './x-youtube-player.vue';

    // This component should be used as a switcher between XVideoPlayer and XYoutubePlayer.
    // poster is exclusive to XVideoPlayer.
    const XPlayer = {
        props: {
            youtube: Boolean,
            src: String,
            height: [String, Number],
            startTime: String,
            endTime: String,
            showControls: Boolean,
        },

        components: {
            XVideoPlayer,
            XYoutubePlayer,
        },
    };

    export default XPlayer;
</script>

<style lang="scss"></style>
