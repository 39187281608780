<template>
  <v-dialog
    ref="menu"
    v-model="isCalendarOpen"
    :return-value.sync="date"
    width="290px">
    
    <template v-slot:activator="{ }">
      <v-text-field 
        class="date-text-field force-pointer"
        :value="formattedDate"
        readonly
        v-bind="$attrs"
        v-on="$listeners"
        @click="isCalendarOpen = true">
      </v-text-field>
    </template>

    <v-date-picker 
      v-model="date" 
      :first-day-of-week="$attrs['first-day-of-week']" 
      :allowed-dates="$attrs['allowed-dates']"
      scrollable color="primary">

      <v-btn text color="primary" @click="isCalendarOpen = false">Cancel</v-btn>
      <v-btn
        text
        color="green"
        @click="updateDateFromDialog()"
        class="ml-auto">
        OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
  // NOTE: This doesn't have the time selector on it yet, it will eventually replace the 3rd party date/time picker.

  import dayjs from 'dayjs';

  const XDialogDatetimePicker = {
    props:{
      value: { type: String | null, default: null},
    },

    data(){
      return {
        date: null,
        time: null,
        isCalendarOpen: false,
      }
    },

    mounted(){
      this.date = this.value;
    },

    watch: {
      value(val) {
        if (val !== this.date) {
          this.date = val;
        }
      },
    },

    computed: {
      formattedDate() {
        if (this.date === null) return '';

        return dayjs(this.date).format('M/DD/YYYY');
      },
    },

    methods: {
      updateDateFromDialog(){
        this.$refs.menu.save(this.date);
        this.$emit('input', this.date);
      }
    },
  };

  export default XDialogDatetimePicker;
</script>

<style scoped lang="scss"></style>