<template><div>

  <div class="header ml-6 mt-6 mr-6">
    <h1 class="mb-4">Visual Report</h1>
    <v-btn color="secondary"
      @click="gotoData()">Data Report</v-btn>
  </div>

  <!-- Loading spinner -->
  <div v-if="loading" class="d-flex align-center justify-center flex-column mb-4">
    <h2 class="error-title mb-6">Loading report...</h2>
    <!-- Update loading indicator to progress circular spinner   -->
    <v-progress-circular indeterminate :size="64"/>
  </div>

  <div class="report-content" v-else>

    <div class="header ml-6 mb-2 mr-6">
      <h2 class="mb-2">Moment to Moment Stats</h2>
      <v-select
        dense
        class="layout__select"
        outlined
        :value="$store.state.ReportStore.visualReportLayout"
        :items="['Standard', 'Rows', 'Full Width']"
        @change="setNewLayout"
      />
    </div>

    <!-- Moment to Moment Stats Table -->
    <div class="flex-grow-1 mt-4 mt-md-0" style="overflow-x: auto; overflow-y: hidden;">
      <table class="stats-table fill-height accent-border">
        <thead>
          <tr>
            <th>Completes</th>
            <th>Active</th>
            <th>Partials</th>
            <th>Disqualifed</th>
            <th>Quota</th>
            <th>Overall Mean</th>
            <th>High</th>
            <th>Low</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ reportData.completes || 0}}</td>
            <td>{{ reportData.active || 0}}</td>
            <td>{{ reportData.partials || 0 }}</td>
            <td>{{ reportData.anomalies || 0 }}</td>
            <td>{{ reportData.viewerCount || 0 }} / {{ reportData.viewerLimit || 0 }}</td>
            <td>{{ reportData.overallMean || 0 }}</td>
            <td>{{ reportData.highMean || 0 }}</td>
            <td>{{ reportData.lowMean || 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Rest of the page -->
    <v-container class="pa-4">
      <!-- Top Row -->
      <v-row :class="$store.state.ReportStore.visualReportLayout === 'Standard' ? 'no-wrap' : ''">

        <!-- Left Column-->
        <v-col class="d-flex flex-column" :cols="$store.state.ReportStore.visualReportLayout === 'Standard' ? 6 : 12">

          <div class="player-header mb-4">
            <span class="mt-2 mr-2">Live Data</span>
            <v-switch v-model="liveData"
              class="mt-2 pa-0 center"
              dense
              hide-details>
            </v-switch>
            <div class="d-flex align-center">
              <v-progress-circular
                class="ml-4"
                size="20"
                width="2"
                v-if="loadingData"
                indeterminate
                color="secondary"
              ></v-progress-circular>

              <!-- Loading Error Indicator -->
              <v-tooltip top v-if="loadingError">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-4 mt-1" color="red"
                    v-bind="attrs"
                    v-on="on">mdi-alert-circle</v-icon>
                </template>
                <span>There was an error fetching the last data chunk</span>
              </v-tooltip>
            </div>

            <!-- Edit Subsets Dialog -->
            <v-dialog max-width="450" v-if="subsets.length">
              <template v-slot:activator="{ on, attrs }">
                <v-btn slot="activator"
                  color="secondary"
                  class="ml-auto"
                  v-on="on"
                  v-bind="attrs">
                  Edit Subsets</v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="headline">Edit Subsets</v-card-title>

                  <v-card-text class="mt-6">
                    <v-list class="dialog-subsets-list">
                      <v-list-item  v-for="(set, i) in subsets" :key="i"
                        class="mb-1" dense>
                        <v-list-item-title>{{ set.value }}</v-list-item-title>
                        <v-btn color="secondary" dense
                          @click="toggleViewSubset(i)">{{ !set.isFavorite ? 'Add' : 'Remove'}}</v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn text
                      :color="allAdded ? 'secondary' : 'green lighten-1'"
                      @click="addAllSubsets(!allAdded)">
                        <v-icon class="mr-1">mdi-plus</v-icon>{{ allAdded ? 'Remove All' : 'Add All' }}</v-btn>
                    <v-btn text
                      @click="dialog.value = false;"
                      class="ml-auto">
                      Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>

          <!-- Video Player -->
          <div ref="videoPlayerContainer" id="video-player__container" class="video-player__container">
            <x-player
              v-if="showPlayer"
              id="video-player"
              class="video-player"
              :youtube="media.mediaType === TYPE_YOUTUBE"
              :src="src"
              :update-current-video-time="updateCurrentVideoTime"
              :updatedVideoTime="updatedVideoTime"
              :show-annotation-popup="() => this.showCreateAnnotationPopup = true"
              :download-screenshot="downloadScreenshotImage"
              :download-video="downloadVideoExport"
            />
            <!--    Player actions dialogs        -->
            <v-dialog v-model="showCreateAnnotationPopup" max-width="500" @click:outside="closeNewAnnotation">
              <div>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Create Annotation
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <div>
                      <v-text-field
                        :rules="[
                          momentValidation
                        ]"
                        :error-messages="errorMessages"
                        v-model="newAnnotation.secondString"
                        label="Moment"
                        placeholder="00:00"
                      />
                    </div>
                    <div>
                      <v-radio-group v-model="newAnnotation.type">
                        <v-radio
                          label="Shared Annotation"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="Private Annotation"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <v-textarea
                        label="Annotation"
                        v-model="newAnnotation.description"
                      />
                    </div>
                    <v-progress-linear
                      v-if="loadingAnnotation"
                      indeterminate
                      color="secondary"
                    ></v-progress-linear>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text class="ml-auto" @click="closeNewAnnotation" :disabled="loadingAnnotation">
                      Cancel
                    </v-btn>
                    <v-btn color="secondary" @click="createAnnotation" :disabled="loadingAnnotation">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
            <!-- Download video screenshot -->
            <v-dialog v-model="showDownloadScreenshotPopUp" width="70vw" max-width="1000px"  @click:outside="closeScreenshotPopup">
              <div>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Screenshot
                  </v-card-title>
                  <v-card-text v-show="!loadingScreenshot" class="pt-4">
                    <div id="screenshot-container" class="screenshot__container justify-center">
                      <img :src="currentScreenshotUrl" alt="screenshot" class="screenshot__container-img">
                      <div id="screenshot-plot-container"></div>
                    </div>
                  </v-card-text>
                  <v-card-text v-if="loadingScreenshot" class="py-10 justify-center">
                    <v-progress-circular
                      :size="70"
                      :width="5"
                      color="red"
                      indeterminate
                    ></v-progress-circular>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn color="secondary" @click="downloadScreenshotImg"  :disabled="loadingScreenshot" >
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
            <!-- Download export video -->
            <v-dialog v-model="showVideoExportPopup" width="70vw" max-width="600px" persistent>
              <div>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Export Media
                  </v-card-title>
                  <v-card-text >
                    <x-expand-alert type="error" ref="alert" :dismissible="false" class="mt-4"/>
                    <!--    Save second to second chart screenshot to server   -->
                    <div v-if="!exportMediaError">
                      <div v-show="isCreatingPlotyScreenshot" class="my-2">
                        <p>Processing export media, please wait...</p>
                        <v-progress-linear
                          :width="5"
                          color="red"
                          indeterminate
                        ></v-progress-linear>
                        <div ref="export-media-plot__container" class="my-4 export-media-plot__container">
                          <div id="export-media-plot"></div>
                        </div>
                      </div>
                      <div v-if="!isCreatingPlotyScreenshot">
                        <div class="my-4" v-if="!isCompletedExportMedia">
                          <v-progress-linear
                            :height="10"
                            :width="5"
                            color="red"
                            indeterminate
                          ></v-progress-linear>
                          <h4 class="mt-4 justify-center">{{ exportMediaLoadingMsg }}</h4>
                        </div>
                        <div class="my-4" v-else>
                          <v-alert
                            dense
                            min-width="100%"
                            text
                            type="success">
                            <span>Media is complete and can be downloaded</span>
                          </v-alert>
                          <div class="mt-4 justify-center">
                            <a :href="exportMediaURL" download class="download__anchor" target="_blank">
                              <v-btn color="success">Download</v-btn>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn plain @click="closeExportVideoPopup" >
                      {{ isCompletedExportMedia ? 'Close' : 'Cancel' }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>


            <!-- Video player scatter plot div to be attached -->
            <div class="video-scatter-plot__container" :class="{invisible: !showVideoPlot}">
              <div id="video-scatter-plot"></div>
            </div>
          </div>

        </v-col>

        <!-- Right Column -->
        <v-col>

          <!-- Displayed Subsets Table -->
          <div class="no-subsets mt-1" v-if="displayedSubsets === 0">
            {{ loadingVisualReport ? 'Loading subsets...' : 'No subsets selected!' }}</div>
          <table class="subsets-table" v-else>
            <thead>
              <tr>
                <th>
                  <v-simple-checkbox v-model="allSelected"
                    @click="selectAllSubsets(allSelected)"
                    :ripple="false"></v-simple-checkbox>
                </th>
                <th>
                  <div class="flex-row">
                    <span>Value</span>

                     <!-- Info tooltip for color picker -->
                     <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="flex"
                          v-bind="attrs"
                          v-on="on">
                          mdi-information</v-icon>
                      </template>
                      <span>Click an item to change the color</span>
                     </v-tooltip>
                  </div>
                </th>
                <th>Count</th>
                <!-- 5/21/24: Disabled till clients to proceed with more expensive DIAL-320 fix (Jackson E) -->
                <!-- <th>Combine</th> -->
                <th>Cumulative Mean</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(set, i) in subsets" :key="i" v-show="set.isFavorite === true">
                <td>
                  <v-simple-checkbox v-model="set.selected"
                     @input="val => setSelectedTracesCache(val, i)"
                    :ripple="false"></v-simple-checkbox>
                </td>

                <!-- Color picker dialog -->
                <v-dialog max-width="325px">
                  <template v-slot:activator="{ on, colorDiag }">
                    <td v-bind="colorDiag" v-on="on" class="cursor-pointer">
                      <div class="color-name"><div class="color-box mr-2" :style="{'background':set.line.color}"></div>{{ set.value }}</div>
                    </td>
                  </template>
                  <template v-slot:default="colorDiag">
                    <v-card>
                      <v-card-title>Choose a Line Color</v-card-title>
                      <v-card-text class="mt-4">
                        <v-color-picker v-model="set.line.color" @input="val => setCustomColor(val,i)"></v-color-picker>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text
                          @click="colorDiag.value = false;"
                          class="ml-auto">
                          Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>

                <td>{{ set.count || 0 }}</td>
                <!-- 5/21/24: Disabled till clients to proceed with more expensive DIAL-320 fix (Jackson E) -->
                <!-- <td>
                  <v-simple-checkbox v-model="set.shouldCombine"
                     @input="val => shouldCombineCache[i] = val"
                    :disabled="i === 0"
                    :ripple="false"></v-simple-checkbox>
                </td> -->
                <td>{{ set.overallMean || 0 }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>

      <!-- Bottom Row -->
      <v-row>
        <v-col cols="12">
          <!-- Page Divider -->
          <v-divider class="mb-4"></v-divider>

          <!-- Bottom scatter plot div to be attached -->
          <div class="bottom-scatter-plot__container">
            <div id="bottom-scatter-plot"></div>
          </div>

          <!-- Question popup dialog template -->
           <v-dialog v-model="showQuestionAnswerPopup" max-width="500">
              <div v-if="selectedQuestionAnswer.length > 0">
                <v-card v-for="question in selectedQuestionAnswer" :key="question.questionKey" class="mb-4">
                  <v-card-title class="text-h5 grey lighten-2">
                    {{ question.questionName }}
                  </v-card-title>
                  <v-card-text v-if="
                      question.questionType === 'Multiple_Choice'
                      || question.questionType === 'Discrete_Choice'
                      || question.questionType === 'Yes_No'" class="py-4">
                      <div v-for="choice in question.choices" :key="choice.id" class="mb-4">
                        <h4>{{ choice.percentage }} - {{ choice.name }}</h4>
                        <div class="percent-bar-area">
                          <v-progress-linear
                            :value="choice.percentage"
                            height="25"
                            :color="question.color"
                            rounded
                            disabled
                          >
                            <strong>{{ choice.percentage }} ({{ choice.counter }})</strong>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="!question.choices.length > 0">
                        There's no data to show
                      </div>
                  </v-card-text>
                  <v-card-text v-else-if="question.questionType === 'Free_Text'" class="py-4">
                    <h4>Participants who answered:</h4>
                    <div class="percent-bar-area">
                      <v-progress-linear
                        :value="((question.totalAnswers / sessions) * 100).toFixed(2)"
                        height="25"
                        :color="question.color"
                        rounded
                        disabled
                      >
                        <strong class="white--text">{{ ((question.totalAnswers / sessions) * 100).toFixed(2) }} ({{ question.totalAnswers }})</strong>
                      </v-progress-linear>
                    </div>
                    <div class="pt-4">
                      <h4>Click below to download list of participant answers:</h4>
                      <v-btn color="secondary" @click="exportOpenEnded" :disabled="loadingExportOpenEnded">
                        Download
                      </v-btn>
                      <v-progress-linear
                        class="mt-2"
                        v-if="loadingExportOpenEnded"
                        indeterminate
                        color="secondary"
                      ></v-progress-linear>
                    </div>
                  </v-card-text>
                  </v-card>
              </div>
           </v-dialog>

          <!-- Annotataion dialog template -->
          <v-dialog v-model="showAnnotationPopup" max-width="500">
            <div v-if="selectedAnnotation.length > 0">
              <v-card>
                <v-card-text>
                  <div class="pb-4">
                    <div class="annotation__header">
                      <v-btn icon @click="showAnnotationPopup = false"><v-icon>mdi-close</v-icon></v-btn>
                    </div>
                    <v-progress-linear
                      v-if="loadingAnnotation"
                      indeterminate
                      color="secondary"
                    ></v-progress-linear>
                    <div class="annotation__content" v-for="annotation in selectedAnnotation" :key="annotation.data.oid">
                      <div>
                        <v-btn icon color="red" @click="deleteAnnotation(annotation.data.oid)" :disabled="loadingAnnotation">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                      <div class="text--black text-body-1 mt-2">
                        {{ annotation.data.text[0] }}
                      </div>
                    </div>
                    <x-confirm-dialog
                      ref="confirmDeleteAnnotation"
                      title="Delete Annotation"
                      message="Are you sure you want to delete this annotation?"
                      :show-confirm-checkbox="false"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

  </div>

</div></template>

<script>
import XPlayer from '@/components/projects/reports/video-player/x-player';
import XConfirmDialog from "@/components/_generics/x-confirm-dialog";
import XExpandAlert from "@/components/_generics/x-expand-alert";

import Api from '@/api/api.js';
import {saveAs} from 'file-saver'
import chroma from 'chroma-js'
import html2canvas from 'html2canvas'
import Events from "@/events/events";

export default {
    components: {
      XPlayer,
      XConfirmDialog,
      XExpandAlert,
    },
    data() {
      const TYPE_HOSTED = 1,
            TYPE_THIRD_PARTY = 2,
            TYPE_YOUTUBE = 3;
      const MEDIA_TYPES = [ TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE ];

      return {
        projectId: -1,
        fileKey: -1,
        active: true,

        media: {},
        reportData: {},
        subsets: [],
        questions: [],
        questionAnswers: [],
        annotations: [],
        hoverPosition: [],
        displayedSubsets: 0,
        allSelected: false,
        selectedQuestionAnswer: [],
        selectedAnnotation: [],
        showQuestionAnswerPopup: false,
        showAnnotationPopup: false,
        currentScreenshotUrl: '',

        loadingReportData: true,
        loadingMediaData: true,
        loadingSubsets: true,
        loadingData: false,
        loadingError: false,
        loading: true,
        showPlayer: false,
        loadingExportOpenEnded: false,
        loadingAnnotation: false,
        loadingScreenshot: false,
        loadingVideoExport: true,
        showConfirmDeleteAnnotation: false,
        showCreateAnnotationPopup: false,
        showDownloadScreenshotPopUp: false,
        showVideoExportPopup: false,
        showVideoPlot: true,
        isCreatingPlotyScreenshot: false,
        exportVideoSecond: 0,
        saveBlobToS3Promises: [],
        isCompletedExportMedia: false,
        exportMediaLoadingMsg: '',
        exportMediaError: false,
        exportMediaURL: '',

        newAnnotation: {
          secondString: '00:00',
          type: 1,
          description: ''
        },
        errorMessages: '',

        MEDIA_TYPES, TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE,
        src: '',

        liveData: false,
        liveDataTimeout: null,
        liveDataQueryEveryXSeconds: 3,
        selectedTracesCache: {},
        customColorsCache: {},
        shouldCombineCache: {},
        isFavoriteCache: {},
        initialOrderCache: {},

        videoDuration: 0,
        currentVideoTime: 0,
        updatedVideoTime: null,
        isMounting: true,
        defaultBottomGraphLayout: {
          showlegend: false,
          dragmode: false,
          hoveringmode: 'closest',
          autosize: true,
          shapes: [
            {
              type: 'line',
              x0: 0,
              y0: 0,
              x1: 0,
              y1: 100,
              layer: 'below',
              opacity: .7,
              line: {
                color: '#000',
                width: 1
              }
            }],
          xaxis: {
            rangeslider: {},
          },
          yaxis: {
            range: [0,100]
          },
          margin: {
            b: 0,
            l: 0,
            r: 0,
            t: 0
          }
        },
      };
    },
    
    mounted() {
      this.projectId = this.$route.params.projectId;
      this.fileKey = this.$route.params.mediaID;

      Events.$on('exportReportNotification', this.listenerExportMediaNotification);
    },

    activated() {
      this.active = true;

      this.projectId = this.$route.params.projectId;
      this.fileKey = this.$route.params.mediaID;
      
      // Load in all project/media data
      this.refresh();
    },

    deactivated() {
      this.active = false;
      this.liveData = false;
    },

    beforeDestroy() {
      this.setNewLayout('Standard');
    },

    methods: {
      refresh(triggerLoading = true) {
        console.log(`Visual Reports: Refreshing... (${triggerLoading})`);

        this.reportData = {}
        this.getMediaData(triggerLoading);
        this.getReportData(triggerLoading);
      },

      setNewLayout(value) {
        this.$store.commit('ReportStore/setVisualReportLayout', value)
      },

      gotoData() {
        // alert('Work in Progress!');
        this.$router.push({
          name: 'Data Report',
          params: {
            projectId: this.projectId,
            mediaID: this.fileKey,
          },
        });
      },

      getReportData(triggerLoading = true) {
        if (triggerLoading) this.loadingVisualReport = true;
        
        // Get current time
        const timeBeforeRequest = new Date().getTime();

        this.loadingData = true
        Api.MomentToMoment.chartRead(this.fileKey).then(res => {
          let data = res.data;

          // Fix range of floats
          data.overallMean = data.overallMean.toFixed(2);
          data.highMean = data.highMean.toFixed(2);
          data.lowMean = data.lowMean.toFixed(2);

          if (triggerLoading) {
            this.allSelected = false;

            data.data = data.data.map((set, index) => {
              const isSelected = set.overallMean > 0 && set.isFavorite;
              this.setSelectedTracesCache(isSelected, index);
              this.isFavoriteCache[index] = set.isFavorite;
              this.initialOrderCache[set.paramId] = index;

              return {
                ...set,
                selected: isSelected
              }
            });

            // Enable total subset after mapping the rest
            data.data[0].added = true; // Show total subset
            data.data[0].selected = true; // select total
            this.setSelectedTracesCache(true, 0);
          } else {
            // Reorder subsets as initial values
            const tempSubsets = [];
            data.data.forEach((set) => {
              const cachedIndex = this.initialOrderCache[set.paramId];
              tempSubsets[cachedIndex] = set;
            });

            data.data = tempSubsets;

            // Restore trace state from cache in Live Data mode
            data.data = data.data.map((set, index) => {
              // Check selected state in cache
              set.selected = this.selectedTracesCache[index] || false;

              // Check line color un cache
              if (this.customColorsCache[index]) {
                set.line.color = this.customColorsCache[index];
              }
              // Check is combination state in cache
              if (this.shouldCombineCache[index]) {
                set.shouldCombine = this.shouldCombineCache[index];
              }
              // Check is favorite state un cache
              set.isFavorite = this.isFavoriteCache[index];

              return set;
            });
          }

          // Set data
          // For the report data, we don't need literally all the data (just for readability)
          this.reportData = {
            completes: data.completes,
            active: data.active,
            partials: data.partials,
            anomalies: data.anomalies,
            viewerCount: data.viewerCount,
            viewerLimit: data.viewerLimit,
            overallMean: data.overallMean,
            highMean: data.highMean,
            lowMean: data.lowMean,
          };

          this.subsets = data.data;
          this.questions = data.questions;
          this.annotations = data.annotations;
          this.questionAnswers = data.questionAnswers.questions;
          this.displayedSubsets = 1;
          
          // Set Loading states
          if (triggerLoading) this.isMounting = true;
          this.loadingVisualReport = false;
          this.loadingData = false;
          this.loadingError = false;

          // Get time after request (to be added to the live data offset fetcher)
          const timeAfterRequest = new Date().getTime();
          const timeDiff = timeAfterRequest - timeBeforeRequest;

          console.log("Finished querying in: " + timeDiff / 1000 + "s");

          // If liveData is ON, we made a new call for chart Data when it finishes
          if (this.liveData === true) {
            this.startLiveDataPoll();
          }
        }).catch(err => {
          this.loadingData = false
          console.log('getReportData(ERR):', err);
          this.failedLoad();
        })
      },

      getMediaData(triggerLoading = true) {
        // Don't reload the player if we're just refreshing the report data
        if (triggerLoading) {
          this.loadingMediaData = true;
          this.showPlayer = false;
        }

        Api.Media.getMedia(this.projectId, this.fileKey).then(res => {
          this.media = res.data;
          this.videoDuration = res.data.videoDuration

          const awsURL = this.media.awsCloudfrontUrl;
          const mediaType = this.media.mediaType;
          let source = this.media.videoUrl;

          // Build src url if the media is hosted on AWS
          if (mediaType === this.TYPE_HOSTED) {
            const timestamp = new Date().getTime();
            source = `${awsURL}transcoded/${this.fileKey}/dash_${this.fileKey}index.mpd?timestamp=${timestamp}`;
          }

          if (source) this.showPlayer = true;
          this.src = source;
          this.loadingMediaData = false;
        }).catch(err => {
          console.log('getMediaData(ERR):', err);
          this.failedLoad();
        });
      },


      toggleViewSubset(index) {
        this.subsets[index].combine = false;
        this.subsets[index].shouldCombine = false;
        this.shouldCombineCache[index] = false;

        const isFavorite = !this.subsets[index].isFavorite
        this.subsets[index].isFavorite = isFavorite;
        this.isFavoriteCache[index] = isFavorite

        if (this.subsets[index].isFavorite) {
          this.displayedSubsets = 1;
          this.allSelected = false;
        }

        this.subsets[index].selected = false
        this.selectedTracesCache[index] = false
      },

      addAllSubsets(val) {
        this.allSelected = false;
        this.subsets.forEach((subset, index) => {
          subset.isFavorite = val;
          this.isFavoriteCache[index] = val
        });

        if (val === true) {
          this.displayedSubsets = this.subsets.length;
        } else {
          this.displayedSubsets = 0;

          // Set all selected to false
          this.subsets.forEach(subset => {
            subset.selected = false;
          });
        }
      },

      toggleSelectSubset(index) {
        this.subsets[index].selected = !this.subsets[index].selected;
      },

      selectAllSubsets(val) {
        this.subsets.forEach((subset, index) => {
          // Only select visible subsets
          if (subset.isFavorite) {
            subset.selected = val;
            this.selectedTracesCache[index] = val
          }
        });
      },

      failedLoad() {
        // Set error states
        this.loadingError = true;
        
        // If live data is on, we keep trying to load the data
        if (this.liveData) {
          this.startLiveDataPoll();
        } else {
          this.displayedSubsets = 0;
          this.loadingMediaData = false;
          this.loadingVisualReport = false;
          this.loading = false;
        }
      },

      /**
       * Starts polling for report data with the variable 
       * liveDataQueryEveryXSeconds used to determine the polling interval.
       */
      startLiveDataPoll() {
        console.log("Querying report data again in: " + this.liveDataQueryEveryXSeconds + "s")

        clearTimeout(this.liveDataTimeout);
        this.liveDataTimeout = setTimeout(() => this.getReportData(false), this.liveDataQueryEveryXSeconds * 1000);
      },

      updateCurrentVideoTime(time) {
        this.currentVideoTime = time
      },

      setBottomScatterPlot(mounted, layout) {
        const data = [...this.subsetTraces, ...this.questions, ...this.annotations, this.combined];
        const graphLayout = layout || this.defaultBottomGraphLayout

        // If media video duration exists set the graph width to that value, we avoid to see errors in graph size when
        // there is no data
        if (this.media.videoDuration > 0) {
          graphLayout.xaxis = {
            rangeslider: {},
            range: [0, this.media.videoDuration],
          }
        }

        // eslint-disable-next-line no-undef
        Plotly.react('bottom-scatter-plot', data, graphLayout, {
          scrollZoom: false,
          displayModeBar: false,
        });

        if (mounted) {
          const $chartPlot = document.getElementById('bottom-scatter-plot');
          $chartPlot.on('plotly_click', this.handleClickXAxis);

          $chartPlot.on('plotly_hover', (data) => {
            this.hoverPosition = [data.xvals[0], data.yvals[0]];
          });
        }
      },

      setVideoScatterPlot() {
        const data = [...this.subsetTraces, this.combined, ...this.questions];
        const layout = {...this.videoPlotLayout}

        layout.height = this.$refs.videoPlayerContainer.clientHeight
        // eslint-disable-next-line no-undef
        Plotly.react('video-scatter-plot', data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },

      handleClickXAxis(data) {
        // Set selected second video playing time
        const selectedSecond = data.points[0].x

        this.setVideoSecondPlayTime(selectedSecond)

        this.$nextTick(() => {
          if (this.hoverPosition[1] < 85 && this.hoverPosition[1] > 0) {
            // const questionId = markerData.oid
            this.checkForQuestionsAndPopupAnswers(data.points, selectedSecond, this.hoverPosition[1])
          } else if (this.hoverPosition[1] >= 85 && this.hoverPosition[1] < 100) {
            this.handleClickAnnotationMarker(data.points)
          }
        })
      },

      checkForQuestionsAndPopupAnswers(points, second, y) {
        const questionAnswer = this.questionAnswers.filter(question => {
          let foundQuestion = false
          points.forEach(point => {
            if (point.data.oid === question.questionKey && point.data.x[0] === question.second) {
              foundQuestion = true
            }
          })

          return foundQuestion
        })

        if (questionAnswer.length > 0) {
          this.selectedQuestionAnswer = questionAnswer
          this.showQuestionAnswerPopup = true
        }
      },

      handleClickAnnotationMarker(points, data) {
        const annotations =  points.filter(point => point.data.markerType === 'Annotation')

        if (annotations.length > 0) {
          this.selectedAnnotation = annotations
          this.showAnnotationPopup = true
        }
      },

      setVideoSecondPlayTime(second){
        this.updatedVideoTime = second
      },

      exportOpenEnded() {
        this.loadingExportOpenEnded = true
        let params = [this.fileKey]
        Api.Projects.createOpenEndedReport(params)
          .then(response => {
            this.loadingExportOpenEnded = false
            // Parse response to .zip file
            const blob = new Blob([response.data], {
              type: 'application/octet-stream'
            })
            let filename = `Open-Ended-${this.fileKey}.zip`
            saveAs(blob, filename)
          })
          .catch(error => {
            console.log(error)
            this.loadingExport = false
            this.$refs.alert.setMessage('An error occurred, try again.', 'error')
          })
      },

      async deleteAnnotation(oid) {
        const shouldDelete = await this.$refs.confirmDeleteAnnotation.confirmAction();

        const annotationKey = oid

        if (annotationKey && shouldDelete) {
          this.loadingAnnotation = true

          Api.Projects.deleteAnnotation(annotationKey)
            .then(response => {
              this.showAnnotationPopup = false
              this.annotations = this.annotations.filter(a => a.oid !== response.data)
              this.setBottomScatterPlot()
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.loadingAnnotation = false
            })
        }
      },

      createAnnotation() {
        this.loadingAnnotation = true


        const params = {
          ...this.newAnnotation,
          fileKey: this.fileKey,
          secondString: '00:' + this.newAnnotation.secondString,
          mediaAnnotationType: this.newAnnotation.type
        }

        Api.Projects.createAnnotation(params)
          .then(response => {
            this.closeNewAnnotation()
            this.getReportData(false)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loadingAnnotation = false
          })
      },

      closeNewAnnotation() {
        this.newAnnotation = {
          secondString: this.formatTimeString(this.currentVideoTime),
          type: 1,
          description: ''
        }

        this.showCreateAnnotationPopup = false
      },

      downloadScreenshotImage() {
        this.showDownloadScreenshotPopUp = true
        this.loadingScreenshot = true
        const $videoContainer = document.getElementById('video_html5_api')

        html2canvas($videoContainer)
          .then((videoCanvas) => {
            this.loadingScreenshot = false
            this.currentScreenshotUrl = videoCanvas.toDataURL()

            setTimeout(() => {
              const data = [...this.subsetTraces, this.combined];

              const layout = {...this.videoPlotLayout}
              // eslint-disable-next-line no-undef
              Plotly.react('screenshot-plot-container', data, layout, {
                displayModeBar: false,
              });
            }, 1)
          })
          .catch(error => {
            console.log(error)
          })
      },

      downloadScreenshotImg() {

        const $screenshotContainer = document.getElementById('screenshot-container')

        html2canvas($screenshotContainer)
          .then((videoCanvas) => {
            const dataUrl = videoCanvas.toDataURL()
            let link = document.createElement("a");
            link.download = `media-${this.fileKey}-screenshot.png`;
            link.href = dataUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            console.log(error)
          })

        this.closeScreenshotPopup()
      },

      async downloadVideoExport() {
        // Join complete export media notifications group
        this.$store.commit('ReportStore/setExportMediaId', this.$route.params.mediaID)

        this.loadingVideoExport = true
        this.showVideoExportPopup = true
        this.isCompletedExportMedia = false

        this.isCreatingPlotyScreenshot = true
        this.exportMediaLoadingMsg = 'Saving overlay images...'

        this.$nextTick(() => {
          this.createPlotlyScreenShots()
        })
      },

      createPlotlyScreenShots() {
        if (this.exportVideoSecond <= this.videoDuration) {
          const chartData = [...this.subsetTraces, this.combined, ...this.questions];
          // eslint-disable-next-line no-undef
          Plotly.react('export-media-plot', chartData, this.exportVideoPlotLayout,{ displayModeBar: false })
            .then(() => {
              // eslint-disable-next-line no-undef
              Plotly.toImage('export-media-plot', {
                format: 'png',
                // Adjust this values if server export video format changes
                width: 800,
                height: 450,
              })
                .then(dataUrl => {
                  const fileKey = '' + this.fileKey
                  const promise =  Api.Projects.saveImageBlogToS3(fileKey, dataUrl, this.exportVideoSecond).catch(error => { this.this.handleExportMediaError(error) })
                  this.saveBlobToS3Promises.push(promise)
                  this.exportVideoSecond = this.exportVideoSecond + 1
                  this.createPlotlyScreenShots()
                })
            })
        } else {
          this.isCreatingPlotyScreenshot = false
          Promise.all(this.saveBlobToS3Promises)
            .then(() => {
              this.exportMediaLoadingMsg = 'Processing video, please be patient...'
              Api.Projects.createExport(this.fileKey)
                .catch(error => {
                  // Ignore error and wait until SignalR notification confirm complete or error
                  console.log(error)
                })
            })
            .catch(error => {
              this.handleExportMediaError(error)
            })
            .finally(() => {
              this.exportVideoSecond = 0
              this.saveBlobToS3Promises = []
            })
        }
      },

      handleExportMediaError(error) {
        console.log(error)
        this.exportMediaError = true
        this.$refs.alert.setMessage('An error occurred, try again or contact support.', 'error')
      },

      listenerExportMediaNotification(msg) {
        if (msg.status === 'completed') {
          this.isCompletedExportMedia = true
          this.exportMediaURL = msg.message
        } else if (msg.status === 'error') {
          this.handleExportMediaError(msg.message)
        }
      },

      closeExportVideoPopup() {
        this.showVideoExportPopup = false
        this.exportVideoSecond = 0
        this.saveBlobToS3Promises = []
        this.exportMediaError = false
        this.isCreatingPlotyScreenshot = false
        this.loadingVideoExport = false
        this.exportMediaURL = ''
        this.exportMediaError = false
        // eslint-disable-next-line no-undef
        Plotly.purge('export-media-plot')
        this.$refs.alert.hideAlert()
      },

      closeScreenshotPopup() {
        this.showDownloadScreenshotPopUp = false
        this.currentScreenshotUrl = ''
      },

      momentValidation() {
        return (
          !!this.newAnnotation && this.newAnnotation.secondString.length === 5 &&
          !isNaN(parseInt(this.newAnnotation.secondString.substring(0,2))) &&
          !isNaN(parseInt(this.newAnnotation.secondString.substring(3,5))) &&
          this.newAnnotation.secondString.indexOf(':') === 2 ||
          'Invalid input format. Valid format 00:00'
        )
      },

      // formatTime takes a time length in seconds and returns the time in
      // minutes and seconds
      formatTimeString(timeInSeconds) {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
        const minutes = result.substr(3, 2)
        const seconds = result.substr(6, 2)
        return `${minutes}:${seconds}`
      },

      // helper functions
      getAverage(array) {
        return array.reduce(function (memo, num) { return memo + num; }, 0) / (array.length === 0 ? 1 : array.length)
      },

      setSelectedTracesCache(value, index) {
        this.selectedTracesCache[index] = value
      },

      setCustomColor(value, index) {
        this.customColorsCache[index] = value
      },

      clearSubsetCaches() {
        this.customColorsCache = {}
        this.selectedTracesCache = {}
        this.shouldCombineCache = {}
      }
    },

    watch: {
      '$route.params.mediaID': function(val) {
        if (!this.active) return;

        this.liveData = false;
        this.fileKey = val;
        this.subsets = [];
        this.currentVideoTime = 0
        this.updatedVideoTime = null
        this.clearSubsetCaches()

        this.setNewLayout('Standard')

        if (val !== undefined) {
          this.refresh(true);
        }

        // Poll until the DOM has loaded and we can fetch the scatter plot
        // The code on this page has so many race conditions and this is the only way to ensure the scatter plot is loaded 100%
        // Otherwise the data can take forever to load and it'll break as the DOM won't finish loading
        const pollInterval = setInterval(() => {
          if (document.getElementById('bottom-scatter-plot')) {
            clearInterval(pollInterval);
            this.setBottomScatterPlot(true);
            this.setVideoScatterPlot();
          }
        }, 1000);
      },

      '$store.state.ReportStore.visualReportLayout': function() {
        this.showVideoPlot = false;
        // eslint-disable-next-line no-undef
        Plotly.purge('bottom-scatter-plot')

        // Set timeout is defined to allow plot to update after container resizing and takes it width and height
        setTimeout(() => {
          this.setVideoScatterPlot();
          this.setBottomScatterPlot(true);
          this.showVideoPlot = true;
        }, 1);
      },

      subsetTraces: function(val) {
        if (!this.active) return;

        this.setBottomScatterPlot(this.isMounting);
        this.setVideoScatterPlot();

        this.isMounting = false;
      },

      combined: function(val) {
        if (!this.active) return;

        this.setBottomScatterPlot()
        this.setVideoScatterPlot()
      },

      currentVideoTime: function (val) {
        if (!this.active) return;

        // Update plot layout second indicator
        const newBottomPlotLayout = {
          ...this.defaultBottomGraphLayout,
          shapes: [
            {
              type: 'line',
              x0: val,
              y0: 0,
              x1: val,
              y1: 100,
              layer: 'above',
              opacity: .7,
              line: {
                color: '#000',
                width: 1
              }
            }],
        }

        this.setBottomScatterPlot(false, newBottomPlotLayout)

        // Update video plot
        this.setVideoScatterPlot()

        // Update new annotation moment
        this.newAnnotation.secondString = this.formatTimeString(val)
      },

      // Async Loading watchers
      loadingVisualReport: function(val) {
        this.loading = val || this.loadingMediaData;
      },
      loadingMediaData: function(val) {
        this.loading = val || this.loadingVisualReports;
      },

      liveData: function(val) {
        if (val === true) {
          // Only watch for chart data
          this.getReportData(false);
        } else {
          // Clear timeout
          clearTimeout(this.liveDataTimeout);
        }
      },
    },

    computed: {
      allAdded() {
        return this.subsets.filter(subset => subset.isFavorite).length === this.subsets.length;
      },

      subsetTraces() {
        return this.subsets.map(subset => {
          const isPassedIn = (subset.questionKey == null && subset.isCombination === false)
          if (!subset.selected)
            return {}

          return {
            x: subset.x,
            y: subset.y,
            mode: 'lines+markers',
            type: 'scatter',
            name: isPassedIn ? subset.value : subset.name,
            hoverinfo: subset.hoverinfo,
            line: {
              color: subset.line.color || '#000',
              shape: subset.line.shape || 'spline',
              width: subset.line.width || 3,
            }
          }
        })
      },

      videoPlotLayout() {
        const xRange = 10

        return {
          autosize: true,
          yaxis: {range: [0, 105], gridcolor: '#eeeeee', zerolinecolor: '#eeeeee', fixedrange: true,},
          xaxis: {
            range: [this.currentVideoTime - xRange, this.currentVideoTime + xRange],
            gridcolor: '#eeeeee',
            zeroline: false,
            zerolinecolor: '#eeeeee',
            ticks: 'outside',
            fixedrange: true,
          },
          shapes: [
            {
              type: 'line',
              x0: this.currentVideoTime,
              y0: 0,
              x1: this.currentVideoTime,
              y1: 100,
              line: {color: '#ffd816', width: 3}
            }],
          hovermode: !1,
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          showlegend: false,
          legend: { 'orientation': 'h' },
          margin: {l: 30, r: 0, b: 20, t: 0, pad: 0
          },
        }
      },

      exportVideoPlotLayout() {
        const xRange = 10

        return {
          autosize: true,
          yaxis: {range: [0, 105], gridcolor: '#eeeeee', zerolinecolor: '#eeeeee', fixedrange: true,},
          xaxis: {
            range: [this.exportVideoSecond - xRange, this.exportVideoSecond + xRange],
            gridcolor: '#eeeeee',
            zeroline: false,
            zerolinecolor: '#eeeeee',
            ticks: 'outside',
            fixedrange: true,
          },
          shapes: [
            {type: 'line', x0: this.exportVideoSecond, y0: 0, x1: this.exportVideoSecond, y1: 100,
              line: {color: '#ffd816', width: 3}
            }],
          hovermode: !1,
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          showlegend: false,
          legend: { 'orientation': 'h' },
          margin: {l: 30, r: 0, b: 20, t: 0, pad: 0},
          height: this.$refs["export-media-plot__container"].clientHeight
        }
      },

      verticalXAxisBars() {
        // Tricky way to detect click on each x-axis of the plot
        const verticalBars = []

        // Get video length for youtube media
        const totalSubsetLength = this.subsets[0]?.x.at(-1)
        const chartLength = this.videoDuration || totalSubsetLength
        for (let i = 0; i < chartLength; i++) {
          const verticalBar = {
            type: 'bar',
            x: [i],
            y: [100],
            showlegend: false,
            opacity: 0,
            hoverinfo: 'none',
          }

          verticalBars.push(verticalBar)
        }
        return verticalBars
      },

      sessions() {
        return this.reportData.completes + this.reportData.partials
      },

      combined() {
        const combined = this.subsets.filter(subset => subset.shouldCombine);

        if (combined.length > 1) {
          const colorsArray = combined.map(subset => subset.line?.color)
          const trace = {
            line: {
              color: chroma.average(colorsArray).hex(),
              width: 3,
              shape: 'spline'
            },
            hoverinfo: 'y+name',
            name: 'Combo',
            x:[],
            y:[]
          }

          const data = {
            name: trace.name,
            plots:{
              all: [],
              seconddata:[]
            },
            x:[],
            y:[]
          }

          combined.forEach(subset => {
            data.plots.all = data.plots.all.concat(subset.plots.all)

            for (let i = 0; i < subset.plots.seconddata.length; i++) {
              var secondData = {
                plots: [],
                second: subset.plots.seconddata[i].second,
                time: subset.plots.seconddata[i].time
              }

              // Check to see if we need to add the secondData
              if (!data.plots.seconddata[i]) {
                data.plots.seconddata.push(secondData);
              }

              // Concat actual seconds data used to set the x and y
              data.plots.seconddata[i].plots = data.plots.seconddata[i].plots.concat(subset.plots.seconddata[i].plots);
            }
          })

          // Get the averages and set the x and y arrays
          for (let i = 0; i < data.plots.seconddata.length; i++) {
            let avg = this.getAverage(data.plots.seconddata[i].plots);

            trace.y.push(avg);
            data.y.push(avg);

            trace.x.push(i);
            data.x.push(i);
          }

          return trace
        } else  {
          return {}
        }
      },
    },
  };
</script>

<style scoped lang="scss">

  .no-wrap {
    flex-wrap: nowrap;
  }
  .justify-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .layout__select {
      max-width: 200px;
  }

  .stats-table th {
    background: #f1f3f582;
    border: 1px solid var(--v-accent-base);
    font-size: 18px;
    text-align: center;
  }

  .stats-table td {
    border: 1px solid var(--v-accent-base);
    font-size: 20px;
    text-align: center;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .player-header {
    display: flex;
    flex-direction: row;
  }

  .video-player__container {
    display: grid;
    position: relative;
    margin-left: 30px;
    padding-bottom: 20px;
  }

  .video-scatter-plot__container {
    position: absolute;
    top: 0;
    left: -30px;
    bottom: -20px;
    right: 0;
  }

  .video-player {
    width: 100%;
    max-width: 100%;
  }

  .screenshot__container {
    position: relative;
    padding-left: 30px;
    padding-bottom: 20px;
  }

  #screenshot-plot-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .screenshot__container-img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .screenshot__container {
    position: relative;
    padding-left: 30px;
    padding-bottom: 20px;
  }

  #screenshot-plot-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .screenshot__container-img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .invisible {
    opacity: 0;
  }
  .no-subsets {
    width: 100%;
    text-align: center;
  }

  .subsets-table {
    font-size: 12px;
    text-align: center;
  }

  .color-name {
    margin-top: 3px; // Align with the rest of the items
    display: flex;
    flex-direction: row;
  }

  .color-box {
    width: 15px;
    height: 15px;
  }

  .dialog-subsets-list {
    max-height: 400px;
    overflow-y: auto;
  }

  .bottom-scatter-plot__container {
    display: grid;
    max-width: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .annotation__header {
    display: flex;
    justify-content: flex-end;
  }

  .annotation__content {
    color: #000;
    display: flex;
    gap: 1rem;
  }

  .export-media-plot__container {
    aspect-ratio: 16/9;
  }

  .download__anchor {
    text-decoration: none;
  }
</style>

<style>
  .js-plotly-plot .plotly .cursor-ew-resize {
    cursor: pointer;
  }
</style>
