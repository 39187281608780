import axios from "axios";

import Authentication from "./routes/authentication";
import Media from "./routes/media";
import Organization from "./routes/organization";
import Projects from "./routes/projects";
import Respondents from "./routes/respondents";
import MomentToMoment from "./routes/momenttomoment";
import Permission from "./routes/permission";
import Profile from "./routes/profile";

import router from "@/router/router";

const _axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "",
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// preventing axios from throwing errors on 4XX status codes
_axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response; // AxiosResponse object.
    if (response.status === 401 && response.data.errors == null) {
      // redirecting all 401s back to the login page and displaying an error message if they were signed in.
      console.log("HIT 401, forcing back to Login page", response);

      var wasUser = false;
      if (localStorage.getItem("currentUser")) {
        wasUser = true;
        localStorage.removeItem("currentUser");
      }

      router.push({
        name: "Login",
        params: {
          message: wasUser
            ? "Your session has timed out! \nPlease sign in again."
            : null,
          messageType: "warning",
          returnUrl: window.location.pathname + window.location.search,
        },
      });
    } else {
      // This can be printed with catches on API call promises
      // console.log('[API] Hit Axios Error:', response);
    }
    if (response.data.errors != null) {
      return response;
    }
    // Return the error object
    return Promise.reject({
      res: response,
      err: error,
    });
  }
);

const extractErrorViewModel = function (axiosFunction) {
  return async function (url, config) {};
};

/**
 * The API to communicate with the Slidermetrix backend.
 */
const Api = {
  _axiosClient,

  /**
   * The Slidermetrix API.
   */
  Authentication: new Authentication(_axiosClient),
  Media: new Media(_axiosClient),
  Organization: new Organization(_axiosClient),
  Projects: new Projects(_axiosClient),
  Respondents: new Respondents(_axiosClient),
  MomentToMoment: new MomentToMoment(_axiosClient),
  Permission: new Permission(_axiosClient),
  Profile: new Profile(_axiosClient),
};

export default Api;
