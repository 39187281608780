<template>
  <div>

    <div class="px-6 py-4">
      <x-expand-alert type="success" ref="alert" :dismissible="true"></x-expand-alert>
      <x-expand-alert type="error" ref="alertError" :dismissible="true"></x-expand-alert>

      <v-data-table :headers="headers" :items="filteredData" :loading="loading" class="accent-border" dense>

        <!-- Table Headers-->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <h2>User Roles</h2>
            </v-toolbar-title>

            <!-- Add Role Button -->
            <v-btn color="secondary" class="ml-auto mr-4" @click="openModal()" :disabled="loading">Add Role
            </v-btn>

            <!-- Advanced Filters Button -->
            <v-menu offset-y :close-on-content-click="false">

              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="primary" v-bind="attrs" v-on="on" :disabled="loading">
                  Filters <v-spacer></v-spacer>
                  <v-icon class="ml-2" small>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <!-- Filters dropdown -->
              <v-list class="condensed-list">
                <v-list-item dense v-for="(item, index) in filterableHeaders" :key="index" link>
                  <v-list-item-title>
                    <v-dialog max-width="325px">
                      <template v-slot:activator="{ on, popout }">
                        <div class="d-flex align-center" v-bind="popout" v-on="on">
                          {{ item.text }} <v-icon color="primary" class="ml-auto">
                            mdi-chevron-right</v-icon>
                        </div>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title class="headline">{{ item.text }} Filters
                          </v-card-title>

                          <v-card-text style="padding: 16px;">
                            <div class="filters-popout" v-if="FC.EQ_FILTERS.includes(item.type)">
                              <span>Show items with a value that:</span>

                              <v-text-field v-model="filterableHeaders[index].filters.first" label="First Equation"
                                outlined dense :hide-details="true"></v-text-field>

                              <v-radio-group v-model="filterableHeaders[index].filters.operator" row>
                                <v-radio :label="'And'" value="and"></v-radio>
                                <v-radio :label="'Or'" value="or"></v-radio>
                              </v-radio-group>

                              <v-text-field v-model="filterableHeaders[index].filters.second" label="Second Equation"
                                outlined dense :hide-details="true"></v-text-field>

                              <v-btn text @click="equationInfo = !equationInfo" style="margin-top: 16px;">
                                How to Use<v-icon>{{ equationInfo ? 'mdi-chevron-up' :
                                'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </div>

                            <div class="filters-popout" v-if="item.type === FC.FILTER_BINARY">
                              <span>Choose which values display:</span>

                              <v-checkbox v-model="filterableHeaders[index].filters.true" :label="'True'"
                                :hide-details="true" style="margin: 0; padding: 0;"></v-checkbox>
                              <v-checkbox v-model="filterableHeaders[index].filters.false" :label="'False'"
                                :hide-details="true" style="margin: 0; padding: 0;"></v-checkbox>
                            </div>
                          </v-card-text>

                          <!-- Equation Instructions -->
                          <v-expand-transition v-if="item.type !== FC.FILTER_BINARY">
                            <div v-show="equationInfo">
                              <v-card-text>
                                <p>All equations are formatted like:
                                  <i>"operator(value)"</i> where operator is an
                                  operator (list below) and value is the value you
                                  want to compare to.
                                </p>
                                <p>Use <i>"not operator(value)"</i> to invert an
                                  operator.</p>
                                <p>For example: <i>"equals(123)"</i> would only show
                                  values equal to "123". <i>"not equals(123)"</i>
                                  would show everything that doesn't equal 123.</p>
                                <p>Picking "and/or" just tests the first equation
                                  against the second. If an equation is invalid it
                                  wont do any filtering.</p>
                                <p v-if="item.type === FC.FILTER_EQUATION_T">
                                  <b>Dates</b>: If you pick a time in a date/time
                                  picker, it will copy it to your clipboard to easily
                                  paste into an equation.
                                </p>
                                <p><b>Operators:</b></p>
                                <ul v-for="(op, opIndex) in FC.validOperations(item.type)" :key="opIndex">
                                  <li>{{ op.op }}(): {{ op.help }}</li>
                                </ul>
                              </v-card-text>
                            </div>
                          </v-expand-transition>

                          <v-card-actions>
                            <v-btn text class="ml-auto" color="secondary"
                                   @click="filterData(), dialog.value = false">Filter</v-btn>
                            <v-btn text
                                   @click="resetAdvancedFilter(index), dialog.value = false">Clear</v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Clear Button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="secondary" v-on="on" v-bind="attrs" :disabled="loading || !data.length"
                  @click="initFilters()">
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span>Clear Filters</span>
            </v-tooltip>

          </v-toolbar>
        </template>

        <!-- Table Items -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-btn icon color="secondary" @click="editItem(item)" :disabled="loading">
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>

              <v-btn icon color="secondary" @click="deleteUserRoles(item)" :disabled="loading" class="mx-4">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>

              <v-btn icon color="secondary" @click="openPermsModal(item)" :disabled="loading">
                <v-icon>mdi-account-key</v-icon>
              </v-btn>
            </td>

            <td>
              {{ item.title }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              <v-simple-checkbox v-model="item.isDefault" disabled dense :ripple="false" :hide-details="true">
              </v-simple-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- Add/Edit Dialog-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-alert
                  dense
                  border="left"
                  type="warning"
                >
                  <strong>Do not change</strong> these settings except for development purposes
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-form ref="userRoleForm">
                  <v-text-field v-model="editedItem.securityRole.title" outlined label="Title">
                  </v-text-field>
                  <v-text-field v-model="editedItem.securityRole.description" outlined label="Description">
                  </v-text-field>
                  <v-select v-model="editedItem.securityRoleVM.copyUserRoleKey" :items="readCopyUserRoles"
                    item-text="title" item-value="roleKey" outlined label="Copy Roles From"
                    :disabled="editedIndex !== -1">
                  </v-select>
                  <v-checkbox v-model="editedItem.securityRole.isDefault" label="Is Default">
                  </v-checkbox>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Cancel</v-btn>

          <v-btn color="green lighten-1" text @click="createUserRoles(editedItem)" v-if="editedIndex < 0">Save
          </v-btn>
          <v-btn color="green lighten-1" text @click="updateUserRoles(editedItem.securityRole)" v-else>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Permissions Dialog-->
    <v-dialog v-model="dialogPerms" max-width="700px">
      <v-card>
        <v-card-title><span class="headline">{{ currentRole.title }} Permissions</span></v-card-title>

        <v-card-text class="mt-4">

          <!-- List of parent perms -->
          <v-expansion-panels multiple flat v-if="!loadingParents">
            <v-expansion-panel v-for="parent in parentPerms" :key="parent.permissionKey"
              @click="fetchChildPerms(parent)">
              <v-expansion-panel-header>{{ parent.controllerName }}</v-expansion-panel-header>

              <v-expansion-panel-content class="pt-4">
                <v-data-table :items="childPerms[parent.permissionKey]"
                  :loading="childPermsLoading[parent.permissionKey]" :headers="childPermHeaders"
                  no-data-text="No permissions found!" :footer-props="{'items-per-page-options':[10, 20, -1]}"
                  style="border: solid var(--v-accent-base) 1px">

                  <!-- Update permission -->
                  <template v-slot:[`item.isAllowed`]="{ item }">
                    <v-checkbox v-model="item.isAllowed" :ripple="false" :hide-details="true"
                      @change="updatePermission(parent.permissionKey)">
                    </v-checkbox>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogPerms = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-confirm-dialog ref="confirmDelete" title="Delete User Role?"
      :message="`${selectedDeleteItem ? `${selectedDeleteItem.title} will be deleted`: '' }`"
      :showConfirmCheckbox="true" />
  </div>
</template>

<script>
import Api from "@/api/api";
import XExpandAlert from '@/components/_generics/x-expand-alert.vue';
import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";
import InputRules from '@/utils/inputFieldRules';
import Vue from "vue";
import * as FC from '@/components/filtering/constants.js';

export default {
  name: "AdminRoles",

  mixins: [InputRules],
  components: {
    XExpandAlert,
    XConfirmDialog
  },

  mounted() {
    this.getRoles();
    this.getCopyRoles();
    this.initFilters();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Add User Role' : 'Edit User Role'
    },

    filterableHeaders() {
      return this.headers.filter(header => header.value !== '');
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    },

  },

  data() {
    return {
      loading: false,
      readCopyUserRoles: [],
      data: [],
      filteredData: [],

      dialog: false,
      dialogDelete: false,
      dialogPerms: false,

      headers: [
        { text: '', value: '', sortable: false, type: FC.FILTER_NONE },
        { text: 'Title', value: 'title', sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
        { text: 'Description', value: 'description', sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
        { text: 'Default', value: 'isDefault', sortable: true, type: FC.FILTER_BINARY, filters: {} },
      ],

      editedIndex: -1,
      editedItem: {
        securityRole: {
          userKey: 0,
          roleKey: 0,
          title: '',
          description: '',
        },
        securityRoleVM: {
          roleKey: 0,
          copyUserRoleKey: 0,
        }
      },

      defaultItem: {
        securityRole: {
          userKey: 0,
          roleKey: 0,
          title: '',
          description: '',
        },
        securityRoleVM: {
          roleKey: 0,
          copyUserRoleKey: 0,
        }
      },

      selectedDeleteItem: {},
      alertType: 'success',

      // Perms dialog data
      currentRole: {},
      loadingParents: false,
      parentPerms: [],
      childPerms: {},
      childPermsLoading: {},
      childPermHeaders: [
        { text: 'Action', value: 'action' },
        { text: 'Area', value: 'area' },
        { text: 'Friendly Name', value: 'friendlyName' },
        { text: 'Is Allowed', value: 'isAllowed' },
      ],

      FC,
      equationInfo: false,
    }
  },

  methods: {
    getRoles() {
      this.loading = true
      Api.Authentication.getRoles().then(res => {
        this.data = res.data.$values;
        this.filteredData = res.data.$values

        this.loading = false;
      }).catch(() => {
        this.handleError('Failed to retrieve user roles');
      });
    },

    getCopyRoles() {
      Api.Authentication.copyUserRoles().then(response => {
        this.readCopyUserRoles = response.data.$values;
      }).catch((error) => {
        console.log(error);
      });
    },

    createUserRoles(userRoleModel) {
      this.dialog = false;
      this.loading = true;

      Api.Authentication.createUserRoles(userRoleModel).then(response => {
        this.$refs.alert.setMessage("User role created successfully.");
        this.getRoles();
      }).catch((error) => {
        this.handleError(error);
      });

      this.$refs.userRoleForm.reset();
    },

    updateUserRoles(userRoleModel) {
      this.loading = true;
      this.dialog = false;

      Api.Authentication.updateUserRoles(userRoleModel).then(response => {
        this.$refs.alert.setMessage("User role updated successfully.");
        this.getRoles();
      }).catch((error) => {
        this.handleError(error);
      });
    },

    async deleteUserRoles(item) {
      this.selectedDeleteItem = item
      const shouldDelete = await this.$refs.confirmDelete.confirmAction();
      if (shouldDelete) {
        this.loading = true
        Api.Authentication.deleteUserRoles(item.roleKey).then(response => {
          this.$refs.alert.setMessage("User role deleted successfully.");
          this.getRoles();
        }).catch((error) => {
          this.handleError(error);
        });
      }
    },

    openModal() {
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.securityRole = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(roleKey) {
      if (roleKey > 0) {
        this.deleteItemConfirm(roleKey);
      } else {
        this.closeDelete();
      }
    },

    deleteItemConfirm(roleKey) {
      this.dialogDelete = true;
      console.log(this.editedItem);
      this.deleteUserRoles(roleKey);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem);
      } else {
        this.data.push(this.editedItem);
      }

      this.createUserRoles(this.editedItem);
      this.close();
    },

    handleError(error = 'An error occurred while processing your request.') {
      this.loading = false
      this.$refs.alertError.setMessage(error);
      console.log(error);
    },

    resetUserRoleForm() {
      this.editedItem = Object.assign({}, this.defaultItem);
      console.log(this.editedItem);
    },

    openPermsModal(role) {
      // Assign data for dialog
      this.currentRole = role;

      // Child perms is a dictionary of arrays
      this.childPermsLoading = {};
      this.childPerms = {};

      // Open dialog
      this.dialogPerms = true;

      // Fetch parents
      this.loadingParents = true;
      Api.Permission.readParent(role.roleKey).then(res => {
        this.parentPerms = res.data.$values;

        // Set loading for all children with permissionKey
        this.parentPerms.forEach(parent => {
          this.childPermsLoading[parent.permissionKey] = true;
        });

        this.loadingParents = false;
      }).catch(err => {
        this.loadingParents = false;
        this.handleError(err);
      });
    },

    fetchChildPerms(parent) {
      if (!this.childPermsLoading[parent.permissionKey]) return;

      // Fetch child perms if the parent hasn't already been expanded
      Api.Permission.readChildren(parent, this.currentRole.roleKey).then(res => {
        Vue.set(this.childPerms, parent.permissionKey, res.data.$values);
        Vue.set(this.childPermsLoading, parent.permissionKey, false);
      }).catch(err => {
        this.childPermsLoading[parent.permissionKey] = false;
        this.handleError(err);
      });
    },

    async updatePermission(parentKey) {
      Api.Permission.update(this.childPerms[parentKey]).catch(() => {
        this.handleError('Failed to update permissions');
      });
    },


    initFilters() {
      // Set advanced filters
      this.filterableHeaders.forEach((header, i) => {
        if (header.type !== FC.FILTER_NONE) {
          this.resetAdvancedFilter(i);
        }
      });
    },

    filterData() {
      this.filteredData = FC.filterData(this.data, this.filterableHeaders);
    },

    resetAdvancedFilter(index) {
      // Reset a specific filter
      if (FC.EQ_FILTERS.includes(this.filterableHeaders[index].type)) {
        this.filterableHeaders[index].filters = { first: '', operator: '', second: '' };
      }

      else if (this.filterableHeaders[index].type === FC.FILTER_BINARY) {
        this.filterableHeaders[index].filters = { true: true, false: true }; // Check both boxes
      }

      this.filterData()
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  border: 1px var(--v-accent-base) solid;
}

.v-expansion-panel--active.v-expansion-panel {
  border-color: var(--v-primary-base);
}

.v-expansion-panel-header.v-expansion-panel-header--active {
  color: white;
  border-radius: 0;
  background: var(--v-primary-base);
}

.v-expansion-panel-header.v-expansion-panel-header--active .v-expansion-panel-header__icon .v-icon::before {
  /* Icon colors are styled through ::before  */
  color: white;
}
</style>
