<template>
  <div class="px-6">
    <v-container style="max-width: 100%;" class="px-0">
      <v-row>
        <v-col>
          <h2>Project Users</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet>
            <v-data-table :headers="DATA_HEADERS" :items="users" item-key="userProfileKey"
              class="elevation-0 fill-width accent-border" loading-text="Loading Users..." :loading="loading">

              <!-- User Row Item -->
              <template v-slot:item="row">
                <tr class="fill-width">
                  <!-- Edit Dialog Popup -->
                  <td>
                    <v-dialog max-width="fit-content">
                      <template v-slot:activator="{ on, editDiag }">
                        <v-btn icon class="ml-6" color="secondary" v-on="on" v-bind="editDiag"
                          @click="setEditingUser(row.item)">
                          <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:default="dialogEdit">
                        <v-card>
                          <v-card-title class="headline">Edit {{ row.item.userProfile.userName
                          }}</v-card-title>

                          <v-card-text style="padding: 16px;">
                            <v-text-field v-model="editingUser.userProfile.userName" label="Username" outlined dense
                              disabled></v-text-field>
                            <v-text-field v-model="editingUser.userProfile.emailAddress" label="Email" outlined dense
                              disabled></v-text-field>
                            <v-select :items="USER_TYPES" item-value="value" item-text="text"
                              v-model="editingUser.userType" label="User Type" outlined dense></v-select>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogEdit.value = false">Cancel</v-btn>
                            <v-btn color="green" text @click="dialogEdit.value = false; setEditingUser(null)">Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </td>

                  <td>{{ row.item.userProfile.userName }}</td>
                  <td>{{ row.item.userProfile.emailAddress }}</td>
                  <td>{{ row.item.typeName }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from '@/api/api';

const DATA_HEADERS = [
  { text: '', value: '', sortable: false },
  { text: 'Username', value: 'userProfile.userName', sortable: true },
  { text: 'Email', value: 'userProfile.emailAddress', sortable: true },
  { text: 'Type', value: 'typeName', sortable: true },
];

const USER_TYPES = [
  { text: 'Unassigned', value: 0 },
  { text: 'Builder', value: 1 },
  { text: 'Viewer', value: 2 }
];

const ProjectUsers = {
  mounted() {
    console.log('mounted')
    // this.refreshProjectUsers();
  },

  activated() {
    console.log('atctivated')
    this.refreshProjectUsers();
  },

  data() {
    return {
      projectId: null,
      users: [],
      loading: true,
      USER_TYPES, DATA_HEADERS,
      editingUser: null,
      previousUserIndex: null,
    }
  },

  watch: {
    'editingUser.userType': function (newValue) {
      const type = this.USER_TYPES.filter(type => type.value === newValue)[0];

      // Set the selected type as the type text param
      this.editingUser.typeName = type.text;
    }
  },

  methods: {
    refreshProjectUsers() {
      this.projectId = this.$route.params.projectId;
      const customerId = null;

      // Get the project info
      Api.Projects.getProjectInfo(this.projectId, customerId).then(res => {
        const project = res.data;
        console.log('refreshProjectUsers(PROJECT):\n', project);

        this.$emit('changeProjectTitle', {
          projectName: project.projectName,
          description: project.description,
          customer: project.customerName,
          currentUserType: project.currentUserType,
          isAdmin: project.isAdmin,
          activeTab: 5,
        });

        // Get users for the project
        Api.Media.readProjectUsers(this.projectId, project.customerKey).then(res => {
          // If a user is unassigned (0), set text to Unassigned
          let users = res.data.$values;
          users.forEach(user => {
            if (user.typeName == '0') user.typeName = 'Unassigned';
          });

          console.log('refreshProjectUsers(USERS):\n', users);
          this.users = users;
          this.loading = false;
        });
      });
    },

    lockUser(userKey) {
      // Not sure how to pass an index from a data table so using this instead
      const userIndex = this.users.findIndex((user) => user.projectUserKey === userKey);
      const lockedOut = !this.users[userIndex].isLockedOut;

      // Lock the user
      this.users[userIndex].isLockedOut = lockedOut; // client
      Api.Projects.userLockOut(userKey, lockedOut).then((success, err) => {
        if (success) console.log(`User[${userKey}]: ${lockedOut ? "Locked" : "Unlocked"}`);
        if (err) console.error('lockUser():', err);
      });
    },

    setEditingUser(user) {
      // If a user is passed in, set it as the editing user and mark its index
      if (user) {
        // Deep copy the user so we don't modify the original
        this.editingUser = JSON.parse(JSON.stringify(user));

        // Find the index of the user
        this.previousUserIndex = this.users.findIndex((u) => u.userProfileKey === user.userProfileKey);
      }

      // If no user is passed in, save the editing data to the previous user index
      else {
        // Send the new user to the server
        Api.Projects.updateProjectUser(this.editingUser).then((success, err) => {
          if (success) console.log(`User[${this.editingUser.userProfileKey}]: Updated`);
          if (err) console.error('setEditingUser():', err);
        });

        // Set the users values to the new ones. Setting the whole object doesn't trigger a DOM
        // refresh for some reason
        this.users[this.previousUserIndex].userProfile.userName = this.editingUser.userProfile.userName;
        this.users[this.previousUserIndex].userProfile.emailAddress = this.editingUser.userProfile.emailAddress;
        this.users[this.previousUserIndex].typeName = this.editingUser.typeName;
        this.users[this.previousUserIndex].userType = this.editingUser.userType;
      }
    },
  }
};

export default ProjectUsers;
</script>

<style lang="scss">

</style>
