<template>
  <!-- Extending the Vuetify alert to actually transition height well. Prevents sudden page jumps  -->
  <div
    :style="`max-width: ${maxWidth}; background-color: ${background}; border: ${border}; color: ${color};
      height: var(--alert-height); margin-bottom: ${marginBottom}; margin-top: ${marginTop}; 
      padding-top: ${yPadding}px; padding-bottom: ${yPadding}px; font-size: ${fontSize}px; 
      --DISMISSIBLE_ANIMATION_LAG_MS: ${DISMISSIBLE_ANIMATION_LAG_MS}ms; --alert-height: ${height}px;`"
    :class="
      `rounded px-4 py-2 x-expand-alert elevation-${elevation} ` +
      (hidden ? 'zero-y' : '')
    "
    v-bind="$attrs"
    v-on="$listeners"
    ref="alertElement"
  >
    <div class="x-expand-alert__row">
      <div class="py-2">
        <v-icon v-if="icon" class="mr-3" :color="color">{{ icon }}</v-icon>
      </div>

      <div class="d-flex align-center x-expand-alert__text-parent">
        <span
          class="x-expand-alert__text"
          ref="alertText"
          v-html="alertMessage"
        ></span>

        <!-- Hide the slot content by default, and we inject it into alertText -->
        <span class="d-none" ref="slotContent"><slot></slot></span>
      </div>

      <div class="x-expand-alert__dismiss-filler">
        <div class="x-expand-alert__dismiss-filler"></div>
      </div>

      <div class="x-expand-alert__dismiss-container" v-if="dismissible">
        <!-- <v-spacer></v-spacer> -->
        <v-btn
          class="x-expand-alert__dismiss-btn"
          icon
          small
          :color="color"
          @click="hideAlert()"
        >
          <v-icon style="z-index: 1"> mdi-close-circle </v-icon>
        </v-btn>

        <v-progress-circular
          v-if="timeoutMs != null && timeoutMs > 0"
          class="x-expand-alert__dimiss-progress"
          :value="currentTimeoutPercent"
          :size="30"
          style="position: absolute; z-index: 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
const XExpandAlert = {
  name: "x-expand-alert",
  props: {
    /** Any valid CSS margin. Pixels, em, rem, etc.  */
    marginTop: { type: [String], default: "0px" },

    /** Any valid CSS margin. Pixels, em, rem, etc.  */
    marginBottom: { type: [String], default: "32px" },
    maxWidth: { type: String, default: "" },

    dismissible: { type: Boolean, default: false },
    timeoutMs: { type: Number, default: 0 },
    customIcon: { type: String, default: null },

    type: { type: String, default: "primary" },
    elevation: { type: String, default: "0" },

    /** This is a number because we need this in raw pixels only. No em/rem supported here.  */
    yPadding: { type: Number, default: 8 },

    // CSS color
    customBackground: { type: String, default: "var(--v-primary-base)" },
    customColor: { type: String, default: "white" },
    customBorder: { type: String, default: "1px solid black" },

    dense: { type: Boolean, default: false },
  },

  data() {
    const themes = {
      primary: {
        icon: null,
        color: "white",
        background: "var(--v-primary-base)",
        border: "",
      },
      warning: {
        icon: "mdi-exclamation",
        color: "white",
        background: "var(--v-warning-darken1)",
        border: "1px solid var(--v-warning-darken1)",
      },
      error: {
        icon: "mdi-alert",
        color: "white",
        background: "var(--v-error-base)",
        border: "1px solid var(--v-error-darken1)",
      },
      info: {
        icon: "mdi-information",
        color: "white",
        background: "var(--v-info-base)",
        border: "",
      },
      success: {
        icon: "mdi-check",
        color: "white",
        background: "var(--v-success-base)",
        border: "",
      },
    };

    return {
      DISMISSIBLE_ANIMATION_LAG_MS: 100,
      TIMEOUT_UPDATE_RATE_MS: 100,
      MAX_HEIGHT_PIXELS: 500,

      _heightObserver: null,
      _lastTextHeight: -1,

      themes: themes,
      hidden: true,

      alertMessage: null,
      minHeight: null,
      height: 0,

      background: "",
      border: "",
      color: "",
      icon: "",
      fontSize: 0,

      // should be a date of when the message was updated last.
      lastMessageDisplayed: null,
      currentTimeoutPercent: 0,
      timeoutInterval: null,
    };
  },

  beforeMount() {
    if (this.dense) {
      this.minHeight = 48;
      this.fontSize = 14;
    } else {
      this.minHeight = 64;
      this.fontSize = 16;
    }

    this.setTheme(this.type);
  },

  mounted() {
    this._setupHeightObserver();
    this.message = this.$refs.slotContent.innerHTML.trim(); // need to trim in case you add a couple spaces inside by accident

    if (this.message && this.message !== "" && this.message !== " ") {
      this.setMessage(this.message);
    }
  },

  // VUE3 UPGRADE WARNING: replace with beforeUnmount()
  beforeDestroy() {
    this._heightObserver.disconnect();
    clearInterval(this.timeoutInterval);
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  watch: {
    hidden(newHidden) {
      // if the alert is hidden, we reset this so it doesn't show the animation rolling back to the last.
      if (newHidden) {
        this.currentTimeoutPercent = 0;
      }
    },
  },

  methods: {
    /** Watching the height */
    _setupHeightObserver() {
      const heightObs = new ResizeObserver(() => {
        this._recalculateAlertHeight();
      });

      heightObs.observe(this.$refs.alertText);

      this._heightObserver = heightObs;
    },

    _recalculateAlertHeight() {
      const textHeight = this.$refs.alertText.getBoundingClientRect().height;
      if (process.env.NODE_ENV === "development") {
        console.log("Recalculated alert height...");
      }

      if (textHeight != this._lastTextHeight) {
        this._lastTextHeight = textHeight;

        const yPadding = isNaN(this.yPadding) ? 0 : this.yPadding;
        const minHeight = this.minHeight;

        let newHeight = minHeight;
        if (yPadding * 2 + textHeight > yPadding * 2 + minHeight) {
          newHeight = yPadding * 2 + textHeight + 8;
        }

        if (newHeight > this.MAX_HEIGHT_PIXELS)
          newHeight = this.MAX_HEIGHT_PIXELS;

        this.height = newHeight;
      }
    },

    /**
     * This renders an animation that shows when the timeoutMs is set. This has no effect if timeoutMs is not set.
     */
    _awaitTimeout() {
      if (this.timeoutInterval) clearInterval(this.timeoutInterval);
      const scopedStart = (this.lastMessageDisplayed = new Date());
      this.currentTimeoutPercent = 0;
      // if the timeout isn't valid we skip, since the alert will close instantly otherwise.
      if (!(this.timeoutMs > 0)) return;

      const scopedLoop = (this.timeoutInterval = setInterval(() => {
        // cancel early if another instance of this has been started
        if (this.lastMessageDisplayed != scopedStart) {
          clearInterval(scopedLoop);
          return;
        }

        const elapsedMs =
          new Date().getTime() - this.lastMessageDisplayed.getTime();
        var newTimeoutPercent = (elapsedMs / this.timeoutMs) * 100; // out of 100 not 1
        // console.log('newTimeoutPercent, elapsedMs, this.timeoutMs', newTimeoutPercent, elapsedMs, this.timeoutMs)

        if (elapsedMs >= this.timeoutMs) {
          newTimeoutPercent = 100;
          clearInterval(this.timeoutInterval);
          setTimeout(() => {
            this.hidden = true;
            this.currentTimeoutPercent = 0;
          }, 200);
        }
        this.currentTimeoutPercent = newTimeoutPercent;
      }, this.TIMEOUT_UPDATE_RATE_MS));
    },

    setTheme(themeType) {
      if (this.themes[themeType]) {
        this.icon = this.themes[themeType].icon;
        this.color = this.themes[themeType].color;

        this.background = this.themes[themeType].background;
        this.border = this.themes[themeType].border;
      }
    },

    hideAlert() {
      this.hidden = true;
    },

    /**
     * Use this to set the alert message via a ref.
     */
    setMessage(message, theme = null) {
      if (theme != null) {
        this.setTheme(theme);
      } else {
        this.setTheme(this.success);
      }

      this._awaitTimeout();
      this.hidden = false;
      this.alertMessage = message;
    },
  },
};

export default XExpandAlert;
</script>

<style lang="scss">
.x-expand-alert .v-progress-circular__overlay {
  // defined in data section
  transition: all var(--DISMISSIBLE_ANIMATION_LAG_MS) linear !important;
}
</style>

<style lang="scss" scoped>
$alert-padding-x: 16px;

$dismiss-btn-dimensions: 28px;
$dismiss-progress-dimensions: 30px;

$transition-time: 0.28s;

.x-expand-alert {
  display: flex;
  align-content: center;

  position: relative;

  transition: all $transition-time cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  background-color: var(--v-primary-base);
  overflow-y: hidden;
  overflow-x: visible;
  width: 100%;

  padding-left: $alert-padding-x;
  padding-right: $alert-padding-x;
}

.x-expand-alert__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.x-expand-alert__text-parent {
  flex-grow: 1;
}

.x-expand-alert__text {
  word-break: break-word; // prevents long words from causing scrollring weirdness
  // allows rendering newline characters
  white-space: pre-wrap;
}

.x-expand-alert__dismiss-filler {
  // progress is always the bigger of the two
  width: $dismiss-progress-dimensions;
}

// had to use absolute pixel calculations since browsers render display: flex; justify-center, align-center poorly for concentric circles
// it looked bad, this looks way better
.x-expand-alert__dismiss-container {
  position: absolute;
  top: 0;
  // progress is always bigger, better margin
  right: calc($dismiss-progress-dimensions) + $alert-padding-x;

  .x-expand-alert__dismiss-btn {
    position: absolute;

    top: calc((var(--alert-height) / 2) - ($dismiss-btn-dimensions / 2));
    transform: translateX(
      calc(($dismiss-progress-dimensions - $dismiss-btn-dimensions) / 2)
    );

    flex-grow: 0;
    z-index: 1;
    // margin: -16px -8px -16px 8px;
  }

  .x-expand-alert__dimiss-progress {
    top: calc((var(--alert-height) / 2) - ($dismiss-progress-dimensions / 2));
  }
}

.x-expand-alert * {
  transition: all $transition-time cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}

.zero-y {
  height: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: none !important;
  overflow: hidden;
}
</style>
