<template>
	<v-dialog :max-width="defaultWidth + 50">
		<template v-slot:activator="{ on, popout }">
			<v-btn text
				v-on="on"
				v-bind="popout"><v-icon class="mr-2">mdi-file-code</v-icon>Get Embed Code</v-btn>
		</template>
		<template v-slot:default="dialog">
			<v-card>
				<v-card-title class="headline">
					Test Embed Code

					<v-btn @click="preview = false; dialog.value = false"
						class="ml-auto"
						icon>
						<v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>

				<v-card-text class="mt-6">
					<v-textarea label="Embed Code"
						v-model="embedCode"
						outlined
						dense
						readonly
						auto-grow></v-textarea>
					<v-form v-model="validEmbed" style="display: flex; flex-direction: row; gap: 16px;">
						<v-text-field v-model="embedWidth"
							:placeholder="defaultWidthPlaceholder"
							outlined
							dense
							label="Embed Width"
							:rules="rule_onlyNumbers"></v-text-field>
						<v-text-field v-model="embedHeight"
							:placeholder="defaultHeightPlaceholder"
							outlined
							dense
							label="Embed Height"
							:rules="rule_onlyNumbers"></v-text-field>
					</v-form>						

					<!-- Preview Expand -->
					<div class="d-flex" style="justify-content: center;">
						<v-btn color="secondary"
							@click="preview = !preview"
							:disabled="!validEmbed"
							class="mb-4">
							Preview<v-icon>{{ preview ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
					</div>

					<v-expand-transition>
						<div v-show="preview" id="embed-preview"></div>
					</v-expand-transition>
				</v-card-text>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
	const XEmbedDialog = {
		props: {
			projectId: [String, Number],
			mediaKey: [String, Number],
			mediaType: [String, Number],
		},

		data() {
			// Media Types
			const TYPE_HOSTED = 1, TYPE_THIRD_PARTY = 2, TYPE_YOUTUBE = 3;

			// Default values
			const defaultWidth = 515, defaultHeight = 500;

			return {
				defaultWidth, defaultHeight,
				defaultWidthPlaceholder: `${defaultWidth}`, defaultHeightPlaceholder: `${defaultHeight}`,

				embedWidth: defaultWidth,
				embedHeight: defaultHeight,
				TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE,
				preview: false,
				validEmbed: true,

				rule_onlyNumbers: [
					v => !!v || 'A value is required',
					v => /^\d+$/.test(v) || 'Only numbers are allowed'
				],
			}
		},

		computed: {
			embedCode() {
				return this.genEmbedCode();
			}
		},

		watch: {
			// Close preview if embed changes
			'embedCode': function(val) { this.preview = false },

			'preview': function(val) {
				if (!val) return;

				// Inject embedCode into div
				let div = document.getElementById('embed-preview');
				div.innerHTML = this.genEmbedCode();
			}
		},

		methods: {
			// Generates an embed iframe for the current media
			// defaultVals is a bool for using default values
			genEmbedCode(defaultVals) {
				const width = defaultVals === true ? this.defaultWidth : this.embedWidth;
				const height = defaultVals === true ? this.defaultHeight : this.embedHeight;
				return `<iframe width="${width}" height="${height}" src="${window.location.origin}/Project/${this.projectId}/View?iframe=true&mediaKey=${this.mediaKey}&v1=0&v2=0&v3=0&RESP_ID=0" frameborder="0" allowfullscreen=""></iframe>`;
			}
		}
	};

	export default XEmbedDialog;
</script>

<style scoped lang="scss"></style>
