<template>
  <v-snackbar
    v-model="visible"
    :timeout="timeout"
    :multi-line="multiline === true"
    :color="color"
    bottom
    :style="containerStyle"
  >
    <div :style="textStyle"> {{ message }} </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        dark
        v-bind="attrs"
        @click="visible = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script>

/** MUST BE MOUNTED ONLY IN V-APP FOR THIS TO WORK CORRECTLY.
 * 
 * WARNING: Also, mount this component near the top of the DOM to ensure it's mounted before components that rely on it. 
 */
const XToastAlert = {

  /**
   * Injecting it's function's into Vuetify to provide global toasts everywhere in the app.
   * Using created is important so the function is registered before other components. 
   * 
   * It
   */
  created() {
    this.$vuetify.setToastMessage = this.setMessage;
  },

  data() {
    const DEFAULT_OPTIONS = {
      timeout: 3000, 
      multiline: false, 
      color: 'primary',
      containerStyle: '',
      textStyle: '',
    }

    return {
      DEFAULT_OPTIONS, 

      visible: false,
      message: '',

      ...DEFAULT_OPTIONS
    }
  },

  methods: {

    /**
     * Set's the toast message
     * @param message The message to be shown on the snack bar
     * 
     * @param {object} newOptions An options object. See the DEFAULT_OPTIONS above. 
     * #### Supports properties
     * `color` - color hex or vuetify color (primary by default)
     * `timeout` - time in ms it takes for message to fade out
     * `multiline` - more room for long messages
     * `containerStyle` - custom CSS to apply to the toast message container
     * `textStyle` - custom CSS to apply to the text container
     */
    setMessage(message, newOptions = this.DEFAULT_OPTIONS){
      this.message = message;
      // if we have an overridden option, we use that
        // otherwise we fill in any missing options with the default one. 
        // It's important to fill in unspecified options with the default to clean up custom behavior from the last message
      Object.keys(this.DEFAULT_OPTIONS).forEach((option) => {
        if(newOptions[option]) this[option] = newOptions[option];
        else this[option] = this.DEFAULT_OPTIONS[option];
      });

      this.visible = true;
    }
  }
};

export default XToastAlert;
</script>


<style>
  .v-snack__content {
    font-size: 0.95rem !important;
  }
</style>