<template>
  <v-expand-transition>
    <v-card
      v-if="!isHidden"
      class="transition-fast-in-fast-out v-card--reveal d-flex flex-column"
      style="height: 100%"
    >
      <v-card-title style="background: var(--v-background-base)">
        <div class="d-flex align-center">
          <v-icon color="primary" large>mdi-lock-reset</v-icon>
          <h2
            style="font-size: 28px; color: var(--v-primary-base)"
            class="ml-4"
          >
            Reset Password
          </h2>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon large color="black" @click="closeMenu">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-8">
        <x-expand-alert
          ref="alert"
          type="success"
          :dismissible="true"
        ></x-expand-alert>

        <v-form ref="passwordResetForm" @submit.prevent="requestPasswordReset">
          <p>Send a reset password request to your email.</p>
          <v-text-field
            label="Email"
            v-model="email"
            outlined
            required
            :rules="[InputRules.isEmail]"
          >
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-8">
        <v-layout justify-center column>
          <v-flex text-xs-center xs12>
            <v-btn
              width="100%"
              color="secondary"
              @click="requestPasswordReset"
              :loading="loading"
              >Send
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
      <v-spacer></v-spacer>
      <div
        class="fill-width"
        style="background: var(--v-primary-base); height: 6px"
      ></div>
    </v-card>
  </v-expand-transition>
</template>

<script>
import Api from "@/api/api";
import XExpandAlert from "@/components/_generics/x-expand-alert.vue";

import XPasswordField from "@/components/_generics/x-password-field.vue";
import InputRules from "@/utils/inputFieldRules";

const RequestPasswordReset = {
  name: "RequestPasswordReset",

  components: {
    XPasswordField,
    XExpandAlert,
  },

  props: ["isHidden", "closeMenu"],
  mixins: [InputRules],

  data() {
    return {
      loading: false,
      changeSuccessful: false,
      email: "",
    };
  },

  methods: {
    requestPasswordReset() {
      if (!this.$refs.passwordResetForm.validate()) {
        return;
      }
      this.loading = true;
      Api.Authentication.recoverPassword(this.email).then((res) => {
        console.log("res: ", res);

        this.$refs.alert.setMessage(res.data.message);
        this.loading = false;
      });
    },
  },
};

export default RequestPasswordReset;
</script>

<style lang="scss" scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
