import Route from "./_route";

class Permission extends Route {

	readParent(roleKey) {
		return this.axios.get(`/Permission/ReadParent?roleKey=${roleKey}`);
	}

	readChildren(parent, roleKey) {
		const permissionKey = parent.permissionKey;
		const controllerName = parent.controllerName;

		return this.axios.get(`/Permission/ReadChildren?permissionKey=${permissionKey}&controllerName=${controllerName}&roleKey=${roleKey}`);
	}

	update(model) {
		return this.axios.post(`/Permission/Update`, model);
	}
}

export default Permission;
