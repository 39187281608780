<template>
  <div>
    <v-banner color="primary" sticky dark>
      <div class="d-flex flex-row-reverse align-center">
        <router-link to="/projects">
          <v-btn plain small color="secondary">Go to new view</v-btn>
        </router-link>
      </div>
    </v-banner>

    <div id=" projects-container" class="d-flex flex-column align-center">
      <v-sheet style="overflow: hidden" class="fill-width mb-12" elevation="1" rounded>
        <v-layout class="px-4 py-2" style="border-bottom: 2px solid var(--v-accent-base)">
          <v-flex>
            <h1 class="d-flex align-center" style="color: black">
              <!-- <v-icon class="mr-4" large style="color: black">mdi-bulletin-board</v-icon>  -->
              <span>Projects</span>
            </h1>
          </v-flex>

          <v-flex d-flex justify-end align-center>
            <v-tabs class="mr-4 d-flex justify-end">
              <v-tab color="primary" @change="toggleTab('active')">Active</v-tab>
              <v-tab color="secondary" @change="toggleTab('archived')">Archived</v-tab>
            </v-tabs>

            <!-- Fixed button width prevents button from changing widths weirdly -->
            <v-btn color="primary" width="170px" @click="togglePanels">
              {{ panel.length ? "Collapse All" : "Expand All" }}</v-btn>
          </v-flex>
        </v-layout>

        <div class="py-4 px-6">
          <!-- ACTIVE PROJECTS TABLE -->
          <v-expansion-panels multiple flat :value="panel" v-if="!loadingOrgs">
            <v-expansion-panel v-for="(org, i) in organizations" :key="i" class="company-card elevation-0"
              @click="toggleExpansion(org.customerKey)">
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <div class="d-flex align-center">
                    <h3>{{ org.name }}</h3>

                    <v-spacer></v-spacer>

                    <div v-if="userPermissions[org.customerKey] && open && !showArchived
                ">
                      <v-btn v-if="userPermissions[org.customerKey].canAddProjects ||
                (userPermissions[org.customerKey] &&
                  userPermissions[org.customerKey].isAdmin)
                " class="mr-6" small color="secondary" @click="gotoSettings(org.customerKey)">
                        <v-icon small class="mr-2">mdi-plus</v-icon>Add
                        Project</v-btn>

                      <x-users-management class="mr-6" :org="org" v-if="isBuilder(org) ||
                (userPermissions[org.customerKey] &&
                  userPermissions[org.customerKey].isAdmin)
                " />
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table :headers="projectHeaders" :items="org.projects" class="elevation-0"
                  no-data-text="No projects found!" hide-default-footer :items-per-page="-1" :loading="org.projects == [] || areProjectsLoading[org.customerKey]
                " style="border: solid var(--v-accent-base) 1px">
                  <template v-slot:[`item.custom_actions`]="{ item }">
                    <div class="d-flex justify-end">
                      <!-- EDIT BUTTON -->
                      <v-btn v-if="item.currentUserType == 'Viewer' &&
                !(
                  userPermissions[org.customerKey] &&
                  userPermissions[org.customerKey].isAdmin
                )
                " icon class="mr-3" color="secondary" :disabled="areProjectsLoading[org.customerKey]"
                        @click="gotoMedia(org.customerKey, item.projectKey)">
                        <v-icon dark>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-else icon class="mr-3" color="secondary" :disabled="areProjectsLoading[org.customerKey]"
                        @click="gotoSettings(org.customerKey, item.projectKey)">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>

                      <!-- COPY BUTTON -->
                      <v-dialog v-if="userPermissions[org.customerKey].canAddProjects ||
                (userPermissions[org.customerKey] &&
                  userPermissions[org.customerKey].isAdmin)
                " max-width="fit-content">
                        <template v-slot:activator="{ on, popout }">
                          <v-btn v-if="(item.currentUserType != 'Viewer' ||
                (userPermissions[org.customerKey] &&
                  userPermissions[org.customerKey].isAdmin)) &&
                !showArchived
                " icon class="mx-3" color="secondary" :disabled="areProjectsLoading[org.customerKey]" v-bind="popout"
                            v-on="on">
                            <v-icon dark>mdi-content-copy</v-icon>
                          </v-btn>
                          <!-- Added for padding of the action buttons -->
                          <v-btn v-else disabled icon class="mx-3" />
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-card-title>
                              <span class="headline">Copy Project</span>

                              <v-btn icon class="ml-auto" @click="dialog.value = false">
                                <v-icon dark>mdi-close</v-icon></v-btn>
                            </v-card-title>

                            <v-card-text style="padding: 24px">
                              <v-btn @click="
                copyProject(item.projectKey, false);
              dialog.value = false;
              " color="secondary" style="margin-right: 24px">Copy</v-btn>

                              <v-btn @click="
                copyProject(item.projectKey, true);
              dialog.value = false;
              " color="secondary">Copy w/ Data</v-btn>
                            </v-card-text>
                          </v-card>
                        </template>
                      </v-dialog>
                      <!-- Added for padding of the action buttons -->
                      <v-btn v-else disabled icon class="mx-3" />

                      <!-- DELETE BUTTON -->
                      <v-btn v-if="item.currentUserType != 'Viewer' ||
                (userPermissions[org.customerKey] &&
                  userPermissions[org.customerKey].isAdmin)
                " icon class="ml-1" color="secondary" @click="
                tryDeleteProject(item.projectKey, org.customerKey)
                " :disabled="areProjectsLoading[org.customerKey]">
                        <v-icon dark>mdi-trash-can</v-icon>
                      </v-btn>
                      <!-- Added for padding of the action buttons -->
                      <v-btn v-else disabled icon class="ml-1" />
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Loading animation -->
          <div class="d-flex justify-center flex-column pa-8 align-center" v-else>
            <h1 style="font-weight: 300" class="my-8">{{ LoadingMessage }}</h1>

            <v-progress-circular color="primary" indeterminate size="128"></v-progress-circular>
          </div>
        </div>
      </v-sheet>

      <x-confirm-dialog ref="confirmProjectDelete" title="Delete Project?" message=""
        :showConfirmCheckbox="true"></x-confirm-dialog>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import Events from "@/events/events";

import XUsersManagement from "@/components/projects/x-users-management";
import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import { nextTick } from "process";

const STATUS_UNDEFINED = 0;
const STATUS_TESTING = 1;
const STATUS_LIVE = 2;
const STATUS_CLOSED = 3;
const STATUS_ARCHIVED = 4;

export default {
  components: {
    XConfirmDialog,
    XUsersManagement,
  },

  mounted() {
    // Hook up global event watchers
    Events.$on("copyProjectComplete", this.toggleExpansion);
    Events.$on("navSearch", this.navSearch);

    this.LoadingMessage = "Loading Customer List...";
    this.checkArchiveProjects();
    this.refreshOrganizations();

    // Get query from storage and execute it
    const query = localStorage.getItem("navSearchQuery");
    if (query !== null && query !== undefined) {
      this.navSearch({
        query: query,
        enter: true,
      });

      // Remove query from storage
      localStorage.removeItem("navSearchQuery");
    }

    // Set current project info store to default
    this.$store.commit("ProjectStore/setCurrentProjectInfo", {});
  },

  beforeDestroy() {
    Events.$off("");
  },

  data() {
    return {
      loadingOrgs: false,
      loadingPerms: false,
      areProjectsLoading: {},

      tab: "active",
      panel: [],

      projectStatusTypes: [
        { text: "Undefined", value: STATUS_UNDEFINED },
        { text: "Testing", value: STATUS_TESTING },
        { text: "Live", value: STATUS_LIVE },
        { text: "Closed", value: STATUS_CLOSED },
        { text: "Archived", value: STATUS_ARCHIVED },
      ],

      projectHeaders: [
        {
          text: "Project Name",
          align: "start",
          sortable: true,
          value: "projectName",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "projectStatus",
        },
        {
          text: "Last Modified",
          align: "start",
          sortable: true,
          value: "lastModifiedDate",
        },
        {
          text: "Total Completes",
          align: "start",
          sortable: true,
          value: "totalCompletes",
        },
        {
          text: "",
          value: "custom_actions",
          sortable: false,
        },
      ],

      organizations: [],
      emptyOrganizations: [],
      LoadingMessage: "Loading Customer List...",
      liveProjects: [],
      archivedProjects: [],
      showArchived: false,
      searchQuery: null,
      userPermissions: {},
    };
  },

  watch: {
    showArchived: function (val) {
      this.resetOrgs();
    },

    organizations: function (val, oldVal) {
      if (val.length !== oldVal.length) {
        // Get user permissions for each project
        val.forEach((org) => {
          Api.Authentication.getProjectPermissions(org.customerKey).then(
            (response) => {
              this.userPermissions = JSON.parse(
                JSON.stringify(this.userPermissions)
              );
              this.userPermissions[org.customerKey] = response.data;
            }
          );
        });
      }
    },
  },

  methods: {
    checkArchiveProjects() {
      Api.Projects.checkArchiveProjects().then((response) => {
        if (response.data.numberOfProjectsArchived > 0) {
          this.refreshOrganizations();
        }
      });
    },

    togglePanels() {
      if (this.panel.length) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
    },

    expandAll(reset = false) {
      this.panel = this.createArray(this.organizations.length);
      this.refreshProjects(reset);
    },

    collapseAll() {
      this.panel = [];
    },

    createArray(size) {
      var x = [];
      for (var i = 0; i < size; ++i) {
        x[i] = i;
      }
      return x;
    },

    // Swap between active and archived projects
    toggleTab(tab) {
      this.tab = tab;
      this.panel = [];

      if (tab == "archived") {
        this.showArchived = true;
      } else {
        this.showArchived = false;
      }

      this.refreshOrganizations();
    },

    // Global Event
    async toggleExpansion(key) {
      this.areProjectsLoading = JSON.parse(
        JSON.stringify(this.areProjectsLoading)
      );
      this.areProjectsLoading[key] = true;
      this.setLastExpanded(key);

      // Get customer projects
      const customerProjects = (
        await Api.Projects.getCustomerProjects(this.showArchived, key)
      ).data;

      // Change project status to text
      customerProjects.forEach((project) => {
        project.projectStatus = this.projectStatusTypes.find(
          (status) => status.value == project.projectStatus
        ).text;
      });

      // Edit project values
      customerProjects.forEach((project) => {
        const lastModifiedDate = project.lastModifiedDate;

        // Remove seconds from timestamp, add last modified user
        let date = new Date(lastModifiedDate);
        project.lastModifiedDate = date.toLocaleDateString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        project.lastModifiedDate += ", " + project.lastModifiedUserName;
      });

      // Set specific info
      if (this.showArchived) {
        const orgs = this.archivedProjects;
        orgs.find((o) => o.customerKey === key).projects = customerProjects;
        this.archivedProjects = [];
        this.archivedProjects = orgs;
      } else {
        const orgs = this.liveProjects;
        orgs.find((o) => o.customerKey === key).projects = customerProjects;
        this.liveProjects = [];
        this.liveProjects = orgs;
      }

      this.resetOrgs();
      this.areProjectsLoading[key] = false;
    },

    // Global Event from Navbar
    async navSearch(event) {
      // Queue up a search if loading (coming from another page)
      if (this.loadingOrgs) {
        setTimeout(() => {
          this.navSearch(event);
        }, 500);
        return;
      }

      this.searchQuery = event.query.toUpperCase();
      if (!event.enter) return;

      this.collapseAll();
      if (this.searchQuery.length) {
        this.expandAll(true);
      }
    },

    goToProject(value) {
      this.setCurrentProject({
        projectName: "Project Name",
        projectDesc: "Project Description",
      });

      this.$router.push({
        text: "Project",
        params: {
          value,
        },
      });
    },

    copyProject(value, copyData) {
      Api.Projects.copyProject(value, copyData);
    },

    async refreshOrganizations() {
      this.loadingOrgs = true;

      const lastExpanded = this.getLastExpanded();
      let foundPanel = null;
      let foundOrgKey = null;

      // Get organizations and sort alphabetically
      const organizations = (
        await Api.Projects.customersRead()
      ).data.$values.sort((a, b) => {
        if (a.name) return a.name.localeCompare(b.name);
        else return -1;
      });
      organizations.forEach((org, i) => {
        org.projects = org.projects.$values;

        // Check if last expanded is in this list, set panel index if so
        if (lastExpanded && lastExpanded == org.customerKey) {
          foundPanel = i;
          foundOrgKey = org.customerKey;
        }
      });

      this.emptyOrganizations = organizations;
      this.organizations = organizations;
      this.liveProjects = organizations;
      this.archivedProjects = organizations;

      // Toggle expansion if last expanded is in this list
      if (foundPanel !== null) {
        this.panel[foundPanel] = foundPanel;
        this.toggleExpansion(foundOrgKey);
      }

      this.loadingOrgs = false;
    },

    async refreshProjects(reset = false) {
      // Refresh Archived Projects
      if (this.showArchived) {
        if (reset) {
          this.archivedProjects.forEach((o, i) => {
            this.areProjectsLoading[i] = true;
            o.projects = [];
          });
        }

        for (var ii = 0; ii < this.archivedProjects.length; ii++) {
          const customerProjects = (
            await Api.Projects.getCustomerProjectArchiveList(
              this.archivedProjects[ii].customerKey,
              this.searchQuery
            )
          ).data;
          const orgs = this.archivedProjects;

          // Map projects to correct org
          orgs.find(
            (o) => o.customerKey === this.archivedProjects[ii].customerKey
          ).projects = customerProjects;
          this.archivedProjects = orgs;
          if (reset) this.areProjectsLoading[i] = false;
        }
      }

      // Refresh Live Projects
      else {
        if (reset) {
          this.liveProjects.forEach((o, i) => {
            this.areProjectsLoading[i] = true;
            o.projects = [];
          });
        }

        for (var i = 0; i < this.liveProjects.length; i++) {
          const customerProjects = (
            await Api.Projects.getCustomerProjectList(
              this.liveProjects[i].customerKey,
              this.searchQuery
            )
          ).data;
          const orgs = this.liveProjects;

          // Map projects to correct org
          orgs.find(
            (o) => o.customerKey === this.liveProjects[i].customerKey
          ).projects = customerProjects;
          this.liveProjects = orgs;
          if (reset) this.areProjectsLoading[i] = false;
        }
      }

      this.resetOrgs();
    },

    resetOrgs() {
      this.organizations = null;
      this.organizations = this.emptyOrganizations;
    },

    /**
     * Opens the delete menu, and waits for the user to confirm or deny the action.
     */
    async tryDeleteProject(projectId, customerKey) {
      const shouldDelete =
        await this.$refs.confirmProjectDelete.confirmAction();
      if (!shouldDelete) return;

      this.areProjectsLoading = JSON.parse(
        JSON.stringify(this.areProjectsLoading)
      );
      this.areProjectsLoading[customerKey] = true;

      console.log("tryDeleteProject(PANEL):", this.panel);

      // delete
      Api.Projects.deleteProject(projectId).then((success) => {
        this.toggleExpansion(customerKey);
      });
    },

    isBuilder(org) {
      // If is Builder in at least one project this should be true
      return org.projects.find(org => org.currentUserType === "Builder");
    },

    gotoSettings(customerKey, projectKey = null) {
      this.setLastExpanded(customerKey);

      // Go to settings if projectKey supplied, else create
      if (projectKey !== null) {
        this.$router.push({
          name: "Project",
          params: {
            projectId: projectKey,
          },
        });
      } else {
        this.$router.push({
          name: "Create Project",
          params: {
            customerId: customerKey,
          },
        });
      }
    },

    gotoMedia(customerKey, projectKey = null) {
      this.setLastExpanded(customerKey);

      // Go to settings if projectKey supplied, else create
      if (projectKey !== null) {
        this.$router.push({
          name: "Media List",
          params: {
            projectId: projectKey,
          },
        });
      }
    },

    setLastExpanded(key) {
      localStorage.setItem("lastExpanded", key);
    },

    getLastExpanded() {
      return localStorage.getItem("lastExpanded");
    },
  },
};
</script>

<style lang="scss">
.sub-table.theme--light.v-data-table {
  background: transparent;
}

.sub-table .v-data-table-header {
  display: none;
}

/* Custom styling for expansion panels on this page. Dialsmith REALLY wanted the primary color wrapping */
#projects-container .v-expansion-panel {
  border: 1px var(--v-accent-base) solid;
}

#projects-container .v-expansion-panel--active.v-expansion-panel {
  border-width: 1px;
  border-color: var(--v-primary-base);
  background: var(--v-primary-base);
}

#projects-container .v-expansion-panel-header.v-expansion-panel-header--active {
  color: white;
  border-radius: 0;
}

#projects-container .v-expansion-panel-header.v-expansion-panel-header--active .v-expansion-panel-header__icon .v-icon::before {
  /* Icon colors are styled through ::before  */
  color: white;
}

#projects-container .v-expansion-panel-content {
  background: white;
  /* Fixes border overrun */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#projects-container .v-expansion-panel-content__wrap {
  padding: 0;
}

/* Fixes minor height jump on opening the expansion panel */
#projects-container .v-expansion-panel--active>.v-expansion-panel-header {
  min-height: auto;
}
</style>
