import Vue from 'vue'
import Vuex from 'vuex'

import AuthStore from './auth'
import ProjectStore from './project'
import ReportStore from "@/store/report";

Vue.use(Vuex);


const initialState = {
  example: true,
}

const Store = new Vuex.Store({
  state: initialState,

  getters: {
  },
  mutations: {
    setLastSelectedMediaKey(state, newExample){
      state.example = newExample;
    }
  },

  actions: {

  },

  modules: {
  }
});

// have to register manually here, modules in the object above wasn't working...
Store.registerModule('AuthStore', AuthStore);
Store.registerModule('ProjectStore', ProjectStore);
Store.registerModule('ReportStore', ReportStore);

export default Store;
