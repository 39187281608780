import Route from "./_route";

class Organization extends Route {

	getOrganizations(searchOptions) {
		// Get 415'd without searchOptions being defined
		searchOptions === undefined ? searchOptions = {} : searchOptions;
		return this.axios.post('/Organization/GetItems', searchOptions);
	}

	read() {
		return this.axios.get('/Organization/Read');
	}

	create(model) {
		return this.axios.post('/Organization/Create', model);
	}

	update(model) {
		return this.axios.post('/Organization/Update', model);
	}

	delete(model) {
		return this.axios.post('/Organization/Delete', model);
	}
}

export default Organization;
