import Vue from 'vue';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';

import App from './App.vue';
import router from './router/router';
import store from './store/store';
import vuetify from './plugins/vuetify';

import DatetimePicker from 'vuetify-datetime-picker';

import VueYoutube from 'vue-youtube';
import 'video.js/dist/video-js.css'
import "@/plugins/vuetify-mask.js";

import Api from './api/api.js';
import VueCookies from 'vue-cookies'


Vue.config.productionTip = false;

Vue.use(TiptapVuetifyPlugin, {
  vuetify, 
  iconsGroup: 'mdi'
});

Vue.use(VueYoutube);

Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin);

Vue.use(VueCookies, { expire: '7d'})

Vue.use(DatetimePicker);

Vue.use(Api, {});
Vue.prototype.$api = Api;

const VUE = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');


if (process.env.NODE_ENV === 'development') {
  window.Vue = VUE;
}