import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    options: { customProperties: true },
    
    themes: {
      light: {
        primary: '#2F3A4A',
        secondary: '#ED3524',
        background: '#f1f3f5',
        accent: '#e7e7e7',
        error: '#b71c1c',
        success: '#4CAF50'
      },
    },
  }
});
