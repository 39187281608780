<template>
  <div>
    <video id="video" ref="videoPlayer" class="video-js vjs-fluid" data-setup="{}"></video>
    <x-video-controls
      :play="play"
      :pause="pause"
      :video-duration="videoDuration"
      :current-video-time="currentVideoTime"
      :skip-time="skipTime"
      :show-annotation-popup="showAnnotationPopup"
      :download-screenshot="downloadScreenshot"
      :download-video="downloadVideo"
    />
  </div>
</template>

<script>
	import videojs from 'video.js';
  import XVideoControls from "@/components/projects/reports/video-player/x-video-controls";

	const XVideoPlayer = {
    components: {
      XVideoControls
    },
		props: {
			src: String,
			poster: String,
      updateCurrentVideoTime: Function,
      updatedVideoTime: Number,
      showAnnotationPopup: Function,
      downloadScreenshot: Function,
      downloadVideo: Function,
    },
    data() {
      const options = {
        autoplay: false,
        muted: false,
        playbackRates: [0.5, 1, 1.5, 2],
        controls: false,
        sources: [{
          src: this.src,
          withCredentials: false,
          type: ''
        }],
        poster: this.poster,
        html5: { vls: { withCredentials: false }},
      };

      return {
        player: null,
        video: null,
        videoDuration: null,
        currentVideoTime: null,
        playerOptions: options,
      }
    },
    watch: {
      currentVideoTime(val){
        this.updateCurrentVideoTime(val)
      },
      updatedVideoTime(val) {
        if (val !== null) {
          this.skipTime(val)
        }
      }
    },
		mounted() {
      this.video = document.getElementById('video')
			this.player = videojs(this.$refs.videoPlayer, this.playerOptions, () => {

			});

      this.video.addEventListener('loadedmetadata', this.initializeData)
      this.video.addEventListener('timeupdate', this.updateTimeElapsed);
		},

    methods: {
      play() {
        this.player.play()
      },
      pause() {
        this.player.pause()
      },
      initializeData() {
        this.videoDuration = this.video.duration
      },
      updateTimeElapsed() {
        this.currentVideoTime = this.video.currentTime
      },
      skipTime(time) {
        this.video.currentTime = time
      }
    },

    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    }
	};

	export default XVideoPlayer;
</script>

<style  lang="scss">
  .video-js .vjs-control-bar,
  .video-js .vjs-big-play-button
  {
    display: none;
  }
</style>
