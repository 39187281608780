<template><div>

  <div class="px-6 py-4">
    <x-expand-alert type="error" ref="alert" :dismissible="true"></x-expand-alert>

    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="value"
      no-data-text="No organizations found!"
      style="border: solid var(--v-accent-base) 1px;"
      :loading="loading">

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title><h2>Organizations</h2></v-toolbar-title>

          <!-- Add Org Button -->
          <v-btn color="secondary"
            :disabled="loading"
            class="ml-auto mr-6"
            @click="openOrgEditForm(null)">Add Organization</v-btn>

          <!-- Advanced Filters Button -->
          <v-menu offset-y
            :close-on-content-click="false">

            <template v-slot:activator="{ on, attrs }">
                <v-btn text color="primary"
                  v-bind="attrs" v-on="on"
                  :disabled="loading">
                  Filters <v-spacer></v-spacer><v-icon class="ml-2" small>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <!-- Filters dropdown -->
            <v-list class="condensed-list">
                <v-list-item dense
                              v-for="(item, index) in filterableHeaders"
                              :key="index"
                              link>
                    <v-list-item-title>
                        <v-dialog max-width="325px">
                            <template v-slot:activator="{ on, popout }">
                                <div class="d-flex align-center"
                                    v-bind="popout"
                                    v-on="on">
                                    {{ item.text }} <v-icon color="primary" class="ml-auto">mdi-chevron-right</v-icon>
                                </div>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-title class="headline">{{ item.text }} Filters</v-card-title>

                                    <v-card-text style="padding: 16px;">
                                        <div class="filters-popout" v-if="FC.EQ_FILTERS.includes(item.type)">
                                            <span>Show items with a value that:</span>

                                            <v-text-field v-model="filterableHeaders[index].filters.first"
                                                          label="First Equation"
                                                          outlined
                                                          dense
                                                          :hide-details="true"></v-text-field>

                                            <v-radio-group v-model="filterableHeaders[index].filters.operator"
                                                            row>
                                                <v-radio :label="'And'" value="and"></v-radio>
                                                <v-radio :label="'Or'"  value="or"></v-radio>
                                            </v-radio-group>

                                            <v-text-field v-model="filterableHeaders[index].filters.second"
                                                          label="Second Equation"
                                                          outlined
                                                          dense
                                                          :hide-details="true"></v-text-field>

                                            <v-btn text @click="equationInfo = !equationInfo"
                                                    style="margin-top: 16px;">
                                                How to Use<v-icon>{{ equationInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                        </div>

                                        <div class="filters-popout" v-if="item.type === FC.FILTER_BINARY">
                                            <span>Choose which values display:</span>

                                            <v-checkbox v-model="filterableHeaders[index].filters.true"
                                                        :label="'True'"
                                                        :hide-details="true"
                                                        style="margin: 0; padding: 0;"></v-checkbox>
                                            <v-checkbox v-model="filterableHeaders[index].filters.false"
                                                        :label="'False'"
                                                        :hide-details="true"
                                                        style="margin: 0; padding: 0;"></v-checkbox>
                                        </div>
                                    </v-card-text>

                                    <!-- Equation Instructions -->
                                    <v-expand-transition v-if="item.type !== FC.FILTER_BINARY">
                                        <div v-show="equationInfo">
                                            <v-card-text>
                                                <p>All equations are formatted like: <i>"operator(value)"</i> where operator is an operator (list below) and value is the value you want to compare to.</p>
                                                <p>Use <i>"not operator(value)"</i> to invert an operator.</p>
                                                <p>For example: <i>"equals(123)"</i> would only show values equal to "123". <i>"not equals(123)"</i> would show everything that doesn't equal 123.</p>
                                                <p>Picking "and/or" just tests the first equation against the second. If an equation is invalid it wont do any filtering.</p>
                                                <p v-if="item.type === FC.FILTER_EQUATION_T"><b>Dates</b>: If you pick a time in a date/time picker, it will copy it to your clipboard to easily paste into an equation.</p>
                                                <p><b>Operators:</b></p>
                                                <ul v-for="(op, opIndex) in FC.validOperations(item.type)" :key="opIndex">
                                                    <li>{{ op.op }}(): {{ op.help }}</li>
                                                </ul>
                                            </v-card-text>
                                        </div>
                                    </v-expand-transition>

                                    <v-card-actions>
                                      <v-btn text class="ml-auto" color="secondary"
                                             @click="filterData(), dialog.value = false">Filter</v-btn>
                                      <v-btn text
                                             @click="resetAdvancedFilter(index), dialog.value = false">Clear</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
          </v-menu>

          <!-- Clear Button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="secondary"
                v-on="on" v-bind="attrs"
                :disabled="loading || !data.length"
                @click="initFilters()">
                <v-icon>mdi-cancel</v-icon></v-btn>
            </template>
            <span>Clear Filters</span>
          </v-tooltip>

        </v-toolbar>
      </template>

      <!-- Action Buttons -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-btn icon color="secondary" @click="openOrgEditForm(item, true)">
            <v-icon dark>mdi-pencil</v-icon></v-btn>

          <!--   BEGIN DELETE BUTTONS      -->
          <v-tooltip top v-if="!item.canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-btn  v-bind="attrs" v-on="on"  icon class="ml-3" color="grey lighten-1" >
                <v-icon >mdi-trash-can</v-icon></v-btn>
            </template>
            <span>Please remove all projects in organization to delete it.</span>
          </v-tooltip>

          <v-btn v-else icon class="ml-3" color="secondary" :disabled="!item.canDelete" @click="deleteOrg(item)">
            <v-icon dark>mdi-trash-can</v-icon></v-btn>
          <!--   END DELETE BUTTONS      -->


        </div>
      </template>
    </v-data-table>

  </div>

  <!-- Organization editor dialogue box -->
  <v-dialog
    :value="showOrganizationForm"
    transition="fade"
    persistent
    width="500">

    <v-card>
      <v-card-title>
        <span class="headline">{{editing ? 'Edit' : 'Create' }} Organization</span>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-form ref="organizationEditForm" v-model="valid">
          <v-text-field v-model="editingOrg.name"
            label="Organization Name"
            outlined dense
            :rules="[InputRules.emptyValueGEN('Please enter an organization name.')]"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary"
          @click="showOrganizationForm = false">Cancel</v-btn>
        <v-btn text color="green lighten-1" @click="closeOrgEditForm" :disabled="!valid">{{ !editing ? 'Save' : 'Update' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <x-confirm-dialog
    ref="confirmDelete"
    title="Delete Organization?"
    :message="`${selectedDeleteItem ? `${selectedDeleteItem.name} will be deleted`: '' }`"
    :showConfirmCheckbox="true" />

</div></template>

<script>
  import Api from "@/api/api";
  import XExpandAlert from '@/components/_generics/x-expand-alert.vue';
  import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";
  import InputRules from '@/utils/inputFieldRules';
  import * as FC from '@/components/filtering/constants.js';

  export default {
    name: "AdminOrganizations",

    components: {
      XExpandAlert,
      XConfirmDialog,
    },
    mixins: [InputRules],

    mounted() {
      this.refreshOrganizations();
      this.initFilters();
    },

    computed: {
      filterableHeaders() {
        return this.headers.filter(header => header.value !== '' && header.value !== 'actions');
      },
    },

    data() {
      return {
        loading: false,
        valid: false,
        editing: false,

        data: [],
        filteredData: [],

        showOrganizationForm: false,
        editingOrg: {},
        selectedDeleteItem: {},

        headers: [
          { text: 'Name', value: 'name', sortable: true,  type: FC.FILTER_EQUATION_S, filters: {} },
          { text: '',     value: 'actions',     sortable: false, type: FC.FILTER_NONE },
        ],

        FC,
        equationInfo: false,
      }
    },

    methods: {
      async refreshOrganizations() {
        this.loading = true;
        Api.Organization.read().then(res => {
          this.data = res.data.$values;
          this.filteredData = res.data.$values;

          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.displayAlert('Failed to retrieve organizations');
          console.log(err);
        });
      },

      displayAlert(msg, type='error') {
        this.$refs.alert.setMessage(msg, type);
      },

      openOrgEditForm(org, editing = false) {
        if (org == null) {
          this.editingOrg = {
            isActive: true,
            customerKey: 0,
            name: '',
            canDelete: true,
          };
        } else {
          // Deep copy org
          this.editingOrg = JSON.parse(JSON.stringify(org));
        }

        this.editing = editing;
        this.showOrganizationForm = true;
      },

      closeOrgEditForm() {
        if (!this.$refs.organizationEditForm.validate()) return;

        this.loading = true;
        if (this.editing) {
          this.updateOrg();
        } else {
          this.createOrg();
        }

        this.showOrganizationForm = false;
      },

      createOrg() {
        Api.Organization.create(this.editingOrg).then(res => {
          this.refreshOrganizations();
          this.displayAlert(`${this.editingOrg.name} created successfully.`, 'success');
        }).catch(err => {
          this.displayAlert(`Error creating ${this.editingOrg.name}.`);
          console.log(err);
        });
      },

      updateOrg() {
        Api.Organization.update(this.editingOrg).then(res => {
          this.refreshOrganizations();
          this.displayAlert(`${this.editingOrg.name} updated successfully.`, 'success');
        }).catch(err => {
          this.displayAlert(`Error updating ${this.editingOrg.name}.`);
          console.log(err);
        });
      },

      async deleteOrg(item) {
        this.selectedDeleteItem = item;
        const shouldDelete = await this.$refs.confirmDelete.confirmAction();
        if (!shouldDelete) return;

        this.loading = true;
        Api.Organization.delete(item).then(res => {
          this.displayAlert(`${item.name} deleted successfully.`, 'success');
          this.refreshOrganizations();
        }).catch(err => {
          console.log('deleteOrg(ERR)', err);
          this.loading = false;
        });
      },


      initFilters() {
        // Set advanced filters
        this.filterableHeaders.forEach((header, i) => {
          if (header.type !== FC.FILTER_NONE) {
            this.resetAdvancedFilter(i);
          }
        });
      },

      filterData() {
        this.filteredData = FC.filterData(this.data, this.filterableHeaders);
      },

      resetAdvancedFilter(index) {
        // Reset a specific filter
        if (FC.EQ_FILTERS.includes(this.filterableHeaders[index].type)) {
          this.filterableHeaders[index].filters = { first: '', operator: '', second: '' };
        }

        else if (this.filterableHeaders[index].type === FC.FILTER_BINARY) {
          this.filterableHeaders[index].filters = { true: true, false: true}; // Check both boxes
        }

        this.filterData()
      },
    },


  };
</script>

<style lang="scss"></style>
