<template>
    <div class="px-6">
        <v-container style="max-width: 100%;" class="px-0">
            
            <!-- Page Header -->
            <v-row>
                <v-col>
                    <h2>All Media</h2>
                </v-col>
                <v-col v-if="!userIsViewer">
                    <div class="d-flex justify-end">
                        <v-btn color="primary" style="margin-right: 16px" @click="goToNewMedia">
                            <v-icon class="mr-2">mdi-plus</v-icon>Add New
                        </v-btn>
                        <XMultipleFileUpload
                            @uploaded="loadMedia()"></XMultipleFileUpload>
                    </div>
                </v-col>
            </v-row>

            <!-- Media List -->
            <v-row><v-col>
                <v-sheet>
                    <v-data-table :headers="mediaHeaders"
                                    :items="media"
                                    class="elevation-0 fill-width accent-border"
                                    :loading="loading">
                        <template v-slot:item="row">
                            <tr class="fill-width">
                                <td>{{row.item.sortOrder}}</td>
                                <td>{{row.item.name}}</td>
                                <td>{{row.item.fileKey}}</td>
                                <td>{{row.item.videoDurationString || "00:00:00"}}</td>
                                <td>{{row.item.mediaTypeName}}</td>
                                <td>{{row.item.completeCount}}</td>
                                <td>{{row.item.activeCount}}</td>
                                <td>{{row.item.partialCount}}</td>
                                <td>{{row.item.disqualifiedCount}}</td>
                                <td>{{row.item.mediaQuotaCounter}}/{{row.item.viewerLimit}}</td>
                                <td>
                                    <!-- Show Action Buttons for Editors -->
                                    <div v-if="!userIsViewer" class="d-flex justify-end">
                                        <!-- EDIT BUTTON -->
                                        <v-btn icon class="mr-3" color="secondary" @click="goToMedia(row.item.fileKey)"
                                                :disabled="loading">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>

                                        <!-- Copy Button Dialog -->
                                        <v-dialog max-width="450">
                                            <template v-slot:activator="{ on, popout }">
                                                <v-btn icon class="mx-3" color="secondary"
                                                        :disabled="loading"
                                                        v-on="on"
                                                        v-bind="popout">
                                                        <v-icon dark>mdi-content-copy</v-icon></v-btn>
                                            </template>
                                            <template v-slot:default="dialog">
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="headline">Copy Media</span>
                                                    </v-card-title>
                                                    <v-card-text class="mt-6">
                                                        Are you sure you want to copy this media item?
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text @click="dialog.value = false">
                                                            <v-icon>mdi-cancel</v-icon>Cancel</v-btn>
                                                        <v-btn color="blue darken-1" text
                                                                @click="copyMedia(row.item.fileKey); dialog.value = false">
                                                            <v-icon>mdi-content-copy</v-icon>Copy</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>

                                        <!-- DELETE BUTTON -->
                                        <v-btn icon class="ml-3" color="secondary"
                                                :disabled="loading"
                                                @click="tryDeleteMedia(row.item.fileKey)">
                                            <v-icon dark>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </div>

                                    <!-- Show Visual Report Button for Viewers -->
                                    <div v-else class="d-flex justify-end">
                                        <v-btn icon class="ml-3" color="secondary"
                                                @click="goToVisualReport(row.item.fileKey)">
                                            <v-icon dark>mdi-eye</v-icon> CATS
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col></v-row>
        </v-container>

        <x-confirm-dialog ref="confirmMediaDelete"
            title="Delete Media?"
            message=""
            :showConfirmCheckbox="true"></x-confirm-dialog>
    </div>
</template>

<script>
    import XMultipleFileUpload from '@/components/x-multiple-file-upload.vue';
    import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";
    import Api from '@/api/api';
    import Events from "@/events/events";

    export default {
        components: {
            XMultipleFileUpload,
            XConfirmDialog
        },

        mounted() {
            this.fetchProjectInfo();
        },

        activated() {
            this.fetchProjectInfo();
        },

        deactivated() {
            clearTimeout(this.timeout);
            this.timeout = null;
        },

        beforeDestroy() {
            clearTimeout(this.timeout);
            this.timeout = null;
        },

        beforeRouteLeave(to, from , next) {
            clearTimeout(this.timeout);
            this.timeout = null;
            next();
        },

        data() {
            return {
                tab: 'active',
                projectId: null,
                mediaHeaders: [
                    {
                        text: 'Sort Order',
                        align: 'start',
                        sortable: true,
                        value: 'sortOrder',
                    },
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    },
                    {
                        text: 'Media ID',
                        align: 'start',
                        sortable: true,
                        value: 'fileKey',
                    },
                    {
                        text: 'Media Length',
                        align: 'start',
                        sortable: true,
                        value: 'mediaLength',
                    },
                    {
                        text: 'Media Type',
                        align: 'start',
                        sortable: true,
                        value: 'mediaType',
                    },
                    {
                        text: 'Complete Count',
                        align: 'start',
                        sortable: true,
                        value: 'completeCount',
                    },
                    {
                        text: 'Actives',
                        align: 'start',
                        sortable: true,
                        value: 'activeCount',
                    },
                    {
                        text: 'Partials',
                        align: 'start',
                        sortable: true,
                        value: 'partialCount',
                    },
                    {
                        text: 'Disqualified',
                        align: 'start',
                        sortable: true,
                        value: 'disqualifiedCount',
                    },
                    {
                        text: 'Quotas',
                        align: 'start',
                        sortable: true,
                        value: 'mediaQuotaCounter',
                    },
                    { text: '', value: 'actions', sortable: false, },
                ],

                media: [],
                loading: false,
                timeout: null,
            }
        },

        computed: {
            userIsViewer() {
                const projectInfo = this.$store.state.ProjectStore.currentProjectInfo;

                if (!projectInfo) return true;
                return !projectInfo.isAdmin && projectInfo.currentUserType == 'Viewer';
            },
        },

        methods: {
            fetchProjectInfo() {
                this.projectId = this.$route.params.projectId;
                const customerId = null;

                Api.Projects.getProjectInfo(this.projectId, customerId).then(res => {
                    this.$emit('changeProjectTitle', {
                        projectName: res.data.projectName,
                        description: res.data.description,
                        customer: res.data.customerName,
                        currentUserType: res.data.currentUserType,
                        isAdmin: res.data.isAdmin,
                        activeTab: 2,
                    });
                });

                this.loadMedia(this.projectId);
            },

            loadMedia() {
                this.loading = true;
                Api.Media.mediaRead(this.projectId).then(res => {
                    this.media = res.data.$values;
                    this.loading = false;

                    // Find the next open sort order (start at 1)
                    let openSortOrder = 1;
                    this.media.forEach(e => {
                        if (e.sortOrder === openSortOrder) {
                            openSortOrder++;
                        } else {
                            return;
                        }
                    });

                    // Put in local storage
                    localStorage.setItem('openSortOrder', openSortOrder);

                    // Set timeout for the next load
                    this.timeout = setTimeout(() => {
                        this.loadMedia();
                    }, 30 * 1000);
                });
            },

            goToNewMedia() {
                this.$router.push({ name: 'Media' });
            },
            
            goToMedia(mediaID=null) {
                this.$router.push({ name: 'Media', params: { mediaID } });
            },

            copyMedia(mediaId) {
                this.loading = true;
                Api.Media.copyMedia(this.projectId, mediaId).then(res => {
                    console.log('copyMedia():', res);
                    this.fetchProjectInfo();
                });
            },

            // Opens the delete menu, and waits for the user to confirm or deny the action.
            async tryDeleteMedia(mediaId) {
                const shouldDelete = await this.$refs.confirmMediaDelete.confirmAction();
                if (!shouldDelete) return;

                this.loading = true;
                Api.Media.deleteMedia(mediaId).then(res => {
                    Events.$emit('updateMedia');
                    this.loadMedia();
                }).catch(err => {
                    console.log('tryDeleteMedia(ERR):', err);
                    this.loading = false;
                });
            },

            goToVisualReport(mediaKey) {
                this.$router.replace({ name: 'Visual Report', params: { mediaID: mediaKey} });
            },
        }
    };
</script>

<style lang="scss"></style>