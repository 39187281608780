<template><v-layout class="justify-center align-center column fill-height">
	
	<v-card width="450px" class="my-8" color="primary">
		<v-card-title class="py-6">
			<v-img alt="Dialsmith Logo" class="ma-4" contain src="/images/dialsmith-logo.svg" height="52"></v-img>
		</v-card-title>

		<v-card-text style="background: white" class="pa-8">

			<!-- Loading Spinner-->
			<div v-show="loadingInitial" class="loading-container">
				<v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
			</div>
			
			<!-- Card Content -->
			<div v-show="!loadingInitial">
				<x-expand-alert ref="alert" :dismissible="false"></x-expand-alert>
			</div>
			<div v-show="!loadingInitial && !loadingInitialFailed">

				<div class="card-header">To complete your registration, please enter a password:</div>

				<v-form v-model="validForm" ref="form">
					<v-text-field v-model="firstPassword"
												label="Password"
												outlined
												:rules="requiredPassword"></v-text-field>

					<v-text-field v-model="secondPassword"
												label="Confirm Password"
												outlined
												:rules="requiredPassword.concat(matchesFirst)"></v-text-field>

					<v-btn @click="validate()"
									color="secondary"
									style="width: 100%"
									class="mb-4"
									:disabled="!validForm || loadingValidation || loadingInitialFailed">{{ !loadingValidation ? 'Validate' : 'Validating...' }}</v-btn>
				</v-form>
			</div>
</v-card-text>
	</v-card>

</v-layout></template>

<script>
	import Api from '@/api/api';
	import XExpandAlert from '@/components/_generics/x-expand-alert.vue';

	const REDIRECTION_TIMEOUT = 3000;

	const Registration = {
		mounted() {
			// Get email and token from query string
			const urlParams = new URLSearchParams(window.location.search);
			const email = urlParams.get('email');
			const token = urlParams.get('token');

			this.getActivationInformation(email, token);
		},

		components: {
			XExpandAlert
		},

		data() {
			return {
				// Models
				model: null,
				firstPassword: '',
				secondPassword: '',
				validForm: false,

				// Rules
                requiredPassword: [
                    v => !!v || 'Required',
                ],
				matchesFirst: [
					v => v === this.firstPassword || 'Passwords do not match',
				],

				// Loading bindings
				loadingInitial: true,
				loadingInitialFailed: false,
				loadingValidation: false,
			}
		},

		methods: {
			getActivationInformation(email, token) {
				Api.Authentication.activateAccount(email, token).then(res => {
					//console.log('getActivationInformation():', res);
					this.loadingInitial = false;
					// Check for errors in response
					if (res.data.message || res.data.errors) {
						this.loadingInitialFailed = true;
						//console.log("res.data.errors: ", res.data);
						//console.log("this.$refs: ", this.$refs);
						//console.log("this.$refs.alert: ", this.$refs.alert);
						//this.showAlert(res.data.errors.$values[0].message);
						if (res.data.message) {
							this.$refs.alert.setMessage(res.data.message, 'error');
						} else {
							this.$refs.alert.setMessage(res.data.errors.$values[0].message, 'error');
						}
					} else {
						this.model = res.data;
					}
				}).catch(err => {
					console.log("error: ", err)
					this.loadingInitial = false;
					this.loadingInitialFailed = true;
					this.showAlert('An error occurred when fetching activation information...');
				});
			},

			validate() {
				this.$refs.form.validate();
				if (!this.validForm) return;
				this.loadingValidation = true;

				// #REGION - Untested Validation
				// Do validation before sending stuff to api
				// Make sure passwords match regardless of rules
				if (this.firstPassword !== this.secondPassword) {
					this.loadingValidation = false;
					this.showAlert('Passwords do not match');
					return;
				}
				// Check they are in bounds of the api
				const max = this.model.maximumPasswordLength;
				const min = this.model.minimumPasswordLength;
				if (this.firstPassword.length > max || this.firstPassword.length < min) {
					this.loadingValidation = false;
					this.showAlert(`Password must be between ${min} and ${max} characters`);
					return;
				}
				// #ENDREGION

				// Hook up form values to model
				this.model.password = this.firstPassword;
				this.model.confirmPassword = this.secondPassword;
				Api.Authentication.completeRegistration(this.model).then(res =>{
					console.log('validate():', res);

					// Check if res has an error in data
					if (res.data.errors) {
						const err = res.data.errors.$values;
						this.showAlert(err);
					}
					
					// Redirect to login (assuming success)
					this.redirectToLogin();
				}).catch(err => {
					console.log('validate(ERR):', err);
					// Display error
					this.showAlert('Validation failed...');
					this.loadingValidation = false;
				});
			},

			redirectToLogin() {
				this.showAlert(`Successfully validated, redirecting in ${REDIRECTION_TIMEOUT / 1000} seconds...`, 'success');
				setTimeout(() => {
					this.$router.push('/');
				}, REDIRECTION_TIMEOUT);
			},

			showAlert(msg, theme = 'error') {
				this.$refs.alert.setMessage(msg, theme);
			},
		}
	};

	export default Registration;
</script>

<style lang="scss" scoped>
	.card-header {
		font-size: 1.1rem;
		margin-bottom: 1.5rem;
	}

	.loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
