<template>
  <div class="d-flex flex-column align-center" :class="{'screen_width': $store.state.ReportStore.visualReportLayout === 'Full Width'}">
    <v-sheet elevation="0" class="fill-width">

      <!-- We want to render media items if we have any.
        This let's us refresh the media items in the background when the user focuses this tab again. -->
      <div v-if="mediaItems instanceof Array && mediaItems.length > 0 && !loadingMediaItems" class="d-flex flex-column flex-sm-row">
        <div class="pt-4" style="border-right: 2px solid var(--v-accent-base); width: fit-content; max-width: 250px;" v-show="$store.state.ReportStore.visualReportLayout === 'Standard'">
          <v-tabs vertical grow style="height: 100%" color="secondary">
            <!-- Wrapped in div to prevent from tabs from auto expanding to the full height -->
            <div style="max-width: 250px">
              <div class="d-flex justify-center">
                <x-export-media-dialog :project-id="projectId" :media-items="mediaItems" :paramList="paramList"/>
              </div>

              <div class="mt-4" style="border-bottom: 1px solid var(--v-accent-base)"></div>

              <!-- Cool loading indicator that mimicks what v-cards do.  -->
              <!-- <div><v-progress-linear :style="`opacity: ${loadingMediaItems ? '1' : '0'};`" indeterminate color="secondary"></v-progress-linear></div> -->

              <v-tab
                v-for="item in mediaItems"
                v-bind:key="item.$id"
                @click="lastSelectedKey = item.fileKey"
                :to="`/projects/${projectId}/reports/${item.fileKey}/visual`"
                class="justify-start">

                <span class="ml-2">{{item.name}} ({{item.fileKey}})</span>
              </v-tab>
            </div>
          </v-tabs>
        </div>

        <div class="flex-grow-1">
          <transition name="fade" mode="out-in">
            <!-- Keeps limited number of components mounted to make it faster to switch between router views -->
            <keep-alive >
              <router-view></router-view>
            </keep-alive>
          </transition>
        </div>
      </div>

      <!-- Loading media items... -->
      <div
        v-else-if="loadingMediaItems"
        style="min-height: 400px;"
        class="d-flex align-center justify-center flex-column">

        <h2 class="error-title mb-6">Loading media items...</h2>
        <v-progress-circular indeterminate :size="64"></v-progress-circular>
      </div>

      <!-- No media items found error -->
      <div
        v-else-if="mediaItems instanceof Array && mediaItems.length == 0"
        style="min-height: 400px;"
        class="d-flex align-center justify-center flex-column">

        <h2 class="error-title mb-3">No media found...</h2>
        <p><router-link :to="`/projects/${projectId}/media-list`">Please upload a media item.</router-link></p>
      </div>

      <!-- General error rendering -->
      <div v-else style="min-height: 400px;" class="d-flex align-center justify-center flex-column">
        <h2 class="error-title mb-3">Unable to load media items</h2>
        <p style="text-align center">{{errorText}}</p>
      </div>
    </v-sheet>
  </div>
</template>

<script>
  import Api from '@/api/api.js';
  import Store from '@/store/store';
  import XExportMediaDialog from "@/components/projects/reports/x-export-media-dialog";

  const Reports = {
    components: {
      XExportMediaDialog,
    },
    data() {
      return {
        projectId: null,
        loadingMediaItems: true,
        mediaItems: null,
        lastSelectedKey: null,
        errorText: 'Unknown error. See browser console...',
        paramList: [],
        surveyProviderParameters: []
      };
    },

    mounted() {
      this.projectId = this.$route.params.projectId;
      const customerId = null;

      Api.Projects.getProjectInfo(this.projectId, customerId).then(res => {
        this.$emit('changeProjectTitle', {
          projectName: res.data.projectName,
          description: res.data.description,
          customer: res.data.customerName,
          currentUserType: res.data.currentUserType,
          isAdmin: res.data.isAdmin,
          activeTab: 4,
        });
      });

      this.getProjectReport()
    },

    beforeRouteUpdate (to, from, next) { next(); },
    beforeRouteEnter (to, from, next) { next(); },

    // Ran even if this component is resurrected from cache.
    activated() {
      this.refreshMediaItems();
    },

    methods: {
      refreshMediaItems() {
        this.loadingMediaItems = true;

        Api.Media.mediaRead(this.projectId).then(res => {
          this.mediaItems = res.data.$values;

          // redirecting to either the last selected media item, or the first one in the list
            // if we are on the root of the reports.
          let redirectedToLastMedia = false;

          if (this.$route.name == "Project Reports") {
            if (this.lastSelectedKey != null) {
              this.$router.replace({ name: 'Visual Report', params: { mediaID: this.lastSelectedKey } });
              redirectedToLastMedia = true;
            }
          }

          if (this.$route.params.mediaID > 0) {
            this.$router.replace({ name: 'Visual Report', params: { mediaID: this.$route.params.mediaID } });
            redirectedToLastMedia = true;
          }

          // Push first media item to router
          if (this.mediaItems && this.mediaItems.length > 0 && !redirectedToLastMedia) {
            this.lastSelectedKey = this.mediaItems[0].FileKey;
            this.$router.replace({ name: 'Visual Report', params: { mediaID: this.mediaItems[0].fileKey } });
          }

          this.loadingMediaItems = false;
        }).catch(err => {
          this.loadingMediaItems = false;
          this.errorText = err.response.data.message;
        });
      },

      getProjectReport() {
        Api.Projects.getProjectReports(this.projectId).then(response => {
            this.getParameterList(response.data.surveyProviderParameters.$values);
            this.surveyProviderParameters = response.data.surveyProviderParameters.$values;
          }).catch(error => {
            console.log(error);
          });
      },

      getParameterList(providerParams) {
        if (providerParams.length <= 0) return;

        // Function to filter survey Provider Parameters to the first param in a group
        this.paramList = providerParams.filter((value, index, self) => {
          return self.findIndex(v => v.parameterName === value.parameterName) === index
        });
      }
    },
  };

  export default Reports;
</script>

<style scoped lang="scss">
  .screen_width {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .error-title {
    font-size: 2em;
    font-weight: 300;
    text-align: center;
  }
</style>
