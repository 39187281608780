<template>
  <!-- Extending the Vuetify text field to have a quick way to toggle password visibility.  -->
  <v-text-field v-bind="$attrs" v-on="$listeners"
    :type="visiblePassword ? 'text' : 'password'"
    :append-icon="visiblePassword ? 'mdi-eye-off' : 'mdi-eye'"
    @click:append="togglePasswordVisibility">

    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="data">
      <slot :scopedSlotName="name" v-bind="data" />
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>

  </v-text-field>
</template>

<script>
  const XPasswordField = {
    data() {
      return {
        visiblePassword: false,
      }
    },

    props: [],

    methods: {
      togglePasswordVisibility() {
        this.visiblePassword = !this.visiblePassword;
      }
    }
  };

  export default XPasswordField;
</script>

<style></style>