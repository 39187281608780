<template>
  <youtube :video-id="videoID" :playerVars="options" @playing="playing" @ended="ended" ref="youtube"></youtube>
</template>

<script>
  // play, pause, current time, set time ?fs=0&start=5
  import Events from '@/events/events.js';

  const XYouTubePlayer = {
    props: {
      videoID: String,
      startTime: String,
      endTime: String,
      propsOptions: Object
    },
    mounted() {
      Events.$on("pauseYoutube", data => { this.pauseYoutubeVideo() });
      Events.$on("getYoutubeTime", data => { this.sendYoutubeDuration() });
    },
    data() {
      // Convert string in form 00:00:00 to seconds
      let start = null;
      if (this.startTime !== undefined && this.startTime !== '00:00:00') {
        start = this.startTime.split(':').reduce((acc, curr) => acc * 60 + +curr);
      }

      let end = null;
      if (this.endTime !== undefined && this.endTime !== '00:00:00') {
        end = this.endTime.split(':').reduce((acc, curr) => acc * 60 + +curr);
      }

      return {
        player: {},
        currentTime_: 0,
        youtubeURL: '',
        videoEnded: false,
        videoStarted: false,
        options: {
          autoplay: 0,
          start: start,
          end: end,
          ...this.propsOptions
        }
      }
    },

    computed: {
      currentTime: {
        get: ({ currentTime_ }) => currentTime_,
        set(time) {
          this.$refs.video.currentTime = time
        }
      }
    },

    methods: {
      playYoutubeVideo() {
        this.$refs.youtube.player.playVideo();
      },

      pauseYoutubeVideo() {
        this.$refs.youtube.player.pauseVideo();
      },

      getYoutubeDuration() {
        return this.$refs.youtube.player.getCurrentTime();
      },

      playing() {
        this.videoStarted = true;
      },

      playerStateChange(){
        return this.$refs.youtube.playerStateChange();
      },

      ended(){
        this.$emit('videoEnded');
        console.log('video termino');
        this.videoEnded = true;
      },

      sendYoutubeDuration() {
        var timePromise = Promise.resolve(this.$refs.youtube.player.getCurrentTime());
        timePromise.then(function(seconds) {
          var time = seconds;
          Events.$emit('sendYoutubeTime', time);
        });
      },

      skipTime(time) {
        this.$refs.youtube.player.seekTo(time)
      }

    }
  };

  export default XYouTubePlayer;
</script>

<style scoped lang="scss"></style>
