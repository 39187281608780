<template>
  <div class="d-flex flex-column align-center mt-4">
    <v-sheet style="max-width: 600px; overflow: hidden;" class="mt-4 mb-12 fill-width" elevation="1"  rounded>
      
      <!-- Cool loading indicator that mimicks what v-cards do.  -->
      <div><v-progress-linear :style="`opacity: ${loading? '1': '0'};'`" indeterminate color="secondary"></v-progress-linear></div>
      <div class="px-6 pt-4">
        <x-expand-alert type="success" ref="alert" :dismissible="true" :timeoutMs="5000"></x-expand-alert>
        
        <h2>User Profile</h2>
        <p>Click save if you made any changes to your profile.</p>

        <v-form ref="profileForm" class="pt-2">
          <v-text-field label="Email" 
            v-model="user.emailAddress"
            outlined
            required
            dense
            style="width: 50%; min-width: 300px;"
            :rules="[InputRules.isEmail]"
            :disabled="loading">
          </v-text-field>

          <v-text-field label="Username" 
            v-model="user.userName"
            outlined
            required
            dense
            style="width: 50%; min-width: 300px;"
            :disabled="loading">
          </v-text-field>

          <v-select label="Timezone"
            v-model="user.timeZone"
            :items="timezones"
            item-text="text"
            dense
            item-value="value"
            style="width: 75%; min-width: 300px;"
            outlined
            :disabled="loading"></v-select>
        </v-form>
      </div>

      <v-divider></v-divider>
      <div class="d-flex px-4 py-4">
        <v-btn color="secondary"
          @click="saveProfile" 
          :disabled="loading"
          class="ml-auto">Save</v-btn>
      </div>
    </v-sheet>

    <div class="d-flex flex-column align-center">
      <legal-notices class="mb-6"></legal-notices>

      <div >
        <v-btn color="primary" to="/"><v-icon dense class="mr-2">mdi-logout-variant</v-icon>Sign Out</v-btn>
      </div>

    </div>  
  </div>
</template>

<script>
  import Api from '@/api/api';
  import LegalNotices from '@/components/AppLegalNotices.vue';
  import InputRules from "@/utils/inputFieldRules";
  import XExpandAlert from '@/components/_generics/x-expand-alert.vue';

  const Profile = {
    name: "Profile",

    mixins: [InputRules],
    components: {
      XExpandAlert,
      LegalNotices,
    },

    mounted() {
      this.fetchInfo();
    },

    data() {
      return {
        loading: true, 
        user: {},
        timezones: [],
      }
    },

    methods: {
      async fetchInfo() {
        // Get user info and timezones
        this.loading = true;
        const [userRes, timezoneRes] = await Promise.all([
          Api.Authentication.getUser(),
          Api.Authentication.getTimeZones(),
        ]);

        this.user = userRes.data;
        this.timezones = timezoneRes.data;
        this.loading = false;
      },

      saveProfile() {
        this.loading = true;

        Api.Profile.update(this.user).then(res => {
          // For some reason, a 400 doesn't go to .catch, so we have to check here.
          if (res.status !== 200) {
            this.throwError('Failed to save profile!');
            return;
          }
          
          this.$refs.alert.setMessage("Profile saved successfully.");
          this.loading = false;
        }).catch(err => {
          this.throwError('Failed to save profile!');
        });
      },

      throwError(msg) {
        this.$refs.alert.setMessage(msg, 'error');
        this.loading = false;
      },
    },
  };

  export default Profile;
</script>

<style lang="scss"></style>