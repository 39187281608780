import { AxiosStatic } from "axios";

/**
 * An interface so we can pass the preconfigured Axios client into the route without imports.
 * 
 * ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !
 *  ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !
 * ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !
 *    MAKE SURE TO ADD ROUTES TO API.js!!!
 * ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !
 *  ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !
 * ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !
 * 
 * Please handle UI and State updates WHERE you are calling these routes NOT INSIDE. 
 */
export default class Route {

  /**
   *@param {AxiosStatic} axios 
   */
  constructor(axios) {
    // https://axios-http.com/docs/post_example
    this.axios = axios;
  }

  sleep(delayMs) {
    return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null), delayMs);
    });
  }
}