<template>
  <div>
    <!-- There was a reference to :color=success here but I removed it as it threw errors -->
    <v-snackbar v-model="snackbar" :timeout="2000" :top="true">
      {{ snackBarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-data-table :headers="headers" :items="subsets" class="elevation-0 fill-width accent-border" :loading="loading">

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <h2>Add/Edit Subsets</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
                Add New
              </v-btn>
            </template>
          </v-dialog>

          <!-- Dialog -->
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <!-- TODO: Fix calculated subsets and re-enable -->
                      <!-- <v-checkbox
                        v-if="!editedItem.isPassedIn" label="Calculated Subset?" v-model="editedItem.isCombination" outlined
                        @click="editedItem.parameters = [], editedItem.add = false"></v-checkbox> -->

                      <!-- Calculated Subset Options -->
                      <div v-if="editedItem.isCombination">
                        <h2>Choose Answers</h2>
                        <div v-for="(group, Inx) in groupedSubsets" :key="Inx">
                          <div class="group" :data-value="`${Inx}`">
                            <h3>{{group.parameterName}}</h3>
                            <div class="answer-box">
                              <div v-for="(subset, Inx2) in group.objects" :key="Inx2">
                                <v-checkbox :label="`${subset.value}`" :value="subset.surveyProviderParameterKey" v-model="editedItem.parameterCombinations" hide-details></v-checkbox>
                              </div>
                              <div class="clear-all" @click="clearAnswers(Inx)">
                                clear all <v-icon x-small>mdi-close-circle</v-icon>
                              </div>
                            </div>
                            <div v-if="Inx != groupedSubsets.length - 1">
                              <h3><em>AND</em></h3>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Question ID -->
                      <v-text-field label="Question ID*"
                        v-if="!editedItem.isCombination"
                        outlined dense
                        v-model="editedItem.parameterID"
                        :rules="!editedItem.isCombination ? required : []"></v-text-field>

                      <!-- Question Label -->
                      <v-text-field label="Question Label*"
                        outlined dense
                        v-model="editedItem.parameterName"
                        :rules="required"></v-text-field>

                      <!-- Answer ID -->
                      <v-text-field :label="'Answer ID' + (!editedItem.isCombination ? '*' : '')"
                        v-if="!editedItem.isCombination"
                        outlined dense
                        v-model="editedItem.name"
                        :rules="!editedItem.isCombination ? required : []"></v-text-field>

                      <!-- Answer Label -->
                      <v-text-field label="Answer Label*"
                        outlined dense
                        v-model="editedItem.value" :rules="required"></v-text-field>

                      <!-- Graph Color -->
                      <p>Graph Color</p>
                      <v-color-picker v-model="editedItem.color"></v-color-picker>

                      <!-- Favorite -->
                      <v-checkbox label="Favorite" v-model="editedItem.favorite"></v-checkbox>

                      <!-- Coded Return -->
                      <v-checkbox label="Coded Return" v-model="editedItem.isCodedReturnValue"></v-checkbox>

                      <!-- Return URL Include -->
                      <v-checkbox label="Return URL Include" v-model="editedItem.includeInFinalURL"></v-checkbox>

                      <!-- Hide Subset -->
                      <v-checkbox label="Hide Subset" v-model="editedItem.isHidden"></v-checkbox>

                      <!-- Passed In -->
                      <!-- <v-checkbox label="Passed In" v-model="editedItem.isPassedIn"></v-checkbox> -->
                      <!-- Add Another Answer -->
                      <v-checkbox label="Add Another Answer" v-if="!editedItem.isCombination" v-model="editedItem.add" @change="checkAddMoreParent()"></v-checkbox>

                    </v-col>

                    <div v-for="(parameter, Inx) in editedItem.parameters" :key="Inx">
                      <v-col>
                        <!-- Question ID -->
                        <!-- <v-text-field :rules="required" label="Question" outlined dense v-model="parameter.parameterID"></v-text-field> -->
                        <!-- Question Label -->
                        <!-- <v-text-field :rules="required" label="Question Label" outlined dense v-model="parameter.parameterName"></v-text-field> -->
                        <!-- Answer ID -->
                        <v-text-field :rules="required" label="Answer ID" outlined dense v-model="parameter.name"></v-text-field>

                        <!-- Answer Label -->
                        <v-text-field :rules="required" label="Answer Label" outlined dense v-model="parameter.value"></v-text-field>

                        <!-- Graph Color -->
                        <p>Graph Color</p>
                        <v-color-picker v-model="parameter.color"></v-color-picker>

                        <!-- Favorite -->
                        <v-checkbox label="Favorite" v-model="parameter.favorite"></v-checkbox>

                        <!-- Coded Return -->
                        <v-checkbox label="Coded Return" v-model="parameter.isCodedReturnValue"></v-checkbox>

                        <!-- Return URL Include -->
                        <v-checkbox label="Return URL Include" v-model="parameter.includeInFinalURL"></v-checkbox>

                        <!-- Hide Subset -->
                        <v-checkbox label="Hide Subset" v-model="parameter.isHidden"></v-checkbox>

                        <!-- Passed In -->
                        <!-- <v-checkbox label="Return URL Include" v-model="parameter.isPassedIn"></v-checkbox> -->
                        <!-- Add Another Answer -->
                        <v-checkbox label="Add Another Answer" v-if="!editedItem.isCombination" v-model="parameter.add" @change="checkAddMore(Inx)"></v-checkbox>

                      </v-col>
                    </div>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dialog = false">
                  <v-icon>mdi-cancel</v-icon>
                  Cancel
                </v-btn>
                <v-btn color="primary" text @click="addSurverParameters(editedItem)">

                  <div v-if="editedIndex < 0">
                    <v-icon>mdi-plus</v-icon> Add
                  </div>
                  <div v-else>Update</div>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog Delete -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary"
                       text
                       @click="closeDelete">
                  <v-icon>mdi-cancel</v-icon>
                  Cancel
                </v-btn>
                <v-btn color="Secondary"
                       text
                       @click="deleteItemConfirm">
                  <v-icon dark>mdi-trash-can</v-icon>
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- Custom Tables -->
      <template v-slot:item="{ item }">
        <tr :id="item.surveyProviderParameterKey" class="fill-width">
          <td>
            <v-icon small light v-if="item.isCombination">mdi-share-variant</v-icon>
          </td>
          <td>{{item.parameterID}}</td>
          <td>{{item.parameterName}}</td>
          <td>{{item.name}}</td>
          <td>{{item.value}}</td>
          <td>
            <div class="color-box" :style="{'background':item.color}">
            </div>
          </td>
          <td>
            <!-- Very important to use simple checkboxes in a data-table, otherwise it can generate lag -->
            <v-simple-checkbox :value="item.favorite" disabled></v-simple-checkbox>
          </td>
          <td>
            <v-simple-checkbox :value="item.isHidden" disabled></v-simple-checkbox>
          </td>
          <td>
            <v-simple-checkbox :value="item.isPassedIn" disabled></v-simple-checkbox>
          </td>
          <td>
            <v-simple-checkbox :value="item.isCodedReturnValue" disabled></v-simple-checkbox>
          </td>
          <td>
            <v-simple-checkbox :value="item.includeInFinalURL" disabled></v-simple-checkbox>
          </td>
          <td>
            <div class="d-flex justify-end">
              <!-- EDIT BUTTON-->
              <v-btn icon class="mr-1" color="secondary" @click="editItem(item)" :disabled="loading">
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <!-- DELETE BUTTON -->
              <v-btn icon class="ml-6" color="secondary" @click="deleteItem(item)" :disabled="loading">
                <v-icon dark>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item.surveyProviderParameterKey)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteSurveyParameters(item.surveyProviderParameterKey)">
          mdi-trash-can
        </v-icon>
      </template>
      <template v-slot:no-data>
        No data available
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import Api from '@/api/api';

  export default {
    components: {
      //XSubsetDialog
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'Add Item' : 'Edit Item'
      },
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },

    created() {
      this.initialize();
      this.getSubsets();
    },

    activated() {
      this.getSubsets();
    },
    mounted() {
      //console.log(this.subsets);

      this.loading = true;
      const projectId = this.$route.params.projectId;
      const customerId = null;

      Api.Projects.getProjectInfo(projectId, customerId).then(res => {
        this.$emit('changeProjectTitle', {
          projectName: res.data.projectName,
          description: res.data.description,
          customer: res.data.customerName,
          currentUserType: res.data.currentUserType,
          isAdmin: res.data.isAdmin,
          activeTab: 1,
        });
      });

    },

    data() {
      return {

        tab: 'active',
        required: [
          value => !!value || 'Required.',
        ],
        expanded: [],
        expand: false,
        expandButton: "Expand All",
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        deleteSurvey: 0,
        editedItem: {
          surveyProviderParameterKey: 0,
          parameterID: '',
          parameterName: "",
          name: "",
          value: "",
          color: "",
          favorite: false,
          isHidden: false,
          isPassedIn: false,
          isCombination: false,
          isCodedReturnValue: false,
          quotas: false,
          add: false,
          parameters: [],
          parameterCombinations: [],

        },
        defaultItem: {
          surveyProviderParameterKey: 0,
          parameterID: '',
          parameterName: "",
          name: "",
          value: "",
          color: "",
          favorite: false,
          isHidden: false,
          isPassedIn: false,
          isCombination: false,
          isCodedReturnValue: false,
          quotas: false,
          add: false,
          parameters: [],
          parameterCombinations: [],
        },

        subsetEditModel: {
          $id: "0",
          add: false,
          color: "#000000",
          combinationsJson: null,
          favorite: true,
          includeInFinalURL: false,
          isCodedReturnValue: true,
          isCombination: false,
          isHidden: false,
          isPassedIn: false,
          mediaQuestionKey: null,
          mediaQuestionType: "",
          name: "",
          parameterCombinations: [],
          parameterID: "",
          parameterName: "",
          parameters: null,
          projectKey: 0,
          surveyProviderParameterKey: 0,
          value: "",
        },

        headers: [{
          text: '',
          value: 'calculated',
          sortable: false,
        },
        {
          text: 'Question ID',
          align: 'start',
          sortable: true,
          value: 'parameterID',
        },
        {
          text: 'Question Label',
          align: 'start',
          sortable: true,
          value: 'parameterName',
        },
        {
          text: 'Answer ID',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Answer Label',
          align: 'start',
          sortable: true,
          value: 'value',
        },
        {
          text: 'Graph Color',
          align: 'start',
          sortable: true,
          value: 'color',
        },
        {
          text: 'Favorite',
          align: 'start',
          sortable: true,
          value: 'favorite',
        },
        {
          text: 'Hidden',
          align: 'start',
          sortable: true,
          value: 'isHidden',
        },
        {
          text: 'Passed In',
          align: 'start',
          sortable: true,
          value: 'isPassedIn',
        },
        {
          text: 'Coded Return',
          align: 'start',
          sortable: true,
          value: 'isCodedReturnValue',
        },
        {
          text: 'Return URL Include',
          align: 'start',
          sortable: true,
          value: 'includeInFinalURL',
        },
        {
          text: '',
          value: 'edit',
          sortable: false,
        },
        ],
        subsets: [],
        groupedSubsets: [],
        loading: false,
        snackbar: false,
        snackBarText: ``,
      }
    },

    methods: {
      initialize() {
        this.subsets = [

        ]
      },
      expandAll() {
        this.expand = !this.expand;
        if (this.expand) {
          this.$data.expanded = this.$data.organizations;
          this.expandButton = 'Collapse All';
        } else {
          this.$data.expanded = [];
          this.expandButton = 'Expand All';
        }
      },
      toggleTab(tab) {
        this.tab = tab;
      },
      rowClassGenerator() {
        return 'pointer';
      },
      goToMedia(mediaID) {
        this.$router.push({
          name: 'Media',
          params: {
            mediaID
          }
        });
      },

      getSubsets() {
        this.loading = true
        // Get Subsets
        const projectId = this.$route.params.projectId;
        Api.Projects.getSubsets(projectId).then(response => {
          this.loading = false;
          console.log(response.data);

          if (response.data.errorViewModel) {
            console.error('error loading subsets', response);
          } else {
            this.subsets = response.data.$values;
            console.log('subsets:', this.subsets);
            this.subsets.forEach(subset => {
              subset.parameterCombinations = subset.parameterCombinations.$values;
            });
            console.log('subsets:', this.subsets);
            this.groupSubsets();
          }
        });
      },
      groupSubsets() {
        //this.groupedSubsets = this.subsets.map(({parameterID}) => [ parameterID, { parameterID }]);
        this.loading = true
        this.groupedSubsets = [];
        this.subsets.forEach(element => {
          // Remove calculated subsets from the object
          if ( !element.isCombination ) {
            if (!this.groupedSubsets.some(e => e.parameterID == element.parameterID)) {
              this.groupedSubsets.push({"parameterID": element.parameterID, "parameterName": element.parameterName, "objects": []});
            }
            this.groupedSubsets.find(e => e.parameterID == element.parameterID).objects.push(element);
          }
        });

        this.loading = false
        // console.log("grouped subsets: ", this.groupedSubsets);
      },
      clearAnswers(index) {
        console.log("Test");
        console.log("Index:", index);
        var answerBox = document.querySelectorAll("[data-value='" + index + "'] input[type='checkbox']");
        console.log("answerbox: ", answerBox);
        answerBox.forEach(element => {
          if (element.checked) {
             element.click();
          }
        });
      },
      // Create/Update Subset
      addSurverParameters(subset) {
        if (!this.checkValidation(subset)) {
          this.snackbar = true;
          this.snackBarText = `All input fields are required, Try Again`;
          return;
        }

        const projectId = this.$route.params.projectId;
        //var addAnother = (subset.add) ? subset.add : false;
        console.log('addSurverParameters():', subset);
        Api.Projects.saveSurveyParameters(projectId, subset).then(res => {
          console.log('addSurverParameters()', res);
          //alert("Subset Saved");
          this.snackbar = true;
          this.snackBarText = `Subset Saved`,
          this.getSubsets();
          //if (addAnother) {
          //    this.editedItem = {surveyProviderParameterKey: 0, parameterID: '', parameterName: "", name: "", value: "", color: "", favorite: false, isHidden: false, isPassedIn: false, isCodedReturnValue: false, quotas: false, add: false,};
          //} else {
          this.close();
          //}
        })
          .catch(err => {
            if (this.editedItem === null) {
              this.snackbar = true;
              this.snackBarText = `There was an error saving the subset`,
                //alert("There was an error saving the subset");
                this.required;
            } else {
              //alert("Fileds Can't be Empty, Try Again");
              this.snackbar = true;
              this.snackBarText = `Fields Can't be Empty, Try Again`;

            }
            console.error('addSurverParameters()', err);
          })
      },
      checkValidation(subset) {
        if (!subset.isCombination && (subset.parameterID == "" || subset.value == "")) {
          return false;
        }
        if (subset.value == "" || subset.parameterName == "") {
          return false;
        }
        return true;
      },
      // Delete Item
      deleteSurveyParameters(subset) {
        if (confirm("You want to delete this item?") === true) {
          Api.Projects.deleteProviderParameter(subset).then((response) => {
            alert("Item Deleted!");
            this.getSubsets();
            console.log(response);
          }).catch(error => {
            this.errorMessage = error.message;
            console.error('deleteSurveyParameters(ERR):', error);
          });
        } else {
          console.log("Item not deleted");
        }
      },

      // Delete Item
      deleteSurveyParametersItem(subset) {
        Api.Projects.deleteProviderParameter(subset).then((response) => {
          //alert("Item Deleted!");
          this.getSubsets();
          console.log(response);
        }).catch(error => {
          this.errorMessage = error.message;
          console.error('deleteSurveyParameters(ERR):', error);
        });
      },

      checkAddMoreParent() {
        console.log(this.editedItem);
        if (this.editedItem.add) {
          this.addMoreParameters();
        } else {
          console.log("Splice");
          this.editedItem.parameters = this.editedItem.parameters.splice(0, 0);
        }
      },
      checkAddMore(index) {
        if (this.editedItem.parameters[index].add) {
          this.addMoreParameters();
        } else {
          this.editedItem.parameters.splice(index + 1)
        }
      },
      addMoreParameters() {
        this.editedItem.parameters.push({
          surveyProviderParameterKey: 0,
          parameterID: this.editedItem.parameterID,
          parameterName: this.editedItem.parameterName,
          name: "",
          value: "",
          color: "",
          favorite: false,
          isHidden: false,
          isPassedIn: false,
          isCodedReturnValue: false,
          quotas: false,
          add: false,
        });
        console.log(this.editedItem);
      },

      deleteItem(subset) {
        this.editedIndex = this.subsets.indexOf(subset);
        this.editedItem = Object.assign({}, subset);
        this.dialogDelete = true;
      },

      deleteItemConfirm() {
        const subset = this.subsets.splice(this.editedIndex, 1)[0];
        this.deleteSurveyParametersItem(subset);
        this.closeDelete();
      },

      close() {
        this.dialog = false;
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.editedIndex = -1

      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
          this.editedIndex = -1
        });
      },

      editItem(item) {
        this.editedIndex = this.subsets.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log(this.editedItem);
        this.dialog = true
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.subsets[this.editedIndex], this.editedItem)
        } else {
          this.subsets.push(this.editedItem)
        }

        this.addSurverParameters(this.editedItem);
        this.close()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .color-box {
    width: 45px;
    height: 30px;
  }
  .group {
    padding-top: 10px;
  }
  .answer-box {
    position: relative;
    border: 1px solid black;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  .answer-box .clear-all {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 5px;
  }
  .answer-box .v-input {
    margin-top: 0px;
  }
  .answer-box .v-messages {
    display: none !important;
  }
</style>
