<template>
  <div class="d-flex flex-column flex-sm-row justify-center">
    <v-container
      class="px-6 py-4"
      style="max-width: 100%"
      fluid
      v-if="!loading"
    >
      <v-form ref="form" v-model="validDetails">
        <!-- Header-->
        <v-row class="px-3">
          <h2>Settings</h2>
          <!-- Copy Media Button -->
          <v-dialog max-width="450">
            <template v-slot:activator="{ on, popout }">
              <v-btn
                class="ml-auto"
                color="secondary"
                v-on="on"
                v-bind="popout"
                :disabled="loading"
              >
                Copy Media</v-btn
              >
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="headline">Copy Media</v-card-title>

                <v-card-text class="mt-6">
                  Are you sure you want to copy this media item?
                </v-card-text>

                <v-card-actions>
                  <v-btn text @click="dialog.value = false"> Cancel</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      copyMedia();
                      dialog.value = false;
                    "
                    class="ml-auto"
                  >
                    <v-icon class="mr-1">mdi-content-copy</v-icon>Copy
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-row>

        <v-row>
          <!-- Left Column -->
          <v-col cols="12" md="6">
            <!-- Media Name -->
            <v-text-field
              label="Media Name*"
              v-model="media.name"
              outlined
              dense
              background-color="white"
              :rules="rule_required"
            ></v-text-field>

            <!-- Sort Order -->
            <v-text-field
              label="Sort Order*"
              v-model="media.sortOrder"
              class="sort-order-field"
              @change="SortOrderError = []"
              :error-messages="SortOrderError"
              :rules="rule_sortOrder"
              outlined
              dense
              background-color="white"
            ></v-text-field>

            <!-- Description -->
            <v-textarea
              outlined
              name="description"
              label="Description"
              v-model="media.description"
              background-color="white"
            ></v-textarea>

            <!-- Media Type -->
            <v-select
              label="Media Type*"
              v-model="media.mediaTypeString"
              :items="media.mediaTypeDD.$values"
              item-value="value"
              item-text="text"
              outlined
              dense
              :rules="rule_required"
            ></v-select>

            <!-- Quota -->
            <v-text-field
              label="Quota"
              v-model="media.viewerLimit"
              value="500"
              outlined
              dense
              type="number"
            >
            </v-text-field>

            <!-- Idle Timeout After -->
            <v-text-field
              v-model="media.idleTimeoutString"
              label="Idle Timeout After"
              outlined
              dense
              :rules="rule_timeFormatNotReq"
            />

            <!-- Idle Timeout Reminder -->
            <v-text-field
              label="Idle Timout Reminder"
              v-model="media.passiveTimeoutReminder"
              outlined
              dense
              background-color="white"
              class="timepicker"
            ></v-text-field>

            <!-- Left (low) Slider Label: -->
            <v-text-field
              label="Left (low) Slider Label"
              v-model="media.decreaseHelperTitle"
              placeholder="Dislike"
              outlined
              dense
              background-color="white"
            ></v-text-field>

            <!-- Right (high) Slider Label -->
            <v-text-field
              label="Right (high) Slider Label"
              v-model="media.increaseHelperTitle"
              placeholder="Like"
              outlined
              dense
              background-color="white"
            ></v-text-field>

            <!-- Slider Range -->
            <v-range-slider
              v-model="rangeSlider"
              :tick-labels="['0', '20', '40', '60', '80', '100']"
              :step="20"
              color="secondary"
              tick-size="2"
            ></v-range-slider>

            <!-- Buttons -->
            <h2>Buttons</h2>
            <v-divider></v-divider>
            <p>Check box to enable multiple click feature.</p>

            <div class="d-flex flex-row">
              <v-checkbox
                v-model="media.hotButton1IsMultiple"
                :disabled="!media.hotButton1Text"
                hide-details
                class="shrink mr-2 mt-1"
              />
              <v-text-field
                outlined
                dense
                v-model="media.hotButton1Text"
                label="Button 1 Text"
              />
            </div>

            <div class="d-flex flex-row">
              <v-checkbox
                v-model="media.hotButton2IsMultiple"
                :disabled="!media.hotButton2Text"
                hide-details
                class="shrink mr-2 mt-1"
              />
              <v-text-field
                outlined
                dense
                v-model="media.hotButton2Text"
                label="Button 2 Text"
              />
            </div>

            <div class="d-flex flex-row">
              <v-checkbox
                v-model="media.hotButton3IsMultiple"
                :disabled="!media.hotButton3Text"
                hide-details
                class="shrink mr-2 mt-1"
              />
              <v-text-field
                outlined
                dense
                v-model="media.hotButton3Text"
                label="Button 3 Text"
              />
            </div>
          </v-col>

          <!-- Right Column -->
          <v-col cols="12" md="6">
            <p>Pre-Media Message</p>
            <XTextEditor v-model="media.beforeMessageText"></XTextEditor>

            <p class="mt-6">Post-Media Message</p>
            <XTextEditor v-model="media.afterMessageText"></XTextEditor>

            <v-divider class="mb-6 mt-6"></v-divider>

            <!-- Slidermetrix Hosted Form -->
            <div v-if="mediaType === TYPE_HOSTED">
              <p>Upload Media:</p>
              <!-- Error for not saving -->
              <p v-if="!saved">
                <i>Please save the project to upload a media item.</i>
              </p>

              <!-- Currently processing the video... -->
              <p v-if="uploadingFile && !posterURL && !transcoding">
                Please wait while the file processes...
              </p>

              <!-- Currently transcoding... -->
              <p v-if="transcoding">
                Your media is being processed. You will receive an email when
                your media is ready.
              </p>

              <!-- Media Uploaded UI -->
              <div v-if="posterURL && !transcoding" class="has-file-box mb-2">
                <img :src="posterURL" alt="Loading Thumbnail..." />
              </div>

              <div class="media-actions" v-if="!transcoding">
                <!-- Initial Upload Modal -->
                <v-dialog max-width="450" v-if="!uploadingFile && saved">
                  <template v-slot:activator="{ on, popout }">
                    <v-btn text v-on="on" v-bind="popout">
                      <v-icon class="mr-2">mdi-upload</v-icon> Upload
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-title class="headline">Upload Media</v-card-title>

                      <v-card-text>
                        <v-alert
                          type="warning"
                          class="mt-6"
                          dismissible
                          v-show="posterURL"
                        >
                          Please note that changing a video may cause questions
                          to no longer make sense or be relevant. It can also
                          cause time based questions to not occur if they are
                          outside the duration of the new video. We encourage
                          you to double check your question setup to ensure that
                          the settings still make sense.
                        </v-alert>

                        <v-checkbox
                          v-model="media.hasWatermark"
                          :label="'Watermark'"
                          :hide-details="true"
                        ></v-checkbox>
                        <v-divider class="mb-6 mt-6"></v-divider>

                        <div v-if="media.hasWatermark">
                          <v-select
                            label="Watermark Type"
                            v-model="media.watermarkType"
                            :items="WATERMARK_TYPES"
                            item-value="value"
                            item-text="title"
                            outlined
                            dense
                          ></v-select>

                          <v-text-field
                            v-if="media.watermarkType === WATERMARK_TYPE_TEXT"
                            label="Watermark Text"
                            v-model="media.watermarkText"
                            outlined
                            dense
                          ></v-text-field>

                          <v-file-input
                            v-if="media.watermarkType === WATERMARK_TYPE_IMAGE"
                            label="Watermark Image"
                            v-model="watermarkImage"
                            accept="image/*"
                            outlined
                            dense
                          ></v-file-input>

                          <v-select
                            label="Watermark Position"
                            v-model="media.watermarkPosition"
                            :items="WATERMARK_POSITIONS"
                            item-value="value"
                            item-text="title"
                            outlined
                            dense
                          ></v-select>
                          <v-divider class="mb-6"></v-divider>
                        </div>

                        <v-file-input
                          show-size
                          label="Media File"
                          v-model="uploadFile"
                          accept="video/*"
                          outlined
                          dense
                        >
                        </v-file-input>
                      </v-card-text>

                      <v-card-actions v-if="readyToUpload">
                        <v-btn text @click="uploadFile = null" color="error"
                          >Clear</v-btn
                        >
                        <v-btn
                          text
                          class="ml-auto"
                          @click="
                            uploadVideo();
                            dialog.value = false;
                          "
                          >Upload</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>

                <!-- Watermark Picker Modal -->
                <v-dialog
                  max-width="450"
                  v-if="posterURL"
                  @click:outside="closeWatermarkDialog()"
                >
                  <template v-slot:activator="{ on, popout }">
                    <v-btn text v-show="posterURL" v-on="on" v-bind="popout">
                      <v-icon class="mr-2">mdi-image</v-icon>Watermark
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-title class="headline">
                        Add Watermark

                        <v-btn
                          @click="
                            closeWatermarkDialog();
                            dialog.value = false;
                          "
                          class="ml-auto"
                          icon
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>

                      <v-card-text class="mt-6">
                        <v-select
                          label="Watermark Type"
                          v-model="media.watermarkType"
                          :items="WATERMARK_TYPES"
                          item-value="value"
                          item-text="title"
                          outlined
                          dense
                        ></v-select>

                        <v-text-field
                          v-if="media.watermarkType === WATERMARK_TYPE_TEXT"
                          label="Watermark Text"
                          v-model="media.watermarkText"
                          outlined
                          dense
                        >
                        </v-text-field>

                        <v-file-input
                          v-if="media.watermarkType === WATERMARK_TYPE_IMAGE"
                          label="Watermark Image"
                          v-model="watermarkImage"
                          accept="image/*"
                          outlined
                          dense
                        ></v-file-input>

                        <v-select
                          label="Watermark Position"
                          v-model="media.watermarkPosition"
                          :items="WATERMARK_POSITIONS"
                          item-value="value"
                          item-text="title"
                          outlined
                          dense
                        ></v-select>
                      </v-card-text>

                      <v-divider></v-divider>
                      <v-card-actions>
                        <div>
                          <v-btn text @click="removeWatermark()" color="error">
                            {{
                              REMOVE_WATERMARK_STAGES[
                                currentWaterMarkRemovalStage
                              ].text
                            }}
                          </v-btn>
                          <v-btn
                            v-if="currentWaterMarkRemovalStage === 1"
                            text
                            @click="currentWaterMarkRemovalStage = 0"
                          >
                            Cancel
                          </v-btn>
                        </div>
                        <v-btn
                          text
                          class="ml-auto"
                          @click="
                            addWatermark();
                            dialog.value = false;
                          "
                          color="green"
                          :disabled="!validWatermark"
                          >Add</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>

                <!-- Embed Code Modal -->
                <XEmbedDialog
                  v-if="posterURL"
                  :projectId="projectId"
                  :mediaKey="media.fileKey"
                  :mediaType="mediaType"
                >
                </XEmbedDialog>

                <!-- Preview Modal -->
                <XPreviewDialog
                  v-if="posterURL && showPreview"
                  :projectId="projectId"
                  :media="media"
                  :mediaType="mediaType"
                ></XPreviewDialog>

                <v-text-field
                  v-model="media.videoDurationString"
                  v-if="posterURL"
                  label="Media Duration"
                  outlined
                  dense
                  style="max-width: 70%"
                  class="mt-4"
                  :rules="rule_timeFormat"
                ></v-text-field>
              </div>
            </div>

            <!-- Third Party Hosted Form -->
            <div v-if="mediaType === TYPE_THIRD_PARTY">
              <v-text-field
                v-model="media.videoUrl"
                label="Media URL*"
                outlined
                dense
                :rules="rule_videoFileURL"
              >
              </v-text-field>

              <div
                class="media-actions mb-8"
                style="margin-top: -20px"
                v-if="media.videoUrl && validDetails"
              >
                <!-- Embed Code Modal -->
                <XEmbedDialog
                  v-if="posterURL"
                  :projectId="projectId"
                  :mediaKey="media.fileKey"
                  :mediaType="mediaType"
                >
                </XEmbedDialog>
                <!-- Preview Modal -->
                <XPreviewDialog
                  v-if="posterURL && showPreview"
                  :projectId="projectId"
                  :media="media"
                  :mediaType="mediaType"
                ></XPreviewDialog>
              </div>

              <v-text-field
                :value="media.videoDurationString"
                label="Media Duration"
                outlined
                dense
                :rules="[rule_required, rule_timeFormat]"
              ></v-text-field>
            </div>

            <!-- YouTube Form -->
            <div v-if="mediaType === TYPE_YOUTUBE">
              <v-text-field
                v-model="media.videoUrl"
                label="YouTube ID*"
                outlined
                dense
                :rules="rule_ytID"
              >
              </v-text-field>
              <div class="media-actions mb-8" style="margin-top: -20px">
                <!-- Embed Code Modal -->
                <XEmbedDialog
                  v-if="posterURL"
                  :projectId="projectId"
                  :mediaKey="media.fileKey"
                  :mediaType="mediaType"
                >
                </XEmbedDialog>
                <!-- Preview Modal -->
                <XPreviewDialog
                  v-if="posterURL && showPreview"
                  :projectId="projectId"
                  :media="media"
                  :mediaType="mediaType"
                ></XPreviewDialog>
              </div>

              <v-text-field
                v-model="media.startTimeString"
                label="Start Time"
                outlined
                dense
                :rules="rule_timeFormat"
              >
              </v-text-field>
              <v-text-field
                v-model="media.endTimeString"
                label="End Time"
                outlined
                dense
                :rules="rule_timeFormat"
              >
              </v-text-field>
              <!-- YT Duration should query this based on the given ID -->
              <v-text-field
                :value="media.videoDurationString"
                label="Media Duration"
                outlined
                dense
                readonly
                :rules="rule_timeFormat"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <!-- Save Buttons -->
        <v-row justify="end" style="padding: 12px !important">
          <v-btn
            color="secondary"
            @click="saveMedia()"
            v-if="pickedMediaType === media.mediaTypeString || newMedia"
            :disabled="loadingSave"
          >
            {{ loadingSave ? "Saving..." : "Save Media" }}</v-btn
          >

          <!-- Confirm Popout if the user changed the media type -->
          <v-dialog width="400px" v-else>
            <template v-slot:activator="{ on, popout }">
              <v-btn color="secondary" v-on="on" v-bind="popout">
                <v-icon class="mr-2">mdi-save</v-icon>Save Media
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="headline">Warning!</v-card-title>

                <v-card-text class="mt-6">
                  Please note that changing a video may cause questions to no
                  longer make sense or be relevant. It can also cause time based
                  questions to not occur if they are outside the duration of the
                  new video. We encourage you to double check your question
                  setup to ensure that the settings still make sense.
                </v-card-text>

                <v-card-actions>
                  <v-btn text @click="dialog.value = false">Cancel</v-btn>
                  <v-btn
                    text
                    @click="saveMedia()"
                    class="ml-auto"
                    color="secondary"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-row>
      </v-form>
    </v-container>

    <!-- Loading Spinner -->
    <div class="d-flex justify-center flex-column pa-8 align-center" v-else>
      <h1 style="font-weight: 300" class="my-8">Loading Media Item...</h1>
      <v-progress-circular
        color="primary"
        indeterminate
        size="128"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import XTextEditor from "@/components/_generics/x-text-editor.vue";
import XEmbedDialog from "@/components/projects/media/x-embed-dialog.vue";
import XPreviewDialog from "@/components/projects/media/x-preview-dialog.vue";
import Api from "@/api/api";
import Events from "@/events/events";
import "@/plugins/vuetify-mask-old.js";

export default {
  components: {
    XTextEditor,
    XEmbedDialog,
    XPreviewDialog,
  },

  mounted() {
    const mediaIDtemp = this.$route.params.mediaID;
    this.newMedia = mediaIDtemp == null || mediaIDtemp == undefined;

    this.reloadMedia(true);
  },

  activated() {
    const mediaIDtemp = this.$route.params.mediaID;
    this.newMedia = mediaIDtemp == null || mediaIDtemp == undefined;

    this.reloadMedia(true);
  },

  data() {
    const WATERMARK_TYPE_TEXT = 1,
      WATERMARK_TYPE_UIP = 2,
      WATERMARK_TYPE_IMAGE = 3,
      WATERMARK_TYPE_EID = 4;
    const WATERMARK_TYPES = [
      { title: "Text", value: WATERMARK_TYPE_TEXT },
      { title: "User IP", value: WATERMARK_TYPE_UIP },
      { title: "Image", value: WATERMARK_TYPE_IMAGE },
      // not including external ID as its not on the old site, just referenced in the api
    ];

    const WATERMARK_POSITION_TOP_LEFT = 1,
      WATERMARK_POSITION_TOP_RIGHT = 2,
      WATERMARK_POSITION_BOTTOM_LEFT = 3,
      WATERMARK_POSITION_BOTTOM_RIGHT = 4;
    const WATERMARK_POSITIONS = [
      { title: "Top Left", value: WATERMARK_POSITION_TOP_LEFT },
      { title: "Top Right", value: WATERMARK_POSITION_TOP_RIGHT },
      { title: "Bottom Left", value: WATERMARK_POSITION_BOTTOM_LEFT },
      { title: "Bottom Right", value: WATERMARK_POSITION_BOTTOM_RIGHT },
    ];

    const REMOVE_WATERMARK_STAGES = [
      { value: 1, text: "remove" },
      { value: 2, text: "are you sure?" },
      { value: 3, text: "removing..." },
    ];

    // Media Types
    const TYPE_HOSTED = 1,
      TYPE_THIRD_PARTY = 2,
      TYPE_YOUTUBE = 3;
    const MEDIA_TYPES = [TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE];

    return {
      newMedia: true,
      mediaID: null,
      dynamicFinalPageFlag: false,
      projectExists: true,
      projectId: null,
      surveyURL: "http://website.com/survey/A53hgDF34eaEWfd31",
      isWhiteLabelCSS: false,
      whiteLabelCSS: "",
      items: ["Test 1", "Test 2"],
      date1: "",
      date2: "",
      range: null,
      ranges: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      btn1: false,
      btn2: false,
      btn3: false,
      media: {},
      SortOrderError: [],
      SortOrderRules: [],
      ExampleError: ["test"],
      rangeSlider: [],

      mediaType: null,
      pickedMediaType: null,

      rule_required: [(v) => !!v || "Required"],
      rule_sortOrder: [
        (v) => !!v || "Sort Order is required",
        (v) => /^\d+$/.test(v) || "Only numbers are allowed",
      ],
      rule_timeFormat: [
        (v) =>
          /^\d{1,2}:\d{2}:\d{2}$/.test(v) || "Must be in the format hh:mm:ss",
      ],
      rule_timeFormatNotReq: [
        (v) =>
          !v ||
          /^\d{1,2}:\d{2}:\d{2}$/.test(v) ||
          "Must be in the format hh:mm:ss",
      ],
      rule_ytID: [
        (v) => !!v || "YouTube ID is required",
        (v) =>
          /^[a-zA-Z0-9_-]{11}$/.test(v) ||
          "YouTube ID must be 11 characters long",
      ],
      rule_videoFileURL: [
        (v) => !!v || "Video URL is required",
        (v) =>
          /^(http|https):\/\/.*\.(mp4|mov|m4v|webm)$/.test(v) ||
          "Video URL must be a valid URL",
      ],

      // Various loading states
      loading: true,
      loadingSave: false,
      uploadingFile: false,
      transcoding: false,

      watermark: false,
      WATERMARK_TYPES,
      WATERMARK_TYPE_TEXT,
      WATERMARK_TYPE_UIP,
      WATERMARK_TYPE_IMAGE,
      WATERMARK_POSITIONS,
      WATERMARK_POSITION_TOP_LEFT,
      WATERMARK_POSITION_TOP_RIGHT,
      WATERMARK_POSITION_BOTTOM_LEFT,
      WATERMARK_POSITION_BOTTOM_RIGHT,
      MEDIA_TYPES,
      TYPE_HOSTED,
      TYPE_THIRD_PARTY,
      TYPE_YOUTUBE,

      // Uploaded State
      saved: false,
      posterURL: null,
      validDetails: true,

      uploadFile: null,
      watermarkImage: null,
      REMOVE_WATERMARK_STAGES,
      currentWaterMarkRemovalStage: 0,
      showPreview: false,
      defaultWatermarkOptions: {
        watermarkPosition: 1,
        watermarkText: null,
        watermarkType: 1,
      },
      hasWatermark: false,
    };
  },

  computed: {
    readyToUpload() {
      if (!this.uploadFile) return false;

      // Verify watermark form is properly filled
      if (this.media.hasWatermark) {
        // Needs a watermark type/ if selected
        if (!this.media.watermarkType) return false;
        if (!this.media.watermarkPosition) return false;

        // Check required fields given watermark type
        if (
          this.media.watermarkType === this.WATERMARK_TYPE_TEXT &&
          !this.media.watermarkText
        ) {
          return false;
        } else if (
          this.media.watermarkType === this.WATERMARK_TYPE_IMAGE &&
          !this.watermarkImage
        ) {
          return false;
        }
      }

      return true;
    },

    validWatermark() {
      // Check required fields given watermark type
      if (
        this.media.watermarkType === this.WATERMARK_TYPE_TEXT &&
        !this.media.watermarkText
      ) {
        return false;
      } else if (
        this.media.watermarkType === this.WATERMARK_TYPE_IMAGE &&
        !this.watermarkImage
      ) {
        return false;
      }

      return true;
    },
  },

  watch: {
    "$route.params.mediaID": function (val) {
      // For switching from the tabs
      this.mediaID = val;
      this.reloadMedia();
    },

    rangeSlider: function (val) {
      this.media.sliderRangeStart = this.rangeSlider[0];
      this.media.sliderRangeEnd = this.rangeSlider[1];
    },

    "media.watermarkType": function (newVal, oldVal) {
      if (Object.keys(this.media).length === 0) return;

      // Update DD value, unselect old value (-1 for zero indexing)
      this.media.watermarkTypeDD.$values[newVal - 1].selected = true;
      if (oldVal)
        this.media.watermarkTypeDD.$values[oldVal - 1].selected = false;
    },

    "media.watermarkPosition": function (newVal, oldVal) {
      if (Object.keys(this.media).length === 0) return;

      // Update DD value, unselect old value (-1 for zero indexing)
      this.media.watermarkPositionDD.$values[newVal - 1].selected = true;
      if (oldVal)
        this.media.watermarkPositionDD.$values[oldVal - 1].selected = false;
    },

    "media.hotButton1Text"(newValue) {
      if (newValue === "") {
        this.media.hotButton1Text = null;
        this.media.hotButton1IsMultiple = false;
      }
    },

    "media.hotButton2Text"(newValue) {
      if (newValue === "") {
        this.media.hotButton2Text = null;
        this.media.hotButton2IsMultiple = false;
      }
    },

    "media.hotButton3Text"(newValue) {
      if (newValue === "") {
        this.media.hotButton3Text = null;
        this.media.hotButton3IsMultiple = false;
      }
    },

    uploadFile: function (newVal) {
      // update media object
      this.media.fileExtension = newVal.type.substr(
        newVal.type.lastIndexOf("/") + 1
      );
    },

    "media.mediaTypeString": function (newVal) {
      // Update media type given user action
      if (newVal === "Slidermetrix_Hosted") {
        this.mediaType = this.TYPE_HOSTED;
      } else if (newVal === "Thirdparty_Hosted") {
        this.mediaType = this.TYPE_THIRD_PARTY;
      } else if (newVal === "YouTube") {
        this.mediaType = this.TYPE_YOUTUBE;
      }
    },
  },

  updated() {
    const mediaId = this.$route.params.mediaID;
    this.media.fileKey = mediaId;

    if (mediaId != this.media.fileKey) {
      this.reloadMedia();
    }
  },

  methods: {
    saveMedia() {
      // Update media object
      this.media.mediaType = this.mediaType;

      // Validate form
      this.validDetails = this.$refs.form.validate();
      if (!this.validDetails) return;

      this.loadingSave = true;
      Api.Media.saveMedia(this.media)
        .then((res) => {
          if (res.data.returnUrl != undefined && this.newMedia) {
            this.$router.push(res.data.returnUrl);
            this.reloadMedia(true);
            this.newMedia = false;
          }

          this.loadingSave = false;
          Events.$emit("updateMedia");
        })
        .catch((err) => {
          // Errors object
          const errors = err.res.data.errors;
          this.loadingSave = false;

          if (errors.SortOrder !== undefined) {
            // Scroll to top smoothly
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            alert("Error:", errors.SortOrder[0]);

            // TODO: Highlight sort order field as errored
            // ...
          }
        });
    },

    reloadMedia(firstRun = false) {
      this.projectId = this.$route.params.projectId;
      const customerId = this.$route.params.customerId;
      const mediaId = this.$route.params.mediaID;

      // Used to disable some features if media isn't uploaded yet
      if (this.newMedia) {
        this.media = {};
        this.mediaType = null;
        this.saved = false;
        this.posterURL = null; // reset posterURL
      } else {
        this.saved = true;
        this.uploadingFile = false;
      }

      this.loading = true;
      Api.Projects.getProjectInfo(this.projectId, customerId).then((res) => {
        this.$emit("changeProjectTitle", {
          projectName: res.data.projectName,
          description: res.data.description,
          customer: res.data.customerName,
          currentUserType: res.data.currentUserType,
          isAdmin: res.data.isAdmin,
          activeTab: 2,
        });
      });

      Api.Media.getMedia(this.projectId, mediaId)
        .then((res) => {
          let media = res.data;

          if (media.watermarkType === null) {
            media.watermarkType = this.WATERMARK_TYPE_TEXT;
            // -1 to use zero indexing
            media.watermarkTypeDD.$values[
              this.WATERMARK_TYPE_TEXT - 1
            ].selected = true;
          }

          if (media.watermarkPosition === null) {
            media.watermarkPosition = this.WATERMARK_POSITION_TOP_LEFT;
            // -1 to use zero indexing
            media.watermarkPositionDD.$values[
              this.WATERMARK_POSITION_TOP_LEFT - 1
            ].selected = true;
          }

          if (media.watermarkType !== null) {
            this.defaultWatermarkOptions.watermarkType = media.watermarkType;
          }

          if (media.watermarkPosition !== null) {
            this.defaultWatermarkOptions.watermarkPosition =
              media.watermarkPosition;
          }

          this.defaultWatermarkOptions.hasWatermark = media.hasWatermark;
          this.defaultWatermarkOptions.watermarkText = media.watermarkText;
          // Set media item
          this.loading = false;
          this.media = media;

          // Mark that the media has a video
          if (
            media.mediaComplete === true &&
            media.checkTranscodingComplete === true
          ) {
            this.setPosterURL();
          }

          if (
            media.mediaComplete != true &&
            media.checkTranscodingComplete != true &&
            this.media.fileExtension != null &&
            this.media.fileExtension != undefined
          ) {
            this.startTranscodingLoop();
          }

          // Set default values
          const start = this.media.sliderRangeStart || 0;
          const end = this.media.sliderRangeEnd || 100;
          this.rangeSlider = [start, end];
          if (firstRun === true)
            this.pickedMediaType = this.media.mediaTypeString;
          if (this.media.startTimeString === null)
            this.media.startTimeString = "00:00:00";
          if (this.media.endTimeString === null)
            this.media.endTimeString = "00:00:00";
          if (this.media.videoDurationString === null)
            this.media.videoDurationString = "00:00:00";
          if (this.media.viewerLimit === null) this.media.viewerLimit = 500;

          // Set sort order from local storage
          if (this.media.sortOrder === null) {
            const sortOrder = localStorage.getItem("openSortOrder");
            if (sortOrder !== null || sortOrder !== undefined) {
              this.media.sortOrder = sortOrder;
            }
          }

          // Set default media type for new media items
          if (this.media.mediaTypeString === null) {
            this.media.mediaTypeString = "Slidermetrix_Hosted";
          }

          // Regen preview dialog if item chances
          this.showPreview = false;
          this.$nextTick(() => {
            this.showPreview = true;
          });

          console.log("reloadMedia():", this.media);
        })
        .catch((err) => {
          console.err("reloadMedia(ERR):", err);
          this.loading = false;
        });
    },

    copyMedia() {
      this.loading = true;

      Api.Media.copyMedia(this.projectId, this.media.fileKey)
        .then((res) => {
          const newID = res.data.resultMessage;
          Events.$emit("updateMedia");
          // Redirect to media page with new media ID
          this.$router.push(`/projects/${this.projectId}/media/${newID}`);
        })
        .catch((err) => {
          this.loading = false;
          console.error("copyMedia(ERR):", err);
        });
    },

    // Call api and upload media items video
    uploadVideo() {
      this.uploadingFile = true;
      this.posterURL = null;

      // Upload to api
      if (this.media.duration === undefined) this.media.duration = 0;
      Api.Media.uploadVideo(this.media, this.uploadFile, this.watermarkImage)
        .then(() => {
          this.startTranscodingLoop();
        })
        .catch((err) => {
          this.uploadingFile = false;
          console.error("uploadVideo(ERR):", err);
        });
    },

    removeWatermark() {
      // Move to next stage (confirmation)
      if (this.currentWaterMarkRemovalStage === 0) {
        this.currentWaterMarkRemovalStage = 1;
        return;
      } else if (this.currentWaterMarkRemovalStage === 1) {
        // Mark as loading
        this.currentWaterMarkRemovalStage = 2;

        // Remove watermark
        Api.Media.removeWatermark(this.media.fileKey).then((res) => {
          // Start transcoding loop check
          this.startTranscodingLoop();
          this.media.hasWatermark = false;
        });
      }
    },

    addWatermark() {
      this.transcoding = true;
      Api.Media.addWatermark(this.media, this.watermarkImage)
        .then(() => {
          // Start transcoding loop check
          this.startTranscodingLoop();
        })
        .catch((err) => {
          this.transcoding = false;
          console.log("addWatermark(ERR):", err);
        });
    },

    // Check if transcoding is complete every 2 seconds
    startTranscodingLoop() {
      this.transcoding = true;
      if (!this.media.fileKey > 0) {
        this.transcoding = false;
        return;
      }
      // Check if transcoding is complete
      Api.Media.checkIfTranscodingComplete(this.media.fileKey).then((res) => {
        if (res.data === true) {
          this.transcoding = false;
          this.currentWaterMarkRemovalStage = 0;
          this.reloadMedia();
        } else {
          // Restart the loop
          setTimeout(() => {
            this.startTranscodingLoop();
          }, 2000);
        }
      });
    },

    setPosterURL() {
      const key = this.media.fileKey;

      // Add timestamp to prevent caching
      const timestamp = new Date().getTime();
      this.posterURL = `${this.media.awsCloudfrontUrl}transcoded/${key}/${key}_HLS2M_00001.png?timestamp=${timestamp}`;
    },

    closeWatermarkDialog() {
      this.media = {
        ...this.media,
        ...this.defaultWatermarkOptions,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.cursor fieldset,
.cursor input {
  cursor: pointer !important;
}

.has-file-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.has-file-box img {
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  max-width: 80%;
}

.media-actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
