<template>
  <v-layout class="justify-center align-center column fill-height">
    <v-card width="400px">
      <v-card-title class="py-6" style="background: var(--v-primary-base)">
        <div class="d-flex align-center">
          <v-icon color="white" large>mdi-lock-reset</v-icon>
          <h2 style="color: white; font-size: 28px;" class="ml-4">Reset Password</h2>
        </div>
      </v-card-title>

      <v-card-text class="pa-8" style="background: white;">
        <x-expand-alert ref="alert" type="success"></x-expand-alert>

        <v-form ref="resetForm">

          <x-password-field v-model="newPassword"
                            :rules="[InputRules.emptyValueGEN('Please enter a password')]"
                            outlined
                            label="New Password"
                            required></x-password-field>

          <x-password-field v-model="verifyPassword"
                            :rules="[InputRules.emptyValueGEN('Please enter a password'), passwordsMustMatch]"
                            label="Confirm Password"
                            outlined
                            required
                            @keyup.enter="tryPasswordReset"></x-password-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-8">
        <v-layout justify-center column>
          <v-flex text-xs-center xs12 mb-3>
            <v-btn width="100%"
                   color="secondary"
                   @click="tryPasswordReset"
                   :loading="loading">Update password</v-btn>
          </v-flex>

        </v-layout>
      </v-card-actions>

    </v-card>
  </v-layout>
</template>

<script>
  import Api from "@/api/api";
  import InputRules from '../../utils/inputFieldRules';
  import XPasswordField from '@/components/_generics/x-password-field.vue';
  import XExpandAlert from '@/components/_generics/x-expand-alert.vue';

  const ResetPassword = {
    name: "ResetPassword",

    components: {
      XPasswordField,
      XExpandAlert
    },

    mixins: [InputRules],

    data() {
      return {
        loading: false,

        newPassword: '',
        verifyPassword: '',
      };
    },

    methods: {


      tryPasswordReset() {
        if (!this.$refs.resetForm.validate()) {
          return;
        }

        this.loading = true;

        Api.Authentication.resetPassword(this.newPassword, this.verifyPassword).then((res) => {
          console.log("res: ", res);

          this.loading = false;

          if (res.status == 200) {
            this.$refs.alert.setMessage("<b>Password reset successfully!</b><br>Redirecting you to the login page shortly...", "success");

            setTimeout(() => {
              this.$router.push('/');
            }, 2500);
          }
          else if (res.status == 401) {
            this.$refs.alert.setMessage("<b>Not authorized</b><br>You are not logged in, redirecting you to login page...", "error");

            setTimeout(() => {
              this.$router.push('/');
            }, 2500);
          }
          else if (res.status == 400) {
            this.$refs.alert.setMessage("<b>Password reset failed</b><br>Please make sure your passwords are matching and try again...", "error");
          }
        });
      },

      passwordsMustMatch(value) {
        if (value !== this.newPassword) return 'Please ensure passwords match.';
        return true;
      }
    }
  };

  export default ResetPassword;
</script>

<style lang="scss" scoped>

  .login-container {

  }

</style>
