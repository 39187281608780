<template>
  <v-dialog transition="scale-transition" max-width="1200" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        ref="userButton"
        :small="small"
        :outlined="outlined"
        :dark="dark"
        :color="color"
        v-bind="attrs"
        v-on="on"
        @click="getProjectUsers()"
        :disabled="disabled"
      >
        {{ label }}
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card style="min-height: 400px">
        <v-card-title class="headline">
          User management for {{ org.name }}
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-0">
          <x-expand-alert
            type="error"
            ref="alert"
            :dismissible="true"
          ></x-expand-alert>

          <v-layout wrap>
            <v-flex xs12 md4>
              <v-layout wrap>
                <v-flex xs12>
                  <div
                    v-if="loadingUsers"
                    class="d-flex align-center justify-center mt-12"
                  >
                    <v-progress-circular
                      :size="70"
                      :width="5"
                      color="red"
                      indeterminate
                    />
                  </div>
                  <div v-else>
                    <div v-if="users.length > 0" class="pa-4">
                      <v-btn
                        v-for="(usr, i) in users"
                        :key="i"
                        :class="{ secondary: selectedUser === usr.profileKey }"
                        :disabled="
                          loadingPerms && selectedUser !== usr.profileKey
                        "
                        @click="selectedUser = usr.profileKey"
                        class="user__button fill-width mb-2"
                        text
                        ><span>{{ usr.usernameAndEmail }}</span>
                      </v-btn>
                    </div>
                    <div class="text-center pa-4" v-else>
                      There are no users for this project.
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-divider vertical style="z-index: 1"></v-divider>
            <v-flex xs12 md8>
              <x-expand-container>
                <v-data-table
                  :headers="assignPermission"
                  :items="userPermissions"
                  dense
                  :loading="loadingPerms"
                  :no-data-text="
                    selectedUser == null
                      ? 'No user selected!'
                      : 'No projects found!'
                  "
                  loading-text="Loading... Please wait"
                  class="elevation-0"
                >
                  <template v-slot:item="{ item }">
                    <tr :id="item.projectKey">
                      <td class="">
                        <div class="d-flex">
                          <input
                            type="radio"
                            :name="'userType' + item.projectKey"
                            value="na"
                            id="roleNa"
                            :checked="!item.isBuilder && !item.isViewer"
                            @click="removeRole(selectedUser, item.projectKey)"
                            :disabled="!item.isEditable"
                          />
                          <input
                            type="hidden"
                            name="none"
                            value="userType-30"
                          />
                        </div>
                      </td>

                      <td class="builder-col">
                        <div class="d-flex">
                          <input
                            id="builder"
                            type="radio"
                            :name="'userType' + item.projectKey"
                            :checked="item.isBuilder"
                            @click="
                              updateRole(
                                selectedUser,
                                item.projectKey,
                                'builder'
                              )
                            "
                            :disabled="!item.isEditable"
                          />
                        </div>
                      </td>

                      <td class="viewer-col">
                        <div class="d-flex">
                          <input
                            id="viewer"
                            type="radio"
                            :name="'userType' + item.projectKey"
                            :checked="item.isViewer"
                            @click="
                              updateRole(
                                selectedUser,
                                item.projectKey,
                                'viewer'
                              )
                            "
                            :disabled="!item.isEditable"
                          />
                        </div>
                      </td>

                      <td>{{ item.projectName }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </x-expand-container>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="closeUserAssignedProjects(), (dialog.value = false)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Api from "@/api/api";
import XExpandAlert from "@/components/_generics/x-expand-alert.vue";
import XExpandContainer from "@/components/_generics/x-expand-container.vue";

const XUsersManagement = {
  components: {
    XExpandAlert,
    XExpandContainer,
  },
  props: {
    org: Object,
    label: {
      type: String,
      default: "Users",
    },
    disabled: Boolean,
    color: {
      type: String,
      default: "primary",
    },
    small: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    // console.log();
  },

  data() {
    return {
      users: [],
      selectedUser: null,
      loadingUsers: false,
      userPermissions: [],
      loadingPerms: false,
      assignPermission: [
        {
          text: "N/A",
          align: "start",
          sortable: false,
          value: "none",
        },
        {
          text: "Builder",
          value: "builder",
          sortable: false,
        },
        {
          text: "Viewer",
          value: "viewer",
          sortable: false,
        },
        {
          text: "Project Names",
          value: "projects",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    selectedUser(newUserKey) {
      if (newUserKey != null) {
        this.loadingPerms = true;
        this.userPermissions = [];

        // reloading the user's project permissions when the selected user is changed.
        Api.Projects.getAssignedProjects(newUserKey, this.org.customerKey)
          .then((response) => {
            this.loadingPerms = false;
            this.userPermissions = response.data.$values;
          })
          .catch((err) => {
            this.loadingPerms = false;
            this.handleError(err);
          });
      }
    },
  },

  methods: {
    getProjectUsers() {
      this.$nextTick(() => {
        this.$refs.userButton.$el.blur();
      });
      this.loadingUsers = true;
      Api.Projects.editProjectUser(this.org.customerKey)
        .then((response) => {
          if (response.data.profiles.$values.length === 0) {
            this.$refs.alert.setMessage(
              `There are no users for this project.`,
              "warning"
            );
          } else {
            this.users = response.data.profiles.$values;
            this.selectedUser = this.users[0].profileKey;
          }
          this.loadingUsers = false;
        })
        .catch((error) => {
          this.handleError(error);
          this.loadingUsers = false;
        });
    },

    removeRole(profileKey, projectKey) {
      Api.Projects.removeUserRole(profileKey, projectKey).catch((error) => {
        this.handleError(error, true);
      });
    },

    updateRole(profileKey, projectKey, type) {
      Api.Projects.updateUserRole(profileKey, projectKey, type).catch(
        (error) => {
          this.handleError(error, true);
        }
      );
    },

    closeUserAssignedProjects(customerKey) {
      this.users = [];
      this.userPermissions = [];
      this.selectedUser = null;
      this.$refs.alert.hideAlert();
    },

    handleError(error, isEditRole = false) {
      console.log(error);
      if (isEditRole) {
        this.$refs.alert.setMessage(
          `An error occurred, your changes may not be saved, try later.`
        );
      } else {
        this.$refs.alert.setMessage(`An error occurred, try later.`);
      }
    },
  },
};

export default XUsersManagement;
</script>

<style lang="scss">
.user__button {
  text-transform: none;
  font-weight: normal;

  .v-btn__content {
    display: block;
    text-align: start;
    letter-spacing: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}
</style>
