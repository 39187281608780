<template>
  <div class="d-flex flex-column align-center mt-4">
    <v-sheet style="width: 100%; overflow: hidden; transition: all 1s ease-in-out" class="mt-4 mb-12" elevation="1"  rounded>

      <v-layout px-6 py-4>
        <v-flex class="d-flex align-center">
          <h1>Add Project</h1>
          <v-spacer></v-spacer>

          <!-- exact prevents the button from being highlighted despite being under that route -->
          <v-btn to="/projects" exact icon large color="black" @click="closeMenu">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>

      <div>
        <ProjectEditForm :createProject="true"></ProjectEditForm>
      </div>
    </v-sheet>
  </div>
</template>

<script>
  import ProjectEditForm from '@/components/projects/ProjectEditForm.vue';

  const CreateProject =  {
    components: { ProjectEditForm },
    
    mounted() { },

    methods: {
      async closeMenu() { }
    },
  }

  export default CreateProject;
</script>