<template>
  <div>
    <v-layout px-6 py-4>
      <v-flex>
        <h2>Edit Project</h2>
      </v-flex>
    </v-layout>

    <ProjectEditForm :projectData="projectData" :surveyProvidersList="surveyProvidersList" :loadingSettings="loadingSettings" @changeProjectTitle="UpdateTitle($event)"></ProjectEditForm>
  </div>
</template>

<script>
  import ProjectEditForm from '@/components/projects/ProjectEditForm.vue';
  import Api from '@/api/api';

  const ProjectSettings = {
    components: {
      ProjectEditForm
    },

    activated() {
      this.refreshProject();
    },

    data() {
      return {
        loadingSettings: false,
        customerList: null,
        projectData: {
          default: {},
        },
        surveyProvidersList: {
          default: {},
        },
      }
    },

    methods: {
      refreshProject() {

        const projectId = this.$route.params.projectId;
        if (!projectId) return;

        this.loadingSettings = true;
        Api.Projects.getProject(projectId).then(project => {
          // Fix start/end date strings as the datetime picker takes date objects
          project.data.startDate = project.data.startDate ? new Date(project.data.startDate) : null;
          project.data.endDate = project.data.endDate ? new Date(project.data.endDate) : null;

          // Manually change archive after days
          const archiveDays = [ 30, 60, 90];
          if (archiveDays.indexOf(project.data.archiveAfterDays) === -1 && project.data.archiveAfterDays !== null) {
            project.data.customArchiveDays = project.data.archiveAfterDays;
            project.data.archiveAfterDays = -1;
          }

          this.projectData = project.data;
          this.loadingSettings = false;
          
          this.$emit('changeProjectTitle', {
            projectName: this.projectData.projectName,
            description: this.projectData.description,
            customer: this.projectData.customerName,
            currentUserType: project.data.currentUserType,
            isAdmin: project.data.isAdmin,
            activeTab: 0,
          });
        });
      },

      UpdateTitle(project) {
        this.$emit('changeProjectTitle', project)
        console.log("Updating Title...");
      },
    },
  };

  export default ProjectSettings;
</script>

<style scoped lang="scss">
  .cursor fieldset, .cursor input {
    cursor: pointer !important;
  }
</style>
