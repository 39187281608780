import Route from "./_route";

/**
 * Manages user sessions and logins.
 */
class Authentication extends Route {
  /**
   *
   * @param {*} userName
   * @param {*} password
   *
   */
  login(userName, password, rememberMe, returnUrl = "/projects") {
    return this.axios.post(`/Authentication/Login?redirectUrl=${returnUrl}`, {
      userName,
      password,
      rememberMe,

      //boiler plate to work with old auth, to be removed / modified at later date
      ef: "string",
      ek: "string",
      passwordEdit: "string",
      timeZone: "string",
      identity: "string",
    });
  }

  /**
   * Get's user object attached to this session cookie.
   */
  getUser() {
    return this.axios.get(`/User/GetThisUser`);
  }

  /**
   * Get's File Version and Date Info.
   */
  getVersion() {
    return this.axios.get(`/Authentication/GetVersion`);
  }

  getUserRoles() {
    return this.axios.get("/User/Read");
  }

  recoverPassword(email) {
    return this.axios.post(`/Authentication/RecoverPassword`, { email });
  }

  resetPassword(password, confirmPassword) {
    return this.axios.post(`/Authentication/PasswordReset`, {
      password,
      confirmPassword,
    });
  }

  toggleLockState(userKey) {
    return this.axios.get(`/User/ToggleLockState?userKey=${userKey}`);
  }

  deleteUser(userKey) {
    return this.axios.get(`/User/Delete?UserKey=${userKey}`);
  }

  getProjectPermissions(customerKey) {
    return this.axios.get(
      `/User/GetProjectPermissions?customerKey=${customerKey}`
    );
  }

  resendActivationEmail(userKey, appBaseURL) {
    return this.axios.post(
      `/User/ResendActivationEmail?userKey=${userKey}&appBaseURL=${appBaseURL}`
    );
  }

  createUserRoles(userRoleModel) {
    return this.axios.post(`/UserRole/Create`, userRoleModel);
  }

  updateUserRoles(userRoleModel) {
    return this.axios.post(`/UserRole/Update`, userRoleModel);
  }

  copyUserRoles() {
    return this.axios.get(`/UserRole/ReadCopyUserRoles`);
  }

  deleteUserRoles(roleKey) {
    return this.axios.get(`/UserRole/Delete?roleKey=${roleKey}`);
  }

  getTimeZones() {
    return this.axios.get(`/Authentication/GetTimezones`);
  }

  // Roles
  getRoles() {
    return this.axios.get(`/UserRole/Read`);
  }

  activateAccount(email, token) {
    return this.axios.get(
      `/Authentication/ActivateAccount?email=${email}&token=${token}`
    );
  }

  completeRegistration(model) {
    return this.axios.post("/Authentication/CompleteRegistration", model);
  }

  /**
   * Logs out the current user, destroying the session.
   *
   * Make sure to do something after, this does not redirect the user if the logout request succeeds.
   */
  logout() {
    // redirect url doesn't matter here since we are just going to redirect them to the login page from vue
    return this.axios.post("/Authentication/Logout?redirectUrl=/");
  }
}

export default Authentication;
