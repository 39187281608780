import Route from "./_route";

/**
 * Requests to manipulate media data
 */
class Media extends Route {

  getMedia(projectId, mediaId) {
    if (mediaId == undefined || isNaN(mediaId)) {
      //Get model to Create a new Media Item
      return this.axios.get(`/Project/Media?id=${projectId}`);
    } else {
      return this.axios.get(`/Project/Media?id=${projectId}&MediaKey=${mediaId}`);
    }
  }

  mediaRead(projectId) {
    return this.axios.get(`/Project/MediaRead?ProjectKey=${projectId}`);
  }

  getMediaQuestions(mediaId) {
    return this.axios.post(`/Project/MediaQuestionRead?mediaKey=${mediaId}`);
  }

  saveMedia(mediaModel) {
    return this.axios.post(`/Project/SaveMedia`, mediaModel);
  }

  deleteMedia(mediaId) {
    return this.axios.get(`/Project/DeleteMedia?mediaKey=${mediaId}`);
  }

  copyMedia(projectId, mediaKey) {
    return this.axios.post(`/Project/CopyMedia?projectKey=${projectId}&mediaKey=${mediaKey}`);
  }

  readProjectUsers(projectId, customerKey) {
    return this.axios.get(`/Project/ReadProjectUsers?ProjectKey=${projectId}&CustomerKey=${customerKey}`);
  }

  uploadVideo(mediaModel, file, ImageFile) {
    var formData = new FormData();
    if (mediaModel.videoWidth == null) mediaModel.videoWidth = 0;
    if (mediaModel.videoHeight == null) mediaModel.videoHeight = 0;
    if (!mediaModel.hasWatermark) {
        mediaModel.hasWatermark = false;
    }
    formData.append("projectKey", Number(mediaModel.projectKey));
    formData.append("mediaKey", Number(mediaModel.fileKey));
    formData.append("hasWatermark", mediaModel.hasWatermark);
    formData.append("watermarkType", mediaModel.watermarkType);
    formData.append("watermarkText", mediaModel.watermarkText);
    formData.append("watermarkPosition", mediaModel.watermarkPosition);
    formData.append("watermarkImg", ImageFile);
    formData.append("file", file);
    formData.append("videoWidth", mediaModel.videoWidth);
    formData.append("videoHeight", mediaModel.videoHeight);
    formData.append("duration", mediaModel.duration);
    //Example: https://localhost:7078/api/Project/UploadVideo?videoWidth=1&videoHeight=2&duration=3
    //return this.axios.post(`/Project/UploadVideo?projectId=${mediaModel.projectId}&mediaKey=${mediaModel.mediaKey}`, formData, {
    return this.axios.post(`/Project/UploadVideo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  multipleMediaUploadSave(projectKey, files) {
    let formData = new FormData();
    formData.append("projectKey", Number(projectKey));
    formData.append("files", files);

    return this.axios.post(`/Project/MultipleMediaUploadSave`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  addWatermark(mediaModel, ImageFile) {
    const mediaKey = mediaModel.fileKey;
    const WATERMARK_TYPE_TEXT = 1, WATERMARK_TYPE_UIP = 2, WATERMARK_TYPE_IMAGE = 3, WATERMARK_TYPE_EID = 4; // consts from MediaDetails.vue

    // Build up form data
    let formData = new FormData();
    mediaModel.videoWidth = 0;
    mediaModel.videoHeight = 0;

    // Get hasWatermark field, USER IP type also has watermark
    let hasWatermark = true;

    formData.append('mediaKey', mediaKey);
    formData.append('hasWatermark', hasWatermark);
    formData.append('watermarkType', mediaModel.watermarkType);
    formData.append('watermarkText', mediaModel.watermarkText);
    formData.append("watermarkImg", ImageFile);
    formData.append('watermarkPosition', mediaModel.watermarkPosition);
    formData.append('isThirdParty', false); // temp for now as i'm not sure how to get this from the model
    formData.append('videoWidth', mediaModel.videoWidth);
    formData.append('videoHeight', mediaModel.videoHeight);

    return this.axios.post(`/Project/AddWatermark?mediaKey=${mediaKey}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  removeWatermark(mediaKey) {
    return this.axios.post(`/Project/RemoveWatermark?mediaKey=${mediaKey}`);
  }

  checkIfTranscodingComplete(mediaKey) {
    return this.axios.get(`/Project/CheckIfTranscodingComplete?mediaKey=${mediaKey}`);
  }

  // Initialize new Media Question Model
  addMediaQuestion(mediaKey, frame) {
    return this.axios.get(`/Project/AddMediaQuestion?mediaKey=${mediaKey}&frame=${frame}`);
  }

  // Create new Media Question
  saveMediaQuestion(mediaQuestion) {
    return this.axios.post(`/Project/SaveMediaQuestion`, mediaQuestion);
  }

  // Update Media Question
  mediaQuestionUpdate(mediaQuestion) {
    return this.axios.post(`/Project/MediaQuestionUpdate`, mediaQuestion);
  }

  // Delete Media Question
  mediaQuestionDelete(mediaQuestion) {
    return this.axios.post(`/Project/MediaQuestionDelete`, mediaQuestion);
  }

  // Save User Answer
  addUserAnswer(mediaModel) {
    return this.axios.post(`/Project/AddUserAnswer`, mediaModel);
  }

  // Post user completed media
  userCompletedMedia(mediaKey, projectKey, userSessionKey) {
    return this.axios.post(`/Project/UserCompletedMedia?mediaKey=${mediaKey}&userSessionKey=${userSessionKey}&projectKey=${projectKey}`);
  }
}

export default Media;
