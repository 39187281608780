<template>
  <div class="d-flex flex-column align-center">
    <v-sheet elevation="0" class="fill-width">

      <div class="d-flex flex-column flex-sm-row">
        <div class="pt-4" style="border-right: 2px solid var(--v-accent-base);">
          <v-tabs vertical grow style="height: 100%;">
            <!-- Wrapped in div to prevent from tabs from auto expanding to the full height -->
            <div>
              <v-tab :to="{ name: 'Media Detail', params:{mediaID: $route.params.mediaID} }" class="justify-start">
                  <v-icon>mdi-account</v-icon>
                  <span class="ml-2 pr-1">Media Info</span>
              </v-tab>

              <v-tab :to="{ name: 'Media Questions', params:{mediaID: $route.params.mediaID} }" class="justify-start">
                  <v-icon>mdi-account</v-icon>
                  <span class="ml-2 pr-1">Questions</span>
              </v-tab>
            </div>
          </v-tabs>
        </div>

        <div class="pt-4 flex-grow-1">
          <!-- Plays a quick fade in transition on route change  -->
<!--            <transition name="fade" mode="out-in">-->
                <!-- Keeping them mounted to increase switching speed -->
                <!--<keep-alive max="2">-->
                <router-view></router-view>
                <!--</keep-alive>-->
<!--            </transition>-->
        </div>

      </div>
    </v-sheet>
  </div>
</template>

<script>
  export default {
    beforeRouteLeave(e) {
      this.$destroy()
      this.$router.push(e.fullPath)
    },  };
</script>

<style lang="scss"></style>
