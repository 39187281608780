import Route from "./_route";

class Profile extends Route {
	getLogin() {
		return this.axios.get(`/Profile/Login`);
	}

	create(userModel, appBaseURL) {
		return this.axios.post(`/Profile/Create?appBaseURL=${appBaseURL}`, userModel);
	}

	edit(userKey) {
		return this.axios.get(`/Profile/Edit?userKey=${userKey}`);
	}

	update(userModel) {
		return this.axios.post(`/Profile/Update`, userModel);
	}
}

export default Profile;
