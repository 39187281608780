<template>
  <div>
    <!-- Cool loading indicator that mimicks what v-cards do.  -->
    <div><v-progress-linear :style="`opacity: ${loading? '1': '0'};'`" indeterminate color="secondary"></v-progress-linear></div>
    
    <div class="px-6 py-4">
      <h2 class="mb-2">Settings</h2>
      <x-expand-alert type="success" ref="alert" :dismissible="true"></x-expand-alert>
      <p>
        This section should be broken into several forms instead of one giant settings table. Maybe a stretch feature, but we should at least
          be making these column names a little more friendly and hiding some of the needlessly techinical descriptions. 
      </p>
    </div>
  </div>
</template>

<script>

import XExpandAlert from '@/components/_generics/x-expand-alert.vue';
import InputRules from '@/utils/inputFieldRules';
import * as FC from '@/components/filtering/constants.js';

export default {
  name: "Settings", 
  
  components: {
    XExpandAlert
  },
  mixins: [InputRules],

  data() {
    return {
      loading: false,
    }
  },

  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },

  methods: {

  },

}
</script>

<style>

</style>