export const FILTER_NONE = 0, FILTER_BINARY = 1,
		FILTER_EQUATION_F = 2, FILTER_EQUATION_N = 3, FILTER_EQUATION_S = 4, FILTER_EQUATION_T = 5;
export const EQ_FILTERS = [FILTER_EQUATION_F, FILTER_EQUATION_N, FILTER_EQUATION_S, FILTER_EQUATION_T];

// Flag Values
export const FLAG_NONE = 0, FLAG_RED = 1, FLAG_YELLOW = 2, FLAG_GREEN = 3;
export const FLAG_TYPES = [FLAG_NONE, FLAG_RED, FLAG_YELLOW, FLAG_GREEN];
export const FLAG_COLORS = ['', 'red', '#daa520', 'green'];

// Equation Operations
export const OP_EQUALS   = { op: 'equals', help: 'Equals value.' };
export const OP_LEQUALS  = { op: 'lequals', help: 'Less than/equals value.' };
export const OP_LESS     = { op: 'less', help: 'Less than value.' };
export const OP_GEQUALS  = { op: 'gequals', help: 'Greater than/equals value.' };
export const OP_GREATER  = { op: 'greater', help: 'Greater than value.' };
export const OP_EMPTY    = { op: 'empty', help: 'Is empty/null.' };
export const OP_STARTS   = { op: 'starts', help: 'Starts with given value.' };
export const OP_CONTAINS = { op: 'contains', help: 'Contains given value.' };
export const OP_ENDS     = { op: 'ends', help: 'Ends with given value.' };
// Need date picker on text fields for these operations
// (Or just make them use MM/DD/YYYY format in text)
export const OP_AFTER   = { op: 'after', help: 'After given value.' };
export const OP_AEQUALS = { op: 'aequals', help: 'After/equals given value.' };
export const OP_BEFORE  = { op: 'before', help: 'Before given value.' };
export const OP_BEQUALS = { op: 'bequals', help: 'Before/equals given value.' };

// Valid operations for various equation filters
export const VALID_OPERATORS_F = [OP_EQUALS, OP_EMPTY];
export const VALID_OPERATORS_N = [OP_EQUALS, OP_LEQUALS, OP_LESS, OP_GEQUALS, OP_GREATER, OP_EMPTY];
export const VALID_OPERATORS_S = [OP_EQUALS, OP_STARTS, OP_CONTAINS, OP_ENDS, OP_EMPTY];
export const VALID_OPERATORS_T = [OP_EQUALS, OP_AFTER, OP_AEQUALS, OP_BEFORE, OP_BEQUALS, OP_EMPTY];

// Gets the valid operators for a given type
export const validOperations = (type) => {
	switch (type) {
		case FILTER_EQUATION_F:
			return VALID_OPERATORS_F;
		case FILTER_EQUATION_N:
			return VALID_OPERATORS_N;
		case FILTER_EQUATION_S:
			return VALID_OPERATORS_S;
		case FILTER_EQUATION_T:
			return VALID_OPERATORS_T;
	}
}

// Checks if the given value passes the equation. Type is the type of the filter.
export const validateEquation = (equation, value, type) => {
	let eqValue = equation.value;
	let operator = equation.operator;

  if (operator !== 'empty') {
    // Manipulate values given the equation type
    if (type === FILTER_EQUATION_N) {
      // Convert values to numbers
      eqValue = Number(eqValue);
      value = Number(value);

      // Check if values are NaN
      if (isNaN(eqValue) || isNaN(value)) return false;
    }

    else if (type === FILTER_EQUATION_F) {
      // Swap value to flag type
      switch (eqValue) {
        case 'red':
          eqValue = FLAG_RED;
          break;
        case 'yellow':
          eqValue = FLAG_YELLOW;
          break;
        case 'green':
          eqValue = FLAG_GREEN;
          break;
        case '':
        case ' ':
        case 'none':
        case 'unflagged':
          operator = 'equals';
          eqValue = FLAG_NONE;
          break;
      }
    }

    else if (type === FILTER_EQUATION_T) {
      if (value === null || value.length === 0) return false
      // Convert values to dates
      eqValue = new Date(eqValue);
      value = new Date(value);

      const isValidDate = eqValue instanceof Date && value instanceof Date
      // Check if values are correct instances of dates
      if (!isValidDate) return false;
    }

    else {
      // Make string for consistent
      if (!value) {
        value = ''
      }
      value = value.toString().toLowerCase();
    }
  } else {
    if (type === FILTER_EQUATION_F) {
      if (value === FLAG_NONE) {
        value = null
      }
    }
  }

	switch (operator) {
		case 'equals':
			return value === eqValue;

		case 'lequals':
			return value <= eqValue;

		case 'less':
			return value < eqValue;

		case 'gequals':
			return value >= eqValue;

		case 'greater':
			return value > eqValue;

		case 'empty':
			return value === null || value.length === 0;

		case 'starts':
			return value.startsWith(eqValue);

		case 'ends':
			return value.endsWith(eqValue);

		case 'contains':
			return value.includes(eqValue);

    case 'after':
      return new Date(value) > new Date(eqValue);

    case 'before':
      return new Date(value) < new Date(eqValue);

    case 'aequals':
      return new Date(value) >= new Date(eqValue);

    case 'bequals':
      return new Date(value) <= new Date(eqValue);

		default:
			return true;
	}
}

// Parses out the equation into usable parts.
// eq is the equation, valid is the list of valid operators
export const extractEquation = (eq, valid) => {
	if (!eq || !valid) return null;
	eq = eq.toLowerCase();

	// Only a valid equation if it contains () and is longer than shortest possible equation
	if (eq.length < 6 || (eq.indexOf('(') === -1 || eq.indexOf(')') === -1)) return null;

	const invert = eq.substring(0, 3) === 'not' ? true : false;
	const operator = invert ? eq.substring(4, eq.indexOf('(')) : // Start after not
								eq.substring(0, eq.indexOf('(')); // From start to (
	const value = eq.substring(eq.indexOf('(') + 1, eq.indexOf(')'));

  // Check if the operator is valid and value exists when the operator isn't 'empty'
	let validOP = false;
	valid.forEach(op => {
		if (operator === op.op) {
			validOP = true;
			return true;
		}
	});

  // Final check
	if (!validOP || (value.length === 0 && operator !== 'empty')) return null;

  return {
		invert: invert,
		operator: operator,
		value: value
	};
}

export const filterData = (data, headers) => {
	return data.filter(row => {
		const keys   = Object.keys(row);
		const values = Object.values(row);
		let passes = true;

		// Only return a row if it properly passes an advanced filter
		headers.forEach(header => {
			if (passes === false) return; // Don't filter if one check fails

			// Get index of the header because the row object isn't in the same order
			const value = values[keys.indexOf(header.value)];
			const type = header.type;

			// Equation filter
			if (EQ_FILTERS.includes(type)) {
				const valid = validOperations(type);
				const eq1 = extractEquation(header.filters.first, valid);

				// Initial checks to see if the equation is valid. If so, check for the second equation as well
				if (eq1 === null) return;


        const eq2 = extractEquation(header.filters.second, valid);
				const op = header.filters.operator;

				// Check if the equation is valid
				const passesFirst = eq1.invert ? !validateEquation(eq1, value, type) : validateEquation(eq1, value, type);
				if (!passesFirst && (eq2 === null || op.length === 0)) { passes = false; return; } // Only not pass if second equation doesn't exist

				// Check if the second equation exists again
				if (eq2 === null || op.length === 0) return;

				// Check if the second equation passes
				const passesSecond = eq2.invert ? !validateEquation(eq2, value, type) : validateEquation(eq2, value, type);

				// Do user bool operation
				if (op === 'and') {
					passes = passesFirst && passesSecond;
				} else if (op === 'or') {
					passes = passesFirst || passesSecond;
				}
			}

			// Binary filter
			else if (type === FILTER_BINARY) {
				// Both true
				if (header.filters.true && header.filters.false) return;

				// Both false
				if (header.filters.false === false && header.filters.true === false) { passes = false; return; }

				// One or the other
				const passesTrue  = header.filters.true  ? value === true  : true;
				const passesFalse = header.filters.false ? value === false : true;
				if (!passesTrue || !passesFalse) passes = false;
			}
		});

		return passes;
	});
}
