<template>
  <div class="ma-6">
    <div class="data-report__header">
      <h1 class="mb-4">Data Report</h1>
      <v-btn color="secondary"
             @click="goToVisual()">Visual Report</v-btn>
    </div>

    <section>
      <x-expand-alert type="success" ref="alert" :dismissible="true"></x-expand-alert>

      <!-- Loading spinner -->
      <div v-if="loadingHeaders" class="d-flex align-center justify-center flex-column">
        <h2 class="error-title mb-6">Loading report...</h2>
        <v-progress-circular indeterminate :size="64"></v-progress-circular>
      </div>

      <div class="report-content" v-else>
        <div class="data-report__header mb-4">
          <h2></h2>
          <div>
            <!--    Import data dialog        -->
            <v-dialog
              v-if="!userIsViewer"
              v-model="importDataDialog"
              max-width="500"
              @click:outside="closeImportDataDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loadingData"
                  @click="joinNotificationsGroup"
                >
                  Import Data
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Import Data
                </v-card-title>
                <v-card-text v-if="!startNotifications">
                  <div class="mb-2">
                    <v-progress-linear
                      v-if="loadingImportData"
                      indeterminate
                      color="secondary"
                    ></v-progress-linear>
                    <span v-else class="import-data__error-message">{{ importDataError }}</span>
                  </div>
                  <v-file-input
                    accept=".xls, .csv, .xlsx"
                    label="Select File..."
                    v-model="importFile"
                  ></v-file-input>
                </v-card-text>
                <v-card-text v-else>
                  <div class="my-4">
                    <h4 class="mb-6">{{ importDataMsg }}</h4>
                    <v-progress-linear
                      color="secondary"
                      height="10"
                      :value="importDataProgress"
                    ></v-progress-linear>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="closeImportDataDialog"
                  >
                    close
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="importData"
                    :disabled="!importFile || loadingImportData || startNotifications"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--     Export to Excel button       -->
            <v-btn color="primary" @click="exportToExcel" :disabled="filteredData.length === 0">
              Export to excel
            </v-btn>
          </div>
        </div>

        <!-- DataReport data-table -->
        <v-flex class="flex-grow-1 mb-6 data-report__container">

          <!-- RESPONDENT TABLE -->
          <div id="reportData-table-bar" class="d-flex align-center py-2 px-4 mb-2 accent-border rounded ">

            <!-- Show/Hide Columns Button -->
            <v-menu offset-y
                    :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary"
                       class="reportData-select"
                       v-bind="attrs"
                       v-on="on">
                  Show / Hide Columns <v-spacer></v-spacer><v-icon class="ml-2" small>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list class="condensed-list">
                <v-list-item dense
                             v-for="(item, index) in allDataTableHeaders"
                             :key="index">
                  <v-list-item-title>
                    <div class="d-flex align-center">
                      <v-checkbox
                                  v-model="item.visible"
                                  :hide-details="true"
                                  :label="item.text"
                                  :ripple="false"
                                  style="margin: 0; padding: 0;"></v-checkbox>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Advanced Filters Button -->
            <v-menu offset-y
                    :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary"
                       class="reportData-select ml-4"
                       v-bind="attrs"
                       v-on="on">
                  Advanced Filters <v-spacer></v-spacer><v-icon class="ml-2" small>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <!-- Filters dropdown -->
              <v-list class="condensed-list">
                <v-list-item dense
                             v-for="(item, index) in allDataTableHeaders"
                             :key="index"
                             link>
                  <v-list-item-title>
                    <v-dialog max-width="325px">
                      <template v-slot:activator="{ on, popout }">
                        <div class="d-flex align-center"
                             v-bind="popout"
                             v-on="on">
                          {{ item.text }} <v-icon color="primary" class="ml-auto">mdi-chevron-right</v-icon>
                        </div>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title class="headline">{{ item.text }} Filters</v-card-title>

                          <v-card-text style="padding: 16px;">
                            <div class="filters-popout" v-if="FC.EQ_FILTERS.includes(item.type)">
                              <span>Show items with a value that:</span>
                              <div class="d-flex align-center">
                                <v-text-field v-model="allDataTableHeaders[index].filters.first"
                                              label="First Equation"
                                              outlined
                                              dense
                                              :hide-details="true"></v-text-field>

                                <div v-if="item.type === FC.FILTER_EQUATION_T" class="filter-datetime-picker__container">
                                  <v-btn icon >
                                    <v-icon>mdi-calendar</v-icon>
                                  </v-btn>
                                  <div class="filter-datetime-picker">
                                    <v-datetime-picker
                                      v-model="firstEquationDate"
                                      label="Select date"
                                      @input="() => allDataTableHeaders[index].filters.first += firstEquationDate + ')'"
                                      date >
                                      <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                      </template>
                                      <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                      </template>
                                    </v-datetime-picker>
                                  </div>
                                </div>
                              </div>

                              <v-radio-group v-model="allDataTableHeaders[index].filters.operator"
                                             row>
                                <v-radio :label="'And'" value="and"></v-radio>
                                <v-radio :label="'Or'"  value="or"></v-radio>
                              </v-radio-group>
                              <div class="d-flex align-center">
                                <v-text-field v-model="allDataTableHeaders[index].filters.second"
                                              label="Second Equation"
                                              outlined
                                              dense
                                              :hide-details="true"></v-text-field>
                                <div v-if="item.type === FC.FILTER_EQUATION_T" class="filter-datetime-picker__container">
                                  <v-btn icon >
                                    <v-icon>mdi-calendar</v-icon>
                                  </v-btn>
                                  <div class="filter-datetime-picker">
                                    <v-datetime-picker
                                      v-model="secondEquationDate"
                                      label="Select date"
                                      @input="() => allDataTableHeaders[index].filters.second += secondEquationDate + ')'"
                                      date >
                                      <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                      </template>
                                      <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                      </template>
                                    </v-datetime-picker>
                                  </div>
                                </div>
                              </div>

                              <v-btn text @click="equationInfo = !equationInfo"
                                     style="margin-top: 16px;">
                                How to Use<v-icon>{{ equationInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </div>

                            <div class="filters-popout" v-if="item.type === FC.FILTER_BINARY">
                              <span>Choose which values display:</span>

                              <v-checkbox v-model="allDataTableHeaders[index].filters.true"
                                          :label="'True'"
                                          :hide-details="true"
                                          style="margin: 0; padding: 0;"></v-checkbox>
                              <v-checkbox v-model="allDataTableHeaders[index].filters.false"
                                          :label="'False'"
                                          :hide-details="true"
                                          style="margin: 0; padding: 0;"></v-checkbox>
                            </div>
                          </v-card-text>

                          <!-- Equation Instructions -->
                          <v-expand-transition v-if="item.type !== FC.FILTER_BINARY">
                            <div v-show="equationInfo">
                              <v-card-text>
                                <p>All equations are formatted like: <i>"operator(value)"</i> where operator is an operator (list below) and value is the value you want to compare to.</p>
                                <p>Use <i>"not operator(value)"</i> to invert an operator.</p>
                                <p>For example: <i>"equals(123)"</i> would only show values equal to "123". <i>"not equals(123)"</i> would show everything that doesn't equal 123.</p>
                                <p>Picking "and/or" just tests the first equation against the second. If an equation is invalid it wont do any filtering.</p>
                                <p v-if="item.type === FC.FILTER_EQUATION_T"><b>Dates</b>: If you pick a time in a date/time picker, it will copy it to your clipboard to easily paste into an equation.</p>
                                <p><b>Operators:</b></p>
                                <ul v-for="(op, opIndex) in FC.validOperations(item.type)" :key="opIndex">
                                  <li>{{ op.op }}(): {{ op.help }}</li>
                                </ul>
                              </v-card-text>
                            </div>
                          </v-expand-transition>

                          <v-card-actions>
                            <v-btn text class="ml-auto" color="secondary"
                                   @click="filterData(), dialog.value = false">Filter</v-btn>
                            <v-btn text
                                   @click="resetAdvancedFilter(index), dialog.value = false">Clear</v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn @click="initFilters" color="primary" class="ml-4" >Clear Filters</v-btn>

          </div>
          <div class="tableWrapper data-report-table__container">
            <!-- DATA REPORT  TABLE -->
            <v-data-table id="reportData-table"
                          height="800"
                          fixed-header
                          :headers="shownDataTableHeaders"
                          :items="filteredData"
                          :loading="loadingData"
                          :footer-props="{'items-per-page-options':[25, 50, 100]}"
                          dense
                          class="accent-border data-report__table"
                          no-data-text="No data found!"
                          @update:items-per-page="updateItemsPerPage"
                          :page.sync="page"
                          >

              <template v-slot:footer>
                <v-pagination
                  class="px-4"
                  v-model="page"
                  :length="pageCount">
                </v-pagination>
              </template>

              <template v-slot:[`item.Flag`]="{ item }">
                <v-icon v-if="item.Flag == FC.FLAG_RED" title="Flagged Red" :color="FC.FLAG_COLORS[FC.FLAG_RED]">mdi-flag</v-icon>
                <v-icon v-else-if="item.Flag == FC.FLAG_YELLOW" title="Flagged Yellow" :color="FC.FLAG_COLORS[FC.FLAG_YELLOW]">mdi-flag</v-icon>
                <v-icon v-else-if="item.Flag == FC.FLAG_GREEN" title="Flagged Green" :color="FC.FLAG_COLORS[FC.FLAG_GREEN]">mdi-flag</v-icon>
              </template>

              <template v-slot:[`item.BrowserUserAgent`]="{ item }">
                <div class="item-truncate" >
                  {{ item.BrowserUserAgent }}
                </div>
              </template>

              <template v-slot:[`item.IsIdentityAnomaly`]="{ item }">
                <v-simple-checkbox :value="item.IsIdentityAnomaly"
                                   disabled></v-simple-checkbox>
              </template>

              <template v-slot:[`item.Dropped`]="{ item }">
                <v-simple-checkbox :value="item.Dropped"
                                   disabled></v-simple-checkbox>
              </template>

              <template v-slot:[`item.Complete`]="{ item }">
                <v-simple-checkbox :value="item.Complete"
                                   disabled></v-simple-checkbox>
              </template>

            </v-data-table>
          </div>

        </v-flex>
      </div>
    </section>
  </div>
</template>

<script>
  import Api from "@/api/api";
  import {json2excel} from 'js2excel';

  import XExpandAlert from '@/components/_generics/x-expand-alert.vue';
  import Events from "@/events/events";

  import * as FC from '@/components/filtering/constants'

  export default {
    components: {
      XExpandAlert,
    },

    data() {
      const typeOfColumn = {
        'Flag': FC.FILTER_EQUATION_F,
        'ExternalUserKey': FC.FILTER_EQUATION_S,
        'UserSessionKey': FC.FILTER_EQUATION_N,
        'Time': FC.FILTER_EQUATION_N,
        'SliderPosition': FC.FILTER_EQUATION_N,
        'MediaKey': FC.FILTER_EQUATION_N,
        'BrowserUserAgent': FC.FILTER_EQUATION_S,
        'DateTimeUTC': FC.FILTER_EQUATION_T,
        'IsIdentityAnomaly': FC.FILTER_BINARY,
        'Complete': FC.FILTER_BINARY,
        'Dropped': FC.FILTER_BINARY,
      }
      return {
        typeOfColumn,

        loadingHeaders: false,
        loadingData: false,

        importDataDialog: false,
        importFile: null,
        loadingImportData: false,
        importDataMsg: '',
        importDataProgress: null,
        importDataError: '',
        startNotifications: false,

        FC,
        allDataTableHeaders: [],
        reportData: [],
        filteredData: [],
        equationInfo: false,
        firstEquationDate: '',
        secondEquationDate: '',

        page: 1,
        itemsPerPage: 25,
      }
    },

    computed: {
      shownDataTableHeaders() {
        return this.allDataTableHeaders.filter(header => header.visible)
      },

      userIsViewer() {
        const projectInfo = this.$store.state.ProjectStore.currentProjectInfo
        return !projectInfo.isAdmin && projectInfo.currentUserType == 'Viewer'
      },

      totalRows() {
        return this.filteredData.length
      },

      pageCount() {
        return Math.ceil(this.totalRows / this.itemsPerPage)
      }
    },

    mounted() {
      this.fetchData()
      Events.$on('importReportNotification', this.importReportListener)
    },

    watch: {
      '$route.params.mediaID': function(val) {
        if (val > 0) {
          this.refresh();
        }
      },
    },

    methods: {
      refresh() {
        this.fetchData()
      },

      fetchData() {
        this.getReportColumns()
      },

      getReportColumns() {
        this.allDataTableHeaders = [];
        this.loadingHeaders = true;
        Api.Projects.getUserDataColumnsForReports(this.$route.params.mediaID)
          .then(response => {
            this.allDataTableHeaders = this.getDataTableHeaders(response.data.$values);
            this.initFilters()
            this.loadingHeaders = false;
            this.getReportData()
          })
          .catch(error => {
            this.handleError(error);
          });
      },

      getReportData() {
        this.reportData = [];
        this.filteredData = [];
        this.loadingData = true;
        Api.Projects.getUserDataForReports(this.$route.params.mediaID)
          .then(response => {
            const data = response.data.$values;
            const mappedData = data.map(row => {
              const mappedRow = {}

              this.allDataTableHeaders.forEach(header => {
                mappedRow[header.value] = row[header.value] || null
              })

              return {
                ...mappedRow,
                Flag: parseInt(row['Flag']),
                IsIdentityAnomaly: this.parseBoolean(row.IsIdentityAnomaly),
                Complete: this.parseBoolean(row.Complete),
                Dropped: this.parseBoolean(row.Dropped)
              }
            })

            this.reportData = mappedData;
            this.filteredData = mappedData;
            this.loadingData = false;
          })
          .catch(error => {
            this.handleError(error);
          })
      },

      getDataTableHeaders(columns) {
        return columns.map(column => {
          const type = this.typeOfColumn[column.title] || FC.FILTER_EQUATION_S
          const header = {
            text: column.title,
            value: column.title,
            sortable: true,
            visible: true,
            filters: {},
            type
          }
          if (column.title === 'BrowserUserAgent') {
            header['width'] = '330px'
          }
          return header
        })
      },

      exportToExcel() {
        const projectId = this.$route.params.projectId
        const mediaId = this.$route.params.mediaID
        try {
          json2excel({
            data: this.filteredData,
            name: `project-${projectId}-media-${mediaId}`,
          });
        } catch (e) {
          console.error('export error');
        }
      },

      joinNotificationsGroup() {
        this.$store.commit('ReportStore/setImportDataMediaId', this.$route.params.mediaID)
      },

      importReportListener(msg) {
        this.loadingImportData = false
        if (msg.message) {
          this.importDataMsg = msg.message
        }

        if (msg.percent) {
          this.importDataProgress = parseInt(msg.percent)
        }

        if (msg.complete) {
          this.importDataProgress = 0
          this.importDataMsg = ''
          this.closeImportDataDialog()
          this.getReportData()
          this.startNotifications = false
          this.$refs.alert.setMessage("Data imported successfully.")
        }
      },

      importData() {
        const formData = new FormData()
        formData.append('files', this.importFile)

        this.loadingImportData = true

        Api.Projects.importReports(this.$route.params.mediaID, formData)
          .then(response => {
            this.startNotifications = true
            this.importDataMsg = "Starting processing data. \nIf process doesn't start soon, please verify the source file format."
          })
          .catch(error => {
            console.log(error)
            this.loadingImportData = false
            this.importDataError = 'An error occurred, try again.'
          })
      },

      closeImportDataDialog() {
        this.loadingImportData = false
        this.importDataError = ''
        this.importFile = null
        this.importDataDialog = false
        this.importDataMsg = ''
        this.startNotifications = false
        this.importDataProgress = 0
      },

      goToVisual() {
        this.$router.push({
          name: 'Visual Report',
          params: {
            projectId:  this.$route.params.projectId,
            mediaID: this.$route.params.mediaID,
          },
        });
      },

      initFilters() {
        // Set advanced filters
        this.allDataTableHeaders.forEach((header, i) => {
          if (header.type !== FC.FILTER_NONE) {
            this.resetAdvancedFilter(i);
          }
        });
      },


      filterData() {
        this.filteredData = FC.filterData(this.reportData, this.allDataTableHeaders);
      },

      resetAdvancedFilter(index) {
        // Reset a specific filter
        if (FC.EQ_FILTERS.includes(this.allDataTableHeaders[index].type)) {
          this.allDataTableHeaders[index].filters = { first: '', operator: '', second: '' };
        }

        else if (this.allDataTableHeaders[index].type === FC.FILTER_BINARY) {
          this.allDataTableHeaders[index].filters = { true: true, false: true}; // Check both boxes
        }

        this.filterData()
      },


      handleError(error)  {
        console.log(error);
        this.$refs.alert.setMessage('An error occurred, try later.', 'error');
        this.loadingHeaders = false;
        this.loadingData = false
      },

      parseBoolean(input) {
        return input === 'True'
      },

      updateItemsPerPage(number) {
        this.itemsPerPage = number
      }

    }
  };
</script>

<style lang="scss">
  .data-report__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .data-report__container {
    display: grid;
  }

  .data-report-table__container {
    max-width: 100%;
    overflow-x: auto;
  }

  .import-data__error-message {
    color: #F44336;
    font-size: .75rem;
  }

  .data-report__table {
    th {
      position: relative;
    }
  }

  .item-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
