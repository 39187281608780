<template>
	<div class="d-flex flex-column align-center fill-width">
		<v-sheet style="overflow: hidden" class="fill-width mb-12" elevation="1" :rounded="!$vuetify.breakpoint.xsOnly" :loading="loading">
			<div id="project-bar" class="d-flex flex-column flex-md-row">

				<x-expand-container style="min-width: 200px;">
					<div class="d-flex flex-column header-parent px-6 py-2">
						<span style="flex: 0 0 auto;" class="project-header">{{ project.customer }}</span>
						<v-tooltip bottom open-delay="400">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" class="project-desc">{{ project.projectName }}</span>
							</template>

							<span v-if="project.description != null && project.description.trim() != ''">{{ project.description }}</span>
							<i v-else>No description found!</i>
						</v-tooltip>
					</div>
				</x-expand-container>

				<div class="flex-grow-1">
					<v-tabs v-model="activeTab" background-color="var(--v-primary-base)" color="white" grow>
						<v-tab v-if="isBuilder || isAdmin" :to="{ name: 'Project Settings' }" :key="0">Settings</v-tab>
						<v-tab v-if="isBuilder || isAdmin" :to="{ name: 'Project Subsets' }" :key="1">Subsets</v-tab>

						<!-- List of Media Items Dropdown -->
						<template v-if="isBuilder || isAdmin">
							<v-menu offset-y open-on-hover>
								<template v-slot:activator="{ on, attrs }">
									<v-tab :to="{ name: 'Media List' }"
										v-bind="attrs"  :key="2"
										v-on="on">Media</v-tab>
								</template>
								<v-list v-if="!loading && media.length">
									<v-list-item v-for="(item, i) in media" :key="i"
										link dense>
										<v-list-item-title @click="gotoMedia(item.fileKey)">
											{{ item.sortOrder }} | {{ item.name }}</v-list-item-title>
									</v-list-item>
								</v-list>
								<v-list v-if="loading"><v-list-item>Loading...</v-list-item></v-list>
							</v-menu>
						</template>
            <v-tab v-else :to="{ name: 'Media List' }" :key="2">Media</v-tab>

						<v-tab :to="{ name: 'Project Respondents' }" :key="3">Respondents</v-tab>
						<v-tab :to="{ name: 'Visual Report', params: { mediaID: -1 }}" :key="4">Reports</v-tab>
						<v-tab v-if="isBuilder || isAdmin" :to="{ name: 'Project Users' }" :key="5">Users</v-tab>
					</v-tabs>
				</div>
			</div>

			<x-expand-container>
				<!-- Plays a quick fade in transition on route change  -->
				<transition name="fade" mode="out-in">
					<!-- Keeps limited number of components mounted to make it faster to switch between router views -->
					<keep-alive max="6">
						<router-view @changeProjectTitle="changeTitle($event)"></router-view>
					</keep-alive>
				</transition>
			</x-expand-container>

		</v-sheet>
	</div>
</template>

<script>
	import XExpandContainer from '@/components/_generics/x-expand-container.vue';
	import Api from '@/api/api';
  import Events from "@/events/events";


  const Project = {
		components: {
			XExpandContainer,
		},

		data() {
			return {
				projectId: -1,
				loading: true,
				isBuilder: false,
				isAdmin: false,
				activeTab: 0,
				media: [],

				project: {
					name: '',
					desc: ''
				}
			}
		},
		mounted() {
      this.$store.commit('ProjectStore/setCurrentProjectInfo', {})
      this.getMedia();
      this.fetchProjectInfo();

      // Initialize listener to update media dropdown items
      Events.$on('updateMedia', this.getMedia);
		},

    computed: {
      currentMediaTitle() {
        if (this.$route.params.mediaID && this.media.length > 0 && this.$route.params.mediaID > 0) {
          const currentMedia = this.media.find((media) => media.fileKey == this.$route.params.mediaID)
          if (currentMedia) {
            return `${currentMedia.sortOrder} | ${currentMedia.name}`
          }
          return ''
        }

        return ''
      }
    },

		methods: {
      fetchProjectInfo() {
        this.projectId = this.$route.params.projectId;
        const customerId = null;

        Api.Projects.getProjectInfo(this.projectId, customerId).then(res => {
          this.changeTitle({
            projectName: res.data.projectName,
            description: res.data.description,
            customer: res.data.customerName,
            currentUserType: res.data.currentUserType,
            isAdmin: res.data.isAdmin,
          })
        });

      },

			changeTitle(project) {
				this.project = project;
				this.isBuilder = project.currentUserType == 'Builder';
				this.isAdmin = project.isAdmin;
				// this.activeTab = (project.activeTab) ? project.activeTab : this.activeTab;


        this.$store.commit('ProjectStore/setCurrentProjectInfo', project)
			},

      getMedia() {
        this.projectId = this.$route.params.projectId;
        this.loading = true;

        // Get media items
        Api.Media.mediaRead(this.projectId).then(res => {
          this.media = res.data.$values;
          this.loading = false;
        });
      },

			gotoMedia(fileKey) {
				// Get current route mediaKey and test if it is the same as the one we are trying to go to
				const mediaKey = this.$route.params.mediaID;
				const test1 = mediaKey === fileKey;

				// Check if '/settings' is in the current route
				const test2 = this.$route.path.includes('/settings');

				// Only go to new route if the id's don't match or if we're not on the settings page
				if (!test1 || !test2) {
					this.$router.push(`/projects/${this.projectId}/media/${fileKey}/settings`);
					// this.$router.push({ name: 'Media', params: { fileKey } });
				} else {
					console.log('gotoMedia(ERR):', 'Already on this media');
				}
			},
		},

		watch: {
			"ProjectStore": function (newVal) {
				console.log("currentProject.projectName", typeof newVal, newVal);
			},
		}
	};

	export default Project;
</script>

<style scoped lang="scss">
	#project-bar {
		position: relative;
		z-index: 1;
		background: var(--v-primary-base);
		border-bottom: 3px solid var(--v-primary-base);
		box-shadow: #00000069 0px 2px 3px;
	}

	/* EXTREMELY IMPORTANT FOR CSS text-overflow cutoffs
	white-space: no-wrap creates super long width elements that flex does not handle well by default */
	#project-bar .header-parent {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		flex: 1 1 auto;
		width: 100%;
		min-height: 50px;
		min-width: 0; /* this one right here does it!*/
		color: white;
	}

	#project-bar .project-header {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		font-size: 26px;
		font-weight: bold;
	}

	#project-bar .project-desc {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		color: rgb(224, 224, 224);
	}

	#project-bar .project-desc, #project-bar .project-header {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	/* makes the tabs fill height */
	#project-bar .v-tabs, #project-bar .v-tabs > .v-tabs-bar {
		height: 100%;
		min-height: 48px; /* This is the normal height of the tabs */
		align-items: flex-end;
	}

	#project-bar .v-tab {
		transition-property: color, text-shadow;
		transition-duration: 0.2s;
		transition-timing-function: ease-in-out;
		color: rgba(255, 255, 255, 0.687);
		/* color: white; */
	}

	#project-bar .v-tab.v-tab--active {
		color: white;
	}

	#project-bar .v-tabs-slider-wrapper {
		box-shadow: rgb(255 255 255 / 84%) 0px -2px 7px, rgb(255 255 255 / 73%) 0px -1px 2px;
		/* background: var(--v-secondary-base); */
	}
</style>
