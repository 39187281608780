<template>
  <div class="px-6">
    <v-container style="width: 100%;" class="px-0">
      <v-row>
        <v-col>
          <h2>Questions</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <x-player v-if="src"
						:youtube="mediaType === TYPE_YOUTUBE"
						:src="src"
						:startTime="media.startTimeString"
						:endTime="media.endTimeString"
            :showControls="true"
						height="400"></x-player>
        </v-col>
        <v-col>
          <div class="d-flex justify-end">
            <MediaDialog
              :edit="false"
              :mediaId="this.$route.params.mediaID"
              :name="media.name"
              :type="media.mediaType"
              :availableHotKeyButtons="availableHotKeyButtons"
              :media="media">
            </MediaDialog>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet>
            <v-data-table
              :headers="headers"
              :items="questions"
              :loading="loadingQuestions || loading"
              class="elevation-0"
              style="width: 100%"
              >
              <template v-slot:item="row">
                <tr style="width: 100%;">
                  <td>{{ row.item.Question }}</td>
                  <td>{{ types[row.item.MediaQuestionType] }}</td>
                  <td>
                    <v-icon v-if="row.item.IsHotKeyQuestion">mdi-fire</v-icon>
                    <span v-else> {{ row.item.FrameTimeString }}</span>
                  </td>
                  <td>{{ row.item.MediaQuestionKey }}</td>
                  <!-- EDIT BUTTON -->
                  <td>
                    <MediaDialog
                      @test-click="sendRowData(row.item)"
                      :edit="true"
                      :questionModel="row.item"
                      :name="media.name"
                      :type="media.mediaType"
                      :availableHotKeyButtons="availableHotKeyButtons"
                      :media="media"
                      :loading="loadingQuestions"
                    >
                    </MediaDialog>
                  </td>
                  <!-- DELETE BUTTON -->
                  <td>
                    <v-btn icon class="ml-1" color="secondary" @click="deleteQuestion(row.item)" :disabled="loadingQuestions">
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <x-confirm-dialog ref="confirmMediaDelete"
                      title="Delete Question?"
                      message=""
                      :showConfirmCheckbox="true"></x-confirm-dialog>
  </div>
</template>

<script>
import MediaDialog from '../../../../components/projects/media/v-media-dialog.vue';
import XPlayer from "@/components/projects/media/x-player.vue";
import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";

import videojs from 'video.js';

import Events from "@/events/events";
import Api from '@/api/api';

export default {
  components: {
    MediaDialog,
    XPlayer,
    XConfirmDialog
  },

  mounted() {
    this.projectId = parseInt(this.$route.params.projectId);
    this.mediaId = parseInt(this.$route.params.mediaID);

    // Get initial data
    this.reloadQuestions(true);

    // Listen for question refresh event
    Events.$on("refreshQuestions", () => { this.getQuestions() });
  },

  created() {
    // add subscriber
    Events.$on('openQuestionDialog', this.openQuestion());
  },

  data () {
    const TYPE_HOSTED = 1,
          TYPE_THIRD_PARTY = 2,
          TYPE_YOUTUBE = 3;

    return {
      loading: true,
      loadingQuestions: false,

      tab: 'active',
      video: 'https://www.youtube.com/embed/tgbNymZ7vqY',
      currentTime: 0,
      expanded: [],
      expand: false,
      expandButton: "Expand All",

      headers: [
        {
          text: 'Question',
          align: 'start',
          sortable: true,
          value: 'Question',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'MediaQuestionType',
        },
        {
          text: 'Time Point',
          align: 'start',
          sortable: true,
          value: 'Frame',
        },
        {
          text: 'Question ID',
          align: 'start',
          sortable: true,
          value: 'MediaQuestionKey',
        },
        { text: '', value: 'edit', sortable: false, },
        { text: '', value: 'delete', sortable: false, }
      ],

      media: {},
      mediaId: 0,
      mediaType: 0,
      projectId: 0,
      questions: [],
      availableHotKeyButtons: [],
      hotKeyButtons: [],
      src: "",
      types: ['Invalid Type', 'Yes/No', 'Discrete Choice', 'Multiple Choice', 'Open-End'],
      TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE,
    }
  },

  methods: {
    reloadQuestions(triggerLoad = false) {
      // Gets disabled in getQuestions
      if (triggerLoad) this.loading = true;

      Api.Media.getMedia(this.projectId, this.mediaId).then(res => {
        this.media = res.data;
        this.mediaType = this.media.mediaType;
        this.src = this.media.videoUrl;

        // Build src url if the media is hosted on AWS
        if (this.mediaType === this.TYPE_HOSTED) {
          const mediaKey = this.media.fileKey;
          const awsURL = this.media.awsCloudfrontUrl;
          const timestamp = new Date().getTime();
          this.src = `${awsURL}transcoded/${mediaKey}/dash_${mediaKey}index.mpd?timestamp=${timestamp}`;
        }

        // Get questions after the media item
        this.getQuestions(triggerLoad);
      }).catch(err => {
        console.log('reloadQuestions(ERR)', err);
        this.loading = false;
      });

    },

    getQuestions(triggerLoad = false) {
      if (triggerLoad) this.loading = true;

      this.loadingQuestions = true

      Api.Media.getMediaQuestions(this.mediaId).then(res => {
        // console.log('getQuestions():', res.data);
        this.questions = res.data;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        console.log('getQuestions(ERR)', err);
      }).finally(() => {
        this.loadingQuestions = false
      })

      this.getAddMediaQuestion()
    },

    getAddMediaQuestion() {
      Api.Media.addMediaQuestion(this.mediaId, 0)
        .then(response => {
          const data = response.data
          this.availableHotKeyButtons = []
          if (data.hotButton1Text)
            this.availableHotKeyButtons.push({hotKey: 1, hotKeyText: data.hotButton1Text})

          if (data.hotButton2Text)
            this.availableHotKeyButtons.push({hotKey: 2, hotKeyText: data.hotButton2Text})

          if (data.hotButton3Text)
            this.availableHotKeyButtons.push({hotKey: 3, hotKeyText: data.hotButton3Text})
        })
        .catch(error => {
          console.log(error)
        })
    },

    expandAll() {
      this.expand = !this.expand;
      if (this.expand) {
        this.$data.expanded = this.$data.organizations;
        this.expandButton = 'Collapse All';
      } else {
        this.$data.expanded = [];
        this.expandButton = 'Expand All';
      }
    },

    toggleTab(tab) {
      this.tab = tab;
    },

    rowClassGenerator() {
      return 'pointer';
    },
    sendRowData(data) {
      Events.$emit('loadQuestionData', data);
    },
    goToMedia(mediaID) {
      this.$router.push({ name: 'Media', params: { mediaID } });
    },

    openQuestion() {
      this.$refs.videoPlayer.pauseYoutubeVideo();
      this.currentTime = this.$refs.videoPlayer.getYoutubeDuration();
      console.log(this.$refs.videoPlayer.getYoutubeDuration());
      console.log(this.currentTime);
    },

    async deleteQuestion(question) {
      const shouldDelete = await this.$refs.confirmMediaDelete.confirmAction();
      if (shouldDelete) {
        const payload = {
          MediaKey: question.MediaKey,
          MediaQuestionKey: question.MediaQuestionKey
        }
        Api.Media.mediaQuestionDelete(payload).then(res => {
          this.getQuestions();
          // console.log(res);
        }).catch(err => {
          console.log(err);
        });
      }
    },

    getCurrentTime() {
      //this.currentTime = this.$refs.videoPlayer.getYoutubeDuration();
      return this.currentTime;
    },

    formatTime(frame) {
      var time = "";
      var hours = Math.floor(frame / 3600);
      time += (hours.toString().length == 1) ? "0" : "";
      time += hours;
      time += ":";
      var minutes = Math.floor((frame % 60) / 60);
      time += (minutes.toString().length == 1) ? "0" : "";
      time += minutes;
      time += ":";
      var seconds = frame % 3600;
      time += (seconds.toString().length == 1) ? "0" : "";
      time += seconds;
      return time;
    },
  },
}
</script>

<style scoped lang="scss">
  .color-box {
    width: 45px;
    height: 30px;
  }
</style>
