<template>
  <div>
    <v-banner color="primary" sticky dark>
      <div class="d-flex flex-row-reverse">
        <router-link to="/projects-old">
          <v-btn plain small color="secondary">Go to original view</v-btn>
        </router-link>
      </div>
    </v-banner>

    <div class="d-flex flex-column align-center">
      <v-sheet class="fill-width mb-12" elevation="5" :rounded="!$vuetify.breakpoint.xsOnly" :loading="loading">
        <div id="project-bar" class="py-2">
          <div class="project-bar-row d-md-inline-flex px-4 mb-4">
            <h2>Projects</h2>

            <!-- Org Select-->
            <v-tabs background-color="transparent" :disabled="loading" class="mb-3 mb-md-0">
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab @change="activeTab = ProjectTab.Active">Active</v-tab>
              <v-tab @change="activeTab = ProjectTab.Archived">Archived</v-tab>
            </v-tabs>

            <!-- Organizations Dropdown -->
            <v-autocomplete label="Viewing Organization" v-model="selectedOrganizationId" :loading="loading"
              :disabled="cannotSelectOrg" :items="filteredOrganizations" :search-input.sync="orgSearchQuery"
              item-text="name" item-value="customerKey" hide-details dense outlined dark clearable
              class="mb-3 mb-md-0" />

            <!-- Manage Users Button -->
            <x-users-management label="Manage Users" :disabled="!isOrgBuilder" :small="false" :outlined="true"
              color="white" :org="selectedOrganization" />
          </div>

          <div class="project-bar-row d-flex mb-4 px-4">
            <!-- Project Search Bar -->
            <v-text-field label="Search" v-model="projectSearchQuery" :disabled="loading"
              placeholder="Search for projects" dense outlined dark flat clearable color="white" hide-details
              prepend-inner-icon="mdi-magnify" />

            <v-btn @click="gotoCreateProjectPage()" dark color="secondary" :disabled="!canAddProjects">
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Add Project
            </v-btn>
          </div>
        </div>

        <!-- Projects Data Table -->
        <v-data-table :items="projects" :headers="projectTableHeaders" :loading="loadingProjects"
          :options.sync="projectTableOptions"
          :server-items-length="projectsPager !== null ? projectsPager.totalItems : projects.length"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }" loading-text="Loading projects..."
          no-data-text="No projects found!">

          <!-- Organization Name -->
          <template v-slot:[`item.organizationName`]="{ item }">
            <b>{{ item.organizationName }}</b>
          </template>

          <!-- Project Status -->
          <template v-slot:[`item.projectStatus`]="{ item }">
            {{ getProjectStatusText(item.projectStatus) }}
          </template>

          <!-- Completions (So we show 0 and not null) -->
          <template v-slot:[`item.totalCompletes`]="{ item }">
            {{ item.totalCompletes || 0 }}
          </template>

          <!-- Date Created/Modified -->
          <template v-slot:[`item.dateCreated`]="{ item }">
            {{ formatDateCreated(item.dateCreated) }}
          </template>
          <template v-slot:[`item.lastModifiedDate`]="{ item }">
            {{ formatDateModified(item.lastModifiedDate) }}
          </template>

          <!-- Custom Actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">

              <!-- View or Edit -->
              <v-btn icon @click="gotoProjectEditPage(item.projectKey)" color="secondary">
                <v-icon v-if="item.userAccess === UserAccess.Builder">mdi-pencil</v-icon>
                <v-icon v-else>mdi-eye</v-icon>
              </v-btn>

              <v-btn icon @click="toCopyProject = item.projectKey; copyProjectDialog = true" v-if="canCopyProject(item)"
                color="secondary" class=mx-3>
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <!-- Added for padding of the action buttons -->
              <v-btn v-else disabled icon class="mx-3" />

              <v-btn v-if="item.userAccess === UserAccess.Builder" icon @click="deleteProject(item.projectKey)"
                color="secondary">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <!-- Added for padding of the action buttons -->
              <v-btn v-else disabled icon />

            </div>
          </template>
        </v-data-table>

        <!-- Loading Spinner -->
        <!-- Note: Was previously shown if loading the orgs failed -->
        <!-- <div v-else class="d-flex align-center justify-center pa-6">
      <v-progress-circular 
        color="primary"
        indeterminate
        size="128" />
      </div> -->
      </v-sheet>
    </div>

    <!-- Delete Project Dialog -->
    <x-confirm-dialog title="Delete Project?" ref="confirmProjectDeleteDialog" :showConfirmCheckbox="true" />

    <!-- Copy Project Dialog -->
    <v-dialog v-model="copyProjectDialog" max-width="300">
      <v-card>
        <v-card-title>
          <span class="headline">Copy Project?</span>

          <v-btn @click="copyProjectDialog = false" class="ml-auto" icon>
            <v-icon dark>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="pa-4 d-flex space-between">
          <v-btn @click="copyProject(toCopyProject, false); copyProjectDialog = false" color="secondary">
            Copy
          </v-btn>

          <v-btn @click="copyProject(toCopyProject, true); copyProjectDialog = false" class="ml-auto" color="secondary">
            Copy w/ Data
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Api from "@/api/api";
import Events from "@/events/events";
import { defineComponent } from 'vue';

import XUsersManagement from "@/components/projects/x-users-management.vue";
import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";

const ProjectStatus = Object.freeze({
  Testing: 1,
  Live: 2,
  Closed: 3,
  Archived: 4,
});

const ProjectTab = Object.freeze({
  Active: 'Active',
  Archived: 'Archived',
});

const UserAccess = Object.freeze({
  Builder: 1,
  Viewer: 2,
});

export default defineComponent({
  name: 'ProjectsDashboard',

  components: {
    XConfirmDialog,
    XUsersManagement
  },

  data() {
    return {
      // This page has 3 loading states:
      //   1. Getting all the organizations initially, which is "loading"
      //   2. Subsequent calls to getting the orgs, which is "loadingOrgs"
      //   3. Getting the paginated projects, which is "loadingProjects"
      // The page loads initially with "loading" and then projects appear based on the "loadingProjects" state
      // Loading states should only be processed in the function relevant to their API call
      loading: true,         // fetchInitialData()
      loadingOrgs: false,    // getOrganizations()
      loadingProjects: true, // getProjects()

      // If a user only has 1 org, the initial project search would have already queried the data
      // This allows you to not refresh the orgs twice on the initial load because we set the selectedOrganizationId
      ignoreProjectRefresh: false,

      // Enums
      ProjectTab,
      UserAccess,
      ProjectStatus,

      activeTab: ProjectTab.Active,

      allOrganizations: [],
      filteredOrganizations: [],
      initialOrgLength: 0,
      selectedOrganizationId: null,

      orgSearchQuery: '',
      projectSearchQuery: '',
      queryDebounceTimeout: null,

      projects: [],
      projectsPager: null,
      projectTableHeaders: [
        { text: 'Name', value: 'projectName' },
        { text: 'Status', value: 'projectStatus' },
        { text: 'Created', value: 'dateCreated' },
        { text: 'Modified', value: 'lastModifiedDate' },
        { text: 'Modified By', value: 'lastModifiedByUserName' },
        { text: 'Completions', value: 'totalCompletes' },
        { text: '', value: 'actions', sortable: false, align: 'right' },
      ],
      projectTableOptions: {},

      toCopyProject: null,
      copyProjectDialog: false,
    };
  }, // data

  mounted() {
    // Event Hookup
    Events.$on("copyProjectComplete", this.refreshProjects);

    // Fetch the initial data
    this.fetchInitialData();
  }, // mounted

  methods: {
    async fetchInitialData() {

      this.loading = true;
      const orgRes = await this.getOrganizations(true);
      const projectRes = await this.refreshProjects();
      this.loading = false;

      // TODO: Show some error state
      if (orgRes === false || projectRes === false) {
        console.error('fetchInitialData(ERR): Failed to fetch organizations');
        return;
      }
    },

    gotoCreateProjectPage() {
      // You need a customId to create a project as it's part of the router information
      if (this.selectedOrganizationId === null) return;

      this.$router.push({
        name: "Create Project",
        params: {
          customerId: this.selectedOrganizationId,
        },
      });
    },

    gotoProjectEditPage(projectId) {
      this.$router.push({
        name: "Project",
        params: {
          projectId: projectId,
        },
      });
    },

    gotoProjectMediaPage(projectId) {
      this.$router.push({
        name: "Media List",
        params: {
          projectId: projectId,
        },
      });
    },

    formatDateCreated(date) {
      if (!date) return '';
      // Puts the timestamp in the format MM/DD/YYYY
      return new Date(date).toLocaleDateString();
    },

    formatDateModified(date) {
      if (!date) return '';

      // Define options to exclude seconds
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // Ensure 12-hour format with AM/PM
      };

      return new Date(date).toLocaleString('en-US', options);
    },

    getProjectStatusText(status) {
      switch (status) {
        case ProjectStatus.Testing:
          return 'Testing';
        case ProjectStatus.Live:
          return 'Live';
        case ProjectStatus.Closed:
          return 'Closed';
        case ProjectStatus.Archived:
          return 'Archived';
        default:
          return 'Undefined';
      }
    },

    // Fetches all the orgs from the API. Returns true/false based on if the fetch was successful
    async getOrganizations(initial = false) {
      try {
        // Build the search options
        const searchOptions = {
          filterText: this.orgSearchQuery,
        };

        this.loadingOrgs = true;
        const res = await Api.Organization.getOrganizations(searchOptions);
        const orgs = res.data.$values;

        // Remove the pager if the last object is a pager
        if (orgs.length > 0 && orgs[orgs.length - 1].pager !== null) {
          orgs.pop();
        }

        // Do initial run processing
        if (initial) {
          // If on the first run, set the amount of initial orgs so we don't lock out the autocomplete after it filters them
          this.initialOrgLength = orgs.length;

          // Add the organization column to the table headers if there's more than 1 org
          if (this.initialOrgLength > 1) {
            this.projectTableHeaders.unshift({ text: 'Organization', value: 'organizationName' });
          } else {
            // ignore the next refresh on initial load
            this.ignoreProjectRefresh = true;

            // If there's only one org, set it as the selected org
            this.selectedOrganizationId = orgs[0].customerKey;
          }

          this.filteredOrganizations = orgs;
        }

        // Set the states and return
        this.allOrganizations = orgs;
        this.loadingOrgs = false;
        return true;
      } catch (err) {
        this.loadingOrgs = false;
        console.error('getOrganizations(ERR):', err);
        return false;
      }
    },

    filterOrganizations() {

      if (!this.orgSearchQuery || this.orgSearchQuery === '') {
        this.filteredOrganizations = this.allOrganizations;
      } else {
        const searchQuery = this.orgSearchQuery.toLowerCase();

        this.filteredOrganizations = this.allOrganizations.filter(org => {
          return org.name.toLowerCase().includes(searchQuery);
        });
      }
    },

    // Fetches all the projects in the app. Returns true/false based on if the fetch was successful
    async refreshProjects() {
      try {
        // Create searchOptions based on query/tab/org
        // Also include data table options
        const searchOptions = {
          filterText: this.projectSearchQuery,
          pageNumber: this.projectTableOptions.page,
          pageSize: this.projectTableOptions.itemsPerPage,
          sortBy: this.projectTableOptions.sortBy ? this.projectTableOptions.sortBy.toString() : null,
          isSortAscending: this.projectTableOptions.sortDesc ? (this.projectTableOptions.sortDesc.length === 0 || !this.projectTableOptions.sortDesc[0]) : false,
          fields: {
            showArchived: this.activeTab === ProjectTab.Archived,
            organizationId: this.selectedOrganizationId,
          },
        };

        // Fetch the projects based on the search options
        this.loadingProjects = true;
        const res = await Api.Projects.getProjects(searchOptions);
        const projects = res.data.$values;

        // Remove the pager if the last object is a pager
        if (projects.length > 0 && projects[projects.length - 1].pager !== null) {
          this.projectsPager = projects.pop().pager;
        }

        // Set the projects and return
        this.projects = projects;
        this.loadingProjects = false;
        return true;
      } catch (err) {
        this.loadingProjects = false;
        console.error('getProjects(ERR):', err);
        return false;
      }
    },

    // Confirms with the user if they want to delete a project, and if so, deletes it
    async deleteProject(projectId) {
      // Confirm with the user before deleting
      const shouldDelete =
        await this.$refs.confirmProjectDeleteDialog.confirmAction();
      if (!shouldDelete) return;

      Api.Projects.deleteProject(projectId)
        .then(res => {
          // Re-fetch the projects
          this.refreshProjects();
        })
        .catch(err => {
          console.error('tryDeleteProject(ERR):', err);
        });
    },

    async copyProject(projectId, fullCopy) {
      Api.Projects.copyProject(projectId, fullCopy);
    },

    canCopyProject(project) {

      // If the project is archived, it can't be copied
      if (project.projectStatus === ProjectStatus.Archived) {
        return false;
      }

      // Get the organization
      const org = this.allOrganizations.find(org => org.customerKey === project.customerKey);

      // If the org doesn't exist or the user can't add projects, they can't copy
      if (!org || !org.userCanAddProjects) {
        return false;
      }

      return project.userAccess === UserAccess.Builder;
    },
  }, // methods

  watch: {
    activeTab() {
      this.projectTableOptions.page = 1;
      this.refreshProjects();
    },

    selectedOrganizationId() {

      if (this.ignoreProjectRefresh) {
        this.ignoreProjectRefresh = false;
        return;
      }

      // Reset the page back to 1
      this.projectTableOptions.page = 1;
      this.refreshProjects();
    },

    projectSearchQuery() {
      // Clear and reset debounce timer
      if (this.queryDebounceTimeout) {
        clearTimeout(this.queryDebounceTimeout);
      }

      // Only search after 500ms of no typing
      this.queryDebounceTimeout = setTimeout(() => {
        this.projectTableOptions.page = 1;
        this.refreshProjects();
      }, 500);
    },

    orgSearchQuery() {
      // Clear and reset debounce timer
      if (this.queryDebounceTimeout) {
        clearTimeout(this.queryDebounceTimeout);
      }

      // Only search after 500ms of no typing
      this.queryDebounceTimeout = setTimeout(() => {
        this.filterOrganizations();
      }, 500);
    },

    projectTableOptions: {
      handler(newValue, oldValue) {
        this.refreshProjects();
      },
      deep: true,
    },
  }, // watch

  computed: {
    selectedOrganization() {
      return this.allOrganizations.find(org => org.customerKey === this.selectedOrganizationId);
    },

    isOrgBuilder() {
      return this.selectedOrganizationId !== null && this.selectedOrganization.userCanManageOrgUsers === true;
    },

    canAddProjects() {
      return this.selectedOrganizationId !== null && this.selectedOrganization.userCanAddProjects === true;
    },

    cannotSelectOrg() {
      return this.loading || this.initialOrgLength <= 1;
    },
  }, // computed
});
</script>

<style scoped lang="scss">
#project-bar {
  position: relative;
  z-index: 1;
  background: var(--v-primary-base);
  border-bottom: 3px solid var(--v-primary-base);
  box-shadow: #00000069 0px 2px 3px;
  gap: 1rem;
}

.project-bar-row {
  align-items: center;
  gap: 2rem;
}

.project-bar-row h2 {
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 26px;
  font-weight: bold;
}

// Tab Styling
#project-bar .v-tabs {
  width: min-content;
}

#project-bar .v-tab {
  transition-property: color, text-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  color: rgba(255, 255, 255, 0.687);
}

#project-bar .v-tab.v-tab--active {
  color: white;
}

#project-bar .v-tabs-slider-wrapper {
  box-shadow: rgb(255 255 255 / 84%) 0px -2px 7px, rgb(255 255 255 / 73%) 0px -1px 2px;
}
</style>
