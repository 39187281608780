<template>
    <div class="d-flex flex-column flex-sm-row align-center">
      <v-btn width="220"  class="legal-notices__button" plain href="https://www.dialsmith.com/terms" target="_blank">
        <v-icon small class="mr-1">mdi-open-in-new</v-icon>Terms of Service
      </v-btn>
      
      <!-- Gives mobile padding -->
      <span class="d-block d-sm-none mb-4"></span>

      <span class="d-none d-sm-inline mx-3" style="user-select: none;">|</span> 

      <v-btn width="220" class="legal-notices__button" plain href="https://www.dialsmith.com/privacy" target="_blank">
        <v-icon small class="mr-1">mdi-open-in-new</v-icon>Privacy Policy
      </v-btn>
  </div>
</template>

<script>
  const AppLegalNotices = {
    name: "AppLegalNotices",
  };

  export default AppLegalNotices;
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 600px) {
    .legal-notices__button {
      font-size: 11px;
    }
  }
</style>
