<template>
  <!-- Binding attributes to a parent container -->
  <div v-bind="$attrs">
    
    <!-- Using CSS vars because we can reference them in the CSS -->
    <div :style="`
            --live-box-color: ${currentBoxColor};
            --live-indicator-color: ${indicatorColor};
            --live-indicator-size: ${indicatorSize}; opacity: ${opacity};`"
          class="live-indicator-container">

      <div class="live-indicator-block">
        <div class="live-indicator-circle blink" aria-hidden="true"></div>
        <div v-if="showLiveBox" class="d-flex align-center ml-1">
          <span class="live-indicator-text">{{boxText}}</span>
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>

const XLiveIndicator =  {
  props: {
    // the box with the LIVE text in it
    showLiveBox: { type: Boolean, default: true },

    boxText:  { type: String, default: 'LIVE' },

    /** Any valid CSS color */
    boxColor: { type: String, default: 'var(--v-secondary-base)' },

    /** Any valid CSS color */
    indicatorColor: { type: String, default: 'white'},

    /** 'small', 'medium', or 'large' */
    indicatorSize: {
      type: String,
      default: 'small',
    },

    opacity: {type: Number, default: 1}
  },

  data(){
    return {
      currentBoxColor: null,
    }
  },

  mounted(){
    if(!this.showLiveBox) {
      this.currentBoxColor = 'transparent';
    } else {
      this.currentBoxColor = this.boxColor;
    }
  }
}

export default XLiveIndicator;

</script>

<style lang="scss" scoped>

  // base code ripped from here: https://codepen.io/thedarkwolf/pen/oVZYrJ
  $text-size: 0.75em;

  .live-indicator-container{

    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    
    .live-indicator-block {
      display: flex;
      align-items: center;

      font-family: 'Roboto', sans-serif;
      background: var(--live-box-color);
      color: var(--live-indicator-color);

      padding: 2px 6px;
      border-radius: 6px;
      font-weight: bold;
      width: auto;

      user-select: none;
      -webkit-user-select: none;

      .live-indicator-circle {
        position: relative;
        z-index: 1;

        background: var(--live-indicator-color);
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }

      .live-indicator-text {
        font-size: 0.6em;
      }

      .blink {
        animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        font-size: 10px;
        vertical-align: baseline;
      }
    }
  }

  @keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0; }
  }
</style>