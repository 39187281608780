<template>
    <div id="respondents-container" class="mt-6">
        <v-layout flex-column px-6>

            <div class="d-flex flex-column flex-md-row mb-6 align-stretch">
                <!-- Title + controls box -->
                <div class="flex-column rounded accent-border-thick" style="min-width: 260px;">
                    <div class="d-flex px-4 py-3 flex-column">

                        <h2>
                            <span class="d-flex align-center">
                                Respondents
                                <x-live-indicator :opacity="autoRefreshing ? 1 : 0" class="ml-2"></x-live-indicator>
                            </span>
                        </h2>

                        <div class=" last-updated-label" v-show="!autoRefreshing">
                            <i style="font-size: 14px; whitespace: nowrap;" :title="lastUpdated">
                                Last updated {{lastUpdatedPretty}}</i>
                        </div>
                    </div>

                    <v-divider class="mb-3"></v-divider>

                    <div class="d-flex align-center justify-space-between px-4 pb-3">
                        <v-switch v-model="autoRefreshing"
                                  @click="autoRefreshing ? refreshAll() : null"
                                  dense
                                  hide-details
                                  class="v-input--reverse mt-0 pa-0 center">
                            <template #label>
                                Auto Refresh:
                            </template>
                        </v-switch>
                        <v-spacer></v-spacer>

                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="refreshAll()" class="ml-4"
                                    small color="secondary"
                                    v-bind="attrs" v-on="on"
                                    :disabled="autoRefreshing"
                                    :loading="loadingRespondents">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh respondents manually</span>
                        </v-tooltip>

                    </div>


                </div>

                <!-- Respondents overall stats -->
                <div class="flex-grow-1 ml-0 ml-md-4 mt-4 mt-md-0" style="overflow-x: auto; overflow-y: hidden;">
                    <table id="respondent-stats-table" class="fill-height rounded accent-border">
                        <thead style="height: 50%">
                            <tr>
                                <th style="font-weight: bold;">Total Entries</th>
                                <th>Completed</th>
                                <th>Partials</th>
                                <th>Active</th>
                                <th>Idle</th>
                                <th>Over Quota</th>
                                <th>Disqualified</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{{ respondentStats.totalRespondents }}</td>
                                <td>{{ respondentStats.completed }}</td>
                                <td>{{ respondentStats.partials }}</td>
                                <td>{{ respondentStats.active }}</td>
                                <td>{{ respondentStats.idle }}</td>
                                <td>{{ respondentStats.overQuota }}</td>
                                <td>{{ respondentStats.disqualified }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Respondent data-table -->
            <v-flex class="flex-grow-1 mb-6">

                <!-- RESPONDENT TABLE ACTION AND SEARCH BAR -->
                <div id="respondent-table-bar" class="d-flex align-center py-2 px-4 mb-2 accent-border rounded ">

                    <!-- Actions Button -->
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary"
                                   dark
                                   class="respondent-select respondent-filter-btn"
                                   v-bind="attrs"
                                   v-on="on">
                                Actions <v-spacer></v-spacer><v-icon class="ml-2" small>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list class="less-condensed-list">
                            <div v-for="(item, index) in respondentActions" :key="index" >

                                <v-list-item v-if="!userIsViewer ||(userIsViewer && item.showForViewer)" link @click="performTableAction(item.value)">
                                    <v-list-item-title style="font-size: 16px;">
                                        <v-icon :color="item.iconColor" style="min-width: 24px;" class="mr-2">{{item.icon}}</v-icon>
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item>

                                <v-divider v-if="item.dividerBelow" class="my-1"></v-divider>

                            </div>
                        </v-list>
                    </v-menu>

                    <!-- Show/Hide Columns Button -->
                    <v-menu offset-y
                            :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary"
                                   dark
                                   class="respondent-select respondent-filter-btn ml-4"
                                   v-bind="attrs"
                                   v-on="on">
                                Show / Hide Columns <v-spacer></v-spacer><v-icon class="ml-2" small>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list class="condensed-list">
                            <v-list-item dense
                                         v-for="(item, index) in allRespondentHeaders"
                                         :key="index">
                                <v-list-item-title>
                                    <div class="d-flex align-center">

                                        <!-- Need the manual refresh header key since Vue isn't smart enough to detect an update inside an object -->
                                        <v-checkbox @click="refreshKeyHeaders = !refreshKeyHeaders"
                                                    v-model="shownRespondentHeadersMap[`${item.value}`]"
                                                    :hide-details="true"
                                                    :label="item.text"
                                                    :ripple="false"
                                                    style="margin: 0; padding: 0;"></v-checkbox>
                                    </div>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!-- Advanced Filters Button -->
                    <v-menu offset-y
                            >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary"
                                    dark
                                    class="respondent-select respondent-filter-btn ml-4"
                                    v-bind="attrs"
                                    v-on="on">
                                Advanced Filters <v-spacer></v-spacer><v-icon class="ml-2" small>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <!-- Filters dropdown -->
                        <v-list class="condensed-list">
                            <v-list-item dense
                                         v-for="(item, index) in allRespondentHeaders"
                                         :key="index"
                                         link>
                                <v-list-item-title>
                                    <v-dialog max-width="325px">
                                        <template v-slot:activator="{ on, popout }">
                                            <div class="d-flex align-center"
                                                v-bind="popout"
                                                v-on="on">
                                                {{ item.text }} <v-icon color="primary" class="ml-auto">mdi-chevron-right</v-icon>
                                            </div>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-card-title class="headline">{{ item.text }} Filters</v-card-title>

                                                <v-card-text style="padding: 16px;">
                                                    <div class="filters-popout" v-if="FC.EQ_FILTERS.includes(item.type)">
                                                        <span>Show items with a value that:</span>
                                                        <div class="d-flex align-center">
                                                          <v-text-field v-model="allRespondentHeaders[index].filters.first"
                                                                        label="First Equation"
                                                                        outlined
                                                                         dense
                                                                        :hide-details="true"></v-text-field>

                                                          <div v-if="item.type === FC.FILTER_EQUATION_T" class="filter-datetime-picker__container">
                                                            <v-btn icon >
                                                              <v-icon>mdi-calendar</v-icon>
                                                            </v-btn>
                                                            <div class="filter-datetime-picker">
                                                              <v-datetime-picker
                                                                v-model="firstEquationDate"
                                                                label="Select date"
                                                                @input="() => allRespondentHeaders[index].filters.first += firstEquationDate + ')'"
                                                                date >
                                                                <template slot="dateIcon">
                                                                  <v-icon>mdi-calendar</v-icon>
                                                                </template>
                                                                <template slot="timeIcon">
                                                                  <v-icon>mdi-clock</v-icon>
                                                                </template>
                                                              </v-datetime-picker>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <v-radio-group v-model="allRespondentHeaders[index].filters.operator"
                                                                       row>
                                                            <v-radio :label="'And'" value="and"></v-radio>
                                                            <v-radio :label="'Or'"  value="or"></v-radio>
                                                        </v-radio-group>
                                                        <div class="d-flex align-center">
                                                          <v-text-field v-model="allRespondentHeaders[index].filters.second"
                                                                        label="Second Equation"
                                                                        outlined
                                                                        dense
                                                                        :hide-details="true"></v-text-field>
                                                          <div v-if="item.type === FC.FILTER_EQUATION_T" class="filter-datetime-picker__container">
                                                            <v-btn icon >
                                                              <v-icon>mdi-calendar</v-icon>
                                                            </v-btn>
                                                            <div class="filter-datetime-picker">
                                                              <v-datetime-picker
                                                                v-model="secondEquationDate"
                                                                label="Select date"
                                                                @input="() => allRespondentHeaders[index].filters.second += secondEquationDate + ')'"
                                                                date >
                                                                <template slot="dateIcon">
                                                                  <v-icon>mdi-calendar</v-icon>
                                                                </template>
                                                                <template slot="timeIcon">
                                                                  <v-icon>mdi-clock</v-icon>
                                                                </template>
                                                              </v-datetime-picker>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <v-btn text @click="equationInfo = !equationInfo"
                                                               style="margin-top: 16px;">
                                                            How to Use<v-icon>{{ equationInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                                        </v-btn>
                                                    </div>

                                                    <div class="filters-popout" v-if="item.type === FC.FILTER_BINARY">
                                                        <span>Choose which values display:</span>

                                                        <v-checkbox v-model="allRespondentHeaders[index].filters.true"
                                                                    :label="'True'"
                                                                    :hide-details="true"
                                                                    style="margin: 0; padding: 0;"></v-checkbox>
                                                        <v-checkbox v-model="allRespondentHeaders[index].filters.false"
                                                                    :label="'False'"
                                                                    :hide-details="true"
                                                                    style="margin: 0; padding: 0;"></v-checkbox>
                                                    </div>
                                                </v-card-text>

                                                <!-- Equation Instructions -->
                                                <v-expand-transition v-if="item.type !== FC.FILTER_BINARY">
                                                    <div v-show="equationInfo">
                                                        <v-card-text>
                                                            <p>All equations are formatted like: <i>"operator(value)"</i> where operator is an operator (list below) and value is the value you want to compare to.</p>
                                                            <p>Use <i>"not operator(value)"</i> to invert an operator.</p>
                                                            <p>For example: <i>"equals(123)"</i> would only show values equal to "123". <i>"not equals(123)"</i> would show everything that doesn't equal 123.</p>
                                                            <p>Picking "and/or" just tests the first equation against the second. If an equation is invalid it wont do any filtering.</p>
                                                            <p v-if="item.type === FC.FILTER_EQUATION_T"><b>Dates</b>: If you pick a time in a date/time picker, it will copy it to your clipboard to easily paste into an equation.</p>
                                                            <p><b>Operators:</b></p>
                                                            <ul v-for="(op, opIndex) in FC.validOperations(item.type)" :key="opIndex">
                                                                <li>{{ op.op }}(): {{ op.help }}</li>
                                                            </ul>
                                                        </v-card-text>
                                                    </div>
                                                </v-expand-transition>

                                                <v-card-actions>
                                                  <v-btn text class="ml-auto" color="secondary"
                                                         @click="filterRespondents(), dialog.value = false">Filter</v-btn>
                                                  <v-btn text
                                                         @click="resetAdvancedFilter(index), dialog.value = false">Clear</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn @click="resetFilters" class="ml-4 respondent-filter-btn" color="primary">Clear Filters</v-btn>

                    <v-spacer></v-spacer>

                    <div class="d-flex align-center flex-grow-1">
                      <v-text-field outlined
                                    dense
                                    class="respondent-search ml-4"
                                    color="secondary"
                                    :hide-details="true"
                                    v-model="filterQuery"
                                    @keydown.enter="filterByUserID">
                        <template v-slot:label>
                          <div class="text-body-2">
                            Search by User ID <small>(Comma-separated)</small>
                          </div>
                        </template>
                      </v-text-field>

                      <v-btn color="secondary" class="ml-2" @click="filterByUserID">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </div>

                </div>

                <!-- RESPONDENT DETAILED STATS TABLE -->
                <v-data-table id="respondents-table"
                              v-model="selectedRespondents"
                              :headers="shownRespondentHeaders"
                              :items="filteredRespondents"
                              item-key="userSessionKey"
                              :loading="loadingRespondents || loadingAction"
                              :footer-props="{'items-per-page-options':[25, 50, 100]}"
                              dense
                              checkbox-color="var(--v-primary-lighten2)"
                              :single-select="false"
                              show-select
                              class="accent-border respondent_data_table"
                              no-data-text="No respondents found!">

                    <template v-slot:[`item.flag`]="{ item }">
                        <v-icon v-if="item.flag === FC.FLAG_RED" title="Flagged Red" :color="FC.FLAG_COLORS[FC.FLAG_RED]">mdi-flag</v-icon>
                        <v-icon v-else-if="item.flag == FC.FLAG_YELLOW" title="Flagged Yellow" :color="FC.FLAG_COLORS[FC.FLAG_YELLOW]">mdi-flag</v-icon>
                        <v-icon v-else-if="item.flag === FC.FLAG_GREEN" title="Flagged Green" :color="FC.FLAG_COLORS[FC.FLAG_GREEN]">mdi-flag</v-icon>
                    </template>

                    <template v-slot:[`item.isSessionActive`]="{ item }">
                        <v-simple-checkbox :value="item.isSessionActive"
                                           disabled></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.isSessionComplete`]="{ item }">
                        <v-simple-checkbox :value="item.isSessionComplete"
                                           disabled></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.isSessionIdle`]="{ item }">
                        <v-simple-checkbox :value="item.isSessionIdle"
                                           disabled></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.isQualified`]="{ item }">
                        <v-simple-checkbox :value="item.isQualified"
                                           disabled></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.isOverQuota`]="{ item }">
                        <v-simple-checkbox :value="item.isOverQuota"
                                           disabled></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.isDuplicateExternalId`]="{ item }">
                        <v-simple-checkbox :value="item.isDuplicateExternalId"
                                           disabled></v-simple-checkbox>
                    </template>

                </v-data-table>

            </v-flex>

        </v-layout>
        <x-confirm-dialog
          ref="confirmDelete"
          title="Delete Respondent?"
          :message="`The following user sessions (SMX ID) will be deleted:
          ${selectedRespondentsID}`"
          :showConfirmCheckbox="true"/>

    </div>
</template>

<script>
    import MiscUtils from '@/utils/miscUtils';

    var relativeTime = require('dayjs/plugin/relativeTime')
    import dayjs from 'dayjs';
    dayjs.extend(relativeTime);

    import XLiveIndicator from '@/components/_generics/x-live-indicator.vue';
    import XConfirmDialog from "@/components/_generics/x-confirm-dialog.vue";

    import * as FC from '@/components/filtering/constants.js';
    import Api from '@/api/api.js'

    const XLSX = require('xlsx');

    const ProjectRespondents = {

        components: {
            XLiveIndicator,
            XConfirmDialog
        },

        created() {
            this.resetFilters();
        },

        data() {
            return {
                lastUpdated: new Date(),
                projectId: '',

                autoRefreshing: false,
                refreshTimeouts: [],

                filterQuery: '',

                // map of header values, hidden if true
                shownRespondentHeadersMap: new Object(),
                refreshKeyHeaders: false,

                respondentActions: [
                    { text: 'Flag (Red)', value: FC.FLAG_RED, icon: 'mdi-flag', iconColor: FC.FLAG_COLORS[FC.FLAG_RED], showForViewer: false },
                    { text: 'Flag (Yellow)', value: FC.FLAG_YELLOW, icon: 'mdi-flag', iconColor: FC.FLAG_COLORS[FC.FLAG_YELLOW], showForViewer: false },
                    { text: 'Flag (Green)', value: FC.FLAG_GREEN, icon: 'mdi-flag', iconColor: FC.FLAG_COLORS[FC.FLAG_GREEN], showForViewer: false },
                    { text: 'Unflag', value: FC.FLAG_NONE, icon: 'mdi-flag-off', dividerBelow: true, showForViewer: false },

                    { text: 'Qualify', value: 'qualify', showForViewer: false },
                    { text: 'Disqualify', value: 'disqualify', showForViewer: false },
                    { text: 'Copy User IDs', value: 'copy_uids', icon: 'mdi-content-copy', showForViewer: true },
                    { text: 'Export', value: 'export', icon: 'mdi-file-export', dividerBelow: true, showForViewer: true },
                    { text: 'Delete', value: 'delete', icon: 'mdi-trash-can', iconColor: 'red', showForViewer: false },
                ],

                // For advanced filtering, the 'type' tag is used
                // FILTER_NONE: No filtering options
                // FILTER_EQUATION: A boolean equation maker (F = Flag, N = Number, S = String, T = Time)
                // FILTER_BINARY: Whether the value is true or false
                allRespondentHeaders: [
                    { text: 'Flag',             value: 'flag',                  sortable: true, type: FC.FILTER_EQUATION_F, filters: {} },
                    { text: 'User ID',          value: 'externalUserId',        sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
                    { text: 'SMX ID',           value: 'userSessionKey',        sortable: true, type: FC.FILTER_EQUATION_N, filters: {} },
                    { text: 'Active',           value: 'isSessionActive',       sortable: true, type: FC.FILTER_BINARY,     filters: {} },
                    { text: 'Complete',         value: 'isSessionComplete',     sortable: true, type: FC.FILTER_BINARY,     filters: {} },
                    { text: 'Idle',             value: 'isSessionIdle',         sortable: true, type: FC.FILTER_BINARY,     filters: {} },
                    { text: 'Qualified',        value: 'isQualified',           sortable: true, type: FC.FILTER_BINARY,     filters: {} },
                    { text: 'Over Quota',       value: 'isOverQuota',           sortable: true, type: FC.FILTER_BINARY,     filters: {} },
                    { text: 'Is Duplicate',     value: 'isDuplicateExternalId', sortable: true, type: FC.FILTER_BINARY,     filters: {} },
                    { text: 'Media Viewed',     value: 'mediaViewed',           sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
                    { text: 'Current Media',    value: 'currentMediaName',      sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
                    { text: 'Current Counter',  value: 'currentMediaCounter',   sortable: true, type: FC.FILTER_EQUATION_N, filters: {} },
                    { text: 'IP Address',       value: 'userHostAddress',       sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
                    { text: 'Browser Type',     value: 'browserUserAgent',      sortable: true, type: FC.FILTER_EQUATION_S, filters: {} },
                    { text: 'Time First Entry', value: 'timeFirstEntry',        sortable: true, type: FC.FILTER_EQUATION_T, filters: {} },
                    { text: 'Time Since Entry', value: 'timeSinceEntry',        sortable: true, type: FC.FILTER_EQUATION_T, filters: {} },
                ],

                // Respondents stuff
                loadingRespondents: true, // If we want to show a loading spinner or something
                loadingAction: false,
                respondents: [],
                filteredRespondents: [],
                selectedRespondents: [],
                respondentStats: {},
                pickedDateTime: null,

                FC, // Filtering constants
                equationInfo: false,
                lastUpdatedPretty: null,
                lastUpdatedTimeOut: null,
                firstEquationDate: '',
                secondEquationDate: '',
            }
        },

        computed: {
            shownRespondentHeaders() {
                this.refreshKeyHeaders;
                const headers = [];

                this.allRespondentHeaders.forEach((header, i) => {
                    if (this.shownRespondentHeadersMap[header.value]) {
                        headers.push(header);
                    }
                });

                return headers;
            },

          selectedRespondentsID() {
              const respondentsId = this.selectedRespondents.map(resp => {
                return resp.userSessionKey
              })

              return respondentsId.join(', ')
          },

          userIsViewer() {
            const projectInfo = this.$store.state.ProjectStore.currentProjectInfo
            return !projectInfo.isAdmin && projectInfo.currentUserType == 'Viewer'
          },
        },

        mounted() {
            this.projectId = this.$route.params.projectId;

            const customerId = null;
            Api.Projects.getProjectInfo(this.projectId, customerId).then(project => {
                this.$emit('changeProjectTitle', {
                    projectName: project.data.projectName,
                    description: project.data.description,
                    customer: project.data.customerName,
                    currentUserType: project.data.currentUserType,
                    isAdmin: project.data.isAdmin,
                    activeTab: 3,
                });
            });

            this.getRespondents();
            this.initializeAdvancedFilters();

            // Update last updated time every minute
            this.lastUpdatedTimeOut = setInterval(() => {
              this.setLastUpdated()
            }, 3600)
        },

          beforeDestroy() {
            // Clear interval
            clearInterval(this.lastUpdatedTimeOut)
          },

      methods: {
            /**
             * Intended to centralize table actions for the action menu. This is for the DETAILED respondent table
             * @param {string | number} action the name of the action to be performed on the currently selected list items
             */
            async performTableAction(action) {
                if (!(this.selectedRespondents instanceof Array)) return;
                if (this.selectedRespondents.length === 0) return;

                // Get session keys for all selected respondents
                const sessionKeys = this.selectedRespondents.map(respondent => respondent.userSessionKey);

                // If this is a flag action, only flags are integers
                if (typeof action === 'number') {
                    // seeing if the action is a flagging action
                    if (this.FC.FLAG_TYPES.includes(action)) {
                       this.loadingAction = true
                        Api.Respondents.flagUserSession(sessionKeys, action)
                          .then(() => {
                            this.getRespondents()
                          })
                          .catch(error => {
                            console.log(error)
                          })
                          .finally(() => {
                            this.loadingAction = false
                          })
                    }
                }

                // All actions other than flagging
                else if (typeof action === "string") {
                    // Qualify given respondents
                    if (action === "qualify") {
                        this.loadingAction = true
                        Api.Respondents.setQualified(sessionKeys, true)
                          .then(() => {
                            this.getRespondents()
                          })
                          .catch(error => {
                            console.log(error)
                          })
                          .finally(() => {
                            this.loadingAction = false
                          })
                    }

                    // Disqualify given respondents
                    else if (action === "disqualify") {
                        this.loadingAction = true
                        Api.Respondents.setQualified(sessionKeys, false)
                          .then(() => {
                            this.getRespondents()
                          })
                          .catch(error => {
                            console.log(error)
                          })
                          .finally(() => {
                            this.loadingAction = false
                          })
                    }

                    // important! does not need to submit updates to the server.
                    else if (action === "copy_uids") {
                        // generating string of all the selected user ids seperated by a comma
                        let userIds = '';
                        this.selectedRespondents.forEach(respondent => {
                            userIds += `${respondent.externalUserId}, `;
                        });
                        userIds = userIds.slice(0, -2); // Remove last comma and space

                        // Copy to clipboard
                        MiscUtils.copyTextToClipboard(userIds).then(() => {
                            this.$vuetify.setToastMessage(
                                `Copied ${this.selectedRespondents.length} User ID${this.selectedRespondents.length === 1 ? '' : 's'} to the clipboard`,
                                { color: 'success' }
                            );
                        });
                    }

                    // Export the table to an xlsx Excell file
                    else if (action === 'export') {

                        const newTable = this.fixTable();

                        if (newTable === null || newTable.length === 0) {
                            // Can only do comparisons of selected user-id's if the column is present
                            // This could potentially be fixed by looking at the value of the checkbox column
                            this.$vuetify.setToastMessage('Could not find the User ID column in the table.', { color: 'error' });
                            return;
                        }

                        // Create the xlsx file
                        let workSheet = XLSX.utils.json_to_sheet(newTable);
                        const workBook = XLSX.utils.book_new()
                        XLSX.utils.book_append_sheet(workBook, workSheet, "Respondents")
                        const date = new Date();
                        XLSX.writeFile(workBook, `respondents_${this.projectId}_${date.toISOString()}.xlsx`);
                    }

                    // Delete sessions
                    else if (action === 'delete') {
                      const shouldDelete = await this.$refs.confirmDelete.confirmAction();

                      if (shouldDelete) {
                        this.loadingAction = true
                        Api.Respondents.deleteUserSession(sessionKeys)
                          .then(() => {
                            this.getRespondents()
                            // Remove from the list
                            this.respondents = this.respondents.filter(respondent => !sessionKeys.includes(respondent.userSessionKey));
                            this.filteredRespondents = this.respondents;
                            this.selectedRespondents = []
                          })
                          .catch(error => {
                            console.log(error)
                          })
                          .finally(() => {
                            this.loadingAction = false
                          })

                      }
                    }
                }
            },

            // Remove unselected items from the table so they don't appear in the export
            fixTable() {
                return this.selectedRespondents.map(resp => {
                  return {
                    Flag: resp.flag,
                    'User ID': resp.externalUserId,
                    'SMX ID': resp.userSessionKey,
                    'Active': resp.isSessionActive,
                    'Complete': resp.isSessionComplete ,
                    'Idle': resp.isSessionIdle ,
                    'Qualified': resp.isQualified ,
                    'Over Quota': resp.isOverQuota ,
                    'Is Duplicate': resp.isDuplicateExternalId ,
                    'Media Viewed': resp.mediaViewed ,
                    'Current Media': resp.currentMediaName ,
                    'Current Counter': resp.currentMediaCounter ,
                    'IP Address': resp.userHostAddress ,
                    'Browser Type': resp.browserUserAgent ,
                    'Time First Entry': resp.timeFirstEntry ,
                    'Time Since Entry': resp.timeSinceEntry ,
                  }
                })
            },

            // Removes all applied filters
            resetFilters() {
                // Nullify all filters
                this.filteredRespondents = this.respondents;
                this.selectedRespondents = [];
                this.shownRespondentHeadersMap = {};
                this.filterQuery = ''; // Clear search bar

                // Set all columns in "show/hide" dropdown to checked
                this.allRespondentHeaders.forEach((header) => {
                    this.shownRespondentHeadersMap[header.value] = true;
                });

                // Clear advanced filters
                this.initializeAdvancedFilters();
            },

            startRefreshLoop() {
                this.refreshTimeouts.push(setTimeout(() => {
                    // Simulate manual refresh every 5 seconds
                    this.refreshAll(true);
                }, 5000));
            },

            // Exists to allow manual refreshes still
            refreshAll(loop = false) {
                this.getRespondents(loop);
            },

            // Fetches all the respondents for the project, can use fake data if failed
            getRespondents(loop = false) {
                this.loadingRespondents = true
                    Api.Respondents.getRespondents(this.projectId).then(res => {
                    const values = res.data.$values

                    this.getRespondentsStats(values);
                    this.loadingRespondents = false;
                    this.respondents = values;

                    this.filterRespondents()

                    // Start another refresh up
                    if (loop === true) {
                        this.startRefreshLoop();
                    }

                    this.lastUpdated = new Date()
                    this.setLastUpdated()
                }).catch(err => {
                    // Use fake data
                    console.log('getRespondents(ERR):', err);

                    this.respondentStats = { total: 0, flagged: 0, qualified: 0, disqualified: 0, overQuota: 0, duplicate: 0 };
                    this.loadingRespondents = false;
                    this.respondents = [];
                    this.filteredRespondents = [];
                });
            },

            // Calculates the stats for the given respondents
            getRespondentsStats(respondents) {
                let stats = {
                    totalRespondents: respondents.length,
                    completed: 0,
                    partials: 0,
                    active: 0,
                    idle: 0,
                    overQuota: 0,
                    disqualified: 0,
                };

                // Gather all the stats
                respondents.forEach(respondent => {
                    if (respondent.isSessionComplete && respondent.isQualified && !respondent.isOverQuota) {
                        stats.completed++;
                    }
                    if (!respondent.isSessionComplete && respondent.isQualified && !respondent.isOverQuota) {
                        stats.partials++;
                    }
                    if (respondent.isSessionIdle) {
                        stats.idle++;
                    }
                    if (respondent.isSessionActive) {
                        stats.active++;
                    }
                    if (respondent.isOverQuota) {
                        stats.overQuota++;
                    }
                    if (respondent.isQualified === false) {
                        stats.disqualified++;
                    }
                });

                this.respondentStats = stats;
            },

            initializeAdvancedFilters() {
                // Set advanced filters
                this.allRespondentHeaders.forEach((header, i) => {
                    if (header.type !== FC.FILTER_NONE) {
                        this.resetAdvancedFilter(i);
                    }
                });
            },

            // Passing update forces a re-render (must be true)
            resetAdvancedFilter(index) {
                // Reset a specific filter
                if (FC.EQ_FILTERS.includes(this.allRespondentHeaders[index].type)) {
                    this.allRespondentHeaders[index].filters = { first: '', operator: '', second: '' };
                }

                else if (this.allRespondentHeaders[index].type === FC.FILTER_BINARY) {
                    this.allRespondentHeaders[index].filters = { true: true, false: true}; // Check both boxes
                }

                this.filterRespondents()
            },

            // Sets filtered respondents based on the current advanced filters filters
            filterRespondents() {
                // Compare each respondent with an advanced filter if provided
                this.filteredRespondents = this.respondents.filter(respondent => {
                    const keys   = Object.keys(respondent);
                    const values = Object.values(respondent);
                    let passes = true;

                    // Only return a respondent if it properly passes an advanced filter
                    this.allRespondentHeaders.forEach(header => {
                        if (passes === false) return; // Don't filter if one check fails

                        // Get index of the header because the respondent object isn't in the same order
                        const value = values[keys.indexOf(header.value)];
                        const type = header.type;

                        // Equation filter
                        if (FC.EQ_FILTERS.includes(type)) {
                            const valid = FC.validOperations(type);
                            const eq1 = FC.extractEquation(header.filters.first, valid);

                            // Initial checks to see if the equation is valid. If so, check for the second equation as well
                            if (eq1 === null) return;
                            const eq2 = FC.extractEquation(header.filters.second, valid);
                            const op = header.filters.operator;

                            // Check if the equation is valid
                            const passesFirst = eq1.invert ? !FC.validateEquation(eq1, value, type) : FC.validateEquation(eq1, value, type);
                            if (!passesFirst && (eq2 === null || op.length === 0)) { passes = false; return; } // Only not pass if second equation doesn't exist

                            // Check if the second equation exists again
                            if (eq2 === null || op.length === 0) return;

                            // Check if the second equation passes
                            const passesSecond = eq2.invert ? !FC.validateEquation(eq2, value, type) : FC.validateEquation(eq2, value, type);

                            // Do user bool operation
                            if (op === 'and') {
                                passes = passesFirst && passesSecond;
                            } else if (op === 'or') {
                                passes = passesFirst || passesSecond;
                            }
                        }

                        // Binary filter
                        else if (type === FC.FILTER_BINARY) {
                            // Both true
                            if (header.filters.true && header.filters.false) return;

                            // Both false
                            if (header.filters.false === false && header.filters.true === false) { passes = false; return; }

                            // One or the other
                            const passesTrue  = header.filters.true  ? value === true  : true;
                            const passesFalse = header.filters.false ? value === false : true;
                            if (!passesTrue || !passesFalse) passes = false;
                        }
                    });

                    return passes;
                });
            },

            setLastUpdated() {
              this.lastUpdatedPretty = dayjs(this.lastUpdated).from(dayjs(new Date()))
            },

            filterByUserID() {
              // Reset filtered
              // This is done because the search filter needs to use advanced filters output
              this.filterRespondents();

              // Get USER IDs comma-separated and then turn to lowercase and remove spaces
              const userIdArray = this.filterQuery.split(',').map(id => id.toLowerCase().trim())

              if (userIdArray.length > 0 && userIdArray[0] !== '') {
                this.filteredRespondents = this.filteredRespondents.filter(respondent => {
                  let uid = respondent.externalUserId + ''; // convert to string
                  uid = uid.toLowerCase(); //convert to lowercase
                  if (uid === null) return false;

                  return userIdArray.includes(uid);
                });
              }
            }
        },

        watch: {
            '$route': function(val) {
                // Disable autorefreshing if the route changes
                this.autoRefreshing = false;
            },

            pickedDateTime(newValue, oldValue) {
                // Copy ISO string to clipboard
                MiscUtils.copyToClipboard(newValue);
            },

            autoRefreshing: {
                immediate: true,
                handler(val) {
                    if (val === true) {
                        this.startRefreshLoop();
                    } else {
                        this.refreshTimeouts.forEach(loop => {
                            clearTimeout(loop);
                        });
                        this.refreshTimeouts = [];
                    }
                }
            },
        },
    };

    export default ProjectRespondents;
</script>

<style lang="scss">

    #respondent-stats-table th {
        background: #f1f3f582;
        border: 1px solid var(--v-accent-base);
        font-size: 18px;
        text-align: center;
    }

    #respondent-stats-table td {
        border: 1px solid var(--v-accent-base);
        font-size: 20px;
        text-align: center;
    }

    #respondents-table th {
        font-size: 14px;
    }

    #respondents-table td {
        white-space: nowrap;
    }

    #respondents-container .last-updated-label {
        /* text-align: center; */
        color: gray;
    }

    .condensed-list .v-list-item {
        /* Can't be any smaller than this unless the checkboxes + labels are shruken */
        min-height: 24px;
    }

    /* for the actions v-menu */
    .less-condensed-list .v-list-item {
        min-height: 30px;
    }

    .condensed-list .v-list-item > .v-list-item__title {
        font-size: 15px;
        /* line-height: 1.2rem; */
    }

    #respondent-table-bar .respondent-select {
        max-width: 240px;
    }

    #respondent-table-bar .respondent-search {
        max-width: 450px;
    }

    .filters-popout {
        display: flex;
        flex-direction: column;
    }

    .filters-popout > span {
        color: black;
        font-size: 18px;
        margin-bottom: 16px;
    }

    .filter-datetime-picker__container {
      position: relative;
    }

    .filter-datetime-picker {
      position: absolute;
      inset: 0;
      top:-15px;
      opacity: 0;
    }

    .respondent_data_table {
      th {
        position: relative;
      }
    }
</style>

<style lang="scss" scoped>
  .respondent-filter-btn {
    font-size: .75rem;
  }
</style>
