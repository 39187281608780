<template>
  <x-expand-container class="fill-width px-4">
    <div class="worker-status-container">
      <div v-for="(status, i) in workerStatuses" v-bind:key="i">
        <div v-if="status && !hiddenStatuses[status.guid]" class="worker-status mt-4 elevation-2 white rounded">
          <div class="worker-status-type px-2 py-1 bg-primary white--text rounded-left" style="">
            <v-progress-linear indeterminate style="top: 4px" absolute color="secondary"></v-progress-linear>
            <div>{{status.type ? status.type : 'UNSPECIFIED'}}</div>
          </div>

          <div class="worker-status-msg px-2">
              {{ status.msgFull}}
          </div>

          <v-btn class="mr-2" style="flex-grow: 0;"
            icon small
            @click="hideStatus(status.guid)">

            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

      </div>
    </div>
  </x-expand-container>
</template>

<script>
  import SignalR from "@microsoft/signalr";
  import * as signalR from "@microsoft/signalr";
  import { defineComponent } from 'vue';

  import Events from "@/events/events";

  import XExpandContainer from "./_generics/x-expand-container.vue";
  let connectionGlobal = null;

  const AppWorkerStatus = defineComponent({

    components: {
      XExpandContainer,
    },

    data() {
      return {
        workerStatuses: [],
        hiddenStatuses: {},
        connection: connectionGlobal
      };
    },

    beforeCreate() {
      //console.log("process.env.VUE_APP_API_BASE_URL: ", process.env.VUE_APP_API_BASE_URL);
      //################################
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_API_BASE_URL + `/notificationsHub`)
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      connectionGlobal = connection;
      console.log("connection: ", connection);

      connection.on("copyProjectNotification", (msg) => {
        var userKey = JSON.parse(localStorage.getItem("currentUser"));
        console.log(userKey);

        // Add the message to the page.
        var copyNotification = JSON.parse(msg);
        copyNotification.userKey = parseInt(copyNotification.userKey);
        console.log("copyNotification: msg: ", copyNotification);
        console.log("copyNotification: userKey: ", copyNotification.userKey);

        //// only show for the current user
        if (copyNotification.userKey == userKey.userKey) {

          // seeing if we have a currentStatus
          var currentStatus = this.workerStatuses.find(status => status.guid === copyNotification.guid);
          if (!currentStatus) {
            currentStatus = copyNotification;
            currentStatus.type = 'COPY PROJECT';
            this.workerStatuses.push(currentStatus);
          }

          currentStatus.msg = copyNotification.msg;
          currentStatus.msgFull = copyNotification.msgFull;

          console.log('currentStatus', currentStatus);
          if (copyNotification.msg === "started") {
            // add loading box to header
            //$elem.html('copy in progress' + loadingIcon);
          } else if (copyNotification.msg === "saved") {
            // project is saved, last step is adding all media
            //$elem.html('details saved, wating for media' + loadingIcon);
          } else if (copyNotification.msg === "error") {
            // Set a robust type
            currentStatus.type = 'COPY PROJECT ERROR';

            // show an error for a limited time
            setTimeout(() => {
              const deleteIndex = this.workerStatuses.findIndex(status => status.guid === copyNotification.guid);
              this.workerStatuses.splice(deleteIndex, 1);
            }, 10000);
          } else if (copyNotification.msg === "complete") {
            // emitting copyProjectComplete so we can refresh the appropriate table
            Events.$emit('copyProjectComplete', parseInt(copyNotification.customerKey));

            const deleteIndex = this.workerStatuses.findIndex(status => status.guid === copyNotification.guid);
            this.workerStatuses.splice(deleteIndex, 1);
          }

          // super hacky refresh of the for loop, there is certainly a better way to do this
          this.workerStatuses = [...this.workerStatuses];
        }
      });

      connection.on("exportMediaNotification", (msg) => {
        console.log(msg);
        const copyNotification = JSON.parse(msg);
        Events.$emit('exportReportNotification', copyNotification);
      });


      connection.on("completedMediaNotification", (msg) => {
        console.log('Complete media notification:');
        console.log(msg);
      });

      // Listener for Project > Report > Export media function
      connection.on("reportNotification", msg => {
        const copyNotification = JSON.parse(msg);
        if (copyNotification.completed) {
          copyNotification.type = 'completed';
        } else if (copyNotification.error) {
          copyNotification.type = 'error';
        } else {
          copyNotification.type = 'progress';
        }

        Events.$emit('createReportNotification', copyNotification);
      });

      // Listener for Project > Report > Data report > import media function
      connection.on("importReportNotification", msg => {
        const notification = JSON.parse(msg);
        Events.$emit('importReportNotification', notification);
      });

      // if connection closed, reopen it
      let startedPromise = null;
      function start() {
        startedPromise = connection.start().catch((err) => {
          return new Promise((resolve, reject) =>
            setTimeout(() => start().then(resolve).catch(reject), 5000)
          );
        });
        return startedPromise;
      }

      connection.onclose(() => start());

      start()
        .then(() => {
          this.joinReportNotificationGroup();
          this.joinImportDataNotificationGroup();
        });

    },

    mounted() { },

    watch: {
      '$store.state.ReportStore.currentProjectKey': function () {
        this.joinReportNotificationGroup();
      },
      '$store.state.ReportStore.importDataMediaId': function () {
        this.joinImportDataNotificationGroup();
      },
      '$store.state.ReportStore.exportMediaId': function () {
        this.joinExportMediaNotificationGroup();
      },

    },

    methods: {
      hideStatus(statusGuid) {
        console.log('hid ', statusGuid);
        this.hiddenStatuses[statusGuid] = true;
        // forcing recheck of the v-for loop up there
        this.hiddenStatuses = JSON.parse(JSON.stringify(this.hiddenStatuses));
      },

      async joinReportNotificationGroup() {
        try {
          if (this.$store.state.ReportStore.currentProjectKey) {
            const projectKey = this.$store.state.ReportStore.currentProjectKey;
            await this.connection.invoke("JoinGroup", `group-reportnotification-${projectKey}`);
            console.log('JOIN REPORT GROUP SUCCESSFULLY');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async joinImportDataNotificationGroup() {
        try {
          if (this.$store.state.ReportStore.importDataMediaId) {
            const mediaId = this.$store.state.ReportStore.importDataMediaId;
            await this.connection.invoke("JoinGroup", `group-importreportnotification-${mediaId}`);
            console.log('JOIN MEDIA IMPORT GROUP SUCCESSFULLY');
          }
        } catch (e) {
          console.log(e);
        }
      },
      async joinExportMediaNotificationGroup() {
        try {
          if (this.$store.state.ReportStore.exportMediaId) {
            const mediaId = this.$store.state.ReportStore.exportMediaId;
            await this.connection.invoke("JoinGroup", `group-completed-${mediaId}`);
            console.log('JOIN EXPORT MEDIA GROUP SUCCESSFULLY');
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

  });

  export default AppWorkerStatus;
</script>

<style scoped lang="scss">
  $status-height: 40px;

  .worker-status-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    max-width: 800px;
  }

  .worker-status {
    max-height: $status-height;
    display: flex;
    flex-direction: row;

    align-items: center;

    overflow: hidden;
  }

  .worker-status-type {
    position: relative;

    display: flex;
    align-items: center;

    font-weight: bold;
    text-align: center;

    line-height: $status-height;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .worker-status-msg {
    display: flex;
    align-items: center;

    flex-grow: 1;
  }

  /* EXTREMELY IMPORTANT FOR CSS text-overflow cutoffs
  white-space: no-wrap creates super long width elements that flex does not handle well by default */
  .worker-status {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100%;
    min-width: 0; /* this one right here does it!*/
  }


  .worker-status-msg {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;

    // important otherwise the other lines are displayed
    line-height: $status-height;
  }

  .rounded-left {
    border-top-left-radius: 4px; border-bottom-left-radius: 4px;
  }

  .rounded-right {
    border-top-right-radius: 4px; border-bottom-right-radius: 4px;
  }
</style>
