<template>
  <v-app-bar id="app-navbar" app color="primary" dark elevated short style="z-index: 99">

    <div class="d-flex justify-center fill-width">
      <div class="nav-container flex-grow-1 d-flex fill-height">
        <!-- Long dialsmith logo for desktop -->
        <div class="d-none d-sm-flex align-center ml-2">
          <router-link to="/projects">
            <v-img alt="Vuetify Logo" class="mr-2" contain src="/images/dialsmith-logo.svg" width="172" height="44" />
          </router-link>
        </div>

        <!-- Smaller logo for mobile -->
        <div class="d-sm-none d-flex align-center ml-2">
          <router-link to="/projects">
            <v-img alt="Vuetify Logo" class="mr-2 rounded" contain src="/images/dialsmith-dial-logo.svg" width="44"
              height="44" style="background: white; border: 1px solid var(--v-secondary-base)" />
          </router-link>
        </div>

        <v-spacer></v-spacer>

        <!-- NAV BUTTONS -->
        <div class="nav-btn-container ml-2">
          <!-- HOME BUTTON -->
          <v-btn to="/projects" class="mx-2 nav-btn" plain icon>
            <v-icon>mdi-home</v-icon>
          </v-btn>

          <!-- SUPPORT MENU -->
          <v-menu offset-y open-on-hover close-delay="130" left class="mx-2">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center nav-tab-parent fill-height">

                <v-btn class="mx-2 nav-btn fill-height" plain icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-help</v-icon>
                </v-btn>

              </div>
            </template>

            <v-list dense>
              <v-list-item link href="http://www.dialsmith.com/support" target="_blank">

                <v-list-item-icon>
                  <v-icon>mdi-face-agent</v-icon>
                </v-list-item-icon>

                <v-list-item-title class="mr-2 d-flex align-center">
                  Support<v-icon style="color: black" small class="ml-1">mdi-open-in-new</v-icon>
                </v-list-item-title>

              </v-list-item>

              <v-list-item link href="http://www.dialsmith.com/contact" target="_blank">

                <v-list-item-icon>
                  <v-icon>mdi-comment-question-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-title class="mr-2 d-flex align-center">
                  Contact<v-icon style="color: black" small class="ml-1">mdi-open-in-new</v-icon>
                </v-list-item-title>

              </v-list-item>
            </v-list>
          </v-menu>

          <!-- PROFILE MENU -->
          <v-menu offset-y open-on-hover close-delay="130" left>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center nav-tab-parent fill-height">

                <v-btn to="/profile" class="mx-2 nav-btn fill-height" plain icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-account</v-icon>
                </v-btn>

              </div>
            </template>

            <v-list dense>
              <v-list-item link to="/profile">
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mr-2">Profile</v-list-item-title>
              </v-list-item>

              <v-list-item link to="/admin" v-if="isAdmin">
                <v-list-item-icon>
                  <v-icon>mdi-security</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mr-2">Admin</v-list-item-title>
              </v-list-item>

              <v-divider class="my-1"></v-divider>

              <v-list-item link @click="logoutClickEvent">
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mr-2">Sign Out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>
      </div>

    </div>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';
import Events from "@/events/events";
import Api from "@/api/api";

const Navbar = {
  name: "Navbar",

  mounted() {
    console.log('Route name: ', this.$route)
    if (this.$route.path !== '/') {
      // Disable admin button if not an admin
      Api.Authentication.getProjectPermissions(0).then(res => {
        this.isAdmin = res.data.isAdmin;
      }).catch(err => {
        console.log('AppNavbar.mounted(ERR):', err)
      });
    }
  },

  data() {
    return {
      isAdmin: false,
    }
  },

  methods: {
    ...mapActions('AuthStore', ['logout']),

    async logoutClickEvent() {
      await this.logout();
      this.$router.push('/');
    },
  },
};

export default Navbar;
</script>

<style lang="scss">
.nav-container {
  max-width: $content-width;
}

.nav-tab-parent .v-tabs,
.nav-tab-parent .v-tabs-bar {
  background-color: #FFF0 !important;
}

.nav-tab-parent .v-tab {
  padding: 0 8px;
  min-width: 56px;
  font-size: 14px !important;
}

.nav-tab-parent .v-tab a {
  display: flex;
  align-items: center;
  color: #f1f3f5 !important;
  text-decoration: none;
  outline: none;
}

.nav-btn-container {
  display: flex;
}

.nav-btn {
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.nav-btn:hover,
.nav-btn.v-btn--active {
  background-color: rgba(255, 255, 255, 0.183);
}
</style>
