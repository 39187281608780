<template>
    <x-youtube-player v-if="youtube"
        :videoID="src"
        :startTime="startTime"
        :endTime="endTime"
        :update-current-video-time="updateCurrentVideoTime"
        :updated-video-time="updatedVideoTime"
        :height="height"
        :show-annotation-popup="showAnnotationPopup"
        :download-screenshot="downloadScreenshot"
        :download-video="downloadVideo"
    ></x-youtube-player>

    <x-video-player v-else
        :update-current-video-time="updateCurrentVideoTime"
        :updated-video-time="updatedVideoTime"
        :show-annotation-popup="showAnnotationPopup"
        :download-screenshot="downloadScreenshot"
        :download-video="downloadVideo"
        :src="src"
        :height="height"></x-video-player>
</template>

<script>
    import XVideoPlayer from './x-video-player.vue';
	import XYoutubePlayer from './x-youtube-player.vue';

    // This component should be used as a switcher between XVideoPlayer and XYoutubePlayer.
    // poster is exclusive to XVideoPlayer.
    const XPlayer = {
        props: {
            youtube: Boolean,
            src: String,
            height: [String, Number],
            startTime: String,
            endTime: String,
            updateCurrentVideoTime: Function,
            updatedVideoTime: Number,
            showAnnotationPopup: Function,
            downloadScreenshot: Function,
            downloadVideo: Function,
        },

        components: {
            XVideoPlayer,
            XYoutubePlayer,
        },
    };

    export default XPlayer;
</script>

<style lang="scss"></style>
