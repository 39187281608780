<template>
  <v-form :disabled="loadingSettings" v-model="settingsForm" ref="settingsform">
    <div v-if="!savingProject">
      <v-layout class="flex-column flex-sm-row d-flex">
        <!-- LEFT COLUMN -->
        <v-flex xs12 sm6 flex px-6 py-4>
          <!-- Project Name -->
          <v-text-field
            v-model="project.projectName"
            label="Project Name*"
            outlined
            dense
            :loading="loadingSettings"
            required
            :rules="[InputRules.emptyValueGEN('Please enter a Project Name')]"
          ></v-text-field>

          <!-- Organization -->
          <v-select
            label="Organization*"
            outlined
            dense
            v-model="project.customerKey"
            :items="customerList"
            :rules="[InputRules.emptyValueGEN('Please select an Organization')]"
            v-if="projectPerms.isAdmin"
          ></v-select>
          <v-text-field
            v-model="project.customerName"
            label="Organization*"
            outlined
            dense
            readonly
            :rules="[InputRules.emptyValueGEN('Please enter an Organization')]"
            v-else
          ></v-text-field>

          <!-- Status -->
          <v-select
            label="Status*"
            outlined
            dense
            v-model="project.projectStatus"
            :items="projectStatusTypes"
            :rules="[InputRules.emptyValueGEN('Please select a Status')]"
          ></v-select>

          <!-- Media Order -->
          <v-select
            label="Media Order*"
            outlined
            dense
            :items="mediaOrderList"
            v-model="project.mediaOrder"
            @change="mediaOrderTypeChanged($event)"
            :rules="[InputRules.emptyValueGEN('Please select a media order')]"
          ></v-select>

          <!-- Custom Media Order Variable -->
          <v-text-field
            label="Custom Media Order Variable"
            v-model="project.customMediaOrder"
            outlined
            dense
            v-show="project.mediaOrder === 2"
          ></v-text-field>

          <!-- External Media Order Variable -->
          <v-text-field
            label="External Media Order Variable"
            v-model="project.externalMediaOrderKey"
            outlined
            dense
            v-show="project.mediaOrder === 4"
          ></v-text-field>

          <!-- Auto Advance Media -->
          <v-checkbox
            label="Auto Advance Media"
            dense
            v-model="project.autoAdvanceMedia"
          ></v-checkbox>

          <v-layout>
            <!-- Start Date -->
            <v-icon class="mr-3">mdi-calendar</v-icon>
            <v-datetime-picker
              v-model="project.startDate"
              label="Start Date"
              date
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
            <!-- <x-dialog-datetime-picker v-model="project.startDate"
                  label="Start Date"></x-dialog-datetime-picker> -->

            <v-spacer></v-spacer>

            <!-- End Date -->
            <v-icon class="mr-3">mdi-calendar</v-icon>
            <v-datetime-picker v-model="project.endDate" label="End Date" dark>
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
            <!-- <x-dialog-datetime-picker v-model="project.endDate"
                  label="End Date"></x-dialog-datetime-picker> -->
          </v-layout>

          <p
            v-if="project.startDate === null && project.endDate === null"
            class="mb-6"
            style="font-size: 14px; margin-top: -12px"
          >
            <i
              >* Unless an end date is entered, the project will automatically
              archive 90 days after it's closed.</i
            >
          </p>

          <!-- Archive After End Date -->
          <v-select
            label="Archive After End Date"
            outlined
            :items="ARCHIVE_AFTER_DAYS"
            v-model="project.archiveAfterDays"
            item-value="value"
            item-text="text"
            v-if="project.endDate !== null"
            dense
          ></v-select>
          <!-- Archive After Days Other -->
          <v-text-field
            label="Archive After Days Custom"
            v-model="project.customArchiveDays"
            outlined
            dense
            v-show="project.archiveAfterDays < 0 && project.endDate !== null"
          ></v-text-field>

          <!-- Custom Lockout Message -->
          <v-textarea
            outlined
            auto-grow
            rows="2"
            v-model="project.customLockoutMessageText"
            name="description"
            label="Custom Lockout Message"
          ></v-textarea>
        </v-flex>

        <!-- RIGHT COLUMN -->
        <v-flex xs12 sm6 flex px-6 py-4>
          <!-- Survey URL -->
          <XCopyTextbox
            label="Survey URL"
            :text="project.viewerMediaURL"
          ></XCopyTextbox>

          <!-- Description -->
          <v-textarea
            outlined
            auto-grow
            rows="2"
            v-model="project.description"
            name="description"
            label="Description"
          ></v-textarea>

          <!-- Survey Provider -->
          <v-select
            label="Survey Provider"
            v-model="project.surveyProviderKey"
            outlined
            dense
            :items="project.providers.$values"
            item-value="surveyProviderKey"
            item-text="title"
            :disabled="createProject"
          ></v-select>

          <!-- Custom Survey Provider Area -->
          <v-text-field
            label="Custom Survey Provider"
            v-model="project.surveyProviderName"
            outlined
            dense
            v-if="customProvider"
          ></v-text-field>

          <!-- Tags -->
          <v-combobox
            v-model="project.projectTags"
            label="Tags"
            chips
            multiple
            placeholder="Type and press 'Enter' to add tags"
            outlined
            append-icon=""
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                small
                close
                v-bind="attrs"
                :input-value="selected"
                @click="select"
                @click:close="
                  project.projectTags.splice(
                    project.projectTags.findIndex((el) => el === item),
                    1
                  )
                "
              >
                {{ item }}</v-chip
              >
            </template>
          </v-combobox>

          <!-- Enable Landing Page Text -->
          <v-checkbox
            v-model="project.enableLandingPageText"
            label="Enable Landing Page Text"
            dense
          ></v-checkbox>

          <v-flex v-show="project.enableLandingPageText">
            <!-- Survey Landing Page URL -->
            <XCopyTextbox
              label="Survey Landing Page URL"
              :text="project.viewerLandingURL"
            ></XCopyTextbox>

            <!-- Landing Page Text -->
            <p>Landing Page Text</p>
            <XTextEditor
              style="max-width: 560px !important"
              v-model="project.landingPageText"
              allow-images="true"
            ></XTextEditor>
          </v-flex>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <div class="px-6 mt-4 mb-2">
        <h3>Advanced Settings</h3>
      </div>

      <!-- Advanced settings section -->
      <v-flex>
        <v-layout>
          <!-- LEFT COLUMN -->
          <v-flex xs12 sm6 flex px-6 py-4>
            <!-- Enable Dynamic Final Page -->
            <v-checkbox
              v-model="project.enableDynamicFinalPage"
              label="Enable Dynamic Final Page"
              dense
            ></v-checkbox>

            <!-- Dynamic Final Page Variable -->
            <v-text-field
              v-if="project.enableDynamicFinalPage"
              v-model="project.dynamicFinalPageVariable"
              label="Dynamic Final Page Variable"
              outlined
              dense
            ></v-text-field>

            <!-- Completed Redirect URL -->
            <v-text-field
              label="Completed Redirect URL"
              v-model="project.finalPageUrl"
              :hint="
                project.exampleFinalPageURL !== null
                  ? 'e.g. ' + project.exampleFinalPageURL
                  : 'Where completed users are sent to'
              "
              persistent-hint
              class="mb-4"
              outlined
              dense
            ></v-text-field>

            <!-- Redirect URL Parameter Selection -->
            <v-select
              label="Redirect URL Parameter Selection"
              v-model="selectedParam"
              :items="project.subsets"
              @blur="selectedParam = ''"
              outlined
              dense
              class="mb-2"
            ></v-select>

            <!-- Over-Quota Redirect URL (Labeled dropped redirect on old site) -->
            <v-text-field
              label="Over-Quota Redirect URL"
              v-model="project.droppedFinalPageUrl"
              outlined
              dense
              hint="Over-quota respondents get redirected here"
              persistent-hint
            ></v-text-field>
            
          </v-flex>

          <!-- RIGHT COLUMN -->
          <v-flex xs12 sm6 flex px-6 py-4>
            <!-- Default Error Message -->
            <v-textarea
              v-model="project.projectStatusErrorText"
              outlined
              dense
              auto-grow
              rows="2"
              label="Default Error Message"
            ></v-textarea>

            <!-- Respondent ID -->
            <v-text-field
              label="External Respondent ID Variable Name"
              outlined
              dense
              v-model="project.externalIDKey"
            ></v-text-field>

            <!-- Require Respondent ID -->
            <v-checkbox
              dense
              label="Require Respondent ID"
              v-model="project.requireExternalID"
            ></v-checkbox>

            <v-checkbox
              dense
              label="Allow Duplicate Respondent ID"
              v-model="project.allowDuplicateExternalID"
            ></v-checkbox>

            <!-- Enable External WaterMark -->
            <v-checkbox
              dense
              label="Enable External Watermark"
              v-model="project.enableExternalIDWaterMark"
            ></v-checkbox>

            <!-- Enable Powered By Logo -->
            <v-checkbox
              label="Enable Powered By Logo"
              dense
              v-model="project.enablePoweredByLogo"
            ></v-checkbox>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- Footer  -->
      <v-flex style="background-color: #f9f9f9" class="d-flex pa-4 justify-end">
        <v-btn color="secondary" @click="saveProject" :disabled="!settingsForm"
          >Save Project</v-btn
        >
      </v-flex>
    </div>

    <!-- Loading animation -->
    <div class="d-flex justify-center flex-column pa-8 align-center" v-else>
      <h1 style="font-weight: 300" class="my-8">
        {{ LoadingMessage }}
      </h1>

      <v-progress-circular
        color="primary"
        indeterminate
        size="128"
      ></v-progress-circular>
      <!-- <div class="brand-gradient" style="height: 2px; width: 100%;"></div> -->
    </div>
  </v-form>
</template>

<script>
import { mapMutations } from "vuex";
import XTextEditor from "@/components/_generics/x-text-editor.vue";
import XDialogDatetimePicker from "@/components/_generics/x-dialog-datetime-picker.vue";
import XCopyTextbox from "@/components/_generics/x-copy-textbox.vue";

import InputRules from "@/utils/inputFieldRules";
import Api from "@/api/api";

const STATUS_UNDEFINED = 0;
const STATUS_TESTING = 1;
const STATUS_LIVE = 2;
const STATUS_CLOSED = 3;
const STATUS_ARCHIVED = 4;

const ORDER_SEQUENTIAL = 1;
const ORDER_CUSTOM = 2;
const ORDER_RANDOM = 3;
const ORDER_EXTERNAL = 4;

const ProjectEditForm = {
  components: {
    XTextEditor,
    XDialogDatetimePicker,
    XCopyTextbox,
  },

  props: {
    projectData: null,
    surveyProvidersList: null,
    loadingSettings: {
      default: false,
    },
    createProject: {
      default: false,
    },
  },

  mixins: [InputRules],

  mounted() {
    this.LoadingMessage = "Loading Project...";
    this.savingProject = true;
    this.projectKey = this.$route.params.projectId;

    this.loadData();
  },

  data() {
    const ARCHIVE_AFTER_DAYS = [
      { text: "None", value: null },
      { text: "30 Days", value: 30 },
      { text: "60 Days", value: 60 },
      { text: "90 Days", value: 90 },
      { text: "Custom", value: -1 },
    ];

    return {
      project: null,
      projectKey: null,
      settingsForm: false,
      loading: false,

      dynamicFinalPageFlag: false,
      projectLogoFile: null,
      projectStatusTypes: [
        { text: "Testing", value: STATUS_TESTING },
        { text: "Live", value: STATUS_LIVE },
        { text: "Closed", value: STATUS_CLOSED },
        { text: "Archived", value: STATUS_ARCHIVED },
      ],
      mediaOrderList: [
        { text: "Sequential", value: ORDER_SEQUENTIAL },
        { text: "Custom", value: ORDER_CUSTOM },
        { text: "Random", value: ORDER_RANDOM },
        { text: "External", value: ORDER_EXTERNAL },
      ],

      projectPerms: { isAdmin: false },
      customerList: [],
      surveyProviders: [],
      selectedParam: "",

      customProvider: false,
      providers: {
        $values: [
          {
            title: null,
            surveyProviderKey: null,
          },
        ],
      },

      LoadingMessage: "Saving Project...",
      savingProject: {
        default: false,
      },

      ARCHIVE_AFTER_DAYS,
    };
  },

  watch: {
    "project.archiveAfterDays"(newVal, oldVal) {
      if (newVal !== -1) {
        this.project.customArchiveDays = null;
      }

      // Fix dropdown selection (for the backend in some way..?)
      // todo...
    },

    projectLogoFile(val) {
      // Call remove, then add a new one
      Api.Projects.removeProjectLogo(this.project.projectKey, val.name).then(
        (res) => {
          if (res.status == 200) {
            Api.Projects.saveProjectLogo(this.project.projectKey, val).then(
              () => {
                this.project.projectLogoName = `project-logo-${this.project.projectKey}.png`;
              }
            );
          } else {
            console.log("watch.projectLogoFile(ERR):", res);
          }
        }
      );
    },

    projectData() {
      this.project = this.projectData;

      // Change some values
      // Change survey url
      this.project.viewerMediaURL = `${window.location.origin}/Project/${this.project.projectKey}/View`;
      this.project.viewerLandingURL = `${window.location.origin}/Project/${this.project.projectKey}/ViewLanding`;
      this.project.subsets =
        this.project.finalPageURLParametersQuestions.$values || [];

      console.log("watch.projectData():", this.project);
    },

    surveyProvidersList() {
      this.surveyProviders = this.surveyProvidersList;
    },

    "project.surveyProviderKey": {
      handler: function (val) {
        if (val === null || val === "") return;
        if (!this.project.providers.$values.length > 0) return;

        const provider = this.project.providers.$values.filter(
          (provider) => provider.surveyProviderKey === val
        )[0];

        // Only overwrite the title if it's not custom
        this.customProvider = provider.title === "Custom";
        if (this.customProvider) {
          this.project.surveyProviderName = "";
        } else {
          this.project.surveyProviderName = provider.title;
        }
      },
      deep: true,
    },

    loadingSettings: {
      handler: function (val, oldVal) {
        this.savingProject = val;
      },
      deep: true,
    },

    selectedParam(val) {
      if (val === "") return;

      // Add this val to project.finalPageUrl
      this.project.finalPageUrl += `{${val}}`;
    },
  },

  methods: {
    ...mapMutations("ProjectStore", ["setCurrentProject"]),

    async loadData() {
      // Get project permissions
      const key =
        this.projectData === undefined
          ? this.$route.params.customerId
          : this.projectKey;
      const permsRes = await Api.Authentication.getProjectPermissions(key);

      if (permsRes.status === 200) {
        this.projectPerms = permsRes.data;

        // Fetch Customers, then check if project is new or existing
        if (this.projectPerms.isAdmin) {
          this.customerList = (
            await Api.Projects.getCustomerList()
          ).data.$values;
        }
      }

      if (this.projectData === undefined) {
        this.setupCreateProject();
      }
    },

    async saveProject() {
      const valid = this.$refs.settingsform.validate();
      if (!valid) return;

      // Swap customArchiveDays into archiveDays
      if (this.project.customArchiveDays !== null) {
        this.project.archiveAfterDays = this.project.customArchiveDays;
      }

      // Remove survey provider key if the name is different than any of the options
      const providers = this.project.providers.$values;
      if (
        providers.filter((p) => p.title === this.project.surveyProviderName)
          .length === 0
      ) {
        this.project.surveyProviderKey = null;
      }

      // Trim project name of spaces
      this.project.projectName = this.project.projectName.trim();
      if (this.project.projectName === "") {
        this.$refs.settingsform.validate();
        return;
      }

      console.log("saveProject():", this.projectData);
      this.LoadingMessage = "Saving Project...";
      this.savingProject = true;
      const response = await Api.Projects.saveProject(this.project);

      if (response.data.returnUrl != undefined && this.createProject) {
        this.$router.push(response.data.returnUrl);
        this.createProject = false;
      }

      // Swap back archive days into customArchiveDays
      const archiveDays = [30, 60, 90];
      if (
        archiveDays.indexOf(this.project.archiveAfterDays) === -1 &&
        this.project.archiveAfterDays !== null
      ) {
        this.project.customArchiveDays = this.project.archiveAfterDays;
        this.project.archiveAfterDays = -1;
      }

      this.savingProject = false;
      this.LoadingMessage = "Loading Project...";
      this.$emit("changeProjectTitle", {
        projectName: this.projectData.projectName,
        description: this.projectData.description,
        customer: this.projectData.customerName,
        currentUserType: this.projectData.data.currentUserType,
        isAdmin: this.projectData.data.isAdmin,
      });
    },

    validateDateRange() {
      const start = new Date(this.project.startDate);
      const end = new Date(this.project.endDate);

      if (start && end && start > end) {
        return "Start date must be before the end date";
      }

      return true;
    },

    mediaOrderTypeChanged(event) {
      switch (event) {
        case 2:
          this.project.externalMediaOrderKey = "";
          break;
        case 4:
          this.project.customMediaOrder = "";
          break;
        default:
          this.project.customMediaOrder = "";
          this.project.externalMediaOrderKey = "";
      }
    },

    setupCreateProject() {
      this.LoadingMessage = "Initializing Project...";
      if (!this.createProject) return;

      const customerId = this.$route.params.customerId;
      Api.Projects.addProject(customerId).then((res) => {
        // Default Values
        res.data.projectStatus = STATUS_TESTING;
        res.data.mediaOrder = ORDER_SEQUENTIAL;
        res.data.customerKey = customerId;
        res.data.providers.$values = [];
        res.data.allowDuplicateExternalID = true;
        res.data.archiveAfterDays = 90;
        res.data.customerKey = Number(customerId);
        res.data.customLockoutMessageText =
          "This media is not currently available for rating.";

        this.project = res.data;
        this.loadingSettings = false;
        this.savingProject = false;
      });
    },
  },
};

export default ProjectEditForm;
</script>

<style scoped lang="scss"></style>
