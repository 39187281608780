<template>
  <div 
    v-bind="$attrs"
    v-on="$listeners"
    ref="expandContainer"
    :style="hasLoaded ? `height: ${this.height}px` : ''"
    class="x-expand-container">
    <div ref="contentContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  
  /**
   * Intended to smoothly adjust it's height whenever the internal content changes height. 
   * Only supports vertical height transitions at the moment. 
   * 
   * 
   * WARNINGS: 
   * -  Be careful when nesting these, it may have a performance impact. 
   * 
   * -  DO NOT APPLY PADDING / MARGIN TO THIS CONTAINER. Apply padding and margin to the child content. 
   *      Anything you want to accomplish can be achieved either by modifying the CSS of the child inside this element or the parent of this element. 
   */
  const XExpandContainer = {
    name: "XExpandContainer",

    props: {
      /** Numbers in raw pixels only. No em/rem supported here.  */
      yPadding: { type: Number, default: 0}
    },

    data() {
      return {
        /** How much time we wait before accepting new resize events. */
        ON_UPDATE_DELAY_MS: 100,
        hasLoaded: false,

        _updatesLocked: 0,
        _lastContainerHeight: 0,
        height: 0,
      }
    },

    beforeMount() {
      if (isNaN(this.yPadding)) {
        throw new Error(`yPadding is NaN. Please ensure it is a non-negative number.`);
      } else if(this.yPadding < 0) {
        throw new Error(`yPadding < 0. Please ensure it is a non-negative number. yPadding: ${this.yPadding}`);
      }
    },

    mounted() {
      // this.$refs.expandContainer.classList.add('x-expand-container');
      this._setupHeightObserver();

      this._recalculateContainerHeight(this.$refs.contentContainer.getBoundingClientRect());
      this.hasLoaded = true;
    },

    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      /** Watching the height
       * TODO: Add a minimum call delay to prevent melting CPU when resizing the tab a lot.
       *    NOTE 3/29/2022, there is a minimum call delay in the resizeObserver it appears. 
       * NOTE: If implementing this will need to recheck height when reobserving the container.
       *  NOTE2: Maybe not necessary...  
       * TODO #2: Fix faster animation caused by shorter container height than parent height.
       *  Height should be clamped to parent initial height (close, but would be wrong)??? 
       */
      _setupHeightObserver() {
        const heightObs = new ResizeObserver(async (el) => {
          if (this.$refs.contentContainer) {
            const containerDims = this.$refs.contentContainer.getBoundingClientRect();

            if(containerDims.height != this._lastContainerHeight) {
              this._recalculateContainerHeight(containerDims);
              this.sleep();
            }
          }
        });

        heightObs.observe(this.$refs.contentContainer);

        this._heightObserver = heightObs;
      },

      /**
       * @param {DOMRect} containerDimensions 
       */
      _recalculateContainerHeight(containerDimensions) {
        // if (process.env.NODE_ENV === "development") {
        //   console.log('Recalculated x-expand-container height...')
        // }

        const containerHeight = containerDimensions.height;
        this._lastContainerHeight = containerHeight;
        this.height = this.yPadding * 2 + containerHeight;
      }
    }
  };

  export default XExpandContainer;
</script>

<style scoped lang="scss">
  $transition-time: 0.2s;

  .x-expand-container {
    transition: $transition-time cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }
</style>