import Route from "./_route";

/**
 * Requests to manipulate respondent data
 */
class Respondents extends Route {

    /**
     * Grabs all the respondents for a project
     */
    getRespondents(projectId) {
        return this.axios.get(`/Project/GetRespondents/?projectKey=${projectId}`);
    }

    /**
     * Sets all given user sessions to a given flag status
     * 
     * @param {Array<number>} keys An array of session keys
     */
    flagUserSession(keys, userSessionFlag) {
        return this.axios.post(`/Project/FlagUserSession?userSessionFlag=${userSessionFlag}`, keys);
    }

    /**
     * Sets all given user sessions to qualified/unqualified
     * 
     * @param {Array<number>} keys An array of session keys
     */
    setQualified(keys, isQualified) {
        return this.axios.post(`/Project/SetQualified?isQualified=${isQualified}`, keys);
    }

    /**
     * Deletes all given user sessions
     * 
     * @param {Array<number>} keys An array of session keys
     */
    deleteUserSession(keys) {
        return this.axios.post('/Project/DeleteUserSession', keys);
    }
}

export default Respondents;
