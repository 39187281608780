import Api from '@/api/api'

// adding error catch in case the profile get's corrupted
var currentUser = null;
try {
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
} catch(err) {
  console.log('User profile corrupt, wiping...'); 
  currentUser = null;
  localStorage.removeItem('currentUser');
}

const AuthStore = {
  namespaced: true,
  
  state: {
    currentUser,
  },
  
  getters: {
    /**
     * Import this as a computed property! Gives access to the currentUser. 
     *  The currentUser is refreshed every page load, and ideally when the window is unfocused and then refocused. 
     * @param {*} state 
     * @returns 
     */
     currentUser: (state) => {
      return state.currentUser;
    },
  },

  // always asynchronous
  actions: {
    logout() {
      localStorage.removeItem('currentUser');
      return Api.Authentication.logout();
    },
  },

  mutations: {
    /**
     * Sets the local storage and the state to the given user. 
     * @param {initialState} state 
     * @param {*} newUser 
     */
    setCurrentUser(state, newUser){
      //console.log('set current user from store')
      localStorage.setItem('currentUser', JSON.stringify(newUser));
      state.currentUser = newUser;
    },
  },
};



export default AuthStore;
