import Api from "@/api/api";

const ReportStore = {
  namespaced: true,

  state: {
    currentProjectKey: null,
    importDataMediaId: null,
    exportMediaId: null,
    visualReportLayout: 'Standard'
  },
  mutations: {
    setCurrentProjectKey(state, newProject) {
      state.currentProjectKey = newProject
    },
    setImportDataMediaId(state, newMedia) {
      state.importDataMediaId = newMedia
    },
    setExportMediaId(state, newMedia) {
      state.exportMediaId = newMedia
    },
    setVisualReportLayout(state, newLayout) {
      state.visualReportLayout = newLayout
    }
  }
};



export default ReportStore;
