<template>
	<v-dialog persistent max-width="fit-content">
		<template v-slot:activator="{ on, popout }">
			<v-btn text
					v-on="on"
					v-bind="popout"
					@click="showPlayer = true"><v-icon class="mr-2">mdi-eye</v-icon>Preview</v-btn>
		</template>
		<template v-slot:default="dialog">
			<!-- Video Player in Dialog -->
			<v-card v-if="showPlayer">
				<v-card-title class="headline">
					Media Preview

					<v-btn icon
						@click="dialog.value = false; showPlayer = false"
						class="ml-auto">
						<v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>

				<v-card-text class="mt-6">
					<x-player
						:youtube="mediaType === TYPE_YOUTUBE"
						:src="src"
						:startTime="media.startTimeString"
						:endTime="media.endTimeString"
						:height="400"></x-player>
				</v-card-text>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
	import XPlayer from "./x-player.vue";

	const XPreviewDialog = {
		props: {
			projectId: [String, Number],
			media: Object,
			mediaType: [String, Number],
		},

		components: {
			XPlayer
		},

		data() {
			const TYPE_HOSTED = 1,
                  TYPE_THIRD_PARTY = 2,
                  TYPE_YOUTUBE = 3;

			const mediaKey = this.media.fileKey;
			const awsURL = this.media.awsCloudfrontUrl;
			let src = this.media.videoUrl;

			// Build src url if the media is hosted on AWS
			if (this.mediaType === TYPE_HOSTED) {
				const timestamp = new Date().getTime();
                src = `${awsURL}transcoded/${mediaKey}/dash_${mediaKey}index.mpd?timestamp=${timestamp}`;
			}

			return {
				src,
				showPlayer: true,
				TYPE_HOSTED, TYPE_THIRD_PARTY, TYPE_YOUTUBE,
			}
		},

		methods: { }
	};

	export default XPreviewDialog;
</script>

<style scoped lang="scss"></style>