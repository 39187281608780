import Api from "../api";
import Route from "./_route";

/**
 * Requests to manipulate project data
 */
class Projects extends Route {

  /**
   *
   * @param {number} projectId
   * @returns
   */
  getProject(projectId) {
    return this.axios.get(`/Project/UpdateProject?id=${projectId}`);
  }

  getProjectInfo(projectId, customerKey) {
    //Todo: The CustomerKey needs to be passed here for security
    customerKey = 0;
    return this.axios.get(`/Project/GetProjectInfo?id=${projectId}&customerKey=${customerKey}`);
  }

  deleteProject(projectId) {
    return this.axios.get(`/Project/DeleteProject?projectKey=${projectId}&agreed=true`);
  }

  getProjects(searchOptions) {
    // Get 415'd without searchOptions being defined
		searchOptions === undefined ? searchOptions = {} : searchOptions;
    return this.axios.post(`/Project/GetItems`, searchOptions);
  }

  customersRead() {
    return this.axios.get(`/Project/CustomersRead`);
  }

  getCustomerList() {
    return this.axios.get(`/Project/GetCustomerList`);
  }

  saveProject(projectModel) {
    return this.axios.post(`/Project/SaveProject`, projectModel);
  }

  addProject(customerKey) {
    return this.axios.get(`/Project/AddProject?customerKey=${customerKey}`);
  }

  saveSurveyParameters(projectId, subsetModel) {
    return this.axios.post(`/Project/SaveSurveyParameters?projectKey=${projectId}`, subsetModel);
  }

  deleteProviderParameter(subsetModel) {
    return this.axios.post('/Project/DeleteProviderParameter', subsetModel);
  }

  getCustomerProjects(archived, key, query='') {
    if (!archived) {
      return this.getCustomerProjectList(key, query);
    } else {
      return this.getCustomerProjectArchiveList(key, query);
    }
  }

  getCustomerProjectList(customerId, query='') {
    if (query) {
      return this.axios.get(`/Project/ProjectRead?CustomerKey=${customerId}&searchText=${query}`);
    } else {
      return this.axios.get(`/Project/ProjectRead?CustomerKey=${customerId}`);
    }
  }

  getCustomerProjectArchiveList(customerId, query='') {
    if (query) {
      return this.axios.get(`/Project/ProjectArchiveRead?CustomerKey=${customerId}&searchText=${query}`);
    } else {
      return this.axios.get(`/Project/ProjectArchiveRead?CustomerKey=${customerId}`);
    }
  }

  async copyProject(projectId, copyData) {
    return this.axios.get(`/Project/CopyProject?ProjectKey=${projectId}&fullCopy=${copyData}`);
  }

  /**
   * Returns the project data needed to run the dial test.
   * @param {number} projectId
   * @returns
   */
  getDialTest(projectId, params) {
    return this.axios.get(`/Project/${projectId}/View${params.length ? '?' + params : ''}`);
  }

  viewLanding(id, params) {
    return this.axios.get(`/Project/${id}/ViewLanding${params.length ? '?' + params : ''}`);
  }

  getSubsets(projectId) {
    return this.axios.get(`/Project/ReadProviderParameters?projectKey=${projectId}`);
  }

  updateProjectUser(userModel) {
    return this.axios.post(`/Project/UpdateProjectUser`, userModel);
  }

  editProjectUser(customerKey) {
      return this.axios.get(`/Project/EditProjectUsers?customerKey=${customerKey}`)
  }

  getAssignedProjects(profileKey, customerKey) {
    return this.axios.get(`/Project/GetAssignedProjectForProfile?profileKey=${profileKey}&customerKey=${customerKey}`)
  }

  userLockOut(userKey, lockOut) {
    return this.axios.post(`/Project/UserLockOut?userKey=${userKey}&lockOut=${lockOut}`);
  }

  removeUserRole(profileKey, projectKey) {
    return this.axios.get(`/Project/RemoveProfileFromProject?profileKey=${profileKey}&projectKey=${projectKey}`);
  }

  updateUserRole(profileKey, projectKey, type) {
    return this.axios.get(`/Project/AddUpdateProfileForProject?profileKey=${profileKey}&projectKey=${projectKey}&type=${type}`);
  }

  getProjectReports(projectKey) {
    return this.axios.get(`/Project/ProjectReports?id=${projectKey}`);
  }

  getUserDataForReports(mediaKey) {
    return this.axios.post(`/Project/GetUserDataForReports?mediaKey=${mediaKey}`);
  }

  getUserDataColumnsForReports(mediaKey) {
    return this.axios.get(`/Project/GetUserDataColumnsForReports?mediaKey=${mediaKey}`);
  }

  importReports(mediaKey, files) {
    return this.axios.post(`/Project/ReportsImport?mediaKey=${mediaKey}`, files);
  }

  createReport(params) {
    return this.axios.post(`/Project/CreateReport`, params);
  }

  createExcelReport(reportName, projectKey, useDataMap, params) {
    return this.axios.post(
      `/Project/ReportWorking?reportName=${reportName}&projectKey=${projectKey}&useDataMap=${useDataMap}`
      , params);
  }

  createOpenEndedReport(params) {
    return this.axios.post(`/Project/ExportOpenEnded`, params,{
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  saveProjectLogo(projectKey, file) {
    let formData = new FormData();
    formData.append('file', file);

    return this.axios.post(`/Project/SaveProjectLogo?projectKey=${projectKey}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  removeProjectLogo(projectKey, newName="") {
    return this.axios.post(`/Project/RemoveProjectLogo?projectKey=${projectKey}`, [newName]);
  }

  saveImageBlogToS3(fileKey, url, second) {
    return this.axios.post(`/Project/SaveImageBlogToS3`, {fileKey, url, second});
  }

  createExport(fileKey) {
    return this.axios.post(`/Project/CreateExport?fileKey=${fileKey}`);
  }

  deleteAnnotation(annotationKey) {
    return this.axios.get(`/Project/DeleteAnnotation?annotationKey=${annotationKey}`);
  }

  createAnnotation(params) {
    return this.axios.post(`/Project/SaveAnnotation`, params);
  }

  getAwsCredentials() {
    return this.axios.get(`/Project/GetAwsCredentials`);
  }

  setClientIP(userSessionKey, clientIp) {
    return this.axios.post(`/Project/SetClientIp?userSessionKey=${userSessionKey}&clientIp=${clientIp}`);
  }

  finalPageRedirect(projectKey, userSessionKey) {
    return this.axios.get(`/Project/FinalPageRedirect?projectKey=${projectKey}&userSessionKey=${userSessionKey}`);
  }

  getExternalIdKey(projectKey) {
    return this.axios.get(`/Project/GetProjectExternalIdKey?projectKey=${projectKey}`);
  }

  checkArchiveProjects() {
    return this.axios.get(`/Project/CheckArchiveProjects`);
  }
}

export default Projects;
