<template>
  <video ref="videoPlayer" class="video-js vjs-big-play-centered media-questions__controls" :controls="showControls"></video>
</template>

<script>
import videojs from "video.js";

const XVideoPlayer = {
  props: {
    src: String,
    poster: String,
    height: [String, Number],
    showControls: Boolean
  },

  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.playerOptions, () => {
      this.player.log("onPlayerReady", this);
      this.player.on("timeupdate", function () {});
    });
  },

  methods: {
    getDuration() {
      return this.$refs.videoPlayer.currentTime;
    },

    setStart(startTime) {
      return (this.$refs.videoPlayer.currentTime = startTime);
    },

    paused() {
      return this.$refs.videoPlayer.paused;
    },

    play() {
      return this.$refs.videoPlayer.play();
    },

    pause() {
      return this.$refs.videoPlayer.pause();
    },
  },

  data() {
    const options = {
      autoplay: false,
      muted: false,
      playbackRates: [0.5, 1, 1.5, 2],
      controls: true,
      responsive: true,
      sources: [
        {
          src: this.src,
          withCredentials: false,
          type: "",
        },
      ],
      poster: this.poster,
      height: this.height,
      html5: { vls: { withCredentials: false } },
    };

    return {
      player: null,
      playerOptions: options,
    };
  },
};

export default XVideoPlayer;
</script>

<style scoped lang="scss">
video {
  max-width: 100%;
  height: auto;
}
</style>

<style lang="scss">
// Fix for not displaying controls in staging
.media-questions__controls {

  .vjs-control-bar {
    display: flex !important;
  }

}
</style>
