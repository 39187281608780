var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-banner',{attrs:{"color":"primary","sticky":"","dark":""}},[_c('div',{staticClass:"d-flex flex-row-reverse align-center"},[_c('router-link',{attrs:{"to":"/projects"}},[_c('v-btn',{attrs:{"plain":"","small":"","color":"secondary"}},[_vm._v("Go to new view")])],1)],1)]),_c('div',{staticClass:"d-flex flex-column align-center",attrs:{"id":" projects-container"}},[_c('v-sheet',{staticClass:"fill-width mb-12",staticStyle:{"overflow":"hidden"},attrs:{"elevation":"1","rounded":""}},[_c('v-layout',{staticClass:"px-4 py-2",staticStyle:{"border-bottom":"2px solid var(--v-accent-base)"}},[_c('v-flex',[_c('h1',{staticClass:"d-flex align-center",staticStyle:{"color":"black"}},[_c('span',[_vm._v("Projects")])])]),_c('v-flex',{attrs:{"d-flex":"","justify-end":"","align-center":""}},[_c('v-tabs',{staticClass:"mr-4 d-flex justify-end"},[_c('v-tab',{attrs:{"color":"primary"},on:{"change":function($event){return _vm.toggleTab('active')}}},[_vm._v("Active")]),_c('v-tab',{attrs:{"color":"secondary"},on:{"change":function($event){return _vm.toggleTab('archived')}}},[_vm._v("Archived")])],1),_c('v-btn',{attrs:{"color":"primary","width":"170px"},on:{"click":_vm.togglePanels}},[_vm._v(" "+_vm._s(_vm.panel.length ? "Collapse All" : "Expand All"))])],1)],1),_c('div',{staticClass:"py-4 px-6"},[(!_vm.loadingOrgs)?_c('v-expansion-panels',{attrs:{"multiple":"","flat":"","value":_vm.panel}},_vm._l((_vm.organizations),function(org,i){return _c('v-expansion-panel',{key:i,staticClass:"company-card elevation-0",on:{"click":function($event){return _vm.toggleExpansion(org.customerKey)}}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('div',{staticClass:"d-flex align-center"},[_c('h3',[_vm._v(_vm._s(org.name))]),_c('v-spacer'),(_vm.userPermissions[org.customerKey] && open && !_vm.showArchived
              )?_c('div',[(_vm.userPermissions[org.customerKey].canAddProjects ||
              (_vm.userPermissions[org.customerKey] &&
                _vm.userPermissions[org.customerKey].isAdmin)
              )?_c('v-btn',{staticClass:"mr-6",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.gotoSettings(org.customerKey)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Add Project")],1):_vm._e(),(_vm.isBuilder(org) ||
              (_vm.userPermissions[org.customerKey] &&
                _vm.userPermissions[org.customerKey].isAdmin)
              )?_c('x-users-management',{staticClass:"mr-6",attrs:{"org":org}}):_vm._e()],1):_vm._e()],1)]}}],null,true)}),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"border":"solid var(--v-accent-base) 1px"},attrs:{"headers":_vm.projectHeaders,"items":org.projects,"no-data-text":"No projects found!","hide-default-footer":"","items-per-page":-1,"loading":org.projects == [] || _vm.areProjectsLoading[org.customerKey]},scopedSlots:_vm._u([{key:`item.custom_actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[(item.currentUserType == 'Viewer' &&
              !(
                _vm.userPermissions[org.customerKey] &&
                _vm.userPermissions[org.customerKey].isAdmin
              )
              )?_c('v-btn',{staticClass:"mr-3",attrs:{"icon":"","color":"secondary","disabled":_vm.areProjectsLoading[org.customerKey]},on:{"click":function($event){return _vm.gotoMedia(org.customerKey, item.projectKey)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1):_c('v-btn',{staticClass:"mr-3",attrs:{"icon":"","color":"secondary","disabled":_vm.areProjectsLoading[org.customerKey]},on:{"click":function($event){return _vm.gotoSettings(org.customerKey, item.projectKey)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),(_vm.userPermissions[org.customerKey].canAddProjects ||
              (_vm.userPermissions[org.customerKey] &&
                _vm.userPermissions[org.customerKey].isAdmin)
              )?_c('v-dialog',{attrs:{"max-width":"fit-content"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, popout }){return [((item.currentUserType != 'Viewer' ||
              (_vm.userPermissions[org.customerKey] &&
                _vm.userPermissions[org.customerKey].isAdmin)) &&
              !_vm.showArchived
              )?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"icon":"","color":"secondary","disabled":_vm.areProjectsLoading[org.customerKey]}},'v-btn',popout,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-copy")])],1):_c('v-btn',{staticClass:"mx-3",attrs:{"disabled":"","icon":""}})]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Copy Project")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticStyle:{"padding":"24px"}},[_c('v-btn',{staticStyle:{"margin-right":"24px"},attrs:{"color":"secondary"},on:{"click":function($event){_vm.copyProject(item.projectKey, false);
            dialog.value = false;}}},[_vm._v("Copy")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.copyProject(item.projectKey, true);
            dialog.value = false;}}},[_vm._v("Copy w/ Data")])],1)],1)]}}],null,true)}):_c('v-btn',{staticClass:"mx-3",attrs:{"disabled":"","icon":""}}),(item.currentUserType != 'Viewer' ||
              (_vm.userPermissions[org.customerKey] &&
                _vm.userPermissions[org.customerKey].isAdmin)
              )?_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","color":"secondary","disabled":_vm.areProjectsLoading[org.customerKey]},on:{"click":function($event){return _vm.tryDeleteProject(item.projectKey, org.customerKey)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-trash-can")])],1):_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":"","icon":""}})],1)]}}],null,true)})],1)],1)}),1):_c('div',{staticClass:"d-flex justify-center flex-column pa-8 align-center"},[_c('h1',{staticClass:"my-8",staticStyle:{"font-weight":"300"}},[_vm._v(_vm._s(_vm.LoadingMessage))]),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"128"}})],1)],1)],1),_c('x-confirm-dialog',{ref:"confirmProjectDelete",attrs:{"title":"Delete Project?","message":"","showConfirmCheckbox":true}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }