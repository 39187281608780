<template><div class="copy-box">

	<v-text-field v-model="text"
		:label="label"
		outlined dense readonly></v-text-field>
	<v-btn icon
		@click="copy()"
		class="ml-2"><v-icon>mdi-content-copy</v-icon></v-btn>

</div></template>

<script>
	const XCopyTextbox = {
		props: {
			label: [String],
			text: [String],
		},

		methods: {
			copy() {
				navigator.clipboard.writeText(this.text).then(() => {
					alert('Copied!');
				}).catch(() => {
					alert('Failed to copy! Manually copy the text below:\n\n' + this.text);
				});
			},
		},
	};

	export default XCopyTextbox;
</script>

<style lang="scss" scoped>
	.copy-box {
		display: flex;
		flex-direction: row;
	}
</style>