<template>
  <v-dialog v-model="showDialog" persistent overlay-color="red" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <span v-if="message" class="text-h6">{{ message }}</span>
              <!-- Agreement -->
              <v-checkbox v-model="confirmedChecked"
                v-if="showConfirmCheckbox"
                required
                label="I understand that this action is permanent."
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <!-- <small>*indicates required field</small> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showDialog = false; resolveAction(false)">
          <v-icon>mdi-cancel</v-icon>
          Cancel
        </v-btn>
        <v-btn color="secondary" :disabled="!confirmedChecked && showConfirmCheckbox" text @click="showDialog = false; resolveAction(true)">
          <v-icon>mdi-trash-can</v-icon>
          DELETE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const XDeleteProjectDialog = {
  props: {
    title: {
      type: String,
      default: "Confirm Action"
    },

    message: {
      type: String,
      defalut: null,
    },

    showConfirmCheckbox: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      showDialog: false,
      confirmationPromise: null,

      resolveAction: null,
      rejectAction: null,
      confirmedChecked: false,
    };
  },

  methods: {
    /**
     * Shows the dialog menu
     */
    async confirmAction(){
      this.showDialog = true;
      this.confirmationPromise = new Promise((resolve, reject) => {
        this.resolveAction = resolve;
        this.rejectAction = reject;
      });

      this.confirmedChecked = false;
      return this.confirmationPromise;
    }
  },
};

export default XDeleteProjectDialog;
</script>

<style>
</style>
