<template>
  <v-dialog max-width="600" @click:outside="clearNotifications">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="secondary" v-bind="attrs" v-on="on">
        <v-icon>mdi-export</v-icon>
        <span class="ml-2">Export Data</span>
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title class="headline">Export Data</v-card-title>

        <v-card-text v-if="!isGeneratingExport">
          <v-container>
            <x-expand-alert type="error" ref="alert" :dismissible="true"></x-expand-alert>
            <v-row class="my-4">
              <div>
                <h3 class="text-uppercase mb-2">Select for export</h3>
                <v-data-table
                  v-model="selectedMediaItems"
                  :headers="headers"
                  :items="mediaItems"
                  :items-per-page="10"
                  show-select
                  item-key="fileKey"
                ></v-data-table>
              </div>
              <div class="fill-width">
                <h3 class="text-uppercase mb-4">Export formats</h3>
                <div class="export-formats__container mb-4">
                  <v-btn
                    :disabled="selectedMediaItems.length === 0 || loadingExport"
                    @click="combinedDialog = true"
                  >
                    EXCEL/CSV (COMBINED)
                  </v-btn>
                  <!--Select use data map dialog-->
                  <v-dialog
                    v-model="combinedDialog"
                    max-width="300px"
                  >
                    <v-card class="px-4 py-4">
                      <h4>Select Option</h4>
                      <v-radio-group v-model="useDataMap">
                        <v-radio
                          label="Use labels"
                          :value="false"
                        ></v-radio>
                        <v-radio
                          label="Use Values w/ Datamap"
                          :value="true"
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="selectedMediaItems.length === 0 || loadingExport"
                        color="secondary"
                        @click="exportMedia('combined', useDataMap), combinedDialog = false"
                      >
                       GENERATE REPORT
                      </v-btn>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    :disabled="selectedMediaItems.length === 0 || loadingExport"
                    @click="exportOpenEnded"
                  >
                    TXT (OPEN ENDED)
                  </v-btn>
                  <v-btn
                    :disabled="selectedMediaItems.length === 0 || loadingExport"
                    @click="selectParamsDialog = true, selectedReportType = 'banner'"
                  >
                    EXCEL/CSV (BANNER)
                  </v-btn>
                  <v-btn
                    :disabled="selectedMediaItems.length === 0 || loadingExport"
                    @click="selectParamsDialog = true, selectedReportType = 'stats'"
                  >
                    EXCEL/CSV (PROJECT STATS)
                  </v-btn>
                  <!--Select Parameters dialog-->
                  <v-dialog
                    v-model="selectParamsDialog"
                    max-width="500px"
                  >
                    <v-card class="px-4 py-4">
                      <h4>Select Parameters</h4>
                      <div class="my-4">
                        <v-checkbox
                          v-for="param in paramList" :key="param.parameterID + param.parameterName"
                          v-model="selectedParamsList"
                          :label="param.parameterName"
                          :value="param"
                          dense
                        ></v-checkbox>
                      </div>
                      <v-btn
                        :disabled="loadingExport"
                        color="secondary"
                        @click="exportMedia(selectedReportType, false, selectedParamsList),
                        selectParamsDialog = false, selectedParamsList = [], selectedReportType = ''"
                      >
                        GENERATE REPORT
                      </v-btn>
                    </v-card>
                  </v-dialog>
                </div>
                <v-progress-linear
                  v-if="loadingExport"
                  indeterminate
                  color="secondary"
                ></v-progress-linear>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <v-container>
            <v-row>
              <div class="mt-4 export-notifications__container" >
                <v-alert
                  v-for="(msg, index) in exportNotificationMessages"
                  :key="`export-dialog-${index}`"
                  dense
                  min-width="100%"
                  text
                  :type="msg.type === 'error' ? 'error' : msg.type === 'completed' ? 'success' : 'info' ">
                  <span v-if="msg.type !== 'completed'"> {{ msg.message }} </span>
                  <span v-else>Media is complete and can be downloaded <a :href="msg.message">here</a>
                <v-icon color="green">mdi-download</v-icon></span>
                </v-alert>
                <v-progress-linear
                  v-if="isExportLoading"
                  class="mt-2"
                  color="secondary"
                  indeterminate
                  rounded
                ></v-progress-linear>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false, clearNotifications()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Api from "@/api/api";
import XExpandAlert from '@/components/_generics/x-expand-alert.vue';
import {saveAs} from 'file-saver'
import Events from "@/events/events";

export default {
  name: "x-export-media-dialog",
  
  components: {
    XExpandAlert
  },
  
  props: {
    projectId: String,
    mediaItems: Array,
    paramList: Array,
  },
  
  data() {
    return {
      selectedMediaItems: [],
      loadingExport: false,
      useDataMap: false,
      isGeneratingExport: false,
      isExportLoading: false,
      exportNotificationMessages: [],
      combinedDialog: false,
      selectParamsDialog: false,
      selectedReportType: '',
      selectedParamsList: [],
      headers: [
        {
          text: 'NAME',
          value: 'name'
        },
        {
          text: 'MEDIA ID',
          value: 'fileKey'
        }
      ],
    }
  },

  mounted() {
    this.$store.commit('ReportStore/setCurrentProjectKey', this.projectId);
    Events.$on('createReportNotification', this.reportListener);
  },

  methods: {
    exportMedia(reportName, useDataMap = false, paramsList) {
      this.loadingExport = true;
      const projectKey = this.projectId;

      let params = {
        mediaKeys: this.selectedMediaItems.map(item => item.fileKey),
        parameterNames: [],
        surveyProviderParameterKeys: []
      };

      if (paramsList && paramsList.length > 0) {
        paramsList.forEach(param => {
          params.parameterNames.push(param.parameterID)
          params.surveyProviderParameterKeys.push(param.surveyProviderParameterKey)
        });
      }

      Api.Projects.createExcelReport(reportName, projectKey, useDataMap, params).then(response => {
        this.loadingExport = false;
        this.createReport(response.data);
      }).catch(error => {
        console.error('exportMedia():', error);
        this.loadingExport = false;
        this.$refs.alert.setMessage('An error occurred, try again.', 'error');
      });
    },

    createReport(data) {
      this.loadingExport = true;
      const params = {
        mediaKeys: data.mediaKeys.$values,
        paramNames: data.paramNames.$values,
        surveyProviderParameterKeys: data.surveyProviderParameterKeys.$values,
        reportName: data.reportName,
        projectKey: data.projectKey,
        useDataMap: data.useDataMap
      };

      Api.Projects.createReport(params).then(response => {
        this.loadingExport = false;
      }).catch(error => {
        console.error('createReport():', error);
        this.loadingExport = false;
        this.$refs.alert.setMessage('An error occurred, try again.', 'error');
      });
    },

    exportOpenEnded() {
      this.loadingExport = true
      let params = this.selectedMediaItems.map(item => item.fileKey)
      Api.Projects.createOpenEndedReport(params)
        .then(response => {
          this.loadingExport = false

          // Parse response to .zip file
          const blob = new Blob([response.data], {
            type: 'application/octet-stream'
          })
          let filename = 'Open-Ended'
          this.selectedMediaItems.forEach(media => {
            filename += `-${media.fileKey}`
          })
          filename += '.zip'
          saveAs(blob, filename)
        })
        .catch(error => {
          console.log(error)
          this.loadingExport = false
          this.$refs.alert.setMessage('An error occurred, try again.', 'error')
        })
    },

    reportListener(msg) {
      this.isGeneratingExport = true;

      this.isExportLoading = !(msg.type === 'error' || msg.type === 'completed');
      this.exportNotificationMessages = [...this.exportNotificationMessages, msg];
    },

    clearNotifications() {
      this.selectedMediaItems = [];
      this.isGeneratingExport = false;
      this.exportNotificationMessages = [];
      this.useDataMap = false;
    },
  },
}
</script>

<style scoped lang="scss">
.export-formats__container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 1rem;
}
.export-notifications__container {
  min-width: 100%;
  max-height: 60vh;
  overflow-y: auto;
}
</style>
