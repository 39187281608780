<template>
  <div class="video-controls" :class="{'auto-hidden': isPlaying, isYoutube}">
    <div class="slider__container">
      <v-slider
        :value="currentTime"
        min="0" :max="videoDuration" dense light class="slider" color="red"
        @change="skipTime"
      ></v-slider>
    </div>
    <div class="inner pa-1">
      <div class="left">
        <v-btn
          @click="togglePlayPause"
          icon color="white"
          x-small class="video-controls__btn mr-2">
          <v-icon v-if="!isPlaying">mdi-play</v-icon>
          <v-icon v-else>mdi-pause</v-icon>
        </v-btn>
        <span class="time">
          <span class="current">{{ currentMinutes }}:{{ currentSeconds }}</span>
          /
          <span class="total">{{ durationMinutes }}:{{ durationSeconds }}</span></span>
      </div>
      <div class="right">
        <v-btn icon color="white" x-small class="video-controls__btn ml-2" @click="showAnnotationPopup"><v-icon>mdi-message</v-icon></v-btn>
        <v-btn v-if="!isYoutube" icon color="white" x-small class="video-controls__btn ml-2" @click="downloadScreenshot"><v-icon>mdi-image</v-icon></v-btn>
        <v-btn v-if="!isYoutube" icon color="white" x-small class="video-controls__btn ml-2" @click="downloadVideo"><v-icon>mdi-download</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "x-video-controls",
  props: {
    play: Function,
    pause: Function,
    skipTime: Function,
    videoDuration: Number,
    currentVideoTime: Number,
    showAnnotationPopup: Function,
    downloadScreenshot: Function,
    downloadVideo: Function,
    isYoutube: Boolean
  },
  data() {
    return {
      isPlaying: false,
      durationMinutes: '00',
      durationSeconds: '00',
      currentMinutes: '00',
      currentSeconds: '00',
    }
  },
  watch: {
    videoDuration(seconds) {
      const time = this.formatTime(seconds)
      this.durationMinutes = time.minutes
      this.durationSeconds = time.seconds
    },
    currentVideoTime(seconds) {
      const time = this.formatTime(seconds)
      this.currentMinutes = time.minutes
      this.currentSeconds = time.seconds

      // Auto pause on video end
      if (this.durationMinutes === this.currentMinutes && this.durationSeconds === this.currentSeconds) {
        this.isPlaying = false
      }
    }
  },
  computed: {
    currentTime() {
      return this.currentVideoTime
    }
  },
  methods: {
    togglePlayPause() {
      if (this.isPlaying) {
       this.pause()
      } else {
        this.play()
      }

      this.isPlaying = !this.isPlaying
    },

    // formatTime takes a time length in seconds and returns the time in
    // minutes and seconds
    formatTime(timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

      return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2),
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .video-controls {
    background-color: #313131;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 20;

    &.isYoutube {
      bottom: 0;
    }

    &.auto-hidden {
      opacity: 0;
      transition: opacity .3s ease;

      &:hover {
        opacity: 1;
      }

    }

    .slider__container {
      position: relative;
      //height: 1px;

      .slider {
        z-index: 21;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(-17px);
        height: 10px;
      }
    }

    .inner {
      display: flex;
      justify-content: space-between;

      .time {
        font-size: .65rem;
      }

      .video-controls__btn {
        position: relative;
        z-index: 23;
        padding: 4px;
      }

      //.left, .right {
      //  display: flex;
      //  column-gap: 8px;
      //}

    }
  }
</style>
