<template>
    <div>
        <el-tiptap v-model="content"
                   :extensions="extensions"
                   :charCounterCount="false" />
    </div>
</template>

<script>
    // This uses a different UI library than x-text-editor-vuetify (Element UI vs Vuetify), but
    // includes the code viewing functionality, so it's replacing it.

    import {
        // necessary extensions
        Doc,
        Text,
        Paragraph,
        Heading,
        Bold,
        Underline,
        Italic,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        CodeView,
        TextAlign,
        Indent,
        Link,
        Image,
        Table, TableHeader, TableCell, TableRow,
    } from 'element-tiptap';

    import codemirror from 'codemirror'; // install 'codemirror' in your own project
    import 'codemirror/lib/codemirror.css'; // import base style
    import 'codemirror/mode/xml/xml.js'; // language

    const XTextEditorNew = {
        props: {
            /** THIS IS THE 'v-model' PROP */
            value: { type: String, default: '' },
            allowImages: { type: [Boolean, String], default: false },
        },

        data () {
            let optionalExtensions = [];
            if (this.allowImages) optionalExtensions.push(new Image());

            return {
                extensions: [
                    new Doc(),
                    new Text(),
                    new Paragraph(),
                    new Heading({ level: 5 }),
                    new Bold({ bubble: true }),
                    new Underline({ bubble: true }),
                    new Italic({ bubble: true }),
                    new Strike({ bubble: true }),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new CodeView({
                        codemirror: codemirror
                    }),
                    new TextAlign(),
                    new Indent(),
                    new Link({ bubble: true }),
                    new Table(), new TableHeader(), new TableCell(), new TableRow(),
                    ...optionalExtensions,
                ],
                content: ''
            };
        },

        watch: {
            // Watching value which is telling us the v-model has been updated in the parent component
            content() {
                this.$emit('input', this.content);
            },
        },

        mounted() {
            this.content = this.value;
        },
    };

    export default XTextEditorNew;
</script>

<style lang="scss">
    .el-tiptap-editor {
        border: 1px solid #aeaeae !important;
    }

    .el-tiptap-editor:hover {
        border: 1px solid var(--v-primary-base) !important;
    }

    /* Keeping the query here for easy access later */
    .el-tiptap-editor > .el-tiptap-editor__menu-bar { }

    .el-tiptap-editor > .el-tiptap-editor__content {
        border-bottom-style: solid !important;
    }
</style>