
const MiscUtils = {
  async _clipboardCopier(string, type){
    function listener(e) {
      e.clipboardData.setData(type, string);
      e.preventDefault();
    }

    // execCommand is deprecated, but it is more reliable lol. 
    try{
      if(navigator && navigator.clipboard){
        
        // patch to prevent the VueCli from failing to compile
        const { ClipboardItem } = window;

        var blob = new Blob([string], { type: type });
        var data = [new ClipboardItem({ [type]: blob })];
        await navigator.clipboard.write(data);

        return true;

      } else if(document.execCommand){
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        return true;

      // if it doesn't exist then we use the new one. 
      }

    } catch(e){
      console.error('uncaught exception while copying to clipboard', e)
    }

    return false
  },

  /**
   * Copies plain text to the clipboard, peformed asyncronously due to the nature of the clipboard. 
   * @param {string} text 
   * @returns 
   */
  copyTextToClipboard(text) {
    return this._clipboardCopier(text, 'text/plain');
  },

  copyHtmlToClipboard(html) {
    return this._clipboardCopier(html, 'text/html');
  },
}

export default MiscUtils;