<template>
  <div class="d-flex flex-column align-center mt-4">
    <v-sheet style="overflow: hidden; transition: all 1s ease-in-out" class="mt-4 mb-12 fill-width" elevation="1"  rounded>
      
      <div class="px-4 py-2" style=" border-bottom: 2px solid var(--v-accent-base);">
        <h1 class="d-flex align-center" style="color: black">
          <v-icon class="mr-4" large style="color: black">mdi-security</v-icon> 
          <span>Admin Center</span>
        </h1>
      </div>

      <div class="d-flex flex-column flex-sm-row">
        <div class="">
          <v-tabs vertical grow style="height: 100%; border-right: 2px solid var(--v-accent-base);">
            <!-- Wrapped in div to prevent from tabs from auto expanding to the full height -->
            <div>
              <v-tab to="/admin/users" class="justify-start" > 
                  <v-icon>mdi-account</v-icon> 
                  <span class="ml-2 pr-1">Users</span>
              </v-tab>

              <v-tab to="/admin/roles" class="justify-start"> 
                <v-icon>mdi-account-group</v-icon> 
                <span class="ml-2 pr-1">Roles</span>
              </v-tab>

              <v-tab to="/admin/organizations" class="justify-start"> 
                <v-icon>mdi-domain</v-icon> 
                <span class="ml-2 pr-1">Organizations</span>
              </v-tab>

              <!-- <v-tab to="/admin/settings" class="justify-start"> 
                <v-icon>mdi-account-cog</v-icon> 
                <span class="ml-2 pr-1">Settings</span>
              </v-tab> -->
            </div>
          </v-tabs>
        </div>

        <x-expand-container class="flex-grow-1">
          <!-- Plays a quick fade in transition on route change  -->
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </x-expand-container>

      </div>
    </v-sheet>
  </div>
</template>

<script>
  import XExpandContainer from '@/components/_generics/x-expand-container.vue';
  import InputRules from "@/utils/inputFieldRules";

  export default {
    name: "Admin",
    
    mixins: [InputRules],
    components: {
      XExpandContainer
    }
  };
</script>

<style lang="scss"></style>
