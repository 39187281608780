
/**
 * Import me as a mixin to have access to all the input field rules!
 * EX: 
 * import InputRules from '.../inputFieldRules.js'
 * {
 *  name: "etc..", 
 *  mixins: [InputRules]
 * }
 */
const InputRules = {
  data() {
    return {
      InputRules: {
        emptyValue: (value) =>{
          if(!value) return "Please enter a value";
          return true;
        },

        /** 
         * MUST BE CALLED as it RETURNS a function!!
         * Generates a input field validator that will issue a custom message if requested. 
         * 
         * @returns A FUNCTION to validate the input */
        emptyValueGEN: (message="Please enter a value") => {
          return (value) => {
            if(!value) return message;
            return true
          }
        },
  
        isEmail: v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Please enter a valid email address",
  
        /**
         * Intended for type="number" fields. Ensures that the input is a number and isn't negative. 
         * @param {*} v 
         * @returns 
         */
        noNegativeNumbers: (v) =>{
          // we ignore no input which is covered by the default required rule (undefined or empty string)
          return v === undefined || v === "" || parseFloat(v) >= 0 || `Only positive numbers are allowed.`
        },
  
        positiveIntegers: (v) => {
        // we ignore no input which is covered by the default required rule (undefined or empty string)
          if(v === undefined || v === ""){
            return true;
          }
  
          const parsedNum = parseFloat(v);
          return (Number.isInteger(parsedNum) && parsedNum > 0) || `Only positive integers are allowed.`;
        },
  
        /**
         * Works on multiple file and single file inputs. Ensures they are collectively smaller than 10mb. 
         * @param {*} v 
         * @returns 
         */
        fileSizeLimit10mb: function(v){
          const mb10 = 10 * 1024 * 1024;
          if(v instanceof File) {
            return mb10 > v.size || 'File must be smaller than 10mb.'
  
          } else if (v instanceof Array) {
            var totalFileSize = 0;
            v.forEach((file) => { totalFileSize += file.size});
            return mb10 > totalFileSize || 'Files must be collectively smaller than 10mb.'
          }
  
          return "This isn't a file input element..."
        }
      }
    }
  },
}

export default InputRules;