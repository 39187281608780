import Route from "./_route";

class MomentToMoment extends Route {

	chartRead(mediaKey) {
		// Crashes the server if you don't specify the mediaKey or it's negative
		if (mediaKey < 0 || mediaKey === null || mediaKey === undefined) return null;

		// Return a promise to the chart data
		return this.axios.get(`/MomentToMoment/MomentToMomentChartRead?mediaKey=${mediaKey}`);
  }
  
}

export default MomentToMoment;
