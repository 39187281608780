<template>
  <div class="youtube-video__container">
    <youtube
      class="youtube-video__player"
      id="youtube-video__player"
      :video-id="videoID"
      :playerVars="options"
      ref="youtube" width="100%"
      @playing="playing"
      @paused="clearTimeInterval"
      @ended="clearTimeInterval"
    ></youtube>
    <x-video-controls
      :play="play"
      :pause="pause"
      :video-duration="videoDuration"
      :current-video-time="currentVideoTime"
      :skip-time="skipTime"
      :show-annotation-popup="showAnnotationPopup"
      :download-video="downloadVideo"
      :isYoutube="true"
    />
  </div>
</template>

<script>
import {Events} from '@/events/events.js';
import XVideoControls from "@/components/projects/reports/video-player/x-video-controls";

const XYouTubePlayer = {
  components: {
    XVideoControls
  },
  props: {
    videoID: String,
    startTime: String,
    endTime: String,
    height: String,
    updateCurrentVideoTime: Function,
    updatedVideoTime: Number,
    showAnnotationPopup: Function,
    downloadScreenshot: Function,
    downloadVideo: Function,
  },
  data() {
    return {
      currentVideoTime: 0,
      videoDuration: null,
      youtubeURL: '',
      options: {
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
      },

      intervalId: null
    }
  },
  mounted() {
    this.initializeVideo()
  },
  beforeDestroy() {
    this.clearTimeInterval()
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    },
  },
  watch: {
    currentVideoTime(val) {
      this.updateCurrentVideoTime(Math.round(val))
    },
    updatedVideoTime(val) {
      if (val !== null) {
        this.currentVideoTime = val
        this.skipTime(val)
      }
    }
  },
  methods: {
    async play() {
      await this.player.playVideo()
    },

    async pause() {
      await this.player.pauseVideo();
    },

    initializeVideo() {
      this.getVideoDuration()
      this.pause()
    },

    playing(e) {
      this.startTimeInterval()
    },

    startTimeInterval() {
      this.intervalId = setInterval(this.getCurrentTime, 1000)
    },

    clearTimeInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    },

    async getVideoDuration() {
      this.videoDuration = await this.player.getDuration();
    },

    async getCurrentTime() {
      this.currentVideoTime = await this.player.getCurrentTime();
    },
    async skipTime(time) {
      this.clearTimeInterval()
      await this.player.seekTo(time)
    }
  }
};

export default XYouTubePlayer;
</script>

<style lang="scss">
  .youtube-video__container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .youtube-video__container iframe {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  }

</style>
