/*
 * This is an event bus
 * Allowing for event communicate between unrelated parts of the application.
 */

import Vue from "vue";

// This creates an separate instance of vue that is decoupled from the DOM that will only contain instance methods
// We can import this when we want to send and receive events
const Events = new Vue();
export default Events;

/*
 * Example:
 * import Events from '@/events/events.js';
 *
 * Sending events:
 * Events.$emit(event: string, payload1: any, ...));
 *
 * Receiving events :
 * Events.$on(event: string, callback(payload1,...)));
 *
 */
