<template>
  <v-layout class="justify-center align-center column fill-height">
    <v-card width="450px" class="my-8" color="primary" :loading="loading">
      <v-card-title class="py-6">
        <v-img
          alt="Dialsmith Logo"
          class="ma-4"
          contain
          src="/images/dialsmith-logo.svg"
          height="52"/>
      </v-card-title>

      <v-card-text style="background: white" class="pa-8">
        <x-expand-alert
          ref="errorAlert"
          type="error"
          :timeoutMs="10000"
          :dismissible="true"
        />

        <v-expand-transition>
          <v-flex xs12 mt-2>
            <v-form ref="loginForm">
              <v-text-field label="Username"
                class="mb-2"
                outlined
                required
                v-model="username"
                :rules="[InputRules.emptyValueGEN('Please enter a username')]">
              </v-text-field>

              <x-password-field label="Password"
                outlined
                required
                v-model="password"
                v-on:keyup.enter="tryLogin"
                :rules="[InputRules.emptyValueGEN('Please enter a password')]">
              </x-password-field>

              <v-checkbox label="Remember me"
                v-model="rememberMe"
                :hide-details="true"
                class="mt-0 mb-6">
              </v-checkbox>

              <v-btn
                color="secondary"
                style="width: 100%"
                class="mb-4"
                @click="tryLogin"
                :loading="loading">Login</v-btn>
            </v-form>
          </v-flex>
        </v-expand-transition>
      </v-card-text>

      <v-card-actions style="background-color: #f1f3f5">
        <v-layout justify-center column align-center>
          <v-flex xs12 text-xs-right>
            
            <v-btn text small @click="openResetPasswordMenu">
              Forgot password?</v-btn>

          </v-flex>
        </v-layout>
      </v-card-actions>

      <RequestPasswordReset
        :isHidden="forgotPasswordHidden"
        :closeMenu="closeResetPasswordMenu">
      </RequestPasswordReset>
    </v-card>


    <x-expand-container>
      <legal-notices class="mb-1"></legal-notices>

      <small class="d-block grey--text text-center fade-trans" :style="`opacity: ${version ? 1 : 0}`">
        Ver: {{ version }}<br />{{ compileDate }}
      </small>
    </x-expand-container>
  </v-layout>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";

  import Api from "@/api/api";

  import InputRules from "../../utils/inputFieldRules";

  import RequestPasswordReset from "./RequestPasswordReset.vue";
  import XPasswordField from "@/components/_generics/x-password-field.vue";
  import XExpandAlert from "@/components/_generics/x-expand-alert.vue";

  import XExpandContainer from "@/components/_generics/x-expand-container.vue";
  import LegalNotices from '@/components/AppLegalNotices.vue';

  import dayjs from "dayjs";

  const Login = {

    components: {
      RequestPasswordReset,
      XPasswordField,
      XExpandAlert,
      XExpandContainer, 
      LegalNotices,
    },
    mixins: [InputRules],

    data() {
      return {
        loading: false,
        forgotPasswordHidden: true,
        rememberMe: false,
        errorMessage: null,

        username: null,
        password: null,
        version: null,
        compileDate: null,
        returnUrl: null,
      };
    },

    activated(){
      if (this.$route.params?.returnUrl) {
        this.returnUrl = this.$route.params?.returnUrl;
      }
    },

    mounted() {
      Api.Profile.getLogin().then(res => {
        console.log("login Response: ", res.data);
        if (res.data.userName != null) {
          this.username = res.data.userName;
          this.rememberMe = true;
        } 
      });

      Api.Authentication.getVersion().then((version) => {
        var compileDate = dayjs(version.data.compileDate);
        if(compileDate.isValid()) compileDate = compileDate.format('YYYY-MM-DD');
        else compileDate = version.data.compileDate;

        this.version = version.data.productVersion;
        this.compileDate = compileDate;
      });
      
      if (this.$route.params?.message) {
        this.setAlertMessage(this.$route.params.message, this.$route.params.messageType ? this.$route.params.messageType : 'error')
        this.$route.params.message = null;
        this.$route.params.messageType = null;
      }
    },

    methods: {
      ...mapMutations("AuthStore", ["setCurrentUser"]),

      openResetPasswordMenu() {
        this.forgotPasswordHidden = false;
      },

      closeResetPasswordMenu() {
        this.forgotPasswordHidden = true;
      },

      setAlertMessage(message, theme="error") {
        this.errorMessage = message;
        this.$refs.errorAlert.setMessage(message, theme);
      },

      async tryLogin() {
        if (!this.$refs.loginForm.validate()) {
          return;
        }

        this.loading = true;
        try {
          const response = await Api.Authentication.login(
            this.username,
            this.password,
            this.rememberMe,
            this.$route.params.returnUrl 
          );

          if (response && response.status === 200) {
            // getting the user object so we can save it to the application state
            const userResponse = await Api.Authentication.getUser();
            if (userResponse.data.userKey) {
              this.setCurrentUser(userResponse.data);
            } else {
              // if the user key isn't set, something has gone horribly wrong...
              this.setAlertMessage("User is malformed. Unable to sign in.");
              this.loading = false;
              return;
            }

            // now seeing if we have somewhere to redirect back to
            if (response.data.returnUrl) {
              // the default page is the projects
              if (response.data.returnUrl === "Index") {
                // we return to the local return URL if possible, otherwise we go to the projects page by default
                this.$router.push(this.returnUrl ? this.returnUrl : "/projects");
              } else {
                this.$router.push(response.data.returnUrl);
              }
            } else {
              this.$router.push(this.returnUrl ? this.returnUrl : "/projects");
            }

          } else {
            // error model adds a second errors object within the object
            if (
              response.data.errors &&
              response.data.errors.$values instanceof Array &&
              response.data.errors.$values.length > 0
            ) {
              const errors = response.data.errors.$values;
              this.setAlertMessage(errors[0].message);
            } else {
              this.setAlertMessage("Unable to sign in.");
            }
          }
        } catch (e) {
          console.error(e);
          this.setAlertMessage(
            "Uncaught error while trying to communicate with the server."
          );
        }

        this.loading = false;
      },
    },
  };

  export default Login;
</script>

<style lang="scss" scoped>
  .login-container {
  }

  .fade-trans {
    transition: opacity 0.3s ease-in-out;
  }

  .login-links a {
    color: var(--v-secondary-base);
    text-decoration: none;
  }

  .login-links a:hover {
    text-decoration: underline;
  }
</style>
