import Api from '@/api/api'

// adding error catch in case the profile get's corrupted
var currentProject = null;
try {
    currentProject = JSON.parse(localStorage.getItem("currentProject"));
} catch (err) {
    console.log('Project profile corrupt, wiping...');
    currentProject = null;
    localStorage.removeItem('currentProject');
}

const initialState = {
    currentProject
}

const ProjectStore = {
    namespaced: true,

    state: {
        currentProject,
        currentProjectInfo: {},
    },

    getters: {
        /**
         * Import this as a computed property! Gives access to the currentProject.
         *  The currentProject is refreshed every page load, and ideally when the window is unfocused and then refocused.
         * @param {*} state
         * @returns
         */
        currentProject: (state) => {
            return state.currentProject;
        },
        currentProjectInfo: (state) => {
            return state.currentProjectInfo
        }

    },
    // always asyncronous
    actions: {
        logout() {
            //console.log('logged out user from store')

            localStorage.removeItem('currentProject');
            return Api.Projectentication.logout();
        }
    },
    mutations: {
        /**
         * Sets the local storage and the state to the given user.
         * @param {initialState} state
         * @param {*} newProject
          */
        setCurrentProject(state, newProject) {
            console.log('set current project from store')
            localStorage.setItem('currentProject', JSON.stringify(newProject));
            state.currentProject = newProject;
        },
        /**
         * Sets the local storage and the state to the given user.
         * @param {initialState} state
         * @param {*} projectInfo
          */
        setCurrentProjectInfo(state, projectInfo) {
            state.currentProjectInfo = projectInfo;
        },
    }
};



export default ProjectStore;
