import Vue from "vue";
import VueRouter from "vue-router";

import Projects from "@/views/projects/Projects.vue";
import ProjectsDashboard from "@/views/projects/ProjectsDashboard.vue";
import CreateProject from "@/views/projects/project/CreateProject.vue";

import Project from "@/views/projects/project/Project.vue";
import Settings from "@/views/projects/project/Settings.vue";
import ProjectRespondents from "@/views/projects/project/Respondents.vue";
import ProjectSubsets from "@/views/projects/project/Subsets.vue";
import ProjectUsers from "@/views/projects/project/Users.vue";

import Media from "@/views/projects/project/media/Media.vue";
import MediaList from "@/views/projects/project/media/MediaList.vue";
import MediaDetail from "@/views/projects/project/media/MediaDetail.vue";
import MediaQuestions from "@/views/projects/project/media/MediaQuestions.vue";

import Reports from "@/views/projects/project/reports/Reports.vue";
import ReportsDataView from "@/views/projects/project/reports/ReportsDataView";
import ReportsVisualView from "@/views/projects/project/reports/ReportsVisualView.vue";

import Admin from "@/views/admin/Admin.vue";
import AdminOrganizations from "@/views/admin/AdminOrganizations.vue";
import AdminRoles from "@/views/admin/AdminRoles.vue";
import AdminSettings from "@/views/admin/AdminSettings.vue";
import AdminUsers from "@/views/admin/AdminUsers.vue";

import Login from "@/views/account/Login.vue";
import Profile from "@/views/account/Profile.vue";
import ResetPassword from "@/views/account/ResetPassword.vue";
import Registration from "@/views/account/Registration.vue";

import NotFound from "@/views/NotFound.vue";

const SITE_FRIENDLY_NAME = "Dialsmith";

Vue.use(VueRouter);

const routes = [
  // ACCOUNT PAGES
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      hideNavbar: true,
      noAuthRequired: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/reset-password",
    name: "Password Reset",
    component: ResetPassword,
    meta: {
      hideNavbar: true,
    },
  },
  // DIAL-116
  {
    path: "/registration",
    name: "Registration Completion",
    component: Registration,
    meta: {
      hideNavbar: true,
      noAuthRequired: true,
    },
  },

  // PROJECT PAGES
  {
    path: "/projects-old",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectsDashboard,
  },
  {
    path: "/projects/:customerId/create",
    name: "Create Project",
    component: CreateProject,
  },
  {
    path: "/projects/:projectId",
    name: "Project",
    redirect: "/projects/:projectId/settings",
    component: Project,
    children: [
      {
        path: "respondents",
        name: "Project Respondents",
        component: ProjectRespondents,
      },
      {
        path: "settings",
        name: "Project Settings",
        component: Settings,
      },
      {
        path: "subsets",
        name: "Project Subsets",
        component: ProjectSubsets,
      },
      {
        path: "users",
        name: "Project Users",
        component: ProjectUsers,
      },
      {
        path: "media-list",
        name: "Media List",
        component: MediaList,
      },
      {
        path: "media/:mediaID?",
        name: "Media",
        component: Media,
        redirect: "/projects/:projectId/media/:mediaID?/settings",
        children: [
          {
            path: "settings",
            name: "Media Detail",
            component: MediaDetail,
          },
          {
            path: "questions",
            name: "Media Questions",
            component: MediaQuestions,
          },
        ],
      },
      {
        path: "reports/:mediaID?",
        name: "Project Reports",
        component: Reports,
        children: [
          {
            path: "data",
            name: "Data Report",
            component: ReportsDataView,
          },
          {
            path: "visual",
            name: "Visual Report",
            component: ReportsVisualView,
          },
        ],
      },
    ],
  },

  // ADMIN DASHBOARD
  {
    path: "/admin",
    name: "Admin Dashboard",
    redirect: "/admin/users",
    component: Admin,
    children: [
      {
        name: "Admin Organizations",
        path: "organizations",
        component: AdminOrganizations,
      },
      {
        name: "Admin Settings",
        path: "settings",
        component: AdminSettings,
      },
      {
        name: "Admin Roles",
        path: "roles",
        component: AdminRoles,
      },
      {
        name: "Admin Users",
        path: "users",
        component: AdminUsers,
      },
    ],
  },

  // PUBLIC FACING DIAL TESTING PAGES
  //TODO: CHANGE PROJECT ID TO TOKEN, USERS CAN TRIVIALLY INCREMENT THESE AND FIND EVERY OPEN DIAL TEST
  {
    path: "/Project/:projectId/View",
    name: "Dial Test",
    component: () => import("@/views/dial-test/DialTest.vue"),
    meta: {
      hideNavbar: true,
      noAuthRequired: true,
    },
  },
  {
    path: "/Project/:projectId/ViewLanding",
    name: "Dial Test Landing",
    component: () => import("@/views/dial-test/DialTestLanding.vue"),
    meta: {
      hideNavbar: true,
      noAuthRequired: true,
    },
  },

  // Test Page
  // {
  //   path: '/test',
  //   component: () => import('@/views/Test.vue'),
  // },

  // 404 + ERROR PAGES
  { path: "*", component: NotFound, name: "Page Not Found" },
];

const router = new VueRouter({
  routes,
  mode: "history", //by default, Vue Router does some weird # based routing to support improperly configured servers (and Vue pockets).
});

// snagged from here: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  // redirectNavbarRoutes(to.path);
  console.log(to);

  // if we need auth to view this page, we
  if (to.meta && !to.meta.noAuthRequired) {
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  if (to.name != null) {
    document.title = `${to.name} | ${SITE_FRIENDLY_NAME}`;
  } else {
    document.title = SITE_FRIENDLY_NAME;
  }

  next();
});

export default router;
